import { IEvaluationInitialState, TEvaluationPayloads } from '../types';

/**
 * Will update thread state
 *
 * @param {IEvaluationInitialState} state
 * @param {*} payload
 */
export const updateEvaluationState = (
    state: IEvaluationInitialState,
    payload: TEvaluationPayloads
) => ({
    ...state,
    loading: false,
    error: null,
    ...payload,
});
