import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        path: '/create/source',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(() => import('src/views/source/SourceCreateView')),
    },
    {
        exact: true,
        path: '/management/sources/:id',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(() => import('src/views/source/SourceDetailsView')),
    },
    {
        exact: true,
        path: '/management/sources/:id/edit',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(() => import('src/views/source/SourceEditView')),
    },
    {
        exact: true,
        path: '/management/sources',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(() => import('src/views/source/SourceListView')),
    },
];
