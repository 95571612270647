import {
    GET_VEHICLES,
    GET_VEHICLES_BY_MAKE,
    GET_VEHICLE,
    CREATE_VEHICLE,
    DELETE_VEHICLE,
    UPDATE_VEHICLE,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';
import { DEFAULT_VEHICLE_STATE } from './constants';

export default (state, action) => {
    switch (action.type) {
        case GET_VEHICLES_BY_MAKE:
            return {
                ...state,
                vehicles: action.payload,
                loading: false,
                error: null,
            };
        case GET_VEHICLES:
            return {
                ...state,
                vehicles: action.payload,
                loading: false,
                error: null,
                pagination: action.pagination,
                count: action?.pagination?.total || 0,
            };
        case GET_VEHICLE:
            return {
                ...state,
                vehicle: action.payload,
                loading: false,
                error: null,
            };
        case CREATE_VEHICLE:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case DELETE_VEHICLE:
            state.vehicles = state.vehicles.filter(
                (vehicle) =>
                    vehicle._id.toString() !== action.payload.toString()
            );
            return {
                ...state,
                vehicle: null,
                loading: false,
                error: null,
            };
        case UPDATE_VEHICLE:
            return {
                ...state,
                vehicle: action.payload,
                loading: false,
                error: null,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CLEAR_STATE:
            return DEFAULT_VEHICLE_STATE;
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
};
