import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
    Button,
    Checkbox,
    Collapse,
    ListItem,
    Typography,
    makeStyles,
    useTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useTranslation } from 'react-i18next';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarBorder';
import {
    Users,
    Send as WhatsAppIcon,
    Phone as CallIcon,
    PieChart as PieChartIcon,
    Database as DatabaseIcon,
    Feather as VsIcon,
    Smile as RoleIcon,
    Home,
    Mail as TemplateIcon,
    Cloud as TemperaturesIcon,
    Airplay as SourceIcon,
    Aperture as MakeIcon,
    Home as StoreIcon,
    Truck as VehicleIcon,
    User as UserIcon,
    Calendar as CalendarIcon,
    DollarSign as SoldChartIcon,
    Globe as AllChartIcon,
    List as ListIcon,
    Clock as HourIcon,
    File as DocumentIcon,
    CloudDrizzle as SettingIcon,
    MessageCircle as WhatsappIcon,
    Tv as CampaignIcon,
    UploadCloud as LoadIcon,
    Clock as TaskIcon,
    Mic as RecordingIcon,
    Globe as CompanyIcon,
    FileText as ClosureIcon,
    Award as MainIcon,
    FileText as ReportIcon,
    Coffee as ControlIcon,
    Package as PackageIcon,
    PlusCircle,
    XCircle,
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'block',
        paddingTop: 0,
        paddingBottom: 0,
    },
    redIcon: {
        color: theme.palette.error.main,
    },
    itemLeaf: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    button: {
        color: theme.palette.text.secondary,
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
    },
    buttonLeaf: {
        color: theme.palette.text.secondary,
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightRegular,
        '&.depth-0': {
            '& $title': {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
    },
    title: {
        marginRight: 'auto',
    },
    active: {
        color: theme.palette.secondary.main,
        '& $title': {
            fontWeight: theme.typography.fontWeightMedium,
        },
        '& $icon': {
            color: theme.palette.secondary.main,
        },
    },
}));

const NavItem = ({
    children,
    className,
    depth = 0,
    href,
    icon: Icon,
    info: Info,
    open: openProp = false,
    isFavoriteActive,
    favoriteRoutes,
    setFavoritesRoutes,
    title,
    ...rest
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(openProp);
    const { t } = useTranslation();
    const theme = useTheme();

    const styleStart = {
        color: theme.palette.warning.main,
        width: 25,
        height: 25,
    };

    const icons = {
        MainIcon: <MainIcon className={classes.icon} size="20" />,
        ReportIcon: <ReportIcon className={classes.icon} size="20" />,
        ControlIcon: <ControlIcon className={classes.icon} size="20" />,
        PlusCircle: <PlusCircle className={classes.icon} size="20" />,
        Users: <Users className={classes.icon} size="20" />,
        WhatsAppIcon: <WhatsAppIcon className={classes.icon} size="20" />,
        CallIcon: <CallIcon className={classes.icon} size="20" />,
        PieChartIcon: <PieChartIcon className={classes.icon} size="20" />,
        DatabaseIcon: <DatabaseIcon className={classes.icon} size="20" />,
        VsIcon: <VsIcon className={classes.icon} size="20" />,
        RoleIcon: <RoleIcon className={classes.icon} size="20" />,
        Home: <Home className={classes.icon} size="20" />,
        TemplateIcon: <TemplateIcon className={classes.icon} size="20" />,
        TemperaturesIcon: (
            <TemperaturesIcon className={classes.icon} size="20" />
        ),
        SourceIcon: <SourceIcon className={classes.icon} size="20" />,
        MakeIcon: <MakeIcon className={classes.icon} size="20" />,
        StoreIcon: <StoreIcon className={classes.icon} size="20" />,
        VehicleIcon: <VehicleIcon className={classes.icon} size="20" />,
        UserIcon: <UserIcon className={classes.icon} size="20" />,
        CalendarIcon: <CalendarIcon className={classes.icon} size="20" />,
        SoldChartIcon: <SoldChartIcon className={classes.icon} size="20" />,
        AllChartIcon: <AllChartIcon className={classes.icon} size="20" />,
        ListIcon: <ListIcon className={classes.icon} size="20" />,
        HourIcon: <HourIcon className={classes.icon} size="20" />,
        DocumentIcon: <DocumentIcon className={classes.icon} size="20" />,
        SettingIcon: <SettingIcon className={classes.icon} size="20" />,
        WhatsappIcon: <WhatsappIcon className={classes.icon} size="20" />,
        CampaignIcon: <CampaignIcon className={classes.icon} size="20" />,
        LoadIcon: <LoadIcon className={classes.icon} size="20" />,
        TaskIcon: <TaskIcon className={classes.icon} size="20" />,
        RecordingIcon: <RecordingIcon className={classes.icon} size="20" />,
        CompanyIcon: <CompanyIcon className={classes.icon} size="20" />,
        ClosureIcon: <ClosureIcon className={classes.icon} size="20" />,
        XCircle: <XCircle className={classes.icon} size="20" />,
        PackageIcon: <PackageIcon className={classes.iconList} size="20" />,
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    let paddingLeft = 8;

    if (depth > 0) {
        paddingLeft = 32 + 8 * depth;
    }

    const handleSetFavorite = (route) => {
        let aux = [...favoriteRoutes];
        if (aux.includes(route)) aux = aux.filter((el) => el !== route);
        else aux = [...aux, route];
        setFavoritesRoutes(aux);
    };

    const style = { paddingLeft };

    if (children) {
        return (
            <ListItem
                className={clsx(classes.item, className)}
                disableGutters
                key={title + Math.random()}
                {...rest}
            >
                <Button
                    className={classes.button}
                    onClick={handleToggle}
                    style={style}
                >
                    {Icon && icons[Icon]}
                    <span className={classes.title}>{t(title)}</span>
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
                <Collapse in={open}>{children && children}</Collapse>
            </ListItem>
        );
    }

    return (
        <ListItem
            className={clsx(classes.itemLeaf, className)}
            disableGutters
            key={title + Math.random()}
            {...rest}
        >
            {isFavoriteActive ? (
                <div
                    activeClassName={classes.active}
                    className={clsx(classes.buttonLeaf, `depth-${depth}`)}
                    style={{
                        ...style,
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: 8,
                        paddingTop: 14,
                        paddingBottom: 12.5,
                    }}
                >
                    {Icon && icons[Icon]}
                    <Typography variant="h5" className={classes.title}>
                        {t(title)}
                    </Typography>
                    {Info && <Info />}
                    <Checkbox
                        style={{ height: 10 }}
                        icon={<StarOutlineIcon style={styleStart} />}
                        checkedIcon={<StarIcon style={styleStart} />}
                        onClick={() => handleSetFavorite(href)}
                        checked={favoriteRoutes.includes(href)}
                    />
                </div>
            ) : (
                <Button
                    activeClassName={classes.active}
                    className={clsx(classes.buttonLeaf, `depth-${depth}`)}
                    component={RouterLink}
                    exact
                    style={style}
                    to={href}
                >
                    {Icon && icons[Icon]}
                    <span className={classes.title}>{t(title)}</span>
                    {Info && <Info />}
                    {favoriteRoutes.includes(href) && (
                        <StarIcon style={styleStart} />
                    )}
                </Button>
            )}
        </ListItem>
    );
};

export default NavItem;
