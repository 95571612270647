import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import {
    Avatar,
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Popover,
    SvgIcon,
    Tooltip,
    Typography,
    makeStyles,
} from '@material-ui/core';
import {
    HelpCircle as TicketIcon,
    AlertCircle as UrgentIcon,
    Circle as NormalIcon,
    MinusCircle as LowIcon,
} from 'react-feather';
import moment from 'moment';
import useAuth from 'src/hooks/useAuth';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { isRockstar } from 'src/utils/AuthRoles';
import useTicket from 'src/hooks/useTicket';
import { CapitalizeNames } from 'src/utils/capitalize';

const useStyles = makeStyles((theme) => ({
    popover: {
        width: 320,
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    urgent: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.secondary.contrastText,
    },
    normal: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.secondary.contrastText,
    },
    low: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.secondary.contrastText,
    },
}));

const Tickets = ({ ...rest }) => {
    const classes = useStyles();
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();
    const { user } = useAuth();
    const { notificationTickets, getTicketsRockstar } = useTicket();

    useEffect(() => {
        if (user && user.tier && isRockstar(user.tier._id))
            getTicketsRockstar(false);
        //eslint-disable-next-line
    }, [user]);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div {...rest}>
            <Tooltip title="Tickets">
                <IconButton color="inherit" ref={ref} onClick={handleOpen}>
                    <SvgIcon fontSize="small">
                        <TicketIcon />
                    </SvgIcon>
                </IconButton>
            </Tooltip>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{ paper: classes.popover }}
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
            >
                <Box p={2}>
                    <Typography variant="h5" color="textPrimary">
                        {t('Tickets.Tickets')}
                    </Typography>
                </Box>

                {notificationTickets && notificationTickets.length === 0 ? (
                    <>
                        <Box p={2}>
                            <Typography variant="h6" color="textPrimary">
                                {t('Tickets.NoTickets')}
                            </Typography>
                        </Box>
                        <Box p={1} display="flex" justifyContent="center">
                            <Button
                                component={RouterLink}
                                size="small"
                                fullWidth
                                to="/management/tickets"
                            >
                                {t('Tickets.SeeMore')}
                            </Button>
                        </Box>
                    </>
                ) : (
                    <>
                        <List disablePadding>
                            {notificationTickets &&
                                notificationTickets.map((ticket) => {
                                    return (
                                        <ListItem
                                            component={RouterLink}
                                            divider
                                            key={ticket._id}
                                            to="#"
                                            onClick={(e) => {
                                                history.push(
                                                    `/management/tickets/${ticket._id}`
                                                );
                                            }}
                                        >
                                            <ListItemAvatar>
                                                <Avatar
                                                    className={clsx({
                                                        [classes.icon]: true,
                                                        [classes.low]:
                                                            ticket &&
                                                            ticket.priority ===
                                                                'low',
                                                        [classes.urget]:
                                                            ticket &&
                                                            ticket.priority ===
                                                                'urgent',
                                                        [classes.normal]:
                                                            ticket &&
                                                            ticket.priority ===
                                                                'normal',
                                                    })}
                                                >
                                                    <SvgIcon fontSize="small">
                                                        {ticket &&
                                                            ticket.priority ===
                                                                'urgent' && (
                                                                <UrgentIcon />
                                                            )}
                                                        {ticket &&
                                                            ticket.priority ===
                                                                'low' && (
                                                                <LowIcon />
                                                            )}
                                                        {ticket &&
                                                            ticket.priority ===
                                                                'normal' && (
                                                                <NormalIcon />
                                                            )}
                                                    </SvgIcon>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    CapitalizeNames(
                                                        ticket?.user?.name ||
                                                            t('Users.User')
                                                    ) +
                                                    ' ' +
                                                    t('Messages.SendYouTicket')
                                                }
                                                primaryTypographyProps={{
                                                    variant: 'subtitle2',
                                                    color: 'textPrimary',
                                                }}
                                                secondary={moment(
                                                    ticket.createdAt
                                                ).fromNow()}
                                            />
                                        </ListItem>
                                    );
                                })}
                        </List>
                        <Box p={1} display="flex" justifyContent="center">
                            <Button
                                component={RouterLink}
                                size="small"
                                to="/management/tickets"
                            >
                                {t('Tickets.SeeMore')}
                            </Button>
                        </Box>
                    </>
                )}
            </Popover>
        </div>
    );
};

export default Tickets;
