import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const ChatbotIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M21.928 11.607c-.202-.488-.635-.605-.928-.633V8c0-1.103-.897-2-2-2h-6V4.61c.305-.274.5-.668.5-1.11a1.5 1.5 0 0 0-3 0c0 .442.195.836.5 1.11V6H5c-1.103 0-2 .897-2 2v2.997l-.082.006A1 1 0 0 0 1.99 12v2a1 1 0 0 0 1 1H3v5c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-5a1 1 0 0 0 1-1v-1.938a1.006 1.006 0 0 0-.072-.455zM5 20V8h14l.001 3.996L19 12v2l.001.005.001 5.995H5z" />
            <ellipse cx="8.5" cy="12" rx="1.5" ry="2" />
            <ellipse cx="15.5" cy="12" rx="1.5" ry="2" />
            <path d="M8 16h8v2H8z" />
        </SvgIcon>
    );
};
