import { SET_LOADING, CLEAR_STATE, SET_ERROR, SET_MEDIAS } from '../types';

const Reducer = (state, action) => {
    switch (action.type) {
        case SET_MEDIAS:
            return {
                ...state,
                ...action.payload,
                loading: false,
                error: null,
            };
        case SET_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        case CLEAR_STATE:
            return {
                media: null,
                medias: [],
                loading: false,
                error: null,
            };
        default:
            return state;
    }
};

export default Reducer;
