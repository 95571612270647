import { AxiosError } from 'axios';
import { ISetError, SET_ERROR } from '../types';
import { UNKNOWN_ERROR_MESSAGE } from './constants';
import { isAxiosError } from './isAxiosError';
/**
 * Will handle state's errors in order to be from axios or an
 * unknown error
 *
 * @param {*} error
 * @return {*}  {ISetError}
 */
export const handleAxiosError = (error: any): ISetError => {
    if (isAxiosError(error)) {
        return { type: SET_ERROR, payload: error.response?.data };
    } else {
        return { type: SET_ERROR, payload: UNKNOWN_ERROR_MESSAGE };
    }
};
