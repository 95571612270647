import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        path: '/create/financialInstitution',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(
            () =>
                import(
                    'src/views/financialInstitution/FinancialInstitutionCreateView'
                )
        ),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        path: '/management/financialInstitutions/:id',
        component: lazy(
            () =>
                import(
                    'src/views/financialInstitution/FinancialInstitutionDetailsView'
                )
        ),
    },
    {
        exact: true,
        path: '/management/financialInstitutions/:id/edit',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(
            () =>
                import(
                    'src/views/financialInstitution/FinancialInstitutionEditView'
                )
        ),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        path: '/management/financialInstitutions',
        component: lazy(
            () =>
                import(
                    'src/views/financialInstitution/FinancialInstitutionListView'
                )
        ),
    },
];
