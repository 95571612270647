import {
    GET_GROUPS,
    CREATE_GROUP,
    GET_GROUP,
    DELETE_GROUP,
    UPDATE_GROUP,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';
import _ from 'lodash';

export default (state, action) => {
    switch (action.type) {
        case GET_GROUPS:
            return {
                ...state,
                groups: action.payload,
                count: action.count,
                loading: false,
                error: null,
            };
        case GET_GROUP:
            return {
                ...state,
                group: action.payload,
                loading: false,
                error: null,
            };
        case CREATE_GROUP:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case DELETE_GROUP:
            if (state.groups) {
                state.groups = state.groups.filter(
                    (group) =>
                        group._id.toString() !== action.payload.toString()
                );
            }
            return {
                ...state,
                group: null,
                loading: false,
                error: null,
            };
        case UPDATE_GROUP:
            if (state && state.groups) {
                const indice = _.findIndex(state.groups, function (o) {
                    return o._id.toString() === action.payload._id.toString();
                });

                state.groups[indice] = action.payload;
            }
            return {
                ...state,
                group: action.payload,
                loading: false,
                error: null,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CLEAR_STATE:
            return {
                loading: false,
                error: null,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
};
