import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        path: '/create/role',
        guard: RolesGuard,
        roles: ['rockstar'],
        component: lazy(() => import('src/views/role/RoleCreateView')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar'],
        path: '/management/roles/:id',
        component: lazy(() => import('src/views/role/RoleDetailsView')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar'],
        path: '/management/roles/:id/edit',
        component: lazy(() => import('src/views/role/RoleEditView')),
    },
    {
        exact: true,
        path: '/management/roles',
        guard: RolesGuard,
        roles: ['rockstar'],
        component: lazy(() => import('src/views/role/RoleListView')),
    },
];
