type TEmbeddedDocument = string | { _id: string } | undefined;

/**
 * Retrieves the ID from an embedded document.
 *
 * This function accepts an embedded document, which can either be a string (representing the document ID)
 * or an object containing an `_id` field. It returns the ID as a string.
 *
 * @param {TEmbeddedDocument} param - The embedded document, which can be either a string,undefined or an object with an `_id` field.
 * @returns {string | undefined} The ID of the document, or `undefined` if the document is not a valid embedded document.
 *
 * @example
 * // Example usage with a string
 * const docId = getDocumentId({ document: "abc123" });
 * console.log(docId); // Output: "abc123"
 */
export const getDocumentId = (document: TEmbeddedDocument) => {
    if (typeof document === 'object') return document?._id;
    return document;
};
