import React from 'react';
import { SnackbarProvider } from 'notistack';
import { IconButton } from '@material-ui/core';
import { ExternalLink } from 'react-feather';

export const NotistackProvider = ({ children }) => {
    const notistackRef = React.createRef();
    const onClickDismiss = (key) => () =>
        notistackRef.current.closeSnackbar(key);

    return (
        <SnackbarProvider
            dense
            maxSnack={3}
            ref={notistackRef}
            action={(key) => (
                <IconButton onClick={onClickDismiss(key)}>
                    <ExternalLink />
                </IconButton>
            )}
        >
            {children}
        </SnackbarProvider>
    );
};
