import React, { useReducer } from 'react';
import ConversationsContext from './dpxConversationsContext';
import ConversationsReducer from './dpxConversationsReducer';
import api from '../../api/api';

import {
    GET_CONVERSATIONS,
    CLEAR_STATE,
    CLEAR_MESSAGES,
    SET_ERROR,
    SEND_MESSAGE,
    CLEAR_ERROR,
    GET_CONVERSATION,
    MESSAGE_SOCKET,
    SET_FINAL,
    CONVERSATION_SOCKET,
    DELETE_CONVERSATION,
    CREATE_LEAD,
    UPDATE_CONVERSATION,
    CREATE_BDC,
    CREATE_SERVICE,
    CLEAR_CONVERSATION,
    SET_LOADING,
} from '../types';
import { HEADERS } from '../../constants/headers';
import { channel } from 'src/service/pusher';
import { useTranslation } from 'react-i18next';
import { AWS_S3 } from 'src/constants/crm/aws';
import axios from 'axios';

const ConversationsState = (props) => {
    const initialState = {
        conversation: {},
        conversations: [],
        chart: [],
        pagination: {},
        lastItems: null,
        messages: [],
        loading: false,
        error: null,
    };

    const { t } = useTranslation();

    const [state, dispatch] = useReducer(ConversationsReducer, initialState);

    const updateConversation = async (values, conversationId) => {
        try {
            const res = await api.put(
                `/dpxConversations/${conversationId}`,
                { ...values },
                HEADERS()
            );
            dispatch({
                type: UPDATE_CONVERSATION,
                payload: res.data.data,
                successMessage: t('SnackBar.ConversationUpdated'),
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const createService = async (values) => {
        try {
            const res = await api.post(
                '/dpxConversations/service',
                { ...values },
                HEADERS()
            );
            dispatch({ type: CREATE_SERVICE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const createBDC = async (values) => {
        try {
            const res = await api.post(
                '/dpxConversations/bdc',
                { ...values },
                HEADERS()
            );
            dispatch({ type: CREATE_BDC, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const createLead = async (values) => {
        try {
            const res = await api.post(
                '/dpxConversations/leads',
                { ...values },
                HEADERS()
            );

            dispatch({
                type: CREATE_LEAD,
                payload: res.data.data,
                successMessage: t('SnackBar.LeadCreated'),
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const deleteConversation = async (conversationId) => {
        try {
            const res = await api.delete(
                `/dpxConversations/${conversationId}`,
                HEADERS()
            );
            dispatch({ type: DELETE_CONVERSATION, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const sendTemplate = async (values) => {
        await api.post(`/dpxConversations/template`, { ...values }, HEADERS());
    };

    const getConversations = async (values, reload) => {
        try {
            setLoading();
            const res = await api.post(
                `/dpxConversations`,
                { ...values },
                HEADERS()
            );
            dispatch({
                type: GET_CONVERSATIONS,
                payload: res.data.data,
                pagination: res.data.pagination,
                reload,
                final: res.data.final,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getConversation = async (conversationId, lastConversationId) => {
        try {
            setLoading();
            await channel.unbind(`conversation_${lastConversationId}`);

            const res = await api.get(
                `/dpxConversations/${conversationId}?isUpdate=true`,
                HEADERS()
            );

            await channel.bind(`conversation_${conversationId}`, (data) => {
                addMessageSocket(data.message, data.conversation);
            });

            dispatch({ type: GET_CONVERSATION, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const addMessageSocket = async (
        message,
        conversation,
        updateConversation = true
    ) => {
        try {
            let messageData = message;
            if (!messageData?._id) {
                let res = await api.get(`/messages/${message}`, HEADERS());
                messageData = res.data.data;
            }
            dispatch({
                type: MESSAGE_SOCKET,
                payload: messageData,
                conversation,
                updateConversation,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const addConversationSocket = async (conversation) => {
        let conversationData = conversation;
        if (!conversationData?._id) {
            const res = await api.get(
                `/dpxConversations/${conversationData}?limit=1&sort=_id,-1&isUpdate=false`,
                HEADERS()
            );
            conversationData = res.data.data;
        }
        dispatch({ type: CONVERSATION_SOCKET, payload: conversationData });
    };

    const sendMessage = async (values) => {
        try {
            if (values.files) {
                let files = [...values.files];
                delete values.files;

                for (let i = 0; i < files.length; i++) {
                    let response = await api.post(
                        '/uploads/image',
                        { type: files[i].type, fileName: files[i].name },
                        HEADERS()
                    );
                    await axios.put(response.data.url, files[i], {
                        headers: { 'Content-Type': files[i].type },
                    });

                    const res = await api.post(
                        `/dpxConversations/sendMessage`,
                        {
                            ...values,
                            MediaUrl0: `${AWS_S3}${response.data.key}`,
                            MediaContentType0: files[i].type.replace(
                                '; codecs=opus',
                                ''
                            ),
                        },
                        HEADERS()
                    );
                    dispatch({ type: SEND_MESSAGE, payload: res.data.data });
                }
            } else if (values.documents) {
                let documents = [...values.documents];
                delete values.documents;

                for (let i = 0; i < documents.length; i++) {
                    const arrayFile = documents[i].file.split('.');
                    const type = arrayFile[arrayFile.length - 1];

                    const res = await api.post(
                        `/dpxConversations/sendMessage`,
                        {
                            ...values,
                            MediaUrl0: `https://automotive-api.s3.us-east-2.amazonaws.com/${documents[i].file}`,
                            MediaContentType0: type,
                        },
                        HEADERS()
                    );
                    dispatch({ type: SEND_MESSAGE, payload: res.data.data });
                }
            } else {
                const res = await api.post(
                    `/dpxConversations/sendMessage`,
                    { ...values },
                    HEADERS()
                );
                dispatch({ type: SEND_MESSAGE, payload: res.data.data });
            }
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const clearError = () => dispatch({ type: CLEAR_ERROR });
    const clearState = () => dispatch({ type: CLEAR_STATE });
    const clearConversation = () => dispatch({ type: CLEAR_CONVERSATION });
    const clearMessages = () => dispatch({ type: CLEAR_MESSAGES });
    const setFinal = (value = false) =>
        dispatch({ type: SET_FINAL, payload: value });
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <ConversationsContext.Provider
            value={{
                conversations: state.conversations,
                pagination: state.pagination,
                loading: state.loading,
                error: state.error,
                chart: state.chart,
                conversation: state.conversation,
                lastItems: state.lastItems,
                messages: state.messages,
                final: state.final,
                addConversationSocket,
                clearError,
                getConversations,
                deleteConversation,
                createLead,
                createBDC,
                createService,
                setFinal,
                updateConversation,
                getConversation,
                clearState,
                addMessageSocket,
                sendMessage,
                clearMessages,
                sendTemplate,
                clearConversation,
            }}
        >
            {props.children}
        </ConversationsContext.Provider>
    );
};

export default ConversationsState;
