import React, { useReducer } from 'react';
import ChartContext from './chartContext';
import ChartReducer from './chartReducer';
import api from '../../api/api';
import {
    GET_ADDONS_REPORT,
    GET_STORES_SCORES_TABLE,
    GET_STORES_EXTRA_SCORES_TABLE,
    GET_CAPTACION_MEDIOS,
    GET_GLOBAL_SOURCES,
    GET_GLOBAL,
    CLEAR_DRILL_TIME,
    GET_REPORT_SALES,
    GET_MONITORING_AGENT_SOURCES,
    GET_MONITORING_AGENT,
    GET_APPOINTMENTS_BY_STORE_CHART,
    GET_VISITS_BY_STORE_CHART,
    GET_SOLDS_BY_STORE_CHART,
    GET_MONTHLY_CHART,
    GET_BUREAU_CHART,
    GET_DAILY_CHART,
    GET_MODELS_CHART,
    GET_HOURS_CHART,
    GET_AGENTS_COMPARATIVE,
    SET_ERROR,
    GET_TOTALS_DASHBOARD,
    GET_TEMPERATURES_LEADS_TABLE,
    GET_TEMPERATURES_APPOINTMENTS_TABLE,
    GET_TEMPERATURES_VISITS_TABLE,
    GET_TEMPERATURES_SALES_TABLE,
    CLEAR_STATE,
    GET_TABLE_AGENT_SOURCES,
    SET_LOADING,
    GET_LEADS_MONTHLY_CHART,
    GET_LEADS_STORE_CHART,
    GET_PROFIT_CHART,
    GET_TOTALS_FINANCING_DASHBOARD,
    GET_TOTALS_APPOINTMENTS_DASHBOARD,
    GET_TOTALS_VISITS_DASHBOARD,
    GET_PROFIT_STORE_CHART,
    GET_PROFIT_MODEL_CHART,
    GET_OPERATION_TYPE_CHART,
    GET_FINANCIAL_INSTITUTION_CHART,
    GET_INVESTMENT_PROFIT_STORE_CHART,
    GET_TABLE_AGENCIES,
    GET_TOTALS_INVESTMENT_DASHBOARD,
    GET_AGENTS_LEADS_TABLE,
    GET_AGENTS_CALLS_TABLE,
    GET_AGENTS_SOLDS_TABLE,
    GET_INVESTMENT_PROFIT_CHART,
    GET_AGENTS_VISITS_TABLE,
    GET_AGENTS_APPOINTMENTS_TABLE,
    GET_AGENTS_STATUS_CHART,
    GET_MODELS_BY_MAKE_CHART,
    GET_CONVERSATIONS_CHART,
    GET_TEMPERATURES_CHART,
    GET_MONTHLY_ADMIN_CHART,
    GET_WEEKLY_PROFIT_CHART,
    GET_WEEKLY_INVESTMENT_PROFIT_CHART,
    GET_VS_CHART,
    GET_TEMPERATURES_FUNNEL,
    GET_TEMPERATURES_FUNNEL_RSI,
    GET_TEMPERATURES_FUNNEL_RSI_COMPARATIVE,
    GET_STATUS_REPORT_CHART,
    GET_SUBSTATUS_AGENT_CHART,
    GET_VISITS_CHART,
    GET_APPOINTMENTS_CHART,
    GET_APPOINTMENTS_SUBSTATUS_CHART,
    GET_VISITS_SUBSTATUS_CHART,
    GET_VISITS_STORES_CHART,
    GET_VISITS_DETAILS_CHART,
    GET_APPOINTMENTS_STORES_CHART,
    GET_APPOINTMENTS_DETAILS_CHART,
    GET_CALLS_SUBSTATUS_CHART,
    GET_CALLS_STORES_CHART,
    GET_CALLS_MAKES_CHART,
    GET_CALLS_DETAILS_CHART,
    GET_DRILLDOWN_COMPARATIVE,
    GET_TOTALS_CALLS_DASHBOARD,
    GET_CLOSURE_TOP_USERS,
    GET_CONVERSATIONS_BY_AGENT,
    GET_CALLS_DRILLDOWN_CHART,
    GET_BUREAU_DRILLDOWN_CHART,
    GET_CONVERSATIONS_BY_AGENT_CHART,
    GET_AGENTS_CALLS_CHART,
    GET_PROFIT_SOURCE_CHART,
    GET_INVESTMENT_BY_STORE_CHART,
    GET_MESSAGES_CHART,
    GET_STATUS_STORES_CHART,
    GET_MONTHLY_COMPARATIVE_CHART,
    GET_UNIT_MONTHLY_COMPARATIVE_CHART,
    GET_INVESTMENTS_CHART,
    GET_STATUS_STORES_CHART_ACUMULATED,
    GET_TOTAL_TEMPERATURES,
    GET_FUNNEL_APPOINTMENT_CHART,
    GET_TEMPERATURES_FUNNEL_APPOINTMENT_COMPARATIVE,
    GET_FUNNEL_DATOS,
    GET_DRILLDOWN_FUNNEL_SOURCE,
    GET_HOURS_COMPARATIVE_CHART,
    GET_DRILLDOWN_TIME,
    GET_RECORDINGS_REVIEWS,
    GET_RECORDINGS_DRILLDOWN_CHART,
    GET_PENDING_TASKS,
    GET_REPORT_VISITS,
    GET_MATRIX,
    GET_MATRIX_INVESTMENT,
    GET_MATRIX_SEGMENTATION,
    GET_CREDIT_REQUEST_TABLE,
    GET_TABLE_SOURCES,
    GET_TABLEMODELS,
    GET_TABLELEADSBYAGENTS,
    GET_MATRIX_INVESTMENT_CAMPAIGN,
    GET_MATRIX_INVESTMENT_ADSET,
    GET_INVESTMENTS,
    CREATE_INVESTMENT,
    DELETE_INVESTMENT,
    GET_INVESTMENT,
    UPDATE_INVESTMENT,
    GET_DRILLDOWN_PENDING_LEADS_CHART,
    POST_CUSTOM_CHART,
    GET_AGENTS_CALLS_PERFORMANCE
} from '../types';
import { HEADERS } from '../../constants/headers';
import { STORE_AREA } from '../../constants/store/areas';
import { DEFAULT_CHART_STATE } from './constants/defaultChartState';
import { ApiV2 } from 'src/api/apiv2';
import { getDocumentId } from 'src/utils/mongo/getDocumentId';
import { FIRST_PAGE } from 'src/views/chartLeads/UserCallPerformance/constants/AccordionList';

const ChartState = (props) => {
    const [state, dispatch] = useReducer(ChartReducer, DEFAULT_CHART_STATE);

    const getAgentsCallsPerformance = async (query) => {
        query.page == FIRST_PAGE && setLoading();
        try {
            const filteredParams = Object.entries(query)
                .filter(([key, value]) => value !== null && value !== undefined)
                .reduce((acc, [key, value]) => {
                    if (key === STORE_AREA) {return { ...acc, [key]: Array.isArray(value) ? value : [value] };} else {return { ...acc, [key]: value };}
                }, {});
            const res = await api.post(`/charts/agentsCallsPerformance`, filteredParams, HEADERS());
            dispatch({ type: GET_AGENTS_CALLS_PERFORMANCE, payload: res.data.data.results, pagination: res.data.data.pagination, isLoadMore: query.page > FIRST_PAGE });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response?.data?.error || 'Error desconocido' });
        }
    };

    const getTableLeadsByAgents = async (initialQuery) => {
        setLoading();
        try {
            const res = await api.post(
                `/charts/tableLeadsByAgents`,
                initialQuery,
                HEADERS()
            );
            dispatch({ type: GET_TABLELEADSBYAGENTS, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getTableModels = async (initialQuery) => {
        setLoading();
        try {
            const res = await api.post(
                `/charts/tableModels`,
                initialQuery,
                HEADERS()
            );
            dispatch({ type: GET_TABLEMODELS, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getTableSources = async (initialQuery) => {
        setLoading();
        try {
            const res = await api.post(
                `/charts/tableSources`,
                initialQuery,
                HEADERS()
            );
            dispatch({ type: GET_TABLE_SOURCES, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getTableAgencies = async (initialQuery) => {
        setLoading();
        try {
            const res = await api.post(
                `/charts/tableAgencies`,
                initialQuery,
                HEADERS()
            );
            dispatch({ type: GET_TABLE_AGENCIES, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getPendingTasksReport = async (values) => {
        setLoading();
        try {
            const res = await api.post(
                `/charts/pendingTasksReport`,
                { ...values },
                HEADERS()
            );
            dispatch({ type: GET_PENDING_TASKS, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getMonitoringAgent = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/monitoringAgent?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_MONITORING_AGENT, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getMonitoringAgentSources = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/monitoringAgentSources?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_MONITORING_AGENT_SOURCES,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getTemperaturesLeadsTable = async (query) => {
        // setLoading();
        try {
            const res = await api.get(
                `/charts/temperaturesLeadsTable?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_TEMPERATURES_LEADS_TABLE,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getTemperaturesAppointmentsTable = async (query) => {
        // setLoading();
        try {
            const res = await api.get(
                `/charts/temperaturesAppointmentsTable?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_TEMPERATURES_APPOINTMENTS_TABLE,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getTemperaturesVisitsTable = async (query) => {
        // setLoading();
        try {
            const res = await api.get(
                `/charts/temperaturesVisitsTable?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_TEMPERATURES_VISITS_TABLE,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getTemperaturesSalesTable = async (query) => {
        // setLoading();
        try {
            const res = await api.get(
                `/charts/temperaturesSalesTable?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_TEMPERATURES_SALES_TABLE,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getConversationsByAgent = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/conversationsByAgent?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_CONVERSATIONS_BY_AGENT,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getConversationsByAgentChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/conversationsByAgentChart?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_CONVERSATIONS_BY_AGENT_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getClosureTopUsers = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/closureTopUsers?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_CLOSURE_TOP_USERS, payload: res.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Visits Report
    const getAppointmentsSubstatusChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/appointmentsSubstatusChart?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_APPOINTMENTS_SUBSTATUS_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getAppointmentsStoresChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/appointmentsStoresChart?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_APPOINTMENTS_STORES_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getAppointmentsDetailsChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/appointmentsDetailsChart?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_APPOINTMENTS_DETAILS_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Visits Report
    const getVisitsChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/visitsChart?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_VISITS_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Appointments Report
    const getAppointmentsChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/appointmentsChart?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_APPOINTMENTS_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Appointments Report
    const getHoursComparativeChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/hoursComparativeChart?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_HOURS_COMPARATIVE_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Substatus Report
    const getSubstatusAgentChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/substatusAgentChart?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_SUBSTATUS_AGENT_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Substatus Report
    const getStatusStoresChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/statusStoresChart?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_STATUS_STORES_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getStatusStoresChartAcumulated = async (query) => {
        // setLoading();

        try {
            const res = await api.get(
                `/charts/statusStoresChart?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_STATUS_STORES_CHART_ACUMULATED,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };
    //Get Status Report
    const getStatusReportChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/statusReportChart?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_STATUS_REPORT_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Temperatures Chart
    const getTemperaturesFunnel = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/temperaturesFunnel?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_TEMPERATURES_FUNNEL, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Temperatures Chart
    const getTemperaturesFunnelRSI = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/temperaturesFunnelRSI?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_TEMPERATURES_FUNNEL_RSI,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getFunnelAppointment = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/funnelAppointment?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_FUNNEL_APPOINTMENT_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getFunnelDatos = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/funnelDatos?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_FUNNEL_DATOS, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Temperatures Chart
    const getTemperaturesFunnelAppointmentComparative = async (
        query,
        position
    ) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/funnelAppointment?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_TEMPERATURES_FUNNEL_APPOINTMENT_COMPARATIVE,
                payload: res.data.data,
                position,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Temperatures Chart
    const getTemperaturesFunnelRSIComparative = async (query, position) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/temperaturesFunnelRSI?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_TEMPERATURES_FUNNEL_RSI_COMPARATIVE,
                payload: res.data.data,
                position,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };
    //Get drilldownTime Chart
    const getDrilldownTime = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/drilldownTime?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_DRILLDOWN_TIME, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const clearDrilldownTime = async () => dispatch({ type: CLEAR_DRILL_TIME });

    //Get Temperatures Chart
    const getTotalTemperatures = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/totalTemperatures?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_TOTAL_TEMPERATURES, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Vs Chart
    const getVsChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(`/charts/vsChart?${query}`, HEADERS());
            dispatch({ type: GET_VS_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Temperatures Chart
    const getMonthlyAdminChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/monthlyAdminChart?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_MONTHLY_ADMIN_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Score Chart
    const getBureauChart = async (values) => {
        setLoading();
        try {
            const res = await api.post(
                `/charts/bureauChart`,
                { ...values },
                HEADERS()
            );
            dispatch({ type: GET_BUREAU_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get comparative Chart
    const getMonthlyComparativeChart = async (query, data) => {
        setLoading();
        try {
            const res = await api.post(
                `/charts/unitComparativeChart?${query}`,
                data,
                HEADERS()
            );
            dispatch({
                type: GET_MONTHLY_COMPARATIVE_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getUnitComparativeChart = async (query, data) => {
        setLoading();
        try {
            const res = await api.post(
                `/charts/unitComparativeChart?${query}`,
                data,
                HEADERS()
            );
            dispatch({
                type: GET_UNIT_MONTHLY_COMPARATIVE_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getUnitComparativeChartGrouped = async (query, data) => {
        setLoading();
        try {
            const res = await api.post(
                `/charts/unitComparativeChartGrouped?${query}`,
                data,
                HEADERS()
            );
            dispatch({
                type: GET_UNIT_MONTHLY_COMPARATIVE_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Temperatures Chart
    const getTemperaturesChart = async (endpoint, query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/globalSumary?${query}${endpoint}`,
                HEADERS()
            );
            dispatch({ type: GET_TEMPERATURES_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Global Chart
    const getGlobal = async (query) => {
        setLoading();
        try {
            const res = await api.get(`/charts/global?${query}`, HEADERS());
            dispatch({ type: GET_GLOBAL, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get global sources Chart
    const getGlobalSources = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/globalSources?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_GLOBAL_SOURCES, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get global sources Chart
    const getCaptacionMedios = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/captacionMedios?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_CAPTACION_MEDIOS, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getInvestmentsChart = async (endpoint, query) => {
        clearCharts();
        setLoading();
        try {
            const res = await api.get(
                `/charts/investmentsChartLead?leadStatus=${endpoint.toLowerCase()}s`,
                HEADERS()
            );
            dispatch({ type: GET_INVESTMENTS_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Conversations Chart
    const getConversationsChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/conversationsChart?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_CONVERSATIONS_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getMessagesChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/messagesChart?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_MESSAGES_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Make - Models Chart
    const getModelsByMakeChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/modelsByMakeChart?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_MODELS_BY_MAKE_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Agents - Status Chart
    const getAgentsStatusChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/agentsStatusChart?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_AGENTS_STATUS_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Recordings reviews Chart
    const getRecordingsReviews = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/recordingsReviews?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_RECORDINGS_REVIEWS, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Agents - Substatus Sold Table
    const getAgentsSoldsTable = async (query) => {
        // setLoading();
        try {
            const res = await api.get(
                `/charts/agentsSoldsTable?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_AGENTS_SOLDS_TABLE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Agents - Substatus Visit Table
    const getAgentsVisitsTable = async (query) => {
        // setLoading();
        try {
            const res = await api.get(
                `/charts/agentsVisitsTable?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_AGENTS_VISITS_TABLE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Agents - Substatus Visit Table
    const getAgentsCallsChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/agentsCallsChart?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_AGENTS_CALLS_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Agents - Substatus Visit Table
    const getAgentsCallsTable = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/agentsCallsTable?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_AGENTS_CALLS_TABLE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getStoresExtraScoresTable = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/storesExtraScoresTable?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_STORES_EXTRA_SCORES_TABLE,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getStoresScoresTable = async (values) => {
        setLoading();
        try {
            const res = await api.post(
                `/charts/storesScoresTable`,
                { ...values },
                HEADERS()
            );
            dispatch({ type: GET_STORES_SCORES_TABLE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Agents - Substatus Visit Table
    const getVisitsReport = async (values) => {
        setLoading();
        try {
            const res = await api.post(
                `/charts/reportVisits`,
                { ...values },
                HEADERS()
            );
            dispatch({ type: GET_REPORT_VISITS, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Agents - Substatus Appointment Table
    const getAgentsAppointmentsTable = async (query) => {
        // setLoading();
        try {
            const res = await api.get(
                `/charts/agentsAppointmentsTable?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_AGENTS_APPOINTMENTS_TABLE,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Agents - Substatus Lead Table
    const getAgentsLeadsTable = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/agentsLeadsTable?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_AGENTS_LEADS_TABLE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Agents - Substatus Lead Table
    const getAgentsComparative = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/agentsComparative?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_AGENTS_COMPARATIVE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getAddonsReport = async (values) => {
        setLoading();
        try {
            const res = await ApiV2.post(
                `/reports/addonsReport`,
                { ...values },
                HEADERS()
            );
            dispatch({ type: GET_ADDONS_REPORT, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getTableAgentSources = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/agentsSourcesComparative?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_TABLE_AGENT_SOURCES, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Financial Chart
    const getFinancialInstitutionChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/financialInstitutionChart?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_FINANCIAL_INSTITUTION_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Operation Type Chart
    const getOperationTypeChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/operationTypeChart?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_OPERATION_TYPE_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Profit Chart
    const getProfitModelChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/profitModelChart?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_PROFIT_MODEL_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Profit Chart
    const getProfitSourceChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/profitSourceChart?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_PROFIT_SOURCE_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Profit Chart
    const getProfitStoreChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/profitStoreChart?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_PROFIT_STORE_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Profit Chart
    const getInvestmentProfitStoreChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/investmentProfitStoreChart?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_INVESTMENT_PROFIT_STORE_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Profit Chart
    const getTotalsInvestmentDashboard = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/totalsInvestmentDashboard?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_TOTALS_INVESTMENT_DASHBOARD,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Profit Chart
    const getTotalsFinancingDashboard = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/totalsFinancingDashboard?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_TOTALS_FINANCING_DASHBOARD,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Appointment Chart
    const getTotalsAppointmentsDashboard = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/totalsAppointmentsDashboard?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_TOTALS_APPOINTMENTS_DASHBOARD,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    // ? visit charts dash
    const getTotalsVisitsDashboard = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/totalsVisitsDashboard?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_TOTALS_VISITS_DASHBOARD,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getVisitsSubstatusChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/visitsSubstatusChart?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_VISITS_SUBSTATUS_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getVisitsStoresChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/visitsStoresChart?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_VISITS_STORES_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getVisitsDetailsChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/visitsDetailsChart?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_VISITS_DETAILS_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    // ? calls charts dash
    const getTotalsCallsDashboard = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/totalsCallsDashboard?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_TOTALS_CALLS_DASHBOARD,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getCallsSubstatusChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/CallsSubstatusChart?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_CALLS_SUBSTATUS_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getCallsStoresChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/CallsStoresChart?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_CALLS_STORES_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getDrilldownCalls = async (endpoint, query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/${endpoint}?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_CALLS_DRILLDOWN_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getDrilldownPendingLeads = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/drilldownPendingLeads?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_DRILLDOWN_PENDING_LEADS_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getDrilldownBureau = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/drillDownBureau?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_BUREAU_DRILLDOWN_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getDrilldownRecordings = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/drilldownRecordings?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_RECORDINGS_DRILLDOWN_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getDrilldownFunnelSource = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/drilldownFunnelSource?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_DRILLDOWN_FUNNEL_SOURCE,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getCallsDetailsChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/CallsDetailsChart?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_CALLS_DETAILS_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getCallsMakesChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/CallsMakesChart?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_CALLS_MAKES_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Profit Chart
    const getProfitChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/profitChart?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_PROFIT_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Profit Chart
    const getInvestmentProfitChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/investmentProfitChart?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_INVESTMENT_PROFIT_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Profit Chart
    const getWeeklyInvestmentProfitChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/weeklyInvestmentProfitChart?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_WEEKLY_INVESTMENT_PROFIT_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Profit Chart
    const getWeeklyProfitChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/weeklyProfitChart?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_WEEKLY_PROFIT_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Monthly Leads
    const getLeadsMonthlyChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/leadsMonthlyChart?${query}&detail=true`,
                HEADERS()
            );
            dispatch({ type: GET_LEADS_MONTHLY_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Leads by Store Chart
    const getLeadsStoreChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/leadsStoreChart?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_LEADS_STORE_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Totals Dashboard
    const getTotalsDashboard = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/getTotalsDashboard?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_TOTALS_DASHBOARD, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Models Chart
    const getHoursChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(`/charts/hoursChart?${query}`, HEADERS());
            dispatch({ type: GET_HOURS_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Models Chart
    const getModelsChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/modelsChart?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_MODELS_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Daily Chart
    const getDailyChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(`/charts/dailyChart?${query}`, HEADERS());
            dispatch({ type: GET_DAILY_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Monthly Chart
    const getMonthlyChart = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/monthlyChart?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_MONTHLY_CHART, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Total Appointments
    const getAppointmentsByStore = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/statusAppointmentChart?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_APPOINTMENTS_BY_STORE_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Total Visits
    const getVisitsByStore = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/statusVisitChart?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_VISITS_BY_STORE_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Total Solds
    const getInvestmentsByStore = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/statusInvestmentChart?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_INVESTMENT_BY_STORE_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Total Solds
    const getSoldsByStore = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/statusSoldChart?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_SOLDS_BY_STORE_CHART,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get report sales
    const getReportSales = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/reportSales?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_REPORT_SALES, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get report sales
    const getDrilldownComparative = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/charts/drilldownComparative?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_DRILLDOWN_COMPARATIVE,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Matrix
    const getMatrix = async ({ account, date }) => {
        setLoading();
        try {
            try {
                setLoading();
                const res = await api.post(
                    `/charts/matrix`,
                    { account, date },
                    HEADERS()
                );
                dispatch({
                    type: GET_MATRIX,
                    payload: {
                        data: res.data.matrix,
                        campaigns: res.data.campaigns,
                        adsets: res.data.adsets,
                    },
                });
            } catch (err) {
                dispatch({ type: SET_ERROR, payload: err.response.data.error });
            }
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Matrix
    const getMatrixInvestment = async (values) => {
        try {
            let query = { ...values };

            if (values.source) {
                query.source = null;
                query.source__id = getDocumentId(values.source);
            }

            setLoading();
            const res = await api.post(
                `/investmentsChart/drilldown`,
                query,
                HEADERS()
            );
            dispatch({ type: GET_MATRIX_INVESTMENT, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Matrix
    const getMatrixSegmentation = async (values) => {
        try {
            setLoading();
            const res = await api.post(
                `/charts/matrixSegmentation`,
                { ...values },
                HEADERS()
            );
            dispatch({ type: GET_MATRIX_SEGMENTATION, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Matrix
    const getMatrixInvestmentCampaign = async (values) => {
        try {
            setLoading();
            const res = await api.post(
                `/charts/matrixInvestment/campaign`,
                { ...values },
                HEADERS()
            );
            dispatch({
                type: GET_MATRIX_INVESTMENT_CAMPAIGN,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Matrix
    const getMatrixInvestmentAdset = async (values) => {
        try {
            setLoading();
            const res = await api.post(
                `/charts/matrixInvestment/adsets`,
                { ...values },
                HEADERS()
            );
            dispatch({
                type: GET_MATRIX_INVESTMENT_ADSET,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const getCreditRequestTable = async (query) => {
        setLoading();
        try {
            const res = await api.post(
                `/charts/creditRequestTable`,
                query,
                HEADERS()
            );
            dispatch({
                type: GET_CREDIT_REQUEST_TABLE,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Clear State
    const clearCharts = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    //Funciones para la grafica pendeja
    const getInvestmentChartList = async (values) => {
        setLoading();
        try {
            const res = await api.post(
                `/investmentsChart/investmentAR`,
                { ...values },
                HEADERS()
            );

            dispatch({
                type: GET_INVESTMENTS,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Create Investment
    const createInvestment = async (investment, file) => {
        setLoading();

        try {
            const res = await api.post(
                `/investmentsChart`,
                { ...investment },
                HEADERS()
            );
            dispatch({ type: CREATE_INVESTMENT, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Delete Investment
    const deleteInvestment = async (investmentId) => {
        setLoading();
        try {
            const res = await api.delete(
                `/investmentsChart/${investmentId}`,
                HEADERS()
            );
            dispatch({ type: DELETE_INVESTMENT, payload: res.data.deletedId });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Update Investment
    const updateInvestment = async (investment, investmentId, file) => {
        clearCharts();
        setLoading();

        try {
            let res = await api.put(
                `/investmentsChart/${investmentId}`,
                { ...investment },
                HEADERS()
            );
            dispatch({ type: UPDATE_INVESTMENT, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Investment
    const getInvestment = async (investmentId) => {
        clearCharts();
        setLoading();
        try {
            const res = await api.get(
                `/investmentsChart/${investmentId}`,
                HEADERS()
            );
            dispatch({ type: GET_INVESTMENT, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    /**
     * Posts a custom chart data to the server.
     *
     * @param {object} options - The options for posting the custom chart.
     * @param {object} options.query - The query parameters for the chart.
     * @param {string} options.chartName - The name of the chart.
     * @param {string} [options.stateName] - The name of the state to update (optional, defaults to chartName).
     * @param {boolean} [options.dissableLoading] - Indicates whether loading state should be set (optional).
     */
    const postCustomChart = async ({
        query,
        chartName,
        stateName,
        dissableLoading,
    }) => {
        if (!dissableLoading) setLoading();
        try {
            const res = await api.post(
                `/charts/${chartName}`,
                { ...query },
                HEADERS()
            );
            dispatch({
                type: POST_CUSTOM_CHART,
                payload: res.data.data,
                stateName: stateName || chartName,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    return (
        <ChartContext.Provider
            value={{
                ...state,
                postCustomChart,
                getDrilldownPendingLeads,
                drilldownPendingLeads: state.drilldownPendingLeads,
                getInvestmentChartList,
                createInvestment,
                deleteInvestment,
                updateInvestment,
                getInvestment,
                investments: state.investments,
                investment: state.investment,
                count: state.count,

                loadingCharts: state.loadingCharts,
                error: state.error,
                leadsByStore: state.leadsByStore,
                appointmentsByStore: state.appointmentsByStore,
                visitsByStore: state.visitsByStore,
                soldsByStore: state.soldsByStore,
                investmentsByStore: state.investmentsByStore,

                getAppointmentsByStore,
                getVisitsByStore,
                getSoldsByStore,
                getInvestmentsByStore,

                getMonthlyChart,
                monthly: state.monthly,

                getDailyChart,
                daily: state.daily,

                getModelsChart,
                models: state.models,

                getHoursChart,
                hours: state.hours,

                linearStores: state.linearStores,

                pieStatus: state.pieStatus,

                getTotalsDashboard,
                totalLeads: state.totalLeads,
                totalVisits: state.totalVisits,
                totalAppointments: state.totalAppointments,
                totalSolds: state.totalSolds,
                total: state.total,
                totalNew: state.totalNew,
                totalFollowUp: state.totalFollowUp,
                totalCallAgain: state.totalCallAgain,
                totalWrongnumber: state.totalWrongnumber,
                totalRejected: state.totalRejected,
                totalDocumentation: state.totalDocumentation,
                totalConfirm: state.totalConfirm,
                totalConfirmed: state.totalConfirmed,
                totalVisitTracking: state.totalVisitTracking,
                totalReschedule: state.totalReschedule,
                totalClientNa: state.totalClientNa,
                totalVisited: state.totalVisited,
                totalApplication: state.totalApplication,
                totalApprovedApplication: state.totalApprovedApplication,
                totalCondicionedApplication: state.totalCondicionedApplication,
                totalRejectedApplication: state.totalRejectedApplication,
                totalSeparation: state.totalSeparation,

                getLeadsMonthlyChart,
                leadsMonthlyChart: state.leadsMonthlyChart,

                getLeadsStoreChart,
                leadsStoreChart: state.leadsStoreChart,

                getProfitChart,
                profitChart: state.profitChart,

                getInvestmentProfitChart,
                investmentProfitChart: state.investmentProfitChart,

                getWeeklyProfitChart,
                weeklyProfitChart: state.weeklyProfitChart,

                getWeeklyInvestmentProfitChart,
                weeklyInvestmentProfitChart: state.weeklyInvestmentProfitChart,

                getTotalsFinancingDashboard,
                totalFinancing: state.totalFinancing,
                totalProfits: state.totalProfits,

                getTotalsInvestmentDashboard,
                totalInvestment: state.totalInvestment,
                totalProfitsInvestment: state.totalProfitsInvestment,

                getProfitStoreChart,
                profitStoreChart: state.profitStoreChart,

                getInvestmentProfitStoreChart,
                investmentProfitStoreChart: state.investmentProfitStoreChart,

                getProfitModelChart,
                profitModelChart: state.profitModelChart,

                getProfitSourceChart,
                profitSourceChart: state.profitSourceChart,

                getOperationTypeChart,
                operationTypeChart: state.operationTypeChart,

                getFinancialInstitutionChart,
                financialInstitutionChart: state.financialInstitutionChart,

                getConversationsByAgentChart,
                conversationsByAgentChart: state.conversationsByAgentChart,

                getAgentsLeadsTable,
                agentsLeadsTable: state.agentsLeadsTable,

                getAgentsVisitsTable,
                agentsVisitsTable: state.agentsVisitsTable,

                getAgentsCallsChart,
                agentsCallsChart: state.agentsCallsChart,

                getAgentsCallsTable,
                agentsCallsTable: state.agentsCallsTable,

                getStoresExtraScoresTable,
                storesExtraScoresTable: state.storesExtraScoresTable,

                getStoresScoresTable,
                storesScoresTable: state.storesScoresTable,

                getAgentsAppointmentsTable,
                agentsAppointmentsTable: state.agentsAppointmentsTable,

                getAgentsSoldsTable,
                agentsSoldsTable: state.agentsSoldsTable,

                getAgentsStatusChart,
                agentsStatusChart: state.agentsStatusChart,

                getModelsByMakeChart,
                modelsByMakeChart: state.modelsByMakeChart,

                getConversationsChart,
                conversationsChart: state.conversationsChart,

                getMessagesChart,
                messagesChart: state.messagesChart,

                getTemperaturesChart,
                temperaturesChart: state.temperaturesChart,

                getInvestmentsChart,
                investmentsChart: state.investmentsChart,

                getMonthlyAdminChart,
                monthlyAdminChart: state.monthlyAdminChart,

                getMonthlyComparativeChart,
                monthlyComparativeChart: state.monthlyComparativeChart,

                getUnitComparativeChart,
                getUnitComparativeChartGrouped,
                unitMonthlyComparativeChart: state.unitMonthlyComparativeChart,

                getTemperaturesFunnel,
                temperaturesFunnel: state.temperaturesFunnel,

                getTemperaturesFunnelRSI,
                temperaturesFunnelRSI: state.temperaturesFunnelRSI,

                getFunnelAppointment,
                funnelAppointment: state.funnelAppointment,

                getStatusReportChart,
                statusReportChart: state.statusReportChart,

                getSubstatusAgentChart,
                substatusAgentChart: state.substatusAgentChart,

                getVisitsChart,
                visitsChart: state.visitsChart,

                getAppointmentsChart,
                appointmentsChart: state.appointmentsChart,

                getHoursComparativeChart,
                hoursComparativeChart: state.hoursComparativeChart,

                getAppointmentsSubstatusChart,
                appointmentsSubstatusChart: state.appointmentsSubstatusChart,

                getAppointmentsStoresChart,
                appointmentsStoresChart: state.appointmentsStoresChart,

                getAppointmentsDetailsChart,
                appointmentsDetailsChart: state.appointmentsDetailsChart,

                getVsChart,
                vsChart: state.vsChart,

                getTotalsAppointmentsDashboard,
                totalAppointmentsDash: state.totalAppointmentsDash,

                getTotalsVisitsDashboard,
                totalVisitsDash: state.totalVisitsDash,

                getVisitsSubstatusChart,
                visitsSubstatusChart: state.visitsSubstatusChart,

                getVisitsStoresChart,
                visitsStoresChart: state.visitsStoresChart,

                getVisitsDetailsChart,
                visitsDetailsChart: state.visitsDetailsChart,

                getTotalsCallsDashboard,
                totalCallsDash: state.totalCallsDash,

                getCallsSubstatusChart,
                callsSubstatusChart: state.callsSubstatusChart,

                getCallsStoresChart,
                callsStoresChart: state.callsStoresChart,

                getCallsDetailsChart,
                callsDetailsChart: state.callsDetailsChart,

                getCallsMakesChart,
                callsMakesChart: state.callsMakesChart,

                getClosureTopUsers,
                closureTopUsers: state.closureTopUsers,

                getConversationsByAgent,
                conversationsByAgent: state.conversationsByAgent,

                getDrilldownCalls,
                drilldownCalls: state.drilldownCalls,

                getDrilldownBureau,
                drilldownBureau: state.drilldownBureau,

                getDrilldownRecordings,
                drilldownRecordings: state.drilldownRecordings,

                getDrilldownFunnelSource,
                drilldownFunnelSource: state.drilldownFunnelSource,

                getStatusStoresChart,
                statusStoresChart: state.statusStoresChart,

                getStatusStoresChartAcumulated,
                statusStoresChartAcumulated: state.statusStoresChartAcumulated,

                getTemperaturesLeadsTable,
                temperaturesLeadsTable: state.temperaturesLeadsTable,

                getTemperaturesAppointmentsTable,
                temperaturesAppointmentsTable:
                    state.temperaturesAppointmentsTable,

                getTemperaturesVisitsTable,
                temperaturesVisitsTable: state.temperaturesVisitsTable,

                getTemperaturesSalesTable,
                temperaturesSalesTable: state.temperaturesSalesTable,

                getMonitoringAgent,
                monitoringAgent: state.monitoringAgent,

                getMonitoringAgentSources,
                monitoringAgentSources: state.monitoringAgentSources,

                getTotalTemperatures,
                totalTemperatures: state.totalTemperatures,

                getTemperaturesFunnelRSIComparative,
                temperaturesFunnelRSIComparative:
                    state.temperaturesFunnelRSIComparative,

                getTemperaturesFunnelAppointmentComparative,
                temperaturesFunnelAppointmentComparative:
                    state.temperaturesFunnelAppointmentComparative,

                getFunnelDatos,
                funnelDatos: state.funnelDatos,

                getReportSales,
                reportSales: state.reportSales,

                getDrilldownComparative,
                drilldownComparative: state.drilldownComparative,

                getDrilldownTime,
                clearDrilldownTime,
                drilldownTime: state.drilldownTime,

                getGlobal,
                global: state.global,

                getGlobalSources,
                globalSources: state.globalSources,

                getCaptacionMedios,
                captacionMedios: state.captacionMedios,

                getRecordingsReviews,
                recordingsReviews: state.recordingsReviews,

                getPendingTasksReport,
                pendingTasksReport: state.pendingTasksReport,

                getTableModels,
                tableModels: state.tableModels,

                getTableSources,
                tableSources: state.tableSources,

                getTableAgentSources,
                tableAgentSources: state.tableAgentSources,

                getTableAgencies,
                tableAgencies: state.tableAgencies,

                getTableLeadsByAgents,
                tableLeadsByAgents: state.tableLeadsByAgents,

                getBureauChart,
                bureauChart: state.bureauChart,

                getVisitsReport,
                visitsReport: state.visitsReport,

                getMatrix,
                matrix: state.matrix,
                matrixCampaigns: state.matrixCampaigns,
                matrixAdsets: state.matrixAdsets,

                getMatrixInvestment,
                matrixInvestment: state.matrixInvestment,

                getMatrixInvestmentCampaign,
                matrixInvestmentCampaign: state.matrixInvestmentCampaign,

                getMatrixInvestmentAdset,
                matrixInvestmentAdset: state.matrixInvestmentAdset,

                getMatrixSegmentation,
                matrixSegmentation: state.matrixSegmentation,

                getCreditRequestTable,
                creditRequestTable: state.creditRequestTable,

                getAgentsComparative,
                agentsComparative: state.agentsComparative,

                getAddonsReport,
                addonsReport: state.addonsReport,

                getAgentsCallsPerformance,
                agentsCallsPerformance: state.agentsCallsPerformance,

                clearCharts,
                setLoading,
            }}
        >
            {props.children}
        </ChartContext.Provider>
    );
};

export default ChartState;
