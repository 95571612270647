import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'general manager',
            'sales manager',
            'marketing',
        ],
        path: '/management/recordings/:id',
        component: lazy(
            () => import('src/views/recording/RecordingDetailsView')
        ),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'general manager',
            'sales manager',
            'marketing',
        ],
        path: '/management/recordings',
        component: lazy(() => import('src/views/recording/RecordingListView')),
    },
];
