import { TChannel } from 'src/interfaces/conversation';
import { TFormattedChannelName } from './types';
import {
    MESSENGER_CHANNEL,
    WHATSAPP_CHANNEL,
} from 'src/constants/conversations';
import {
    FB_MESSENGER_FORMATED_NAME,
    SMS_FORMATED_NAME,
    WHATSAPP_FORMATED_NAME,
} from './constants';

/**
 * Will return the name of the
 *
 * @param {TChannel} channel
 * @return {*}  {TFormattedChannelName}
 */
export const getChannelName = (channel: TChannel): TFormattedChannelName => {
    if (channel === WHATSAPP_CHANNEL) return WHATSAPP_FORMATED_NAME;
    if (channel === MESSENGER_CHANNEL) return FB_MESSENGER_FORMATED_NAME;
    return SMS_FORMATED_NAME;
};
