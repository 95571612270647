import React from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles,
    Divider,
    Card,
    Button,
    Box,
    CardContent,
    Grid,
    CardHeader,
    Avatar,
    Typography,
    IconButton,
    Modal,
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import Label from 'src/components/Label';
import { ExternalLink } from 'react-feather';
import useReminder from 'src/hooks/useReminder';
import { useHistory } from 'react-router';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
    },
    actions: {
        float: 'right',
    },
    capitalize: {
        'text-transform': 'capitalize',
    },
}));

function ReminderModal({
    className,
    open,
    setOpen,
    reminder,
    disableSnack,
    ...rest
}) {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const { deleteReminder } = useReminder();

    const handleReRoute = (id) => {
        setOpen(false);
        history.push(`/management/leads/${id}`);
    };

    const handleDeleteReminder = () => {
        deleteReminder(reminder._id);
        setOpen(false);
        disableSnack(reminder._id);
    };

    return (
        <Modal onClose={() => setOpen(false)} open={open} {...rest}>
            <Card className={classes.root}>
                <Divider />

                <CardContent>
                    <CardHeader
                        avatar={
                            <Avatar
                                alt="Reviewer"
                                src={
                                    reminder &&
                                    reminder.user &&
                                    `https://automotive-api.s3.us-east-2.amazonaws.com/${reminder.user.image}`
                                }
                            >
                                {reminder &&
                                    reminder.user &&
                                    getInitials(reminder.user.name)}
                            </Avatar>
                        }
                        disableTypography
                        title={
                            <>
                                <Typography
                                    color="textPrimary"
                                    variant="h4"
                                    style={{ textTransform: 'capitalize' }}
                                >
                                    {reminder &&
                                        reminder.user &&
                                        reminder.user.name}{' '}
                                    <IconButton
                                        onClick={(e) =>
                                            handleReRoute(reminder.lead._id)
                                        }
                                    >
                                        <ExternalLink
                                            style={{
                                                cursor: 'pointer',
                                                width: 20,
                                                height: 20,
                                            }}
                                        />
                                    </IconButton>
                                    {reminder.task &&
                                        reminder.task.action &&
                                        reminder.task.action.map((item) => (
                                            <div
                                                className={classes.actions}
                                                key={item}
                                            >
                                                <Label
                                                    className={
                                                        classes.capitalize
                                                    }
                                                    key={item}
                                                    color={
                                                        item === 'mailing'
                                                            ? 'warning'
                                                            : item ===
                                                                'information'
                                                              ? 'error'
                                                              : item ===
                                                                  'documentation'
                                                                ? 'blue'
                                                                : item ===
                                                                    'whatsapp'
                                                                  ? 'green'
                                                                  : item ===
                                                                      'pending'
                                                                    ? 'purple'
                                                                    : item ===
                                                                        'visit'
                                                                      ? 'pink'
                                                                      : item ===
                                                                          'visit-update'
                                                                        ? 'pink'
                                                                        : item ===
                                                                            'no-visit'
                                                                          ? 'pink'
                                                                          : item ===
                                                                              'sold'
                                                                            ? 'teal'
                                                                            : item ===
                                                                                'recall'
                                                                              ? 'cyan'
                                                                              : item ===
                                                                                  'calling'
                                                                                ? 'yellow'
                                                                                : item ===
                                                                                    'rsi'
                                                                                  ? 'lime'
                                                                                  : false
                                                    }
                                                >
                                                    {t(`KeysComment.${item}`)}
                                                </Label>
                                            </div>
                                        ))}
                                </Typography>

                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    <ScheduleIcon style={{ fontSize: 14 }} />{' '}
                                    {moment(
                                        reminder &&
                                            reminder.task &&
                                            reminder.task.createdAt
                                    ).fromNow()}
                                </Typography>
                            </>
                        }
                    />
                    <Box display="flex" ml={2}>
                        <Box>
                            <Typography variant="body2" color="textSecondary">
                                {reminder &&
                                    reminder.task &&
                                    reminder.task.comment}
                            </Typography>
                        </Box>
                    </Box>

                    <Box display="flex" justifyContent="flex-end" m={2} ml={2}>
                        <Box flexGrow={1}>
                            {reminder &&
                            reminder.task &&
                            reminder.task.reschedule ? (
                                <Typography
                                    color="textSecondary"
                                    variant="h5"
                                    style={{ marginBottom: '0.5em' }}
                                >
                                    {t('Comments.Task')}:{' '}
                                    <Label
                                        className={classes.capitalize}
                                        color="cyan"
                                    >
                                        {reminder.task.reschedule
                                            ? `${moment(
                                                  reminder.task.reschedule
                                              ).format('lll')}`
                                            : ''}
                                    </Label>
                                </Typography>
                            ) : (
                                'none'
                            )}
                        </Box>
                    </Box>
                    <Divider style={{ marginBottom: '2em' }} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Box display="flex" ml={2}>
                                <Box>
                                    <Typography
                                        color="textPrimary"
                                        variant="h4"
                                        style={{
                                            textTransform: 'capitalize',
                                            marginBottom: '0.5em',
                                        }}
                                    >
                                        {t('Leads.Info')}
                                    </Typography>
                                </Box>
                            </Box>
                            {reminder.task && reminder.task.lead && (
                                <>
                                    <Box display="flex" ml={2}>
                                        <Box>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                style={{
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                <strong>
                                                    {t('Leads.Name')}:{' '}
                                                </strong>
                                                {reminder &&
                                                    reminder.lead &&
                                                    reminder.lead.name}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box display="flex" ml={2}>
                                        <Box>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                <strong>
                                                    {t('Leads.Email')}:{' '}
                                                </strong>
                                                {reminder.lead.email}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box display="flex" ml={2}>
                                        <Box>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                <strong>
                                                    {t('Leads.Phone')}:{' '}
                                                </strong>
                                                {reminder.lead.phone}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>

                <Divider />
                <Box p={2} display="flex" justifyContent="flex-end">
                    <Button
                        color="secondary"
                        type="submit"
                        variant="contained"
                        onClick={handleDeleteReminder}
                    >
                        {t('Buttons.Reminder')}
                    </Button>
                </Box>
            </Card>
        </Modal>
    );
}

ReminderModal.propTypes = {
    open: PropTypes.bool.isRequired,
};

export default ReminderModal;
