import React, { useReducer } from 'react';
import TemplateTwilioContext from './templateTwilioContext';
import TemplateTwilioReducer from './templateTwilioReducer';
import api from '../../api/api';
import {
    GET_TEMPLATESTWILIO,
    GET_TEMPLATESTWILIO_BY_STORE,
    CREATE_TEMPLATETWILIO,
    GET_TEMPLATETWILIO,
    DELETE_TEMPLATETWILIO,
    UPDATE_TEMPLATETWILIO,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    GET_TEMPLATES,
} from '../types';
import { HEADERS } from '../../constants/headers';
import { ApiV2 } from 'src/api/apiv2';

const TemplateTwilioState = (props) => {
    const initialState = {
        templatesTwilio: [],
        templateTwilio: {},
        templates: [],
        loading: false,
        error: null,
        count: null,
    };

    const [state, dispatch] = useReducer(TemplateTwilioReducer, initialState);

    /**
     * Will return all templates by values given
     *
     * @param {*} values
     */
    const getTemplatesV2 = async (values) => {
        setLoading();
        try {
            const res = await ApiV2.post(
                `/templates/list`,
                { ...values },
                HEADERS()
            );
            dispatch({
                type: GET_TEMPLATES,
                payload: res.data.results,
                total: res.data.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const AdvancedResults = async (pagination, query) => {
        setLoading();
        try {
            const res = await api.get(
                `/templatesTwilio/templatesTwilioAr?page=${pagination.page}&limit=${pagination.limit}${query}&searchType=and&validation=1`,
                HEADERS()
            );
            dispatch({
                type: GET_TEMPLATESTWILIO,
                payload: res.data.data,
                count: res.data.count,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get TemplatesTwilio
    const getTemplatesTwilio = async (
        pagination,
        query,
        typeQuery,
        stores = ''
    ) => {
        setLoading();
        try {
            const res = await api.get(
                `/templatesTwilio/templatesTwilioAr?page=${pagination.page}&searchIndex=title-templateTitle&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1${stores}`,
                HEADERS()
            );
            dispatch({
                type: GET_TEMPLATESTWILIO,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get TemplatesTwilio by store
    const getTemplatesTwilioByStore = async (storesId, typeLead = 'lead') => {
        setLoading();
        try {
            const res = await api.get(
                `/templatesTwilio/templatesTwilioAr?multiStores=${storesId}&typeLead=${typeLead}&isActive=true`,
                HEADERS()
            );

            dispatch({
                type: GET_TEMPLATESTWILIO_BY_STORE,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get TemplateTwilio
    const getTemplateTwilio = async (templateTwilioId) => {
        clearState();
        setLoading();
        try {
            const res = await api.get(
                `/templatesTwilio/${templateTwilioId}`,
                HEADERS()
            );
            dispatch({ type: GET_TEMPLATETWILIO, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Delete TemplateTwilio
    const deleteTemplateTwilio = async (templateTwilioId) => {
        setLoading();
        try {
            const res = await api.delete(
                `/templatesTwilio/${templateTwilioId}`,
                HEADERS()
            );
            dispatch({
                type: DELETE_TEMPLATETWILIO,
                payload: res.data.deletedId,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Create TemplateTwilio
    const createTemplateTwilio = async (templateTwilio) => {
        clearState();
        setLoading();
        try {
            const res = await api.post(
                `/templatesTwilio`,
                { ...templateTwilio },
                HEADERS()
            );
            dispatch({ type: CREATE_TEMPLATETWILIO, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Update TemplateTwilio
    const updateTemplateTwilio = async (templateTwilio, templateTwilioId) => {
        setLoading();
        try {
            const res = await api.put(
                `/templatesTwilio/${templateTwilioId}`,
                { ...templateTwilio },
                HEADERS()
            );
            dispatch({ type: UPDATE_TEMPLATETWILIO, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <TemplateTwilioContext.Provider
            value={{
                loading: state.loading,
                templatesTwilio: state.templatesTwilio,
                templateTwilio: state.templateTwilio,
                error: state.error,
                count: state.count,
                getTemplatesTwilio,
                createTemplateTwilio,
                getTemplateTwilio,
                deleteTemplateTwilio,
                updateTemplateTwilio,
                clearState,
                setLoading,
                getTemplatesTwilioByStore,
                AdvancedResults,

                getTemplatesV2,
                templates: state.templates,
            }}
        >
            {props.children}
        </TemplateTwilioContext.Provider>
    );
};

export default TemplateTwilioState;
