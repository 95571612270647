import api from 'src/api/api';
import { HEADERS } from 'src/constants/headers';
import { IUploadImageToS3 } from './types';
import axios from 'axios';

/**
 * Will upload an image to AWS S3 and return its url and key
 *
 * @param {File} file
 * @return {*}
 */
export const uploadImageToS3: IUploadImageToS3 = async (file: File) => {
    const { type, name: fileName } = file;

    const uploadConfig = await api.post(
        '/uploads/image',
        { type, fileName },
        HEADERS()
    );

    const { url, key } = uploadConfig.data;

    await axios.put(url, file, {
        headers: { 'Content-Type': type },
    });

    return { url, key };
};
