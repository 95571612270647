import React, { useReducer } from 'react';

import axios, { AxiosError } from 'axios';

import FleetCompanyContext from './fleetCompanyContext';
import FleetCompanyReducer from './fleetCompanyReducer';
import api from '../../api/api';

import {
    GET_FLEET_COMPANIES,
    GET_FLEET_COMPANY,
    CREATE_FLEET_COMPANY,
    DELETE_FLEET_COMPANY,
    UPDATE_FLEET_COMPANY,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';

import { HEADERS } from '../../constants/headers';

import {
    DEFAULT_FLEET_COMPANY_STATE,
    FLEET_COMPANY_ENDPOINT,
} from 'src/constants/fleetCompany/stateConfiguration';

import { IFleetCompany } from '../../interfaces/fleetCompany';

interface FleetCompanyStateProps {
    children: React.ReactNode;
}

export interface IFleetCompanyState {
    fleetCompany?: IFleetCompany | null;
    fleetCompanies?: IFleetCompany[] | null;
    deleteId?: string;
    count?: number | undefined | null;
    error?: boolean | undefined | null;
    loading?: boolean;
}

const VALID_REDUCER_TYPES = [
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    GET_FLEET_COMPANIES,
    GET_FLEET_COMPANY,
    CREATE_FLEET_COMPANY,
    DELETE_FLEET_COMPANY,
    UPDATE_FLEET_COMPANY,
];

type ReducerTypes = (typeof VALID_REDUCER_TYPES)[number];
export interface IAction {
    type: ReducerTypes;
    payload?: IFleetCompanyState;
}

const FleetCompanyState: React.FC<FleetCompanyStateProps> = (props) => {
    const [state, dispatch] = useReducer<
        React.Reducer<IFleetCompanyState, IAction>
    >(FleetCompanyReducer, DEFAULT_FLEET_COMPANY_STATE);

    const getFleetCompany = async (fleetCompanyId: string) => {
        clearState();
        setLoading();
        try {
            const res = await api.get<{ data: IFleetCompanyState }>(
                `/${FLEET_COMPANY_ENDPOINT}/${fleetCompanyId}`,
                HEADERS()
            );
            dispatch({
                type: GET_FLEET_COMPANY,
                payload: res.data.data,
            });
        } catch (err) {
            if (axios.isAxiosError(err) && err.response)
                dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const createFleetCompany = async (fleetCompany: IFleetCompany) => {
        clearState();
        setLoading();
        try {
            const res = await api.post<{ data: IFleetCompanyState }>(
                `/${FLEET_COMPANY_ENDPOINT}`,
                { ...fleetCompany },
                HEADERS()
            );
            dispatch({ type: CREATE_FLEET_COMPANY, payload: res.data.data });
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                dispatch({ type: SET_ERROR, payload: err.response.data });
            }
        }
    };

    const deleteFleetCompany = async (fleetCompanyId: string) => {
        setLoading();
        try {
            const res = await api.delete<{ data: IFleetCompanyState }>(
                `/${FLEET_COMPANY_ENDPOINT}/${fleetCompanyId}`,
                HEADERS()
            );
            dispatch({
                type: DELETE_FLEET_COMPANY,
                payload: res.data.data,
            });
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                dispatch({ type: SET_ERROR, payload: err.response.data });
            }
        }
    };

    const updateFleetCompany = async (fleetCompany: IFleetCompany) => {
        setLoading();

        try {
            const res = await api.put<{ data: IFleetCompanyState }>(
                `/${FLEET_COMPANY_ENDPOINT}/${fleetCompany?._id}`,
                { ...fleetCompany },
                HEADERS()
            );
            dispatch({ type: UPDATE_FLEET_COMPANY, payload: res.data.data });
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                dispatch({ type: SET_ERROR, payload: err.response.data });
            }
        }
    };

    const getFleetCompanies = async (query: string) => {
        clearState();
        setLoading();
        try {
            if (!query) query = '';
            const res = await api.get<{ data: IFleetCompanyState }>(
                `/${FLEET_COMPANY_ENDPOINT}?${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_FLEET_COMPANIES,
                payload: res.data.data,
            });
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                dispatch({ type: SET_ERROR, payload: err.response.data });
            }
        }
    };

    const clearState = () => dispatch({ type: CLEAR_STATE });

    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <FleetCompanyContext.Provider
            value={{
                ...state,
                getFleetCompanies,
                createFleetCompany,
                getFleetCompany,
                deleteFleetCompany,
                updateFleetCompany,
                setLoading,
                clearState,
            }}
        >
            {props.children}
        </FleetCompanyContext.Provider>
    );
};

export default FleetCompanyState;
