import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    FORGOT_PASSWORD_FAIL,
    FORGOT_PASSWORD,
    RESET_PASSWORD_FAIL,
    RESET_PASSWORD,
    UPDATE_PROFILE,
    UPDATE_PASSWORD,
    SET_ERROR,
    SET_LOADING,
    CLEAR_STATE,
    UPDATE_STORE_GROUP,
    UPDATE_USER_STORES,
    GET_STATIC_LOOKUP_DATA,
    VERIFY_PHONE,
    VERIFY_TOKEN,
} from '../types';
import { channel } from 'src/service/pusher';

export default (state, action) => {
    switch (action.type) {
        case GET_STATIC_LOOKUP_DATA:
            return {
                ...state,
                staticLookupData: action.payload,
                loading: false,
                error: null,
            };
        case UPDATE_STORE_GROUP:
            let auxGroup = [];
            if (
                state &&
                state.user &&
                state.user.group &&
                state.user.group.stores
            ) {
                state.user.group.stores.map((store) => {
                    if (store._id === action.payload._id) {
                        auxGroup.push(action.payload);
                    } else {
                        auxGroup.push(store);
                    }
                    return false;
                });

                state.user.group.stores = auxGroup;
            }
            return {
                ...state,
            };
        case UPDATE_USER_STORES:
            let auxStores = [];
            if (state && state.user && state.user.stores) {
                state.user.stores.map((store) => {
                    if (store._id === action.payload._id) {
                        auxStores.push(action.payload);
                    } else {
                        auxStores.push(store);
                    }
                    return false;
                });

                state.user.stores = auxStores;
            }
            return {
                ...state,
            };
        case CLEAR_STATE:
            return {
                ...state,
                loading: false,
                error: null,
                user: null,
                staticLookupData: null,
                isAuthenticated: false,
                verifyPhone: null,
                verifyToken: null,
                verifyTokenError: null,
                verifyPhoneError: null,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: action.payload,
                error: null,
            };
        case SET_ERROR:
            return {
                ...state,
                [action?.errorType || 'error']: action.payload,
                loading: false,
            };
        case LOGIN_SUCCESS:
        case REGISTER_SUCCESS:
            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('tableTemperatures', true);
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
                loading: false,
                error: null,
                verifyPhone: null,
                verifyToken: null,
                verifyTokenError: null,
                verifyPhoneError: null,
            };
        case UPDATE_PASSWORD:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
                loading: false,
                error: null,
            };
        case RESET_PASSWORD_FAIL:
        case FORGOT_PASSWORD_FAIL:
        case REGISTER_FAIL:
        case LOGIN_FAIL:
        case LOGOUT:
            localStorage.clear();
            channel.unbindAll();
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                user: {},
                error: action.payload,
            };
        case FORGOT_PASSWORD:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case RESET_PASSWORD:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case UPDATE_PROFILE:
            return {
                ...state,
                loading: false,
                error: null,
                user: action.payload,
            };
        case VERIFY_PHONE:
            return {
                ...state,
                loading: false,
                error: null,
                verifyPhone: action.payload,
                verifyPhoneError: null,
            };
        case VERIFY_TOKEN:
            return {
                ...state,
                loading: false,
                error: null,
                verifyToken: action.payload,
                verifyTokenError: null,
            };
        default:
            return state;
    }
};
