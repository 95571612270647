import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        path: '/create/leadService',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'userService',
            'adminService',
            'super admin',
            'general manager',
        ],
        component: lazy(
            () => import('src/views/leadService/LeadServiceCreateView')
        ),
    },
    {
        exact: true,
        path: '/management/leadsService/:id',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'userService',
            'adminService',
            'super admin',
            'general manager',
        ],
        component: lazy(
            () => import('src/views/leadService/LeadServiceDetailsView')
        ),
    },
    {
        exact: true,
        path: '/management/leadsService/:id/edit',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'userService',
            'adminService',
            'super admin',
            'general manager',
        ],
        component: lazy(
            () => import('src/views/leadService/LeadServiceEditView')
        ),
    },
    {
        exact: true,
        path: '/management/leadsService',
        guard: RolesGuard,
        roles: ['rockstar', 'userService', 'adminService', 'super admin'],
        component: lazy(
            () => import('src/views/leadService/LeadServiceListView')
        ),
    },
];
