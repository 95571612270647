import { IEvaluation } from 'src/interfaces/evaluation';
import { CLEAR_STATE, SET_LOADING } from '../types';
import { TEvaluationActionTypes, IEvaluationInitialState } from './types';
import {
    CREATE_EVALUATION,
    DELETE_EVALUATION,
    GET_EVALUATION,
    GET_EVALUATIONS,
    UPDATE_EVALUATION,
} from './constants/actions';
import { updateEvaluationState } from './utils';

export default (
    state: IEvaluationInitialState,
    action: TEvaluationActionTypes
): IEvaluationInitialState => {
    switch (action.type) {
        case GET_EVALUATIONS:
            return updateEvaluationState(state, {
                evaluations: action.payload,
                total: action.total,
            });

        case GET_EVALUATION:
            return updateEvaluationState(state, { evaluation: action.payload });
        case CREATE_EVALUATION:
            return updateEvaluationState(state, {
                evaluation: action.payload,
            });
        case DELETE_EVALUATION:
            if (state.evaluations) {
                state.evaluations = state.evaluations.filter(
                    (evaluation) =>
                        evaluation._id.toString() !== action.payload.toString()
                );
            }
            return updateEvaluationState(state, {});

        case UPDATE_EVALUATION:
            let updatedevaluations: IEvaluation[] = state?.evaluations || [];
            if (!Array.isArray(action.payload)) {
                const payload: IEvaluation = action.payload;

                const updatedIndex = updatedevaluations.findIndex(
                    (evaluation: IEvaluation) => evaluation?._id === payload._id
                );

                if (updatedIndex !== -1)
                    updatedevaluations[updatedIndex] = action.payload;
            }
            return updateEvaluationState(state, {
                evaluations: updatedevaluations,
                evaluation: action.payload,
            });

        case CLEAR_STATE:
            return action.payload;
        case SET_LOADING:
            return updateEvaluationState(state, { loading: true });
        default:
            return state;
    }
};
