import {
    GET_CONVERSATIONS,
    CLEAR_STATE,
    GET_CONVERSATION,
    SET_ERROR,
    CLEAR_ERROR,
    SET_LOADING,
    MESSAGE_SOCKET,
    CONVERSATION_SOCKET,
    DELETE_CONVERSATION,
    SET_FINAL,
    CREATE_LEAD,
    CREATE_BDC,
    UPDATE_CONVERSATION,
    CREATE_SERVICE,
    CLEAR_CONVERSATION,
} from '../types';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

export default (state, action) => {
    const { enqueueSnackbar } = useSnackbar();

    switch (action.type) {
        case UPDATE_CONVERSATION:
            if (action.successMessage) {
                enqueueSnackbar(action.successMessage, {
                    variant: 'success',
                });
            }

            let indexE = state.conversations.findIndex(
                (item) => item._id === action.payload._id
            );
            state.conversations[indexE] = action.payload;
            return {
                ...state,
                conversation: action.payload,
            };
        case CREATE_SERVICE:
            let indexService = state.conversations.findIndex(
                (item) => item._id === action.payload._id
            );
            state.conversations[indexService] = action.payload;
            return {
                ...state,
                conversation: action.payload,
            };
        case CREATE_BDC:
            let indexUBDC = state.conversations.findIndex(
                (item) => item._id === action.payload._id
            );
            state.conversations[indexUBDC] = action.payload;
            return {
                ...state,
                conversation: action.payload,
            };
        case CREATE_LEAD:
            if (action.successMessage) {
                enqueueSnackbar(action.successMessage, {
                    variant: 'success',
                });
            }

            let indexU = state.conversations.findIndex(
                (item) => item._id === action.payload._id
            );
            state.conversations[indexU] = action.payload;
            return {
                ...state,
                conversation: action.payload,
            };
        case DELETE_CONVERSATION:
            let deleteC = state.conversations.filter(
                (item) => item._id !== action.payload
            );
            return {
                ...state,
                loading: false,
                conversation: {},
                conversations: deleteC,
            };
        case SET_FINAL:
            return {
                ...state,
                final: action.payload,
            };
        case CONVERSATION_SOCKET:
            let filterConversations = state.conversations.filter(
                (item) => item._id.toString() !== action.payload._id.toString()
            );

            return {
                ...state,
                conversations: [action.payload, ...filterConversations],
            };
        case MESSAGE_SOCKET:
            let converMessage = _.find(
                state.conversations,
                (item) => item._id === action.conversation
            );
            if (converMessage) {
                let indexConversation = _.findIndex(
                    state.conversations,
                    (item) => item._id === action.conversation
                );

                const currentConverMessage = converMessage.messages;
                const updatedConverMessage = Array.isArray(currentConverMessage)
                    ? currentConverMessage
                    : [];
                state.conversations[indexConversation].messages = [
                    ...updatedConverMessage,
                    action.payload,
                ];
            }

            const currentConversationMessages = state.conversation.messages;
            const updatedConversationMessages = Array.isArray(
                currentConversationMessages
            )
                ? currentConversationMessages
                : [];
            const currentMessages = state.messages;
            const updatedMessages = Array.isArray(currentMessages)
                ? currentMessages
                : [];

            return {
                ...state,
                conversation: {
                    ...state.conversation,
                    messages: [...updatedConversationMessages, action.payload],
                },
                messages: [...updatedMessages, action.payload],
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };
        case GET_CONVERSATION:
            let isRead = _.findIndex(
                state.conversations,
                (item) => item._id === action.payload._id
            );
            state.conversations[isRead] = action.payload;

            return {
                ...state,
                messages: action.payload.messages || [],
                conversation: action.payload,
                loading: false,
            };

        case GET_CONVERSATIONS:
            let convers = [];
            if (action.reload) convers = action.payload;
            else convers = [...state.conversations, ...action.payload];

            return {
                ...state,
                conversations: convers,
                pagination: action.pagination,
                lastItems: action.payload.length,
                final: action.final,
                loading: false,
            };

        case CLEAR_STATE:
            return {
                ...state,
                conversation: {},
                conversations: [],
                pagination: {},
                messages: [],
                error: null,
            };
        case CLEAR_CONVERSATION:
            return {
                ...state,
                conversation: {},
                messages: [],
                error: null,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };

        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};
