import React from 'react';
import { Box, List, ListSubheader } from '@material-ui/core';

const Repeater = ({
    renderNavItems,
    data,
    user,
    location,
    role,
    isFavoriteActive,
    favoriteRoutes,
    setFavoritesRoutes,
}) => {
    return (
        <>
            {user &&
                user.tier &&
                user.tier._id === role &&
                data.map((section) => (
                    <Box p={2} key={Math.random() + Math.random()}>
                        <List
                            key={section.subheader + Math.random()}
                            subheader={
                                <ListSubheader disableGutters disableSticky>
                                    {section.subheader}
                                </ListSubheader>
                            }
                        >
                            {renderNavItems({
                                items: section.items,
                                pathname: location.pathname,
                                isFavoriteActive,
                                favoriteRoutes,
                                setFavoritesRoutes,
                                user,
                            })}
                        </List>
                    </Box>
                ))}
        </>
    );
};

export default Repeater;
