import React from 'react';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';

import useStore from 'src/hooks/useStore';
import useSource from 'src/hooks/useSource';
import useUser from 'src/hooks/useUser';
import useVehicle from 'src/hooks/useVehicle';
import useComment from 'src/hooks/useComment';
import useMake from 'src/hooks/useMake';
import useAppointment from 'src/hooks/useAppointment';
import useFinancialInstitution from 'src/hooks/useFinancialInstitution';
import useDocument from 'src/hooks/useDocument';
import useCompany from 'src/hooks/useCompany';
import useRole from 'src/hooks/useRole';
import usePackage from 'src/hooks/usePackage';

import {
    getMultiStoresIds,
    getMultiStoresMakesIds,
} from 'src/utils/storesUser';

import {
    isAdmin,
    isDigitalMkt,
    isGeneralManager,
    isMarketing,
    isRockstar,
    isUserBDC,
    isSuper,
    isUser,
    isAdminBDC,
    isUserService,
    isAdminService,
    isSalesManager,
    isRH,
    isCarone,
} from 'src/utils/AuthRoles';
import moment from 'moment';
import useLeadService from 'src/hooks/useLeadsService';

const DPXWrapper = ({ children, props }) => {
    const { user } = useAuth();

    const { getSources } = useSource();
    const { getAgents } = useUser();
    const { getStores } = useStore();
    const { getMakes } = useMake();
    const { getCompanies } = useCompany();
    const { getPackages, getPackagesV3 } = usePackage();
    const { getFinancialInstitutions } = useFinancialInstitution();
    const { getVehicles } = useVehicle();
    const { getLeadsServiceV2 } = useLeadService();
    const { getDocuments, getDocumentsByMultiStore } = useDocument();
    const {
        getCommentsByStore,
        getCommentsByUser,
        getCommentsByStoreLeadsBDC,
        getCommentsByUserLeadsBDC,
    } = useComment();
    const { getAppointmentsByStore, getAppointmentsByUser, clearState } =
        useAppointment();
    const { getAllRoles, getRolesByGroup } = useRole();

    const getPackagesDPX = async ({ role, make, user }) => {
        if (isRockstar(role)) {
            await getPackages();
        } else {
            await getPackagesV3({ user: user._id });
        }
    };

    const getDocumentsDPX = async ({ role, store }) => {
        if (
            isSuper(role) ||
            isRH(role) ||
            isAdmin(role) ||
            isAdminBDC(role) ||
            isAdminService(role) ||
            isMarketing(role) ||
            isDigitalMkt(role)
        ) {
            await getDocumentsByMultiStore(
                `&store[in]=${getMultiStoresIds(store)}`
            );
        } else if (isRockstar(role)) {
            await getDocuments();
        }
    };

    const getAppointmentsDPX = async ({ role, id, store }) => {
        if (
            isUser(role) ||
            isUserBDC(role) ||
            isUserService(role) ||
            role === 'agent'
        ) {
            clearState();
            await getAppointmentsByUser(
                id,
                `&startDate[lt]=${moment(new Date())
                    .add(15, 'days')
                    .format('l')}&lead[exists]=true`
            );
        } else if (
            isAdmin(role) ||
            isAdminBDC(role) ||
            isAdminService(role) ||
            isSuper(role) ||
            isRH(role)
        ) {
            clearState();
            await getAppointmentsByStore(
                `&store[in]=${getMultiStoresIds(store)}&startDate[lt]=${moment(
                    new Date()
                )
                    .add(15, 'days')
                    .format('l')}&lead[exists]=true`
            );
        } else if (isRockstar(role)) {
            // await getAppointments()
        } else if (role === 'store') {
            clearState();
            await getAppointmentsByStore(
                `&store[in]=${store}&startDate[lt]=${moment(new Date())
                    .add(15, 'days')
                    .format('l')}&lead[exists]=true`
            );
        }
    };

    const getAppointmentsLeadsBDCDPX = async ({ role, id, store }) => {
        if (
            isUser(role) ||
            isUserBDC(role) ||
            isUserService(role) ||
            role === 'agent'
        ) {
            let queryStores = store
                ? `&store[in]=${getMultiStoresIds(store)}`
                : '';
            await getAppointmentsByUser(
                id,
                `&startDate[lt]=${moment(new Date())
                    .add(15, 'days')
                    .format('l')}&leadBDC[exists]=true${queryStores}`
            );
        } else if (
            isAdmin(role) ||
            isAdminBDC(role) ||
            isAdminService(role) ||
            isSuper(role) ||
            isRH(role)
        ) {
            await getAppointmentsByStore(
                `&store[in]=${getMultiStoresIds(store)}&startDate[lt]=${moment(
                    new Date()
                )
                    .add(15, 'days')
                    .format('l')}&leadBDC[exists]=true`
            );
        } else if (isRockstar(role)) {
            // await getAppointments()
        } else if (role === 'store') {
            await getAppointmentsByStore(
                `&store[in]=${store}&startDate[lt]=${moment(new Date())
                    .add(15, 'days')
                    .format('l')}&leadBDC[exists]=true`
            );
        }
    };

    const getAppointmentsLeadsServiceDPX = async ({ role, id, store }) => {
        const preFilterQuery = [
            {
                unwind: {
                    path: '$vehicles',
                },
            },
            {
                unwind: {
                    path: '$vehicles.appointments',
                },
            },
        ];
        let query = { preFilterQuery };
        if (isUser(role) || role === 'agent') {
            query = {
                ...query,
                or: [
                    { vehicles_appointments_agent__id: id },
                    { vehicles_appointments_operator__id: id },
                ],
                // ['vehicles_appointments_isPending']: true,
                vehicles_appointments_startDate: {
                    lt: moment(new Date()).add(15, 'days').format('l'),
                },
            };
            if (store)
                query['vehicles_appointments_store__id'] =
                    getMultiStoresIds(store).length === 1
                        ? getMultiStoresIds(store)[0]
                        : getMultiStoresIds(store);
            await getLeadsServiceV2(query);
        } else if (
            isAdmin(role) ||
            isAdminService(role) ||
            isSuper(role) ||
            isRH(role) ||
            isUserService(role)
        ) {
            query = {
                ...query,
                vehicles_appointments_store__id: getMultiStoresIds(store),
                // ['vehicles_appointments_isPending']: true,
                vehicles_appointments_startDate: {
                    lt: moment(new Date()).add(15, 'days').format('l'),
                },
            };
            await getLeadsServiceV2(query);
        } else if (isRockstar(role)) {
            // disabled
            // await getLeadsServiceV2(query);
        } else if (role === 'store') {
            query = {
                ...query,
                vehicles_appointments_store__id: store,
                // ['vehicles_appointments_isPending']: true,
                vehicles_appointments_startDate: {
                    lt: moment(new Date()).add(15, 'days').format('l'),
                },
            };
            await getLeadsServiceV2(query);
        }
    };

    const getCommentsDPX = async ({ role, id, store, carType }) => {
        let query = '';
        if (carType) query = carType;
        if (
            isUser(role) ||
            isUserBDC(role) ||
            isUserService(role) ||
            role === 'agent'
        ) {
            await getCommentsByUser(
                id,
                `${query}&reschedule[lt]=${moment(new Date())
                    .add(15, 'days')
                    .format('l')}`
            );
        } else if (
            isAdmin(role) ||
            isAdminBDC(role) ||
            isAdminService(role) ||
            isSuper(role) ||
            isRH(role)
        ) {
            await getCommentsByStore(
                `&store[in]=${getMultiStoresIds(
                    store
                )}${query}&reschedule[lt]=${moment(new Date())
                    .add(15, 'days')
                    .format('l')}`
            );
        } else if (role === 'store') {
            await getCommentsByStore(
                `&store[in]=${store}${query}&reschedule[lt]=${moment(new Date())
                    .add(15, 'days')
                    .format('l')}`
            );
        }
    };

    const getCommentsLeadsBDCDPX = async ({
        role,
        id,
        store,
        carType = false,
    }) => {
        let query = '';
        if (carType) query = carType;
        if (
            isUser(role) ||
            isUserBDC(role) ||
            isUserService(role) ||
            role === 'agent'
        ) {
            await getCommentsByUserLeadsBDC(
                id,
                `${query}&reschedule[lt]=${moment(new Date())
                    .add(15, 'days')
                    .format('l')}`
            );
        } else if (
            isAdmin(role) ||
            isAdminBDC(role) ||
            isAdminService(role) ||
            isSuper(role) ||
            isRH(role)
        ) {
            await getCommentsByStoreLeadsBDC(
                `&store[in]=${getMultiStoresIds(
                    store
                )}${query}&reschedule[lt]=${moment(new Date())
                    .add(15, 'days')
                    .format('l')}`
            );
        } else if (isRockstar(role)) {
            // await getComments(`${query}&reschedule[lt]=${moment(new Date()).add(15, 'days').format('l')}`)
        } else if (role === 'store') {
            await getCommentsByStoreLeadsBDC(
                `&store[in]=${store}${query}&reschedule[lt]=${moment(new Date())
                    .add(15, 'days')
                    .format('l')}`
            );
        }
    };

    const getFinancialInstitutionsDPX = async (role) => {
        if (
            isAdmin(role) ||
            isAdminBDC(role) ||
            isAdminService(role) ||
            isUser(role) ||
            isUserBDC(role) ||
            isUserService(role) ||
            isSuper(role) ||
            isRH(role) ||
            isGeneralManager(role) ||
            isSalesManager(role)
        ) {
            await getFinancialInstitutions(
                `&isActive=true&group=${user.group._id}`
            );
        } else if (isRockstar(role)) {
            await getFinancialInstitutions(`&isActive=true`);
        }
    };

    const getSourcesDPX = async (role, groupId, isActive = true) => {
        let act = '';

        if (isActive) act = '&isActive=true';
        if (
            isAdmin(role) ||
            isAdminBDC(role) ||
            isAdminService(role) ||
            isUser(role) ||
            isUserBDC(role) ||
            isUserService(role) ||
            isSuper(role) ||
            isRH(role) ||
            isGeneralManager(role) ||
            isSalesManager(role) ||
            isMarketing(role) ||
            isDigitalMkt(role)
        ) {
            await getSources(`${act}&group=${user.group._id}`);
        } else if (isRockstar(role)) {
            await getSources(``);
        } else if (role === 'group') {
            await getSources(`${act}&group=${groupId}`);
        }
    };

    const getRolesDPX = async ({ role, group }) => {
        if (
            isAdmin(role) ||
            isAdminBDC(role) ||
            isAdminService(role) ||
            isUser(role) ||
            isUserBDC(role) ||
            isUserService(role) ||
            isMarketing(role) ||
            isSuper(role) ||
            isRH(role) ||
            isDigitalMkt(role)
        ) {
            await getRolesByGroup(user.group._id);
        } else if (isRockstar(role)) {
            await getAllRoles();
        } else if (role === 'group') {
            await getRolesByGroup(group);
        }
    };

    const getCompaniesDpx = async (role) => {
        if (
            isSuper(role) ||
            isRH(role) ||
            isUser(role) ||
            isUserBDC(role) ||
            isUserService(role) ||
            isMarketing(role) ||
            isAdmin(role) ||
            isAdminBDC(role) ||
            isAdminService(role) ||
            isDigitalMkt(role)
        ) {
            await getCompanies(`&group=${user.group._id}`);
        } else if (isRockstar(role)) {
            await getCompanies();
        }
    };

    const childrenDpxProps = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                ...props,
                getStores,
                getMakes,
                getSources: getSourcesDPX,
                getAgents,
                getVehicles,
                getCommentsLeadsBDC: getCommentsLeadsBDCDPX,
                getComments: getCommentsDPX,
                getAppointments: getAppointmentsDPX,
                getAppointmentsLeadsBDC: getAppointmentsLeadsBDCDPX,
                getAppointmentsLeadsService: getAppointmentsLeadsServiceDPX,
                getFinancialInstitutions: getFinancialInstitutionsDPX,
                getDocuments: getDocumentsDPX,
                getCompanies: getCompaniesDpx,
                getPackages: getPackagesDPX,
                getRoles: getRolesDPX,
            });
        }
        return child;
    });

    return <>{childrenDpxProps}</>;
};

DPXWrapper.propTypes = {
    children: PropTypes.node,
};

export default DPXWrapper;
