import { THEMES } from 'src/constants';

export default {
    siteName: 'surman',
    senderName: 'Warbox - Grupo Surman',
    logo: '/static/warbox_logo.png',
    name: 'Warbox',
    theme: THEMES.LIGHT_WARBOX,
    client: 'surman',
    themes: [
        { theme: 'WARBOX_LIGHT', name: 'LIGHT' },
        { theme: 'WARBOX_ONE_DARK', name: 'DARK' },
    ],
};
