import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'general manager',
            'marketing',
        ],
        path: '/create/catalogue',
        component: lazy(
            () => import('src/views/catalogue/CatalogueCreateView')
        ),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'general manager',
            'marketing',
        ],
        path: '/management/catalogue/:id',
        component: lazy(
            () => import('src/views/catalogue/CatalogueDetailsView')
        ),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'general manager',
            'marketing',
        ],
        path: '/management/catalogue/:id/edit',
        component: lazy(() => import('src/views/catalogue/CatalogueEditView')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'general manager',
            'marketing',
        ],
        path: '/management/catalogue',
        component: lazy(() => import('src/views/catalogue/CatalogueListView')),
    },
];
