import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        path: '/create/template-wsp',
        guard: RolesGuard,
        roles: ['rockstar'],
        component: lazy(
            () => import('src/views/templateTwilio/TemplateTwilioCreateView')
        ),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        path: '/management/templates-wsp/:id',
        component: lazy(
            () => import('src/views/templateTwilio/TemplateTwilioDetailsView')
        ),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar'],
        path: '/management/template-wsp/:id/edit',
        component: lazy(
            () => import('src/views/templateTwilio/TemplateTwilioEditView')
        ),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        path: '/management/templates-wsp',
        component: lazy(
            () => import('src/views/templateTwilio/TemplateTwilioListView')
        ),
    },
];
