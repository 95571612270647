import React, { useReducer } from 'react';
import AuthContext from './authContext';
import AuthReducer from './authReducer';
import api from '../../api/api';
import {
    REGISTER_SUCCESS,
    USER_LOADED,
    LOGIN_SUCCESS,
    LOGOUT,
    LOGIN_FAIL,
    REGISTER_FAIL,
    FORGOT_PASSWORD_FAIL,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    RESET_PASSWORD_FAIL,
    SET_ERROR,
    UPDATE_PROFILE,
    UPDATE_PASSWORD,
    SET_LOADING,
    CLEAR_STATE,
    UPDATE_STORE_GROUP,
    GET_STATIC_LOOKUP_DATA,
    UPDATE_USER_STORES,
    VERIFY_PHONE,
} from '../types';
import { HEADERS } from '../../constants/headers';
import { MEXICO_COUNTRY_CODE } from 'src/constants/countryCodes';
import axios from 'axios';

const AuthState = (props) => {
    const initialState = {
        token: localStorage.getItem('token'),
        isAuthenticated: false,
        user: {},
        loading: false,
        staticLookupData: null,
        verifyPhone: null,
        verifyToken: null,
        verifyTokenError: null,
        verifyPhoneError: null,
        error: null,
    };

    const [state, dispatch] = useReducer(AuthReducer, initialState);

    const clearState = () => dispatch({ type: CLEAR_STATE });

    const register = async (values) => {
        try {
            const res = await api.post('/auth/register', values, HEADERS());
            dispatch({ type: REGISTER_SUCCESS, payload: res.data });

            loadUser();
        } catch (err) {
            dispatch({
                type: REGISTER_FAIL,
                payload: err.response.data,
            });
        }
    };

    const updateStoreAuth = async (data, type) => {
        if (type === 'group') {
            dispatch({ type: UPDATE_STORE_GROUP, payload: data });
        }
        if (type === 'stores') {
            dispatch({ type: UPDATE_USER_STORES, payload: data });
        }
    };

    //Set Current User
    const loadUser = async () => {
        try {
            const res = await api.get(`/auth/me`, HEADERS());
            dispatch({
                type: USER_LOADED,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: LOGIN_FAIL, payload: err.response.data });
        }
    };

    //Login User
    const login = async (values) => {
        setLoading();
        try {
            const res = await api.post('/auth/login', values, HEADERS());
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: LOGIN_FAIL,
                payload: err.response.data,
            });
        }
    };

    const handleVerifyPhone = async (phone) => {
        setLoading();
        try {
            const res = await api.post(
                '/twilio/verifyPhone',
                { phone, to: MEXICO_COUNTRY_CODE + phone },
                HEADERS()
            );
            dispatch({
                type: VERIFY_PHONE,
                payload: { ...res.data, phone },
            });
        } catch (err) {
            dispatch({
                type: SET_ERROR,
                payload: err.response.data.error,
                errorType: 'verifyPhoneError',
            });
        }
    };
    const handleVerifyToken = async (code) => {
        setLoading();
        try {
            let { to, phone } = state.verifyPhone;
            const res = await api.post(
                '/twilio/verifyToken',
                { to, code, phone },
                HEADERS()
            );
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: SET_ERROR,
                payload: err.response.data.error,
                errorType: 'verifyTokenError',
            });
        }
    };

    //Update profile
    const updateProfile = async (values, type) => {
        setLoading();

        let res;
        try {
            if (type === 'photo') {
                const uploadConfig = await api.post(
                    '/uploads/image',
                    { type: values.type, fileName: values.name },
                    HEADERS()
                );

                await axios.put(uploadConfig.data.url, values, {
                    headers: { 'Content-Type': values ? values.type : null },
                });

                const dataKey = uploadConfig.data.key;

                res = await api.put(
                    `/auth/updatedetails`,
                    { image: dataKey },
                    HEADERS()
                );
            } else {
                res = await api.put('/auth/updatedetails', values, HEADERS());
            }

            dispatch({
                type: UPDATE_PROFILE,
                payload: res.data,
            });

            loadUser();
        } catch (err) {
            dispatch({
                type: SET_ERROR,
                payload: err.response.data,
            });
        }
    };

    // Logout
    const logout = () => dispatch({ type: LOGOUT });

    //Forgot Password
    const forgotPassword = async (email) => {
        try {
            const res = await api.post(
                '/auth/forgotpassword',
                { ...email },
                HEADERS()
            );
            dispatch({ type: FORGOT_PASSWORD, payload: res.data });
        } catch (err) {
            dispatch({
                type: FORGOT_PASSWORD_FAIL,
                payload: err.response.data,
            });
        }
    };

    //updatePassword
    const updatePassword = async (values) => {
        setLoading();

        try {
            const res = await api.put(
                '/auth/updatepassword',
                { ...values },
                HEADERS()
            );
            dispatch({ type: UPDATE_PASSWORD, payload: res.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Reset Password
    const resetPassword = async (values) => {
        try {
            const res = await api.put(
                `/auth/resetPassword/${values.token}/`,
                { password: values.password },
                HEADERS()
            );
            dispatch({ type: RESET_PASSWORD, payload: res.data });
        } catch (err) {
            dispatch({ type: RESET_PASSWORD_FAIL, payload: err.response.data });
        }
    };

    const getStaticLookupData = async () => {
        setLoading();
        try {
            const res = await api.get(`/auth/staticLookupData`, HEADERS());
            dispatch({ type: GET_STATIC_LOOKUP_DATA, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <AuthContext.Provider
            value={{
                ...state,
                getStaticLookupData,
                register,
                login,
                loadUser,
                logout,
                forgotPassword,
                resetPassword,
                updateProfile,
                updatePassword,
                clearState,
                updateStoreAuth,
                handleVerifyPhone,
                handleVerifyToken,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthState;
