import {
    GET_LEADSSERVICE,
    GET_LEADSERVICE,
    UPDATE_LEADSERVICE,
    DELETE_LEADSERVICE,
    CREATE_LEADSERVICE,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    GET_LEADSSERVICE_BY_STORE,
    GET_LEADSSERVICE_BY_USER,
    SET_VALUE,
    CALL_USER,
    DELETE_MANY_LEADSERVICE,
    ASSIGN_AGENTS,
    ASSIGN_LIST,
    DELETE_SERVICE,
    ADD_APPOINTMENT,
    ADD_COMMENT,
    DELETE_COMMENT,
} from '../types';
import _ from 'lodash';

export default (state, action) => {
    switch (action.type) {
        case ADD_APPOINTMENT:
            if (state.leadService && state.leadService.appointments) {
                let leadServiceApps = [
                    ...state.leadService.appointments,
                    action.payload,
                ];
                state.leadService.appointments = leadServiceApps;
            }
            return {
                ...state,
            };

        case ADD_COMMENT:
            let leadServiceComments = [];
            if (state.leadService && state.leadService.comments) {
                let index = _.findIndex(state.leadService.comments, {
                    _id: action.payload._id,
                });
                if (index !== -1) {
                    leadServiceComments = [...state.leadService.comments];
                } else {
                    leadServiceComments = [
                        action.payload,
                        ...state.leadService.comments,
                    ];
                    if (leadServiceComments[1]) {
                        leadServiceComments[1].pending = false;
                    }
                }
            }
            state.leadService.comments = leadServiceComments;
            return {
                ...state,
            };
        case DELETE_COMMENT:
            if (state.leadService && state.leadService.comments) {
                state.leadService.comments = state.leadService.comments.filter(
                    (comment) =>
                        comment._id.toString() !== action.payload.toString()
                );
            }
            return {
                ...state,
                loading: false,
                error: null,
            };

        case DELETE_SERVICE:
            if (state.leadService && state.leadService.services) {
                state.leadService.services = state.leadService.services.filter(
                    (service) =>
                        service._id.toString() !== action.payload.toString()
                );
            }
            return {
                ...state,
                loading: false,
                error: null,
            };
        case ASSIGN_LIST: {
            state.leadsService.map((leadService, index) => {
                if (action.payload.leadsService.includes(leadService._id)) {
                    state.leadsService[index].lists.push(action.payload.list);
                }
                return false;
            });

            return {
                ...state,
                loading: false,
                error: false,
            };
        }
        case ASSIGN_AGENTS:
            state.leadsService.map((leadService, index) => {
                if (action.payload.leadsService.includes(leadService._id)) {
                    state.leadsService[index].agent = action.payload.user;
                    state.leadsService[index].assignedDate =
                        action.payload.assignedDate;
                }
                return false;
            });

            if (action.tab !== 'all') {
                let type = action.tab.split('.')[0];
                switch (type) {
                    case 'assigned':
                        state.leadsService = state.leadsService.filter(
                            (item) =>
                                item.agent === undefined || item.agent === null
                        );
                        break;
                    default:
                        break;
                }
            }
            return {
                ...state,
                loading: false,
                error: false,
            };
        case CALL_USER:
            return {
                ...state,
                loading: false,
                callToken: action.payload,
                error: null,
            };
        case SET_VALUE:
            return {
                ...state,
                loading: false,
                value: action.payload,
                error: null,
            };
        case GET_LEADSSERVICE:
        case GET_LEADSSERVICE_BY_USER:
            return {
                ...state,
                leadsService: action.payload,
                count: action.count,
                loading: false,
                error: null,
            };
        case GET_LEADSERVICE:
            let sortedServices = action.payload.services;
            sortedServices = _.orderBy(
                sortedServices,
                ['serviceDate'],
                ['desc']
            );
            action.payload.services = sortedServices;
            return {
                ...state,
                leadService: action.payload,
                loading: false,
                error: null,
            };
        case UPDATE_LEADSERVICE:
            return {
                ...state,
                leadService: action.payload,
                loading: false,
                error: null,
            };
        case DELETE_MANY_LEADSERVICE:
            return {
                ...state,
                loading: false,
                error: null,
                deleteMany: true,
            };
        case DELETE_LEADSERVICE:
            state.leadsService = state.leadsService.filter(
                (leadService) =>
                    leadService._id.toString() !== action.payload.toString()
            );
            return {
                ...state,
                leadService: null,
                loading: false,
                error: null,
            };
        case CREATE_LEADSERVICE:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CLEAR_STATE:
            return {
                leadService: {},
                leadsService: [],
                chart: [],
                loading: false,
                error: null,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        case GET_LEADSSERVICE_BY_STORE:
            return {
                ...state,
                loading: false,
                error: null,
                leadsService: action.payload,
                count: action.count,
            };
        default:
            return state;
    }
};
