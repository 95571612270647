import {
    GET_LEADS,
    GET_LEAD,
    UPDATE_LEAD,
    DELETE_LEAD,
    CREATE_LEAD,
    SET_ERROR,
    GET_LEADS_BY_STATUS,
    CLEAR_STATE,
    SET_LOADING,
    GET_LEADS_BY_STORE,
    GET_LAST_LEADS,
    GET_LEADS_BY_USER,
    CALL_USER,
    LOAD_CSV,
    SET_DELETING_MANY,
    DELETE_MANY_LEAD,
    ASSIGN_AGENTS,
    ASSIGN_STATUSES,
    ASSIGN_LIST,
    ADD_COMMENT,
    ADD_APPOINTMENT,
    UPDATE_TASK_FROM_LEAD,
    DELETE_COMMENT,
    UPDATE_PAPERWORK,
    LOAD_CORRECT_CSV,
    GET_TOKEN_UPDATE,
    CREATE_COMMENT,
    SET_SUCCESS,
    MARK_AS_DUPLICATED,
    SEND_MAIL,
    SEND_WHATSAPP,
} from '../types';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

export default (state, action) => {
    const { enqueueSnackbar } = useSnackbar();

    switch (action.type) {
        case SEND_MAIL:
        case MARK_AS_DUPLICATED:
        case CREATE_COMMENT:
        case UPDATE_LEAD:
        case SEND_WHATSAPP:
            return {
                ...state,
                lead: action.payload,
                loading: false,
                success: action.successMessage,
                error: null,
            };
        case GET_TOKEN_UPDATE:
            return {
                ...state,
                tokenUpdate: action.payload,
                loading: false,
                error: null,
            };
        case UPDATE_PAPERWORK:
            if (state.lead) {
                state.lead.paperworkUrl = action.payload;
            }
            return {
                ...state,
                loading: false,
                error: null,
            };

        case DELETE_COMMENT:
            if (state.lead && state.lead.comments) {
                state.lead.comments = state.lead.comments.filter(
                    (comment) =>
                        comment._id.toString() !== action.payload.toString()
                );
            }
            return {
                ...state,
                loading: false,
                error: null,
            };

        case UPDATE_TASK_FROM_LEAD:
            state.lead.comments.map((task, index) => {
                if (task._id === action.payload) {
                    state.lead.comments[index] = {
                        ...state.lead.comments[index],
                        pending: false,
                    };
                }
                return false;
            });
            return {
                ...state,
            };
        case ASSIGN_LIST: {
            state.leads.map((lead, index) => {
                if (action.payload.leads.includes(lead._id)) {
                    state.leads[index].lists.push(action.payload.list);
                }
                return false;
            });

            return {
                ...state,
                loading: false,
                error: false,
            };
        }
        case ASSIGN_AGENTS:
            state.leads.map((lead, index) => {
                if (action.payload.leads.includes(lead._id)) {
                    state.leads[index].agent = action.payload.user;
                    state.leads[index].assignedDate =
                        action.payload.assignedDate;
                }
                return false;
            });

            if (action.tab !== 'all') {
                let type = action.tab.split('.')[0];
                switch (type) {
                    case 'unassigned':
                        state.leads = state.leads.filter(
                            (item) =>
                                item.agent === undefined || item.agent === null
                        );
                        break;
                    default:
                        break;
                }
            }

            return {
                ...state,
                loading: false,
                error: false,
            };
        case ASSIGN_STATUSES:
            state.leads.map((lead, index) => {
                if (action.payload.leads.includes(lead._id)) {
                    state.leads[index].status = action.payload.status;
                    state.leads[index].substatus = action.payload.substatus;
                    state.leads[index].isContacted = action.payload.isContacted;
                }
                return false;
            });

            if (action.tab !== 'all') {
                let type = action.tab.split('.')[0];
                switch (type) {
                    case 'status':
                        state.leads = state.leads.filter(
                            (item) =>
                                item.status._id.toString() ===
                                action.tab.split('.')[1]
                        );
                        break;
                    case 'subStatus':
                        state.leads = state.leads.filter(
                            (item) =>
                                item.substatus._id.toString() ===
                                action.tab.split('.')[1]
                        );
                        break;
                    case 'contacted':
                        state.leads = state.leads.filter(
                            (item) =>
                                item.isContacted.toString() ===
                                action.tab.split('.')[1]
                        );
                        break;
                    default:
                        break;
                }
            }

            return {
                ...state,
                loading: false,
                error: false,
            };
        case LOAD_CORRECT_CSV:
            return {
                ...state,
                loading: false,
                error: null,
            };

        case LOAD_CSV:
            return {
                ...state,
                excelResponse: action.payload,
                loading: false,
                error: null,
            };
        case CALL_USER:
            return {
                ...state,
                loading: false,
                callToken: action.payload,
                error: null,
            };
        case GET_LEADS:
            //? Only current lead type is setted in lead state
            if (!state?.leadType || state?.leadType === action?.leadType) {
                return {
                    ...state,
                    leads: action.payload,
                    count: action.count,
                    leadType: action?.leadType,
                    loading: false,
                    error: null,
                };
            }
            return { ...state, loading: false, error: null };
        case GET_LAST_LEADS:
            return {
                ...state,
                lastLeads: action.payload,
                loading: false,
                error: null,
            };
        case GET_LEAD:
            let sortedActivities = action.payload.activities;
            sortedActivities = _.orderBy(
                sortedActivities,
                ['createdAt'],
                ['desc']
            );
            action.payload.activities = sortedActivities;

            let sortedComments = action.payload.comments;
            sortedComments = _.orderBy(sortedComments, ['createdAt'], ['desc']);
            action.payload.comments = sortedComments;

            let sortedRecordings = action.payload.recordings;
            sortedRecordings = _.orderBy(
                sortedRecordings,
                ['createdAt'],
                ['desc']
            );
            action.payload.recordings = sortedRecordings;
            return {
                ...state,
                lead: action.payload,
                loading: false,
                error: null,
            };
        case ADD_COMMENT:
            let leadComments = [];
            if (state.lead && state.lead.comments) {
                let index = _.findIndex(state.lead.comments, {
                    _id: action.payload._id,
                });
                if (index !== -1) {
                    leadComments = [...state.lead.comments];
                } else {
                    leadComments = [action.payload, ...state.lead.comments];
                    if (leadComments[1]) {
                        leadComments[1].pending = false;
                    }
                }
            }
            state.lead.comments = leadComments;
            return {
                ...state,
            };
        case ADD_APPOINTMENT:
            if (state.lead && state.lead.appointments) {
                let leadApps = [...state.lead.appointments, action.payload];
                state.lead.appointments = leadApps;
            }
            return {
                ...state,
            };
        case GET_LEADS_BY_USER:
            return {
                ...state,
                leads: action.payload,
                loading: false,
                count: action.count,
                error: null,
            };
        case DELETE_MANY_LEAD:
            return {
                ...state,
                loading: false,
                error: null,
                deleteMany: true,
            };
        case SET_DELETING_MANY:
            return {
                ...state,
                loading: false,
                error: null,
                deleteMany: false,
            };
        case DELETE_LEAD:
            state.leads = state.leads.filter(
                (lead) => lead._id.toString() !== action.payload.toString()
            );
            return {
                ...state,
                lead: null,
                loading: false,
                error: null,
            };
        case CREATE_LEAD:
            return {
                ...state,
                success: true,
                loading: false,
                error: null,
            };
        case GET_LEADS_BY_STATUS:
            return {
                ...state,
                leads: action.payload,
                loading: false,
                error: null,
                count: action.count,
            };
        case SET_ERROR:
            enqueueSnackbar(
                action?.payload.error ? action.payload.error : 'error',
                {
                    variant: 'error',
                }
            );
            return {
                ...state,
                error: action.payload,
                loading: false,
                success: false,
            };
        case CLEAR_STATE:
            return {
                leadType: null,
                lead: {},
                leads: [],
                chart: [],
                loading: false,
                error: null,
                success: false,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        case SET_SUCCESS:
            return {
                ...state,
                success: false,
            };
        case GET_LEADS_BY_STORE:
            return {
                ...state,
                loading: false,
                error: null,
                leads: action.payload,
                count: action.count,
            };
        default:
            return state;
    }
};
