import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        path: '/create/user',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('src/views/user/UserCreateView')),
    },
    {
        exact: true,
        path: '/management/users/:id',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'general manager',
            'marketing',
        ],
        component: lazy(() => import('src/views/user/UserDetailsView')),
    },
    {
        exact: true,
        path: '/management/users/:id/edit',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(() => import('src/views/user/UserEditView')),
    },
    {
        exact: true,
        path: '/management/users',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'general manager',
            'marketing',
        ],
        component: lazy(() => import('src/views/user/UserListView')),
    },
];
