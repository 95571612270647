import React, { useReducer } from 'react';
import { EvaluationContext } from './evaluationContext';
import EvaluationReducer from './evaluationReducer';
import { IEvaluation } from 'src/interfaces/evaluation';
import { IEvaluationState } from './types';
import { clearState, setLoading } from '../utils';
import { DEFAULT_EVALUATION_STATE } from './constants/defaultState';
import {
    getEvaluation,
    getEvaluations,
    updateEvaluation,
    deleteEvaluation,
    createEvaluation,
    deleteEvaluations,
} from './utils';

export const EvaluationState = ({ children }: IEvaluationState) => {
    const [state, dispatch] = useReducer(
        EvaluationReducer,
        DEFAULT_EVALUATION_STATE
    );

    return (
        <EvaluationContext.Provider
            value={{
                ...state,
                clearState: () =>
                    clearState(dispatch, DEFAULT_EVALUATION_STATE),
                setLoading: () => setLoading(dispatch),
                getEvaluation: (evaluationId: string) =>
                    getEvaluation(dispatch, evaluationId),
                getEvaluations: (values: Partial<IEvaluation>) =>
                    getEvaluations(dispatch, values),
                updateEvaluation: (
                    evaluationId: string,
                    evaluation: Partial<IEvaluation>
                ) => updateEvaluation(dispatch, evaluationId, evaluation),
                deleteEvaluation: (evaluationId: string) =>
                    deleteEvaluation(dispatch, evaluationId),
                createEvaluation: (evaluation: Partial<IEvaluation>) =>
                    createEvaluation(dispatch, evaluation),
                deleteEvaluations: (
                    evaluation: Partial<IEvaluation>,
                    filters: Partial<IEvaluation>
                ) => deleteEvaluations(dispatch, evaluation, filters),
            }}
        >
            {children}
        </EvaluationContext.Provider>
    );
};
