import _ from 'lodash';
import { getDocumentId } from './mongo/getDocumentId';

export const phoneAgencies = ({ area, areas }) => {
    if (!area) return { success: false };
    let a = areas.find((item) => item.name === area);
    if (a && a.phone)
        return { success: true, phone: a.phone, callerId: a?.phoneCallerId };
    return { success: false };
};

export const isWspAvailable = ({ area, areas }) => {
    if (!area) return { isWspAvailable: false };
    let a = areas.find((item) => item.name === area);
    if (a && a.isWspAvailable)
        return { success: true, isWspAvailable: a.isWspAvailable };
    return { isWspAvailable: false };
};

export const fbAgencies = ({ area, areas }) => {
    let a = areas.find((item) => item.name === area);
    if (a.fb) return { success: true, fb: a.fb };
    return { success: false };
};

export const countMakes = (arr) => {
    let makes = [];
    arr.map((item) => makes.push(item.make._id));

    makes = _.uniqBy(makes);

    if (makes[0] === '5f88806abcf8300017beec5a') {
        return 6;
    }

    return makes.length;
};

export const countStores = (arr) => {
    let stores = [];
    arr.map((item) => stores.push(item._id));

    stores = _.uniqBy(stores);

    return stores.length;
};

export const getMultiRolesIds = (arr) => {
    let result = [];
    arr.map((item) => result.push(item._id));
    return result;
};

export const getMultiRolesNames = (arr) => {
    let result = [];
    arr.map((item) => result.push(item.name));
    return result;
};

export const getMultiStoresIds = (arr = []) => {
    let result = [];
    arr.map((item) => result.push(getDocumentId(item)));
    return result;
};

export const getMultiStoresNames = (arr) => {
    let result = [];
    arr.map((item) => result.push(item.make.name + ' ' + item.name));
    return result;
};

export const getMultiStoresTwilioNumber = (arr) => {
    let result = [];
    arr.map((item) => result.push(item.twilioNumber));
    return result;
};

export const getMultiStoresFbPage = (arr) => {
    let result = [];
    arr.map((item) => result.push(item.fbPage));
    return result;
};

export const getGroupsIds = (arr) => {
    let result = [];
    arr.map((item) => result.push(item._id));
    return result;
};

export const getMultiMakesIds = (arr) => {
    let result = [];
    arr.map((item) => result.push(item._id));
    return result;
};

export const getMultiStoresMakesIds = (arr) => {
    let result = [];
    arr.map((item) => result.push(getDocumentId(item?.make)));

    if (result.includes('5f88806abcf8300017beec5a')) {
        result.push('5d713995b721c3bb38c1f5d3');
        result.push('5f88804dbcf8300017beec56');
        result.push('5f888058bcf8300017beec57');
        result.push('5f887fe6bcf8300017beec52');
    }
    return result;
};

export const getMultiStoresMakesNames = (arr) => {
    let result = [];
    arr.map((item) => result.push(item.make.name));
    return result;
};

export const getMakesUser = (arr) => {
    let result = [];
    arr.map((item) => result.push(item.make));

    return _.uniqBy(result, '_id');
};

export const getStoresByMake = (stores, makeId) => {
    let result = [];
    stores.map((item) => {
        if (item.make && item.make._id === makeId) {
            result.push(item);
        }
        return false;
    });

    return result;
};

export const getStoresByMakeIds = (stores, makeId) => {
    let result = [];
    stores.map((item) => {
        if (item.make && item.make._id === makeId) {
            result.push(item._id);
        }
        return false;
    });

    if (result.length <= 0) result = '60f5890cf852df12c8d10591';

    return result;
};

export const getStoresByMakeNames = (stores, makeId) => {
    let result = [];
    stores.map((item) => {
        if (item.make && item.make._id === makeId) {
            result.push(item.make.name + ' ' + item.name);
        }
        return false;
    });

    return result;
};

export const getUserStoresByMake = (stores, makeId) => {
    let result = [];
    stores.map((item) => {
        if (item.make && item.make._id === makeId) {
            result.push(item._id);
        }
        return false;
    });

    return result;
};

export const getMakesChrysler = (make) => {
    if (
        make === '5f88806abcf8300017beec5a' ||
        make === '616dc2e7a9270cb75207923e'
    ) {
        return [
            '5f88806abcf8300017beec5a',
            '5d713995b721c3bb38c1f5d3',
            '5f88804dbcf8300017beec56',
            '5f888058bcf8300017beec57',
            '5f887fe6bcf8300017beec52',
            '616dc2e7a9270cb75207923e',
        ];
    } else if (make === '64405e2388d80a7bcc7baafe') {
        return ['64fbb9cfab20c786d95ed7f7', '64405e2388d80a7bcc7baafe'];
    } else {
        return [make];
    }
};

export const getPromptsArea = ({ area, prompts }) => {
    if (!area) return { success: false };
    let a = prompts.find((item) => item.name === area);
    if (a && a.name)
        return {
            success: true,
            addressInfo: a.addressInfo || '',
            phoneInfo: a.phoneInfo || '',
            vehicleInfo: a.vehicleInfo || '',
            promotionsInfo: a.promotionsInfo || '',
            otherInfo: a.otherInfo || '',
            name: a.name || area,
            isActive: a && a.isActive === true ? true : false,
        };
    return {
        success: false,
        addressInfo: '',
        phoneInfo: '',
        vehicleInfo: '',
        promotionsInfo: '',
        otherInfo: '',
        name: area,
        isActive: true,
    };
};
