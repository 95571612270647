import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
    Button,
    Checkbox,
    Collapse,
    ListItem,
    Typography,
    makeStyles,
    useTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarBorder';

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'block',
        paddingTop: 0,
        paddingBottom: 0,
    },
    itemLeaf: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    button: {
        color: theme.palette.text.secondary,
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
    },
    buttonLeaf: {
        color: theme.palette.text.secondary,
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightRegular,
        '&.depth-0': {
            '& $title': {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
    },
    title: {
        marginRight: 'auto',
    },
    active: {
        color: theme.palette.secondary.main,
        '& $title': {
            fontWeight: theme.typography.fontWeightMedium,
        },
        '& $icon': {
            color: theme.palette.secondary.main,
        },
    },
}));

const NavItem = ({
    children,
    isFavoriteActive,
    favoriteRoutes,
    setFavoritesRoutes,
    className,
    depth = 0,
    href,
    icon: Icon,
    info: Info,
    open: openProp = false,
    title,
    ...rest
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(openProp);
    const theme = useTheme();

    const styleStart = {
        color: theme.palette.warning.main,
        width: 25,
        height: 25,
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    let paddingLeft = 8;

    if (depth > 0) {
        paddingLeft = 32 + 8 * depth;
    }

    const style = { paddingLeft };

    const handleSetFavorite = (route) => {
        let aux = [...favoriteRoutes];
        if (aux.includes(route)) aux = aux.filter((el) => el !== route);
        else aux = [...aux, route];
        setFavoritesRoutes(aux);
    };

    if (children) {
        return (
            <ListItem
                className={clsx(classes.item, className)}
                disableGutters
                key={title + Math.random()}
                {...rest}
            >
                <Button
                    className={classes.button}
                    onClick={handleToggle}
                    style={style}
                >
                    {Icon && <Icon className={classes.icon} size="20" />}
                    <span className={classes.title}>{title}</span>
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
                <Collapse in={open}>{children && children}</Collapse>
            </ListItem>
        );
    }

    return (
        <ListItem
            className={clsx(classes.itemLeaf, className)}
            disableGutters
            key={title + Math.random()}
            {...rest}
        >
            {isFavoriteActive ? (
                <div
                    activeClassName={classes.active}
                    className={clsx(classes.buttonLeaf, `depth-${depth}`)}
                    style={{
                        ...style,
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: 8,
                        paddingTop: 14,
                        paddingBottom: 12.5,
                    }}
                >
                    {Icon && <Icon className={classes.icon} size="20" />}
                    <Typography variant="h5" className={classes.title}>
                        {title}
                    </Typography>
                    {Info && <Info />}
                    <Checkbox
                        style={{ height: 10 }}
                        icon={<StarOutlineIcon style={styleStart} />}
                        checkedIcon={<StarIcon style={styleStart} />}
                        onClick={() => handleSetFavorite(href)}
                        checked={favoriteRoutes.includes(href)}
                    />
                </div>
            ) : (
                <Button
                    activeClassName={classes.active}
                    className={clsx(classes.buttonLeaf, `depth-${depth}`)}
                    component={RouterLink}
                    exact
                    style={style}
                    to={href}
                >
                    {Icon && <Icon className={classes.icon} size="20" />}
                    <span className={classes.title}>{title}</span>
                    {Info && <Info />}
                    {favoriteRoutes.includes(href) && (
                        <StarIcon style={styleStart} />
                    )}
                </Button>
            )}
        </ListItem>
    );
};

export default NavItem;
