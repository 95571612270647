import React, { useReducer } from 'react';
import MediaContext from './mediaContext';
import MediaReducer from './mediaReducer';
import api from '../../api/api';
import { SET_LOADING, SET_ERROR, CLEAR_STATE, SET_MEDIAS } from '../types';
import { HEADERS } from '../../constants/headers';
import axios from 'axios';

const MediaState = (props) => {
    const initialState = {
        media: null,
        medias: [],
        loading: false,
        error: null,
        count: 0,
    };

    const [state, dispatch] = useReducer(MediaReducer, initialState);

    //Upload one single file
    const uploadFile = async (file) => {
        try {
            clearState();
            setLoading();

            const uploadConfig = await api.post(
                '/uploads/image',
                { type: file.type, fileName: file.name },
                HEADERS()
            );

            await axios.put(uploadConfig.data.url, file, {
                headers: { 'Content-Type': file ? file.type : null },
            });

            const dataKey = uploadConfig.data.key;
            dispatch({
                type: SET_MEDIAS,
                payload: {
                    media: dataKey,
                },
            });
        } catch (err) {
            dispatch({
                type: SET_ERROR,
                payload: err.response.data.error,
            });
        }
    };

    //Clear the state
    const clearState = () => dispatch({ type: CLEAR_STATE });

    //Set loading true
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <MediaContext.Provider
            value={{
                media: state.media,
                medias: state.medias,
                error: state.error,
                count: state.count,
                loadingMedias: state.loading,
                clearMedias: clearState,
                setLoading,
                uploadFile,
            }}
        >
            {props.children}
        </MediaContext.Provider>
    );
};

export default MediaState;
