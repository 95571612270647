import { DIGITAL_AREA, TUserCarType } from 'src/constants/user';
import { BOTH, NEW, PREOWNED } from 'src/constants/CARTYPE';
import {
    NEWS_LEAD_TYPE,
    PREOWNEDS_LEAD_TYPE,
    TAreas,
} from 'src/constants/lead/leadTypes';

/**
 * Will return the area's name corresponding of the area and carType
 *
 * @param {TAreas} area
 * @param {TUserCarType} carType
 */
export const getNotificationArea = (
    area: TAreas,
    carType: TUserCarType
): string[] => {
    let response = [];

    if (area === DIGITAL_AREA) {
        const isNew = carType === NEW;
        const isPrewoned = carType === PREOWNED;
        const isBoth = carType === BOTH;

        if (isNew || isBoth) response.push(NEWS_LEAD_TYPE);
        if (isPrewoned || isBoth) response.push(PREOWNEDS_LEAD_TYPE);
    } else {
        response.push(area);
    }

    return response;
};
