import {
    GET_SERVICES,
    CREATE_SERVICE,
    GET_SERVICE,
    DELETE_SERVICE,
    UPDATE_SERVICE,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';

export default (state, action) => {
    switch (action.type) {
        case GET_SERVICES:
            return {
                ...state,
                services: action.payload,
                loading: false,
                error: null,
            };
        case GET_SERVICE:
            return {
                ...state,
                service: action.payload,
                loading: false,
                error: null,
            };
        case CREATE_SERVICE:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case DELETE_SERVICE:
            state.services = state.services.filter(
                (service) =>
                    service._id.toString() !== action.payload.toString()
            );
            return {
                ...state,
                service: null,
                loading: false,
                error: null,
            };
        case UPDATE_SERVICE:
            return {
                ...state,
                service: action.payload,
                loading: false,
                error: null,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CLEAR_STATE:
            return {
                loading: false,
                error: null,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
};
