export const WHATSAPP_CHANNEL = 'whatsapp';
export const MESSENGER_CHANNEL = 'messenger';
export const CHAT_CHANNEL = 'chat';

export const CONVERSATION_CHANNELS = [
    WHATSAPP_CHANNEL,
    MESSENGER_CHANNEL,
    CHAT_CHANNEL,
];

export const INCOME_DIRECTION = 'income';
export const OUTCOME_DIRECTION = 'outcome';

export const API_TWILIO = 'twilio';
export const API_WSP = 'wsp';

export const CONVERSATION_DIRECTIONS = [INCOME_DIRECTION, OUTCOME_DIRECTION];

export const TWILIO_NAME = 'Twilio';
export const WSP_NAME = 'Whatsapp';

export type TConversationProvider = typeof API_TWILIO | typeof API_WSP;
