import { lazy } from 'react'
import RolesGuard from 'src/components/RolesGuard'

export const Routes = [
    {
        exact: true,
        path: '/management/fleetCompanies',
        guard: RolesGuard,
        roles: ['rockstar', 'admin', 'super admin', 'general manager'],
        component: lazy(
            () => import('src/views/fleetCompany/FleetCompanyListView')
        ),
    },
]
