import axios from 'axios';
import SITE_SETTINGS from 'src/constants/whiteLabel';
import apiService from './apiService';

export const baseURL =
    SITE_SETTINGS.apiV1[process.env.REACT_APP_NODE_ENV || 'development'];

const baseURLWithoutApiV1 = baseURL.replace('/api/v1', '');

export const pingApi = axios.create({
    baseURL: baseURLWithoutApiV1,
});

export default apiService;
