import { THEMES } from 'src/constants';

export default {
    siteName: 'caronecloud',
    senderName: 'Car One Cloud',
    logo: '/static/carone_logo.png',
    name: 'Car One Cloud',
    theme: THEMES.LIGHT,
    client: 'carone',
    themes: [
        { theme: 'LIGHT', name: 'LIGHT' },
        { theme: 'ONE_DARK', name: 'DARK' },
        { theme: 'PINK', name: 'PINK' },
        { theme: 'LIGHT_PINK', name: 'LIGHT PINK' },
        { theme: 'CATS', name: 'PUSHEEN' },
        { theme: 'BLUE', name: 'BLUE' },
        { theme: 'RED', name: 'RED' },
    ],
};
