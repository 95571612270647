import React, { useReducer } from 'react';
import LeadContext from './leadContext';
import LeadReducer from './leadReducer';
import api from '../../api/api';
import calls from '../../api/apiCalls';
import {
    GET_LEADS,
    GET_LEAD,
    UPDATE_LEAD,
    DELETE_LEAD,
    CREATE_LEAD,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    GET_LEADS_BY_STORE,
    GET_LEADS_BY_USER,
    CALL_USER,
    LOAD_CSV,
    ASSIGN_AGENTS,
    ASSIGN_STATUSES,
    ADD_COMMENT,
    ADD_APPOINTMENT,
    UPDATE_TASK_FROM_LEAD,
    DELETE_COMMENT,
    UPDATE_PAPERWORK,
    LOAD_CORRECT_CSV,
    GET_TOKEN_UPDATE,
    CREATE_COMMENT,
    SET_SUCCESS,
    MARK_AS_DUPLICATED,
    SEND_MAIL,
    SEND_WHATSAPP,
} from '../types';
import { HEADERS } from '../../constants/headers';
import { ApiV2 } from 'src/api/apiv2';
import { ADD } from 'src/constants/events/files';
import { useTranslation } from 'react-i18next';
import { uploadImageToS3 } from '../utils/uploadImageToS3';
import { AWS_S3 } from 'src/constants/crm/aws';
import axios from 'axios';
import { getSignatureImage } from 'src/utils/evaluation';

const LeadState = (props) => {
    const initialState = {
        leads: [],
        lead: {},
        leadType: null,
        loading: false,
        error: null,
        analytics: [],
        lastLeads: [],
        labels: null,
        count: null,
        callToken: null,
        excelResponse: [],
        tokenUpdate: null,
        success: false,
    };
    const { t } = useTranslation();

    const [state, dispatch] = useReducer(LeadReducer, initialState);

    /**
     * Will send a whatsapp template to the lead
     *
     * @param {*} template
     * @param {*} leadId
     */
    const sendTemplateV2 = async (template, leadId) => {
        setLoading();
        try {
            const res = await ApiV2.post(
                `/leads/whatsApp`,
                { ...template, lead: leadId },
                HEADERS()
            );
            dispatch({
                type: SEND_WHATSAPP,
                payload: res.data.response,
                successMessage: 'TwilioTemplates.Send',
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };
    /**
     * Will send the documentation to the lead
     *
     * @param {*} mail
     */
    const sendDocumentationV2 = async (mail) => {
        setLoading();
        try {
            const { attachments } = mail;

            let files = [];
            for (let i = 0; i < attachments.length; i++) {
                const currentAttachment = attachments[i];

                const { key } = await uploadImageToS3(currentAttachment);

                const file = {
                    title: key.split('.').slice(0, -1).join('.'),
                    file: AWS_S3 + key,
                    id: i,
                };

                files.push(file);
            }

            const res = await ApiV2.post(
                `/mailing/documentation`,
                { ...mail, attachments: files },
                HEADERS()
            );
            dispatch({
                type: SEND_MAIL,
                payload: res.data.response,
                successMessage: 'SnackBar.DocumentationSent',
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    /**
     * Will create a task for the specified lead
     *
     * @param {*} values comment information
     * @return {*}
     */
    const createTaskV2 = async (values) => {
        try {
            const res = await ApiV2.post(
                `tasks/create`,
                { ...values },
                HEADERS()
            );
            dispatch({
                type: CREATE_COMMENT,
                payload: res.data.response,
                successMessage: 'SnackBar.NewTask',
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    /**
     * Will reject the lead
     *
     * @param {*} values
     */
    const rejectLeadV2 = async (values) => {
        try {
            const res = await ApiV2.post(
                `leads/reject`,
                { ...values },
                HEADERS()
            );
            dispatch({
                type: CREATE_COMMENT,
                payload: res.data.response,
                successMessage: 'SnackBar.RejectLead',
            });
            setSuccess();
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Delete lead from state
    const deleteCommentLead = async (commentId) =>
        dispatch({ type: DELETE_COMMENT, payload: commentId });

    const getTokenUpdate = async (leadId) => {
        try {
            const res = await api.get(`/leads/token/${leadId}`, HEADERS());
            dispatch({ type: GET_TOKEN_UPDATE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const sendURL = async (data) => {
        try {
            const res = await api.post(
                `/leads/paperwork`,
                { url: data.url, email: data.email, lead: data.lead },
                HEADERS()
            );
            dispatch({ type: UPDATE_PAPERWORK, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Leads
    const getLeads = async (values) => {
        setLoading();

        try {
            const res = await api.post(
                `/leads/AdvancedResults`,
                { ...values },
                HEADERS()
            );
            dispatch({
                type: GET_LEADS,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const getLeadsV2 = async (values, leadType) => {
        setLoading();
        try {
            const res = await api.post(
                `/leads/AdvancedResultsV2`,
                { ...values },
                HEADERS()
            );
            dispatch({
                type: GET_LEADS,
                leadType,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Leads
    const startCron = async (status) => {
        try {
            await api.post(`/leads/sendAlerts`, { start: status }, HEADERS);
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Leads
    const getLeadsByStore = async (
        storequery,
        pagination,
        tabs,
        query,
        typeQuery,
        multiStore
    ) => {
        setLoading();
        try {
            let res;
            if (!query) query = '';

            if (pagination) {
                if (tabs.includes('temperature')) {
                    let temp = tabs.split('.');
                    res = await api.get(
                        `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&rating=${temp[1]}&searchText=${query}${storequery}${typeQuery}&validation=1${multiStore}`,
                        HEADERS()
                    );
                } else if (tabs.includes('status')) {
                    let status = tabs.split('.');
                    res = await api.get(
                        `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&status=${status[1]}&searchText=${query}${storequery}${typeQuery}&validation=1${multiStore}`,
                        HEADERS()
                    );
                } else if (tabs.includes('subStatus')) {
                    let substatus = tabs.split('.');
                    res = await api.get(
                        `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&substatus=${substatus[1]}&searchText=${query}${storequery}${typeQuery}&validation=1${multiStore}`,
                        HEADERS()
                    );
                } else if (tabs.includes('contacted')) {
                    let contacted = tabs.split('.');
                    res = await api.get(
                        `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&isContacted=${contacted[1]}&searchText=${query}${storequery}${typeQuery}&validation=1${multiStore}`,
                        HEADERS()
                    );
                } else if (tabs.includes('assigned')) {
                    let assigned = tabs.split('.');
                    res = await api.get(
                        `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&assigned=${assigned[1]}&searchText=${query}${storequery}${typeQuery}&validation=1${multiStore}`,
                        HEADERS()
                    );
                } else {
                    res = await api.get(
                        `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&searchText=${query}${storequery}${typeQuery}&validation=1${multiStore}`,
                        HEADERS()
                    );
                }
            }

            dispatch({
                type: GET_LEADS_BY_STORE,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Leads by User
    const getLeadsByUser = async (
        userId,
        pagination,
        tabs,
        query,
        typeQuery
    ) => {
        setLoading();
        try {
            let res;
            if (!query) query = '';

            if (pagination) {
                if (tabs.includes('temperature')) {
                    let temp = tabs.split('.');
                    res = await api.get(
                        `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&rating=${temp[1]}&searchText=${query}&agent=${userId}${typeQuery}&validation=1`,
                        HEADERS()
                    );
                } else if (tabs.includes('status')) {
                    let status = tabs.split('.');
                    res = await api.get(
                        `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&status=${status[1]}&searchText=${query}&agent=${userId}${typeQuery}&validation=1`,
                        HEADERS()
                    );
                } else if (tabs.includes('subStatus')) {
                    let substatus = tabs.split('.');
                    res = await api.get(
                        `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&substatus=${substatus[1]}&searchText=${query}&agent=${userId}${typeQuery}&validation=1`,
                        HEADERS()
                    );
                } else if (tabs.includes('contacted')) {
                    let contacted = tabs.split('.');
                    res = await api.get(
                        `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&isContacted=${contacted[1]}&searchText=${query}&agent=${userId}${typeQuery}&validation=1`,
                        HEADERS()
                    );
                } else if (tabs.includes('assigned')) {
                    let assigned = tabs.split('.');
                    res = await api.get(
                        `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&assigned=${assigned[1]}&searchText=${query}&agent=${userId}${typeQuery}&validation=1`,
                        HEADERS()
                    );
                } else {
                    res = await api.get(
                        `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&searchText=${query}&agent=${userId}${typeQuery}&validation=1`,
                        HEADERS()
                    );
                }
            }

            dispatch({
                type: GET_LEADS_BY_USER,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const setAsDuplicated = async (values, leadId) => {
        setLoading();

        try {
            const res = await ApiV2.post(
                `/leads/mark-as-duplicated/${leadId}`,
                { ...values },
                HEADERS()
            );

            dispatch({
                type: MARK_AS_DUPLICATED,
                payload: res.data.response,
                successMessage: 'SnackBar.Duplicated',
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Single Item by ID
    const getLead = async (leadId) => {
        setLoading();

        try {
            if (leadId && String(leadId) !== 'undefined') {
                const res = await api.get(`/leads/${leadId}`, HEADERS());
                dispatch({
                    type: GET_LEAD,
                    payload: res.data.data,
                });
            } else {
                clearState();
            }
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Update task from Lead
    const updateTaskFromLead = async (taskId) =>
        dispatch({ type: UPDATE_TASK_FROM_LEAD, payload: taskId });

    const uploadCsv = async (data) => {
        setLoading();
        try {
            await api.post(`/utils/uploadCsv`, data, HEADERS());
            dispatch({ type: LOAD_CORRECT_CSV });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const validateExcel = async (data) => {
        setLoading();
        try {
            let res = await api.post(`/utils/validateExcel`, data, HEADERS());
            dispatch({ type: LOAD_CSV, payload: res.data.errores });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const createLeadByExcel = async (data) => {
        setLoading();
        try {
            let res = await api.post(`/leads/excel`, data, HEADERS());
            dispatch({ type: CREATE_LEAD, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const getCreditBureau = async (leadId) => {
        setLoading();
        try {
            const res = await api.get(`/leads/credit/${leadId}`, HEADERS());
            dispatch({
                type: UPDATE_LEAD,
                payload: res.data.data,
                successMessage: 'SnackBar.LeadUpdate',
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Update Lead
    const updateLead = async (lead, leadId) => {
        setLoading();
        try {
            const res = await api.put(
                `/leads/${leadId}`,
                { ...lead },
                HEADERS()
            );
            dispatch({
                type: UPDATE_LEAD,
                payload: res.data.data,
                successMessage: 'SnackBar.LeadUpdate',
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const updatedEvaluationSignatures = async (lead, leadId) => {
        setLoading();
        try {
            const { evaluation } = lead;
            const updateSignature = getSignatureImage(evaluation);
            const updatedLeadEvaluation = {
                evaluation: {
                    ...lead.evaluation,
                    signs: updateSignature,
                },
            };
            const res = await api.put(
                `/leads/${leadId}`,
                updatedLeadEvaluation,
                HEADERS()
            );
            dispatch({
                type: UPDATE_LEAD,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const updateFiles = async ({ files = [], event = ADD, leadId, index }) => {
        setLoading();
        try {
            let uploadConfig;
            let dataKeys = [];

            for (let i = 0; i < files.length; i++) {
                uploadConfig = await api.post('/uploads/publicImage', {
                    type: files[i].type,
                    fileName: files[i].name,
                });

                await axios.put(uploadConfig.data.url, files[i], {
                    headers: {
                        'Content-Type': files[i] ? files[i].type : null,
                    },
                });

                dataKeys.push(`${AWS_S3}${uploadConfig.data.key}`);
            }

            const res = await api.put(
                `/leads/evidences/${leadId}`,
                {
                    images: dataKeys,
                    event,
                    index,
                },
                HEADERS()
            );

            dispatch({
                type: UPDATE_LEAD,
                payload: res.data.data,
                successMessage: 'SnackBar.LeadUpdate',
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const updateLeadDocumentation = async (file, leadId) => {
        setLoading();
        try {
            let uploadConfig;
            let dataKeys = [];
            let res;

            for (let i = 0; i < file.length; i++) {
                uploadConfig = await api.post('/uploads/publicImage', {
                    type: file[i].type,
                    fileName: file[i].name,
                });
                await axios.put(uploadConfig.data.url, file[i], {
                    headers: {
                        headers: {
                            'Content-Type': file[i] ? file[i].type : null,
                        },
                    },
                });
                dataKeys.push(uploadConfig.data.key);
            }

            res = await api.put(`/leads/documentation/${leadId}`, {
                data: dataKeys,
            });
            dispatch({
                type: UPDATE_LEAD,
                payload: res.data.data,
                successMessage: 'SnackBar.LeadUpdate',
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Delete Lead
    const deleteLead = async (leadId) => {
        setLoading();
        try {
            const res = await api.delete(`/leads/${leadId}`, HEADERS());
            dispatch({ type: DELETE_LEAD, payload: res.data.deletedId });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Create Lead
    const createLead = async (lead) => {
        clearState();
        setLoading();
        try {
            const res = await api.post(`/leads`, { ...lead }, HEADERS());
            dispatch({ type: CREATE_LEAD, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const assignAgents = async (leads, agent, currentTab) => {
        setLoading();
        try {
            const res = await api.post(
                `/leads/assignAgent`,
                { leads, agent },
                HEADERS()
            );
            dispatch({
                type: ASSIGN_AGENTS,
                payload: res.data.data,
                tab: currentTab,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const assignStatus = async (
        leads,
        status,
        substatus,
        isContacted,
        currentTab
    ) => {
        setLoading();
        try {
            const res = await api.post(
                `/leads/assignStatus`,
                { leads, status, substatus, isContacted },
                HEADERS()
            );
            dispatch({
                type: ASSIGN_STATUSES,
                payload: res.data.data,
                tab: currentTab,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const AdvancedResults = async (pagination, query) => {
        setLoading();
        try {
            const res = await api.get(
                `/leads?page=${pagination.page}&limit=${pagination.limit}${query}&searchType=and&`,
                HEADERS()
            );
            dispatch({
                type: GET_LEADS,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const generateToken = async (store) => {
        try {
            const res = await calls.post('/calls/token', { store }, HEADERS());
            dispatch({ type: CALL_USER, payload: res.data.token });
        } catch (err) {
            let error = { error: t('Errors.UnableToGetToken') };
            if (err.error) error.error = err.error;

            dispatch({ type: SET_ERROR, payload: error });
        }
    };

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE });

    const addComment = (comment) => {
        if (comment && comment.action && comment.action.includes('sold'))
            return;
        dispatch({ type: ADD_COMMENT, payload: comment });
    };
    const addAppointment = (appointment) =>
        dispatch({ type: ADD_APPOINTMENT, payload: appointment });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    const setSuccess = () => {
        setTimeout(function () {
            dispatch({ type: SET_SUCCESS });
        }, 1000);
    };

    return (
        <LeadContext.Provider
            value={{
                loading: state.loading,
                leads: state.leads,
                lead: state.lead,
                error: state.error,
                newS: state.newS,
                sold: state.sold,
                follow: state.follow,
                date: state.date,
                lastLeads: state.lastLeads,
                analytics: state.analytics,
                labels: state.labels,
                value: state.value,
                count: state.count,
                chart: state.chart,
                callToken: state.callToken,
                chartDash: state.chartDash,
                excelResponse: state.excelResponse,
                tokenUpdate: state.tokenUpdate,
                success: state.success,
                updateTaskFromLead,
                startCron,
                generateToken,
                getLeads,
                getLead,
                updateLead,
                updatedEvaluationSignatures,
                deleteLead,
                createLead,
                clearState,
                setLoading,
                validateExcel,
                getLeadsByStore,
                getLeadsByUser,
                assignAgents,
                updateLeadDocumentation,
                uploadCsv,
                AdvancedResults,
                assignStatus,
                addComment,
                addAppointment,
                deleteCommentLead,
                sendURL,
                getTokenUpdate,
                getCreditBureau,
                updateFiles,
                getLeadsV2,
                setAsDuplicated,
                createLeadByExcel,

                createTaskV2,
                rejectLeadV2,
                sendDocumentationV2,
                sendTemplateV2,
            }}
        >
            {props.children}
        </LeadContext.Provider>
    );
};

export default LeadState;
