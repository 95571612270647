import {
    GET_CONVERSATIONS,
    ADD_CONVERSATION,
    SET_CURRENT_CONVERSATION,
    GET_MESSAGES,
    ADD_MESSAGE,
    CLEAR_STATE,
    CLEAR_MESSAGES,
    SET_CURRENT_CONVERSATION_PRO,
    UPDATE_CONVERSATION_LIST,
    UPDATE_CURRENT,
    UPDATE_LAST_MESSAGE,
    UPDATE_AFTER_DELETE,
    ARCHIVE_CONVERSATION,
    SET_ERROR,
    CLEAR_ERROR,
    SET_LOADING,
    GET_ALL_CHART_LEADS,
    CLEAR_CURRENT,
} from '../types';

export default (state, action) => {
    switch (action.type) {
        case CLEAR_CURRENT:
            return {
                ...state,
                currentConversation: {},
            };
        case ARCHIVE_CONVERSATION:
            let archivedList = [];
            state.conversations.map((conversation) => {
                if (conversation._id !== action.payload._id) {
                    archivedList.push(conversation);
                }
                return false;
            });
            return {
                ...state,
                conversations: archivedList,
                currentConversation: {},
                loading: false,
                error: null,
            };
        case GET_ALL_CHART_LEADS:
            return {
                ...state,
                chart: action.payload,
                count: action.count,
                loading: false,
                error: null,
            };
        case UPDATE_LAST_MESSAGE:
            if (state && state.conversations)
                state.conversations.map((conversation) => {
                    if (
                        conversation._id.toString() ===
                        action.payload.conversation.toString()
                    ) {
                        conversation.messages.push(action.payload.message);

                        if (
                            state &&
                            state.currentConversation &&
                            state.currentConversation._id
                        ) {
                            if (
                                action.payload.conversation.toString() !==
                                state.currentConversation._id.toString()
                            ) {
                                conversation.isRead = false;
                            }
                        }
                    }

                    return false;
                });
            return {
                ...state,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };
        case UPDATE_AFTER_DELETE:
            let updateList = [];

            state.conversations.map((conversation) => {
                if (conversation._id !== action.payload) {
                    updateList.push(conversation);
                }
                return false;
            });
            return {
                ...state,
                conversations: updateList,
                currentConversation: {},
                loading: false,
                error: null,
            };
        case UPDATE_CONVERSATION_LIST:
            if (action.payload[0].event === 'yes') {
                state.conversations = state.conversations.map(
                    (conversation) => {
                        if (conversation._id !== action.payload[0]._id) {
                            return conversation;
                        } else {
                            return action.payload[0];
                        }
                    }
                );
            } else {
                let finalList = [];
                finalList.push(action.payload[0]);
                state.conversations.map((conversation) => {
                    if (
                        conversation._id.toString() !==
                        action.payload[0]._id.toString()
                    ) {
                        finalList.push(conversation);
                    }
                    return false;
                });
                state.conversations = finalList;
            }
            return {
                ...state,
                loading: false,
            };
        case UPDATE_CURRENT:
            return {
                ...state,
                currentConversation: action.payload,
                loading: false,
                error: null,
            };
        case GET_CONVERSATIONS:
            return {
                ...state,
                conversations: [...state.conversations, ...action.payload.data],
                pagination: action.payload.pagination,
                lastItems: action.payload.data.length,
                // currentConversation: {},
                loading: false,
            };
        case ADD_CONVERSATION:
            return {
                ...state,
                conversations: [action.payload, ...state.conversations],
                loading: false,
            };

        case GET_MESSAGES:
            return {
                ...state,
                messages: action.payload,
                loading: false,
            };
        case ADD_MESSAGE:
            return {
                ...state,
                messages: [...state.messages, action.payload],
                loading: false,
            };
        case SET_CURRENT_CONVERSATION_PRO:
            return {
                ...state,
                currentConversation: action.payload,
                messages: action.payload.messages,
                loading: false,
            };
        case SET_CURRENT_CONVERSATION:
            return {
                ...state,
                currentConversation: action.payload,
                loading: false,
            };
        case CLEAR_STATE:
            return {
                ...state,
                currentConversation: null,
                conversations: [],
                pagination: {},
                messages: [],
                error: null,
            };
        case CLEAR_MESSAGES:
            return {
                ...state,
                messages: [],
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loadig: false,
            };

        default:
            return state;
    }
};
