import {
    GET_LEADSBDC,
    GET_LEADBDC,
    UPDATE_LEADBDC,
    DELETE_LEADBDC,
    CREATE_LEADBDC,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    GET_LEADSBDC_BY_STORE,
    GET_LEADSBDC_BY_USER,
    SET_VALUE,
    CALL_USER,
    DELETE_MANY_LEADBDC,
    ASSIGN_AGENTS,
    ASSIGN_LIST,
    DELETE_SERVICE,
    ADD_APPOINTMENT,
    ADD_COMMENT,
    DELETE_COMMENT,
} from '../types';
import _ from 'lodash';

export default (state, action) => {
    switch (action.type) {
        case ADD_APPOINTMENT:
            if (state.leadBDC && state.leadBDC.appointments) {
                let leadBDCApps = [
                    ...state.leadBDC.appointments,
                    action.payload,
                ];
                state.leadBDC.appointments = leadBDCApps;
            }
            return {
                ...state,
            };

        case ADD_COMMENT:
            let leadBDCComments = [];
            if (state.leadBDC && state.leadBDC.comments) {
                let index = _.findIndex(state.leadBDC.comments, {
                    _id: action.payload._id,
                });
                if (index !== -1) {
                    leadBDCComments = [...state.leadBDC.comments];
                } else {
                    leadBDCComments = [
                        action.payload,
                        ...state.leadBDC.comments,
                    ];
                    if (leadBDCComments[1]) {
                        leadBDCComments[1].pending = false;
                    }
                }
            }
            state.leadBDC.comments = leadBDCComments;
            return {
                ...state,
            };
        case DELETE_COMMENT:
            if (state.leadBDC && state.leadBDC.comments) {
                state.leadBDC.comments = state.leadBDC.comments.filter(
                    (comment) =>
                        comment._id.toString() !== action.payload.toString()
                );
            }
            return {
                ...state,
                loading: false,
                error: null,
            };

        case DELETE_SERVICE:
            if (state.leadBDC && state.leadBDC.services) {
                state.leadBDC.services = state.leadBDC.services.filter(
                    (service) =>
                        service._id.toString() !== action.payload.toString()
                );
            }
            return {
                ...state,
                loading: false,
                error: null,
            };
        case ASSIGN_LIST: {
            state.leadsBDC.map((leadBDC, index) => {
                if (action.payload.leadsBDC.includes(leadBDC._id)) {
                    state.leadsBDC[index].lists.push(action.payload.list);
                }
                return false;
            });

            return {
                ...state,
                loading: false,
                error: false,
            };
        }
        case ASSIGN_AGENTS:
            state.leadsBDC.map((leadBDC, index) => {
                if (action.payload.leadsBDC.includes(leadBDC._id)) {
                    state.leadsBDC[index].agent = action.payload.user;
                    state.leadsBDC[index].assignedDate =
                        action.payload.assignedDate;
                }
                return false;
            });

            if (action.tab !== 'all') {
                let type = action.tab.split('.')[0];
                switch (type) {
                    case 'assigned':
                        state.leadsBDC = state.leadsBDC.filter(
                            (item) =>
                                item.agent === undefined || item.agent === null
                        );
                        break;
                    default:
                        break;
                }
            }
            return {
                ...state,
                loading: false,
                error: false,
            };
        case CALL_USER:
            return {
                ...state,
                loading: false,
                callToken: action.payload,
                error: null,
            };
        case SET_VALUE:
            return {
                ...state,
                loading: false,
                value: action.payload,
                error: null,
            };
        case GET_LEADSBDC:
        case GET_LEADSBDC_BY_USER:
            return {
                ...state,
                leadsBDC: action.payload,
                count: action.count,
                loading: false,
                error: null,
            };
        case GET_LEADBDC:
            let sortedServices = action.payload.services;
            sortedServices = _.orderBy(
                sortedServices,
                ['serviceDate'],
                ['desc']
            );
            action.payload.services = sortedServices;
            return {
                ...state,
                leadBDC: action.payload,
                loading: false,
                error: null,
            };
        case UPDATE_LEADBDC:
            return {
                ...state,
                leadBDC: action.payload,
                loading: false,
                error: null,
            };
        case DELETE_MANY_LEADBDC:
            return {
                ...state,
                loading: false,
                error: null,
                deleteMany: true,
            };
        case DELETE_LEADBDC:
            state.leadsBDC = state.leadsBDC.filter(
                (leadBDC) =>
                    leadBDC._id.toString() !== action.payload.toString()
            );
            return {
                ...state,
                leadBDC: null,
                loading: false,
                error: null,
            };
        case CREATE_LEADBDC:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CLEAR_STATE:
            return {
                leadBDC: {},
                leadsBDC: [],
                chart: [],
                loading: false,
                error: null,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        case GET_LEADSBDC_BY_STORE:
            return {
                ...state,
                loading: false,
                error: null,
                leadsBDC: action.payload,
                count: action.count,
            };
        default:
            return state;
    }
};
