import {
    GET_FINANCIAL_INSTITUTIONS,
    CREATE_FINANCIAL_INSTITUTION,
    GET_FINANCIAL_INSTITUTION,
    DELETE_FINANCIAL_INSTITUTION,
    UPDATE_FINANCIAL_INSTITUTION,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';

export default (state, action) => {
    switch (action.type) {
        case GET_FINANCIAL_INSTITUTIONS:
            return {
                ...state,
                financialInstitutions: action.payload,
                loading: false,
                error: null,
            };
        case GET_FINANCIAL_INSTITUTION:
            return {
                ...state,
                financialInstitution: action.payload,
                loading: false,
                error: null,
            };
        case CREATE_FINANCIAL_INSTITUTION:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case DELETE_FINANCIAL_INSTITUTION:
            state.financialInstitutions = state.financialInstitutions.filter(
                (financialInstitution) =>
                    financialInstitution._id.toString() !==
                    action.payload.toString()
            );
            return {
                ...state,
                financialInstitution: null,
                loading: false,
                error: null,
            };
        case UPDATE_FINANCIAL_INSTITUTION:
            return {
                ...state,
                financialInstitution: action.payload,
                loading: false,
                error: null,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CLEAR_STATE:
            return {
                loading: false,
                error: null,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
};
