import Pusher from 'pusher-js';
import { DEVELOPMENT } from 'src/constants/environment';
import SITE_SETTINGS from 'src/constants/whiteLabel';
import {
    printByColor,
    RED_COLOR,
    GREEN_COLOR,
    BLUE_COLOR,
} from 'src/utils/logs';

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
    cluster: 'us2',
});

const pusherSubscription = pusher.subscribe(`${SITE_SETTINGS.client}_channels`);

class Channel {
    constructor() {
        this.callbacks = {};
        this.boundEvents = {};
    }

    bind(eventName, callback) {
        if (!this.boundEvents[eventName]) {
            this.boundEvents[eventName] = true;

            if (!this.callbacks[eventName]) {
                this.callbacks[eventName] = [];
            }
            if (process.env.REACT_APP_NODE_ENV === DEVELOPMENT)
                printByColor({
                    message: `Bind: ${eventName}`,
                    color: GREEN_COLOR,
                });

            pusherSubscription.bind(eventName, (data) => {
                if (process.env.REACT_APP_NODE_ENV === DEVELOPMENT) {
                    printByColor({
                        message: `Callback: ${eventName}`,
                        color: BLUE_COLOR,
                    });
                }
                callback(data);
            });
        } else {
            if (process.env.REACT_APP_NODE_ENV === DEVELOPMENT)
                printByColor({
                    message: `Event ${eventName} is already bound`,
                    color: RED_COLOR,
                });
        }
    }

    unbind(eventName) {
        if (process.env.REACT_APP_NODE_ENV === DEVELOPMENT)
            printByColor({ message: `Unbind: ${eventName}`, color: RED_COLOR });

        pusherSubscription.unbind(eventName);
        delete this.boundEvents[eventName];
    }

    unbindAll() {
        if (process.env.REACT_APP_NODE_ENV === DEVELOPMENT)
            printByColor({
                message: `Unbinded All subscriptions`,
                color: RED_COLOR,
            });

        pusherSubscription.unbind_all();
        this.boundEvents = {};
    }
}

export const channel = new Channel();
