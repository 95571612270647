import SITE_SETTINGS from 'src/constants/whiteLabel';

export const HEADERS = () => {
    let headers = {
        'Content-Type': 'application/json',
        'Client-Name': SITE_SETTINGS.client,
    };

    if (localStorage.getItem('token'))
        headers.Authorization = `Bearer ${localStorage.getItem('token')}`;

    return {
        headers,
    };
};
