import _ from 'lodash';
import { colors, responsiveFontSizes } from '@material-ui/core';
import { THEMES } from 'src/constants';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';
import { createTheme } from '@material-ui/core/styles';
const baseOptions = {
    direction: 'ltr',
    typography,
    overrides: {
        MuiLinearProgress: {
            root: {
                borderRadius: 3,
                overflow: 'hidden',
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 32,
            },
        },
        MuiChip: {
            root: {
                backgroundColor: 'rgba(0,0,0,0.075)',
            },
        },
    },
};

const themesOptions = [
    {
        name: THEMES.DARK_WARBOX,
        palette: {
            type: 'dark',
            action: {
                active: 'rgba(255, 255, 255, 0.54)',
                hover: 'rgba(255, 255, 255, 0.04)',
                selected: 'rgba(255, 255, 255, 0.08)',
                disabled: 'rgba(255, 255, 255, 0.26)',
                disabledBackground: 'rgba(255, 255, 255, 0.12)',
                focus: 'rgba(255, 255, 255, 0.12)',
            },
            background: {
                default: '#191919',
                dark: '#191919',
                paper: '#292929',
            },
            primary: {
                main: '#9D6DF6',
            },
            secondary: {
                main: '#9D6DF6',
            },
            error: {
                main: '#a81a46',
            },
            success: {
                main: '#67965b',
            },
            warning: {
                main: '#967c5b',
            },
            text: {
                primary: '#e6e5e8',
                secondary: '#adb0bb',
            },
            custom: {
                primary: '#e6e5e8',
                secondary: '#eeeeee',
            },
        },
        shadows: strongShadows,
    },
    {
        name: THEMES.LIGHT_WARBOX,
        overrides: {
            MuiInputBase: {
                input: {
                    '&::placeholder': {
                        opacity: 1,
                        color: colors.blueGrey[600],
                    },
                },
            },
        },
        palette: {
            type: 'light',
            action: {
                active: colors.blueGrey[600],
            },
            background: {
                default: colors.common.white,
                dark: '#f4f6f8',
                paper: colors.common.white,
            },
            primary: {
                main: '#9D6DF6',
            },
            secondary: {
                main: '#7D26FD',
            },
            error: {
                main: '#f44336',
            },
            text: {
                primary: colors.blueGrey[900],
                secondary: colors.blueGrey[600],
            },
            custom: {
                primary: colors.blueGrey[900],
                secondary: colors.blueGrey[600],
            },
        },
        shadows: softShadows,
    },

    {
        name: THEMES.LIGHT,
        overrides: {
            MuiInputBase: {
                input: {
                    '&::placeholder': {
                        opacity: 1,
                        color: colors.blueGrey[600],
                    },
                },
            },
        },
        palette: {
            type: 'light',
            action: {
                active: colors.blueGrey[600],
            },
            background: {
                default: colors.common.white,
                dark: '#f4f6f8',
                paper: colors.common.white,
            },
            primary: {
                main: '#423b77',
            },
            secondary: {
                main: '#423b77',
            },
            error: {
                main: '#f44336',
            },
            text: {
                primary: colors.blueGrey[900],
                secondary: colors.blueGrey[600],
            },
            custom: {
                primary: colors.blueGrey[900],
                secondary: colors.blueGrey[600],
            },
        },
        shadows: softShadows,
    },
    {
        name: THEMES.ONE_DARK,
        palette: {
            type: 'dark',
            action: {
                active: 'rgba(255, 255, 255, 0.54)',
                hover: 'rgba(255, 255, 255, 0.04)',
                selected: 'rgba(255, 255, 255, 0.08)',
                disabled: 'rgba(255, 255, 255, 0.26)',
                disabledBackground: 'rgba(255, 255, 255, 0.12)',
                focus: 'rgba(255, 255, 255, 0.12)',
            },
            background: {
                default: '#282C34',
                dark: '#1c2025',
                paper: '#282C34',
            },
            primary: {
                main: '#8a85ff',
            },
            secondary: {
                main: '#8a85ff',
            },
            error: {
                main: '#f44336',
            },
            text: {
                primary: '#e6e5e8',
                secondary: '#adb0bb',
            },
            custom: {
                primary: '#e6e5e8',
                secondary: '#eeeeee',
            },
        },
        shadows: strongShadows,
    },
    {
        name: THEMES.HALLOWEEN,
        palette: {
            type: 'dark',
            action: {
                active: 'rgba(255, 255, 255, 0.54)',
                hover: 'rgba(255, 255, 255, 0.04)',
                selected: 'rgba(255, 255, 255, 0.08)',
                disabled: 'rgba(255, 255, 255, 0.26)',
                disabledBackground: 'rgba(255, 255, 255, 0.12)',
                focus: 'rgba(255, 255, 255, 0.12)',
            },
            background: {
                default: '#282C34',
                dark: '#1c2025',
                paper: '#282C34',
            },
            primary: {
                main: '#F07D23',
            },
            secondary: {
                main: '#b3550c',
            },
            error: {
                main: '#f44336',
            },
            text: {
                primary: '#e6e5e8',
                secondary: '#adb0bb',
            },
            custom: {
                primary: '#e6e5e8',
                secondary: '#eeeeee',
            },
        },
        shadows: strongShadows,
    },
    {
        name: THEMES.NAVIDAD,
        palette: {
            type: 'light',
            action: {
                active: colors.blueGrey[600],
            },
            background: {
                default: colors.common.white,
                dark: '#f4f6f8',
                paper: colors.common.white,
            },
            primary: {
                main: '#8ccec4',
            },
            secondary: {
                main: '#139e93',
            },
            error: {
                main: '#f44336',
            },
            text: {
                primary: colors.blueGrey[900],
                secondary: colors.blueGrey[600],
            },
            custom: {
                primary: colors.blueGrey[900],
                secondary: colors.blueGrey[600],
            },
        },
        shadows: softShadows,
    },
    {
        name: THEMES['NAVIDAD 2023'],
        palette: {
            type: 'light',
            action: {
                active: colors.blueGrey[600],
            },
            background: {
                default: colors.common.white,
                dark: '#f4f6f8',
                paper: colors.common.white,
            },
            primary: {
                main: '#254070',
            },
            secondary: {
                main: '#254070',
            },
            error: {
                main: '#f44336',
            },
            text: {
                primary: colors.blueGrey[900],
                secondary: colors.blueGrey[600],
            },
            custom: {
                primary: colors.blueGrey[900],
                secondary: colors.blueGrey[600],
            },
        },
        shadows: softShadows,
    },
    {
        name: THEMES.GRINCH,
        palette: {
            type: 'light',
            action: {
                active: colors.green[600],
            },
            background: {
                default: colors.common.white,
                dark: '#f4f6f8',
                paper: colors.common.white,
            },
            primary: {
                main: '#589F56',
            },
            secondary: {
                main: '#589F56',
            },
            error: {
                main: '#f44336',
            },
            text: {
                primary: colors.green[900],
                secondary: colors.green[600],
            },
            custom: {
                primary: colors.green[900],
                secondary: colors.green[600],
            },
        },
        shadows: softShadows,
    },
    {
        name: THEMES['GRINCH DARK'],
        palette: {
            type: 'dark',
            action: {
                active: 'rgba(255, 255, 255, 0.54)',
                hover: 'rgba(255, 255, 255, 0.04)',
                selected: 'rgba(255, 255, 255, 0.08)',
                disabled: 'rgba(255, 255, 255, 0.26)',
                disabledBackground: 'rgba(255, 255, 255, 0.12)',
                focus: 'rgba(255, 255, 255, 0.12)',
            },
            background: {
                default: '#191919',
                dark: '#191919',
                paper: '#292929',
            },
            primary: {
                main: '#589F56',
            },
            secondary: {
                main: '#589F56',
            },
            error: {
                main: '#a81a46',
            },
            success: {
                main: '#67965b',
            },
            warning: {
                main: '#967c5b',
            },
            text: {
                primary: '#e6e5e8',
                secondary: '#adb0bb',
            },
            custom: {
                primary: '#e6e5e8',
                secondary: '#eeeeee',
            },
        },
        shadows: strongShadows,
    },
    {
        name: THEMES.ORNN,
        palette: {
            type: 'dark',
            action: {
                active: 'rgba(255, 255, 255, 0.54)',
                hover: 'rgba(255, 255, 255, 0.04)',
                selected: 'rgba(255, 255, 255, 0.08)',
                disabled: 'rgba(255, 255, 255, 0.26)',
                disabledBackground: 'rgba(255, 255, 255, 0.12)',
                focus: 'rgba(255, 255, 255, 0.12)',
            },
            background: {
                default: '#191919',
                dark: '#191919',
                paper: '#292929',
            },
            primary: {
                main: '#ee3d4e',
            },
            secondary: {
                main: '#ce5161',
            },
            error: {
                main: '#a81a46',
            },
            success: {
                main: '#67965b',
            },
            warning: {
                main: '#967c5b',
            },
            text: {
                primary: '#e6e5e8',
                secondary: '#adb0bb',
            },
            custom: {
                primary: '#e6e5e8',
                secondary: '#eeeeee',
            },
        },
        shadows: strongShadows,
    },
    {
        name: THEMES.NAVIDARKS,
        palette: {
            type: 'dark',
            action: {
                active: 'rgba(255, 255, 255, 0.54)',
                hover: 'rgba(255, 255, 255, 0.04)',
                selected: 'rgba(255, 255, 255, 0.08)',
                disabled: 'rgba(255, 255, 255, 0.26)',
                disabledBackground: 'rgba(255, 255, 255, 0.12)',
                focus: 'rgba(255, 255, 255, 0.12)',
            },
            background: {
                default: '#191919',
                dark: '#191919',
                paper: '#292929',
            },
            primary: {
                main: '#fff',
            },
            secondary: {
                main: '#ffa600',
            },
            error: {
                main: '#a81a46',
            },
            success: {
                main: '#67965b',
            },
            warning: {
                main: '#967c5b',
            },
            text: {
                primary: '#e6e5e8',
                secondary: '#adb0bb',
            },
            custom: {
                primary: '#e6e5e8',
                secondary: '#eeeeee',
            },
        },
        shadows: strongShadows,
    },
    {
        name: THEMES.RED,
        palette: {
            type: 'dark',
            action: {
                active: 'rgba(255, 255, 255, 0.54)',
                hover: 'rgba(255, 255, 255, 0.04)',
                selected: 'rgba(255, 255, 255, 0.08)',
                disabled: 'rgba(255, 255, 255, 0.26)',
                disabledBackground: 'rgba(255, 255, 255, 0.12)',
                focus: 'rgba(255, 255, 255, 0.12)',
            },
            background: {
                default: '#191919',
                dark: '#191919',
                paper: '#292929',
            },
            primary: {
                main: '#ee3d4e',
            },
            secondary: {
                main: '#ce5161',
            },
            error: {
                main: '#a81a46',
            },
            success: {
                main: '#67965b',
            },
            warning: {
                main: '#967c5b',
            },
            text: {
                primary: '#e6e5e8',
                secondary: '#adb0bb',
            },
            custom: {
                primary: '#e6e5e8',
                secondary: '#eeeeee',
            },
        },
        shadows: strongShadows,
    },
    {
        name: THEMES.BLUE,
        palette: {
            type: 'dark',
            action: {
                active: 'rgba(255, 255, 255, 0.54)',
                hover: 'rgba(255, 255, 255, 0.04)',
                selected: 'rgba(255, 255, 255, 0.08)',
                disabled: 'rgba(255, 255, 255, 0.26)',
                disabledBackground: 'rgba(255, 255, 255, 0.12)',
                focus: 'rgba(255, 255, 255, 0.12)',
            },
            background: {
                default: '#191919',
                dark: '#191919',
                paper: '#292929',
            },
            primary: {
                main: '#3dbcee',
            },
            secondary: {
                main: '#51a2ce',
            },
            error: {
                main: '#ee3d3d',
            },
            success: {
                main: '#36cf9c',
            },
            warning: {
                main: '#eeb03d',
            },
            text: {
                primary: '#e6e5e8',
                secondary: '#adb0bb',
            },
            custom: {
                primary: '#e6e5e8',
                secondary: '#eeeeee',
            },
        },
        shadows: strongShadows,
    },
    {
        name: THEMES.GWEN,
        palette: {
            type: 'dark',
            action: {
                active: 'rgba(255, 255, 255, 0.54)',
                hover: 'rgba(255, 255, 255, 0.04)',
                selected: 'rgba(255, 255, 255, 0.08)',
                disabled: 'rgba(255, 255, 255, 0.26)',
                disabledBackground: 'rgba(255, 255, 255, 0.12)',
                focus: 'rgba(255, 255, 255, 0.12)',
            },
            background: {
                default: '#191919',
                dark: '#191919',
                paper: '#292929',
            },
            primary: {
                main: '#3dbcee',
            },
            secondary: {
                main: '#51a2ce',
            },
            error: {
                main: '#ee3d3d',
            },
            success: {
                main: '#36cf9c',
            },
            warning: {
                main: '#eeb03d',
            },
            text: {
                primary: '#e6e5e8',
                secondary: '#adb0bb',
            },
            custom: {
                primary: '#e6e5e8',
                secondary: '#eeeeee',
            },
        },
        shadows: strongShadows,
    },
    {
        name: THEMES.GWEN_CAFE_CUTIE,
        overrides: {
            MuiInputBase: {
                input: {
                    '&::placeholder': {
                        opacity: 1,
                        color: colors.blueGrey[600],
                    },
                },
            },
        },
        palette: {
            type: 'light',
            action: {
                active: colors.blueGrey[600],
            },
            background: {
                default: '#ffffff',
                dark: '#f0edf5',
                paper: '#f9f5ff',
            },
            primary: {
                main: '#ed98b7',
            },
            secondary: {
                main: '#FFDBE8',
            },
            error: {
                main: '#b53161',
            },
            success: {
                main: '#abefa2',
            },
            warning: {
                main: '#faeb93',
            },
            text: {
                primary: colors.blueGrey[900],
                secondary: colors.blueGrey[600],
            },
            custom: {
                primary: '#e6e5e8',
                secondary: '#eeeeee',
            },
        },
        shadows: softShadows,
    },
    {
        name: THEMES.SAN_VALENTIN,
        overrides: {
            MuiInputBase: {
                input: {
                    '&::placeholder': {
                        opacity: 1,
                        color: colors.blueGrey[600],
                    },
                },
            },
        },
        palette: {
            type: 'light',
            action: {
                active: colors.blueGrey[600],
            },
            background: {
                default: '#ffffff',
                dark: '#f0edf5',
                paper: '#f9f5ff',
            },
            primary: {
                main: '#A64838',
            },
            secondary: {
                main: '#faafaf',
            },
            error: {
                main: '#E85F47',
            },
            success: {
                main: '#8cb064',
            },
            warning: {
                main: '#c99565',
            },
            text: {
                primary: colors.blueGrey[900],
                secondary: colors.blueGrey[600],
            },
            custom: {
                primary: '#e6e5e8',
                secondary: '#eeeeee',
            },
        },
        shadows: softShadows,
    },
    {
        name: THEMES.SAN_VALENTIN_SAD,
        palette: {
            type: 'dark',
            action: {
                active: 'rgba(255, 255, 255, 0.54)',
                hover: 'rgba(255, 255, 255, 0.04)',
                selected: 'rgba(255, 255, 255, 0.08)',
                disabled: 'rgba(255, 255, 255, 0.26)',
                disabledBackground: 'rgba(255, 255, 255, 0.12)',
                focus: 'rgba(255, 255, 255, 0.12)',
            },
            background: {
                default: '#151515',
                dark: '#191919',
                paper: '#292929',
            },
            primary: {
                main: '#9c1a1d',
            },
            secondary: {
                main: '#9c3e40',
            },
            error: {
                main: '#9c1a1d',
            },
            success: {
                main: '#296325',
            },
            warning: {
                main: '#635c25',
            },
            text: {
                primary: '#e6e5e8',
                secondary: '#adb0bb',
            },
            custom: {
                primary: '#e6e5e8',
                secondary: '#eeeeee',
            },
        },
        shadows: strongShadows,
    },
    {
        name: THEMES.CATS,
        overrides: {
            MuiInputBase: {
                input: {
                    '&::placeholder': {
                        opacity: 1,
                        color: colors.blueGrey[600],
                    },
                },
            },
        },
        palette: {
            type: 'light',
            action: {
                active: colors.blueGrey[600],
            },
            background: {
                default: '#ffffff',
                dark: '#f0edf5',
                paper: '#f9f5ff',
            },
            primary: {
                main: '#CEBFB3',
            },
            secondary: {
                main: '#A97D61',
            },
            error: {
                main: '#EDAEB0',
            },
            success: {
                main: '#79b874',
            },
            warning: {
                main: '#f5e6be',
            },
            text: {
                primary: colors.brown[600],
                secondary: colors.brown[900],
            },
            custom: {
                primary: '#CEBFB3',
                secondary: '#A97D61',
            },
        },
        shadows: softShadows,
    },
    {
        name: THEMES.LIGHT_PINK,
        overrides: {
            MuiInputBase: {
                input: {
                    '&::placeholder': {
                        opacity: 1,
                        color: colors.blueGrey[600],
                    },
                },
            },
        },
        palette: {
            type: 'light',
            action: {
                active: colors.blueGrey[600],
            },
            background: {
                default: '#ffffff',
                dark: '#f0edf5',
                paper: '#f9f5ff',
            },
            primary: {
                main: '#ed98b7',
            },
            secondary: {
                main: '#FFDBE8',
            },
            error: {
                main: '#b53161',
            },
            success: {
                main: '#abefa2',
            },
            warning: {
                main: '#faeb93',
            },
            text: {
                primary: colors.blueGrey[900],
                secondary: colors.blueGrey[600],
            },
            custom: {
                primary: '#e6e5e8',
                secondary: '#eeeeee',
            },
        },
        shadows: softShadows,
    },
    {
        name: THEMES.NEW_YEAR,
        overrides: {
            MuiInputBase: {
                input: {
                    '&::placeholder': {
                        opacity: 1,
                        color: colors.blueGrey[600],
                    },
                },
            },
        },
        palette: {
            type: 'light',
            action: {
                active: colors.blueGrey[600],
            },
            background: {
                default: '#ffffff',
                dark: '#f0edf5',
                paper: '#f9f5ff',
            },
            primary: {
                main: '#9c4099',
            },
            secondary: {
                main: '#591e57',
            },
            error: {
                main: '#ab4a5f',
            },
            success: {
                main: '#47a159',
            },
            warning: {
                main: '#b8a13e',
            },
            text: {
                primary: colors.blueGrey[900],
                secondary: colors.blueGrey[600],
            },
            custom: {
                primary: colors.blueGrey[900],
                secondary: colors.blueGrey[600],
            },
        },
        shadows: softShadows,
    },
    {
        name: THEMES.DEADS_DAY,
        palette: {
            type: 'dark',
            action: {
                active: 'rgba(255, 255, 255, 0.54)',
                hover: 'rgba(255, 255, 255, 0.04)',
                selected: 'rgba(255, 255, 255, 0.08)',
                disabled: 'rgba(255, 255, 255, 0.26)',
                disabledBackground: 'rgba(255, 255, 255, 0.12)',
                focus: 'rgba(255, 255, 255, 0.12)',
            },
            background: {
                default: '#191919',
                dark: '#191919',
                paper: '#292929',
            },
            primary: {
                main: '#f86795',
            },
            secondary: {
                main: '#815ecc',
            },
            error: {
                main: '#da0d59',
            },
            success: {
                main: '#0cbea3',
            },
            warning: {
                main: '#f5b63a',
            },
            text: {
                primary: '#e6e5e8',
                secondary: '#adb0bb',
            },
            custom: {
                primary: '#e6e5e8',
                secondary: '#eeeeee',
            },
        },
        shadows: strongShadows,
    },

    // {
    //   name: THEMES.UNICORN,
    //   palette: {
    //     type: 'dark',
    //     action: {
    //       active: 'rgba(255, 255, 255, 0.54)',
    //       hover: 'rgba(255, 255, 255, 0.04)',
    //       selected: 'rgba(255, 255, 255, 0.08)',
    //       disabled: 'rgba(255, 255, 255, 0.26)',
    //       disabledBackground: 'rgba(255, 255, 255, 0.12)',
    //       focus: 'rgba(255, 255, 255, 0.12)'
    //     },
    //     background: {
    //       default: '#2a2d3d',
    //       dark: '#222431',
    //       paper: '#2a2d3d'
    //     },
    //     primary: {
    //       main: '#a67dff'
    //     },
    //     secondary: {
    //       main: '#a67dff'
    //     },
    //     error: {
    //       main: '#f44336'
    //     },
    //     text: {
    //       primary: '#f6f5f8',
    //       secondary: '#9699a4'
    //     },
    //     custom: {
    //       primary: '#e6e5e8',
    //       secondary: '#eeeeee'
    //     }
    //   },
    //   shadows: strongShadows
    // },
    {
        name: THEMES.PINK,
        palette: {
            type: 'dark',
            action: {
                active: 'rgba(255, 255, 255, 0.54)',
                hover: 'rgba(255, 255, 255, 0.04)',
                selected: 'rgba(255, 255, 255, 0.08)',
                disabled: 'rgba(255, 255, 255, 0.26)',
                disabledBackground: 'rgba(255, 255, 255, 0.12)',
                focus: 'rgba(255, 255, 255, 0.12)',
            },
            background: {
                default: '#2a2d3d',
                dark: '#222831',
                paper: '#2a323d',
            },
            primary: {
                main: '#f48fb1',
            },
            secondary: {
                main: '#f06292',
            },
            error: {
                main: '#f44336',
            },
            text: {
                primary: '#f6f5f8',
                secondary: '#fde2ff',
            },
            custom: {
                primary: '#e6e5e8',
                secondary: '#eeeeee',
            },
        },
        shadows: strongShadows,
    },
    {
        name: THEMES.CASTANUELA,
        palette: {
            type: 'light',
            action: {
                active: 'rgba(255, 255, 255, 0.54)',
                hover: 'rgba(255, 255, 255, 0.04)',
                selected: 'rgba(255, 255, 255, 0.08)',
                focus: 'rgba(255, 255, 255, 0.12)',
            },
            background: {
                default: colors.common.white,
                dark: '#f4f6f8',
                paper: colors.common.white,
            },
            primary: {
                main: '#d7bdff',
            },
            secondary: {
                main: '#b585ff',
            },
            error: {
                main: '#f44336',
            },
            text: {
                primary: '#b585ff',
                secondary: '#b585ff',
            },
            custom: {
                primary: '#e6e5e8',
                secondary: '#eeeeee',
            },
        },
        shadows: strongShadows,
    },
    {
        name: THEMES.NOWAYHOME,
        palette: {
            type: 'dark',
            action: {
                active: 'rgba(255, 255, 255, 0.54)',
                hover: 'rgba(255, 255, 255, 0.04)',
                selected: 'rgba(255, 255, 255, 0.08)',
                disabled: 'rgba(255, 255, 255, 0.26)',
                disabledBackground: 'rgba(255, 255, 255, 0.12)',
                focus: 'rgba(255, 255, 255, 0.12)',
            },
            background: {
                default: '#191919',
                dark: '#191919',
                paper: '#292929',
            },
            // #d9e2e7,#10000a,#a71814,#ffffff,#0a2b4e
            primary: {
                main: '#a71814',
            },
            secondary: {
                main: '#a71814',
            },
            error: {
                main: '#ee3d3d',
            },
            success: {
                main: '#36cf9c',
            },
            warning: {
                main: '#eeb03d',
            },
            text: {
                primary: '#e6e5e8',
                secondary: '#adb0bb',
            },
            custom: {
                primary: '#e6e5e8',
                secondary: '#eeeeee',
            },
        },
        shadows: strongShadows,
    },
];

export const createWarboxTheme = (config = {}) => {
    let themeOptions = themesOptions.find(
        (theme) => theme.name === config.theme
    );

    if (!themeOptions) {
        console.warn(new Error(`The theme ${config.theme} is not valid`));
        [themeOptions] = themesOptions;
    }

    let theme = createTheme(
        _.merge({}, baseOptions, themeOptions, { direction: config.direction })
    );

    if (config.responsiveFontSizes) {
        theme = responsiveFontSizes(theme);
    }

    return theme;
};
