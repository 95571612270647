import { lazy } from 'react';
import { AccesLevelGuard } from 'src/components/accessLevelGuard/AccessLevelGuard';
import {
    DEVELOPER_LEVEL_ACCESS,
    ADMIN_LEVEL_ACCESS,
    GROUP_LEVEL_ACCESS,
} from 'src/components/access';

const EVALUATION_VALID_USER_ROLES = [
    DEVELOPER_LEVEL_ACCESS,
    ADMIN_LEVEL_ACCESS,
    GROUP_LEVEL_ACCESS,
];

export const EVALUATION_EDIT_ROUTE = '/management/evaluations/:id';
export const EVALUATION_LIST_ROUTE = '/management/evaluations';
export const EVALUATION_CREATE_ROUTE = '/create/evaluation';

export const Routes = [
    {
        exact: true,
        guard: AccesLevelGuard,
        roles: EVALUATION_VALID_USER_ROLES,
        path: EVALUATION_EDIT_ROUTE,
        component: lazy(
            () => import('src/views/evaluation/EvaluationEditView')
        ),
    },
    {
        exact: true,
        path: EVALUATION_CREATE_ROUTE,
        guard: AccesLevelGuard,
        roles: EVALUATION_VALID_USER_ROLES,
        component: lazy(
            () => import('src/views/evaluation/EvaluationCreateView')
        ),
    },
    {
        exact: true,
        path: EVALUATION_LIST_ROUTE,
        guard: AccesLevelGuard,
        roles: EVALUATION_VALID_USER_ROLES,
        component: lazy(
            () => import('src/views/evaluation/EvaluationListView')
        ),
    },
];
