import React, { useReducer } from 'react';
import TemperatureContext from './temperatureContext';
import TemperatureReducer from './temperatureReducer';
import api from '../../api/api';
import {
    GET_TEMPERATURES,
    GET_TEMPERATURES_BY_MODEL,
    CREATE_TEMPERATURE,
    GET_TEMPERATURE,
    DELETE_TEMPERATURE,
    UPDATE_TEMPERATURE,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';
import { HEADERS } from '../../constants/headers';

const TemperatureState = (props) => {
    const initialState = {
        temperatures: [],
        temperature: {},
        loading: false,
        error: null,
        count: null,
    };

    const [state, dispatch] = useReducer(TemperatureReducer, initialState);

    //Get Temperatures By Store
    const getTemperaturesByStore = async (stores) => {
        setLoading();
        try {
            const res = await api.get(
                `/temperatures/admin?${stores}`,
                HEADERS()
            );
            dispatch({ type: GET_TEMPERATURES, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const AdvancedResults = async (pagination, query) => {
        setLoading();

        try {
            const res = await api.get(
                `/temperatures/temperaturesAr?page=${pagination.page}&limit=${pagination.limit}${query}&searchType=and&validation=1`,
                HEADERS()
            );
            dispatch({
                type: GET_TEMPERATURES,
                payload: res.data.data,
                count: res.data.count,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Temperatures
    const getTemperatures = async (
        pagination,
        query,
        typeQuery,
        queryExtra
    ) => {
        setLoading();

        if (!queryExtra) queryExtra = '';
        try {
            const res = await api.get(
                `/temperatures/temperaturesAr?page=${pagination.page}&searchIndex=make-store-vehicle&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1${queryExtra}`,
                HEADERS()
            );
            dispatch({
                type: GET_TEMPERATURES,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Temperature
    const getTemperature = async (temperatureId) => {
        clearState();
        setLoading();
        try {
            const res = await api.get(
                `/temperatures/${temperatureId}`,
                HEADERS()
            );
            dispatch({ type: GET_TEMPERATURE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Temperature
    const getTemperaturesByModel = async (modelId) => {
        clearState();
        setLoading();
        try {
            const res = await api.get(
                `/vehicles/${modelId}/temperatures`,
                HEADERS()
            );
            dispatch({
                type: GET_TEMPERATURES_BY_MODEL,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Create Temperature
    const createTemperature = async (temperature) => {
        setLoading();
        try {
            const res = await api.post(
                `/temperatures`,
                { ...temperature },
                HEADERS()
            );
            dispatch({ type: CREATE_TEMPERATURE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Delete Temperature
    const deleteTemperature = async (temperatureId) => {
        setLoading();
        try {
            const res = await api.delete(
                `/temperatures/${temperatureId}`,
                HEADERS()
            );
            dispatch({ type: DELETE_TEMPERATURE, payload: res.data.deletedId });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Update Temperature
    const updateTemperature = async (temperature, temperatureId) => {
        setLoading();
        try {
            const res = await api.put(
                `/temperatures/${temperatureId}`,
                { ...temperature },
                HEADERS()
            );
            dispatch({ type: UPDATE_TEMPERATURE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <TemperatureContext.Provider
            value={{
                loading: state.loading,
                temperatures: state.temperatures,
                temperature: state.temperature,
                error: state.error,
                count: state.count,
                getTemperature,
                createTemperature,
                deleteTemperature,
                updateTemperature,
                AdvancedResults,
                getTemperaturesByModel,
                getTemperaturesByStore,
                clearState,
                setLoading,
                getTemperatures,
            }}
        >
            {props.children}
        </TemperatureContext.Provider>
    );
};

export default TemperatureState;
