/**
 * It will convert a base64 URL into a File type object.
 * @param {*} url URL The URL in base64 format.
 * @param {*} fileName fileName The name of the resulting file.
 * @return {*} File object created from the base64 URL.
 */

export const getConvertedFile = (url: string | undefined, fileName: string) => {
    if (!url) {
        return null;
    }

    const arr = url.split(',');
    if (arr.length !== 2) {
        return null;
    }

    const mimeMatch = arr[0].match(/:(.*?);/);
    if (!mimeMatch) {
        return null;
    }

    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    const n = bstr.length;
    const u8arr = new Uint8Array(n);

    for (let i = 0; i < n; i++) {
        u8arr[i] = bstr.charCodeAt(i);
    }

    return new File([u8arr], fileName, { type: mime });
};
