export const NEW = 'nuevo';
export const NEW_TRANSLATED = 'new';

export const PREOWNED = 'seminuevo';
export const PREOWNED_TRANSLATED = 'preowned';

export const BOTH = 'ambos';

export const CARTYPES = [NEW, PREOWNED];

export const CARTYPES_TRANSLATIONS_LIBRARY = {
    [NEW]: NEW_TRANSLATED,
    [PREOWNED]: PREOWNED_TRANSLATED,
};
