import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

import {
    ADMIN_STRING,
    DIGITAL_MARKETING_STRING,
    GENERAL_MANAGER_STRING,
    MARKETING_STRING,
    RECEPTIONIST_STRING,
    ROCKSTAR_STRING,
    SALES_MANAGER_STRING,
    SUPER_ADMIN_STRING,
    USER_STRING,
} from 'src/constants/role';

const FLEET_VALID_USER_ROLES = [
    ROCKSTAR_STRING,
    ADMIN_STRING,
    SUPER_ADMIN_STRING,
    USER_STRING,
    GENERAL_MANAGER_STRING,
    SALES_MANAGER_STRING,
    RECEPTIONIST_STRING,
    DIGITAL_MARKETING_STRING,
    MARKETING_STRING,
];

export const FLEET_LEAD_DETAIL_ROUTE = '/management/fleet-leads/:id';
export const FLEET_LEAD_LIST_ROUTE = '/management/fleet-leads';
export const FLEET_LEAD_CREATE_ROUTE = '/create/fleet-lead';

export const LeadsFleetRoutes = [
    {
        exact: true,
        guard: RolesGuard,
        roles: FLEET_VALID_USER_ROLES,
        path: FLEET_LEAD_DETAIL_ROUTE,
        component: lazy(() => import('src/views/leadFleet/LeadDetailsView')),
    },
    {
        exact: true,
        path: FLEET_LEAD_LIST_ROUTE,
        guard: RolesGuard,
        roles: FLEET_VALID_USER_ROLES,
        component: lazy(() => import('src/views/leadFleet/ListView')),
    },
    {
        exact: true,
        path: FLEET_LEAD_CREATE_ROUTE,
        guard: RolesGuard,
        roles: FLEET_VALID_USER_ROLES,

        component: lazy(() => import('src/views/leadFleet/CreateView')),
    },
];
