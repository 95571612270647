import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Leads = [
    {
        exact: true,
        path: '/create/lead',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'user',
            'general manager',
        ],
        component: lazy(() => import('src/views/lead/LeadCreateView')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'user',
            'general manager',
            'sales manager',
            'receptionist',
            'digital marketing',
            'marketing',
        ],
        path: '/management/leads/:id',
        component: lazy(() => import('src/views/lead/LeadDetailsView')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'general manager',
            'marketing',
        ],
        path: '/management/leads/:id/edit',
        component: lazy(() => import('src/views/lead/LeadEditView')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'user',
            'general manager',
            'sales manager',
            'receptionist',
            'marketing',
            'digital marketing',
        ],
        path: '/management/leads',
        component: lazy(() => import('src/views/lead/LeadListView')),
    },
];

export const Showroom = [
    {
        exact: true,
        path: '/create/showroom-lead',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'user',
            'general',
            'general manager',
        ],
        component: lazy(() => import('src/views/lead/ShowRoomCreateView')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'user',
            'general manager',
            'sales manager',
            'marketing',
            'digital marketing',
        ],
        path: '/management/showroom-leads',
        component: lazy(() => import('src/views/lead/ShowRoomListView')),
    },
];

export const BDC = [
    {
        exact: true,
        path: '/create/bdc',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'user',
            'general manager',
            'marketing',
            'digital marketing',
        ],
        component: lazy(() => import('src/views/lead/BDCCreateView')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'user',
            'general manager',
            'sales manager',
            'marketing',
        ],
        path: '/management/bdc/:id',
        component: lazy(() => import('src/views/lead/BDCDetailsView')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'general manager',
            'marketing',
        ],
        path: '/management/bdc/:id/edit',
        component: lazy(() => import('src/views/lead/BDCEditView')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'user',
            'general manager',
            'sales manager',
            'marketing',
        ],
        path: '/management/bdc',
        component: lazy(() => import('src/views/lead/BDCListView')),
    },
];
