import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        path: '/create/status/:id/substatus',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(
            () => import('src/views/substatus/SubstatusCreateView')
        ),
    },
    {
        exact: true,
        path: '/management/substatus/:id',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(
            () => import('src/views/substatus/SubstatusDetailsView')
        ),
    },
    {
        exact: true,
        path: '/management/substatus/:id/edit',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(() => import('src/views/substatus/SubstatusEditView')),
    },
];
