export const DEFAULT_CHART_STATE = {
    count: 0,
    addonsReport: [],
    creditRequestTable: [],
    captacionMedios: [],
    globalSources: [],
    global: [],
    drilldownTime: [],
    drilldownComparative: [],
    reportSales: [],
    funnelDatos: [],
    monitoringAgent: [],
    monitoringAgentSources: {},
    tableSources: [],
    tableAgentSources: [],
    tableAgencies: [],
    drilldownCalls: [],
    drilldownPendingLeads: [],
    drilldownBureau: [],
    drilldownRecordings: [],
    drilldownFunnelSource: [],
    leadsByStore: [],
    appointmentsByStore: [],
    visitsByStore: [],
    soldsByStore: [],
    investmentsByStore: [],
    monthly: [],
    daily: [],
    models: [],
    hours: [],
    linearStores: [],
    matrixInvestment: [],
    pieStatus: [],
    leadsStoreChart: [],
    leadsMonthlyChart: [],
    profitChart: [],
    investmentProfitChart: [],
    operationTypeChart: [],
    temperaturesLeadsTable: [],
    temperaturesAppointmentsTable: [],
    temperaturesVisitsTable: [],
    temperaturesSalesTable: [],
    financialInstitutionChart: [],
    agentsLeadsTable: [],
    agentsComparative: [],
    agentsCallsTable: [],
    storesExtraScoresTable: [],
    storesScoresTable: [],
    agentsCallsChart: [],
    totalTemperatures: [],
    agentsVisitsTable: [],
    agentsAppointmentsTable: [],
    agentsSoldsTable: [],
    agentsStatusChart: [],
    modelsByMakeChart: {},
    conversationsChart: [],
    messagesChart: [],
    temperaturesChart: [],
    investmentsChart: [],
    monthlyAdminChart: [],
    monthlyComparativeChart: [],
    unitMonthlyComparativeChart: [],
    substatusAgentChart: [],
    vsChart: [],
    temperaturesFunnel: [],
    temperaturesFunnelRSI: [],
    temperaturesFunnelRSIComparative: [[], []],
    temperaturesFunnelAppointmentComparative: [[], []],
    statusReportChart: [],
    appointmentsChart: [],
    hoursComparativeChart: [],
    appointmentsStoresChart: [],
    appointmentsDetailsChart: [],
    visitsChart: [],
    weeklyProfitChart: [],
    weeklyInvestmentProfitChart: [],
    appointmentsSubstatusChart: [],
    total: null,
    totalLeads: null,
    totalVisits: null,
    totalAppointments: null,
    totalSolds: null,
    totalNew: null,
    totalFollowUp: null,
    totalCallAgain: null,
    totalWrongnumber: null,
    totalRejected: null,
    totalDocumentation: null,
    totalConfirm: null,
    totalConfirmed: null,
    totalVisitTracking: null,
    totalReschedule: null,
    totalClientNa: null,
    totalVisited: null,
    totalApplication: null,
    totalApprovedApplication: null,
    totalCondicionedApplication: null,
    totalRejectedApplication: null,
    totalSeparation: null,
    loadingCharts: false,
    totalFinancing: null,
    totalInvestment: null,
    totalProfits: null,
    totalProfitsInvestment: null,
    profitStoreChart: null,
    investmentProfitStoreChart: null,
    profitModelChart: null,
    profitSourceChart: null,
    totalAppointmentsDash: null,
    totalVisitsDash: null,
    visitsSubstatusChart: [],
    visitsStoresChart: [],
    visitsDetailsChart: [],
    totalCallsDash: null,
    callsSubstatusChart: [],
    callsStoresChart: [],
    callsDetailsChart: [],
    callsMakesChart: [],
    closureTopUsers: [],
    conversationsByAgent: [],
    conversationsByAgentChart: [],
    statusStoresChart: [],
    statusStoresChartAcumulated: [],
    funnelAppointment: [],
    recordingsReviews: [],
    pendingTasksReport: [],
    bureauChart: [],
    getVisitsReport: [],
    tableModels: [],
    tableLeadsByAgents: [],
    error: null,
    matrix: {
        clicks: 0,
        impressions: 0,
        cpm: 0,
        leads: 0,
        spend: 0,
        costPerLead: 0,
    },
    matrixCampaigns: [],
    matrixAdsets: [],
    matrixSegmentation: {},
    matrixInvestmentCampaign: [],
    matrixInvestmentAdset: [],

    investments: [],
    investment: {},
    saleTimeChart: [],
};
