import { TPluginsKeys } from 'src/constants/plugins';
import { IStore } from 'src/interfaces/store';

/**
 * Will return if the plugin is available on plugins array
 *
 * @param {IStore[] | IStore} stores Agency or agencias where to find
 * @param {TPluginsKeys} plugin Plugin that will be checked
 */
export const isPluginAvailable = (
    stores: IStore[] | IStore,
    plugin: TPluginsKeys
): boolean => {
    if (Array.isArray(stores))
        return stores.some((store) => store?.plugins?.includes(plugin));

    return stores?.plugins?.includes(plugin) ?? false;
};
