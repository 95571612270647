import { THEMES } from 'src/constants';

export default {
    siteName: 'demo',
    senderName: 'Warbox Demo',
    logo: '/static/warbox_logo.png',
    name: 'Warbox Demo',
    theme: THEMES.ONE_DARK,
    client: 'demo',
    themes: [
        { theme: 'WARBOX_LIGHT', name: 'LIGHT' },
        { theme: 'WARBOX_ONE_DARK', name: 'DARK' },
    ],
};
