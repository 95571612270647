import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        path: '/create/recording-test',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(
            () => import('src/views/recordingTest/RecordingCreateView')
        ),
    },
    {
        exact: true,
        path: '/management/recording-tests/:id/edit',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(
            () => import('src/views/recordingTest/RecordingEditView')
        ),
    },
    {
        exact: true,
        path: '/management/recording-tests/:id',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(
            () => import('src/views/recordingTest/RecordingDetailsView')
        ),
    },
    {
        exact: true,
        path: '/management/recording-tests',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(
            () => import('src/views/recordingTest/RecordingListView')
        ),
    },
];
