import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        path: '/create/adCampaign',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(
            () => import('src/views/adCampaign/AdCampaignCreateView')
        ),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        path: '/management/adCampaigns/:id',
        component: lazy(
            () => import('src/views/adCampaign/AdCampaignDetailsView')
        ),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        path: '/management/adCampaigns/:id/edit',
        component: lazy(
            () => import('src/views/adCampaign/AdCampaignEditView')
        ),
    },

    {
        exact: true,
        path: '/management/adCampaigns',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'marketing',
        ],
        component: lazy(
            () => import('src/views/adCampaign/AdCampaignListView')
        ),
    },
];
