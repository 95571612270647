import { getConvertedFile } from '../file/getConvertedFile';
import { AWS_S3 } from 'src/constants/crm/aws';
import { IEvaluation } from 'src/interfaces/evaluation';
import { uploadImageToS3 } from 'src/contexts/utils/uploadImageToS3';

/**
 * will return the signature in the AWS3 format
 *
 * @param {IEvaluation} evaluation
 */
export const getSignatureImage = async (evaluation: IEvaluation) => {
    if (!evaluation?.signs?.length) return [];

    await Promise.all(
        evaluation.signs.map(async (sign, index) => {
            const file = getConvertedFile(
                sign.sign,
                `signature-${index}-${Date.now()}`
            );

            if (file) {
                const { key } = await uploadImageToS3(file);

                return {
                    ...sign,
                    sign: `${AWS_S3}${key}`,
                };
            }
            return sign;
        })
    );
};
