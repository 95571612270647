import {
    GET_USERS,
    GET_AGENTS,
    GET_USER,
    UPDATE_USER,
    DELETE_USER,
    CREATE_USER,
    SET_ERROR,
    SET_LOADING,
    SET_AGENT,
    GET_AGENTS_FORM,
    GET_OPERATORS,
    CLEAR_OPERATORS,
    CLEAR_STATE,
    CLEAR_STATE_AGENTS_FORM,
} from '../types';

const DEFAULT_STATE = {
    user: {},
    users: [],
    loading: false,
    error: null,
    agents: [],
    agentsForm: [],
    operators: [],
};

export default (state, action) => {
    switch (action.type) {
        case GET_OPERATORS:
            return {
                ...state,
                operators: action.payload,
                loading: false,
                error: null,
            };
        case CLEAR_OPERATORS:
            return {
                ...state,
                operators: [],
                loading: false,
                error: null,
            };
        case SET_AGENT:
            return {
                ...state,
                agents: [action.payload],
                loading: false,
                error: null,
            };
        case GET_AGENTS_FORM:
            return {
                ...state,
                agentsForm: action.payload,
                loading: false,
                error: null,
            };
        case GET_AGENTS:
            return {
                ...state,
                agents: action.payload,
                loading: false,
                error: null,
            };
        case GET_USERS:
            return {
                ...state,
                users: action.payload,
                count: action.count,
                loading: false,
                error: null,
            };

        case GET_USER:
            return {
                ...state,
                user: action.payload,
                loading: false,
                error: null,
            };
        case UPDATE_USER:
            return {
                ...state,
                success: 'SnackBar.UserUpdated',
                user: action.payload,
                loading: false,
                error: null,
            };

        case DELETE_USER:
            state.users = state.users.filter(
                (user) => user._id.toString() !== action.payload.toString()
            );
            return {
                ...state,
                user: null,
                loading: false,
                error: null,
            };
        case CREATE_USER:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        case CLEAR_STATE:
            return {
                ...DEFAULT_STATE,
            };

        case CLEAR_STATE_AGENTS_FORM:
            return {
                ...DEFAULT_STATE,
                agents: state.agents,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
};
