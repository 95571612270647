import {
    GET_RECORDINGS,
    CREATE_RECORDING,
    GET_RECORDING,
    DELETE_RECORDING,
    UPDATE_RECORDING,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';

export default (state, action) => {
    switch (action.type) {
        case GET_RECORDINGS:
            return {
                ...state,
                recordings: action.payload,
                count: action.count,
                loading: false,
                error: null,
            };
        case GET_RECORDING:
            return {
                ...state,
                recording: action.payload,
                loading: false,
                error: null,
            };
        case CREATE_RECORDING:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case DELETE_RECORDING:
            state.recordings = state.recordings.filter(
                (recording) =>
                    recording._id.toString() !== action.payload.toString()
            );
            return {
                ...state,
                recording: null,
                loading: false,
                error: null,
            };
        case UPDATE_RECORDING:
            return {
                ...state,
                recording: action.payload,
                loading: false,
                error: null,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CLEAR_STATE:
            return {
                recordings: [],
                recording: {},
                loading: false,
                error: null,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
};
