import React, { useReducer } from 'react';
import ConversationsContext from './conversationsContext';
import ConversationsReducer from './conversationsReducer';
import api from '../../api/api';

import {
    GET_CONVERSATIONS,
    SET_LOADING,
    ADD_CONVERSATION,
    SET_CURRENT_CONVERSATION,
    GET_MESSAGES,
    ADD_MESSAGE,
    CLEAR_STATE,
    CLEAR_MESSAGES,
    SET_CURRENT_CONVERSATION_PRO,
    UPDATE_CURRENT,
    UPDATE_CONVERSATION_LIST,
    UPDATE_AFTER_DELETE,
    UPDATE_LAST_MESSAGE,
    ARCHIVE_CONVERSATION,
    SET_ERROR,
    CLEAR_ERROR,
    GET_ALL_CHART_LEADS,
    CLEAR_CURRENT,
} from '../types';
import axios from 'axios';

const ConversationsState = (props) => {
    const initialState = {
        currentConversation: null,
        conversations: [],
        chart: [],
        pagination: {},
        lastItems: null,
        messages: [],
        loading: false,
        error: null,
    };

    const [state, dispatch] = useReducer(ConversationsReducer, initialState);

    const archiveConversationPro = async (conversationId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        setLoading();
        try {
            const res = await api.put(
                `conversation/archiveConversation/${conversationId}`,
                config
            );
            dispatch({ type: ARCHIVE_CONVERSATION, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const createConversationMessage = async (message) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        setLoading();
        try {
            const res = await api.post(
                'conversation/createConversationMessage',
                message,
                config
            );

            dispatch({ type: ADD_MESSAGE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };
    const sendAudios = async (data, message, storeId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        try {
            const audioResponse = await api.post(
                '/uploads/audios',
                data,
                config
            );

            await api.post(
                '/conversation/sendsms',
                {
                    user: message.user,
                    to: message.to,
                    from: message.from,
                    body: message.body,
                    store: storeId,
                    MediaUrl0: audioResponse.data.key,
                    MediaContentType0: 'audio/wav',
                },
                config
            );
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const createConversationMessagePro = async (
        message,
        files,
        documentation,
        storeId
    ) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };

        try {
            if (!files && !documentation) {
                await api.post(
                    '/conversation/sendsms',
                    {
                        ...message,
                        store: storeId,
                    },
                    config
                );
            }

            if (files) {
                let uploadConfig;
                let dataKeys = [];
                for (let i = 0; i < files.length; i++) {
                    uploadConfig = await api.post(
                        '/uploads/image',
                        { type: files[i].type, fileName: files[i].name },
                        config
                    );
                    await axios.put(uploadConfig.data.url, files[i], {
                        headers: { 'Content-Type': files[i].type },
                    });
                    dataKeys.push(uploadConfig.data.key);
                }

                for (let i = 0; i < dataKeys.length; i++) {
                    await api.post(
                        '/conversation/sendsms',
                        {
                            user: message.user,
                            to: message.to,
                            from: message.from,
                            body: message.body,
                            author: message.author,
                            store: storeId,
                            MediaUrl0: `${dataKeys[i]}`,
                            MediaContentType0: files[i].type.replace(
                                ';codecs=opus',
                                ''
                            ),
                        },
                        config
                    );
                }
            }

            if (documentation) {
                for (let i = 0; i < documentation.length; i++) {
                    await api.post(
                        '/conversation/sendsms',
                        {
                            user: message.user,
                            to: message.to,
                            from: message.from,
                            body: message.body,
                            author: message.author,
                            store: storeId,
                            MediaUrl0: documentation[i],
                            MediaContentType0:
                                documentation[i].split('.')[
                                    documentation[i].split('.').length - 1
                                ],
                        },
                        config
                    );
                }
            }
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const createConversationMessageProBDC = async (
        message,
        files,
        documentation,
        storeId
    ) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        try {
            if (!files && !documentation) {
                await api.post(
                    '/conversation/sendsmsBDC',
                    {
                        ...message,
                        store: storeId,
                    },
                    config
                );
            }

            if (files) {
                let uploadConfig;
                let dataKeys = [];
                for (let i = 0; i < files.length; i++) {
                    uploadConfig = await api.post(
                        '/uploads/image',
                        { type: files[i].type, fileName: files[i].name },
                        config
                    );
                    await axios.put(uploadConfig.data.url, files[i], {
                        headers: { 'Content-Type': files[i].type },
                    });
                    dataKeys.push(uploadConfig.data.key);
                }

                for (let i = 0; i < dataKeys.length; i++) {
                    await api.post(
                        '/conversation/sendsmsBDC',
                        {
                            user: message.user,
                            to: message.to,
                            from: message.from,
                            body: message.body,
                            author: message.author,
                            store: storeId,
                            MediaUrl0: `${dataKeys[i]}`,
                            MediaContentType0: files[i].type,
                        },
                        config
                    );
                }
            }

            if (documentation) {
                for (let i = 0; i < documentation.length; i++) {
                    await api.post(
                        '/conversation/sendsmsBDC',
                        {
                            user: message.user,
                            to: message.to,
                            from: message.from,
                            body: message.body,
                            author: message.author,
                            store: storeId,
                            MediaUrl0: documentation[i],
                            MediaContentType0:
                                documentation[i].split('.')[
                                    documentation[i].split('.').length - 1
                                ],
                        },
                        config
                    );
                }
            }
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getConversation = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        clearState();

        try {
            const res = await api.get(
                `/conversation/getConversations?sort=+dateCreated`,
                config
            );
            dispatch({ type: GET_CONVERSATIONS, payload: res.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const addNewConversationFromSocket = async (data) => {
        dispatch({ type: ADD_CONVERSATION, payload: data });
    };

    const updateConversationLastMessage = async (data) => {
        dispatch({ type: UPDATE_LAST_MESSAGE, payload: data });
    };

    const deleteConversationFromSocket = async (id) => {
        dispatch({ type: UPDATE_AFTER_DELETE, payload: id });
    };

    const addNewMessageFromSocket = async (data) => {
        dispatch({ type: ADD_MESSAGE, payload: data });
    };

    const sendTemplate = async (customer, template, agent, phone, id) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        if (!phone || phone === '') {
            phone = 0;
        }

        await api.post(
            `/conversation/sendTemplate`,
            { customer, agent, ...template, phone, id },
            config
        );
    };

    const sendTemplateNoConversation = async (values) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };

        await api.post(
            `/conversation/sendTemplateNoConversation`,
            { ...values },
            config
        );
    };

    const getMessagesFromConversation = async (sid) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        clearMessages();
        try {
            const res = await api.get(
                `/conversation/getMessages/${sid}`,
                config
            );
            dispatch({ type: GET_MESSAGES, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const setCurrentConversation = async (sid) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        try {
            const res = await api.get(
                `/conversation/getConversation/${sid}`,
                config
            );
            dispatch({
                type: SET_CURRENT_CONVERSATION,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getConversationAr = async (query) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        clearState();
        setLoading();
        try {
            const res = await api.get(
                `/conversation/chartInfo?${query}`,
                config
            );
            dispatch({ type: GET_ALL_CHART_LEADS, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const setCurrentConversationPro = async (sid) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        try {
            dispatch({ type: CLEAR_CURRENT });

            const res = await api.get(
                `/conversation/getConversationsPro/${sid}`,
                config
            );
            dispatch({
                type: SET_CURRENT_CONVERSATION_PRO,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getConversationsPro = async (query, page) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        if (page === 1) clearState();
        setLoading();
        try {
            if (!query) query = '';
            const res = await api.get(
                `/conversation/getConversationsPro?sort=-lastMessage${query}&page=${page}`,
                config
            );
            dispatch({ type: GET_CONVERSATIONS, payload: res.data, page });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const updateConversationPro = async (conversation, conversationId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        try {
            const res = await api.put(
                `/conversation/updateConversationsPro/${conversationId}`,
                { ...conversation },
                config
            );
            dispatch({ type: UPDATE_CURRENT, payload: res.data.data[0] });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const updateConversationProBDC = async (conversation, conversationId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        try {
            const res = await api.put(
                `/conversation/updateConversationsProBDC/${conversationId}`,
                { ...conversation },
                config
            );
            dispatch({ type: UPDATE_CURRENT, payload: res.data.data[0] });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const deleteConversationPro = async (conversationId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        try {
            const res = await api.delete(
                `/conversation/deleteConversationPro/${conversationId}`,
                config
            );
            dispatch({ type: UPDATE_AFTER_DELETE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const updateConversationsList = async (conversation) =>
        dispatch({ type: UPDATE_CONVERSATION_LIST, payload: conversation });

    //   Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });
    const setError = () => dispatch({ type: CLEAR_ERROR });
    const clearState = () => dispatch({ type: CLEAR_STATE });
    const clearMessages = () => dispatch({ type: CLEAR_MESSAGES });

    return (
        <ConversationsContext.Provider
            value={{
                conversations: state.conversations,
                pagination: state.pagination,
                loading: state.loading,
                error: state.error,
                chart: state.chart,
                currentConversation: state.currentConversation,
                lastItems: state.lastItems,
                getConversation,
                addNewConversationFromSocket,
                setCurrentConversation,
                setError,
                getMessagesFromConversation,
                updateConversationsList,
                messages: state.messages,
                createConversationMessage,
                addNewMessageFromSocket,
                clearState,
                clearMessages,
                getConversationsPro,
                setCurrentConversationPro,
                updateConversationPro,
                updateConversationProBDC,
                createConversationMessagePro,
                createConversationMessageProBDC,
                deleteConversationPro,
                deleteConversationFromSocket,
                updateConversationLastMessage,
                sendTemplateNoConversation,
                sendTemplate,
                archiveConversationPro,
                getConversationAr,
                sendAudios,
            }}
        >
            {props.children}
        </ConversationsContext.Provider>
    );
};

export default ConversationsState;
