import React, { useState, useRef, useEffect } from 'react';
import {
    Box,
    Button,
    FormControlLabel,
    IconButton,
    Popover,
    SvgIcon,
    TextField,
    Tooltip,
    Typography,
    makeStyles,
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import { Settings as SettingsIcon } from 'react-feather';
import useSettings from 'src/hooks/useSettings';
import SITE_SETTINGS from 'src/constants/whiteLabel';
import i18next from 'src/utils/i18next';
import { useSnackbar } from 'notistack';
import DpxSwitch from 'src/components/DpxSwitch';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    badge: {
        height: 10,
        width: 10,
        borderRadius: 5,
        marginTop: 10,
        marginRight: 5,
    },
    popover: {
        width: 320,
        padding: theme.spacing(2),
    },
}));

const Settings = () => {
    const classes = useStyles();
    const ref = useRef(null);
    const { settings, saveSettings } = useSettings();
    const [language, setLanguage] = useState(false);
    const { user } = useAuth();
    const [isOpen, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    // const [typeMenu, setTypeMenu] = useState('1');

    const [values, setValues] = useState({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        theme: settings.theme,
    });

    useEffect(() => {
        if (localStorage.getItem('language')) {
            i18next.changeLanguage(localStorage.getItem('language'));

            if (localStorage.getItem('language') === 'es') {
                setLanguage(true);
            } else {
                setLanguage(false);
            }
        } else {
            localStorage.setItem('language', 'en');
            i18next.changeLanguage(localStorage.getItem('language'));
        }

        // if (localStorage.getItem('typeMenu')) {
        //   setTypeMenu(localStorage.getItem('typeMenu'));
        // }
    }, []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (field, value) => {
        setValues({
            ...values,
            [field]: value,
        });
    };

    const handleClearCache = () => {
        let lenguaje = localStorage.getItem('language');
        // let menu = localStorage.getItem('typeMenu')
        let lan = localStorage.getItem('i18nextLng');
        let token = localStorage.getItem('token');
        let settings = localStorage.getItem('settings');

        localStorage.clear();

        localStorage.setItem('language', lenguaje);
        // localStorage.setItem('typeMenu', menu)
        localStorage.setItem('i18nextLng', lan);
        localStorage.setItem('token', token);
        localStorage.setItem('settings', settings);

        enqueueSnackbar(t('SnackBar.Cache'), {
            variant: 'info',
        });
    };

    const handleSave = () => {
        if (language) {
            localStorage.setItem('language', 'es');
        }
        if (!language) {
            localStorage.setItem('language', 'en');
        }

        // localStorage.setItem('typeMenu', typeMenu)

        i18next.changeLanguage(localStorage.getItem('language'));
        saveSettings(values);
        setOpen(false);
    };

    let themesList;
    if (Object.keys(SITE_SETTINGS.themes).length > 0) {
        themesList = Object.values(SITE_SETTINGS.themes);
    }

    return (
        <>
            <Tooltip title={t('Settings.Settings')}>
                <IconButton color="inherit" onClick={handleOpen} ref={ref}>
                    <SvgIcon fontSize="small">
                        <SettingsIcon />
                    </SvgIcon>
                </IconButton>
            </Tooltip>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{ paper: classes.popover }}
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
            >
                <Typography variant="h4" color="textPrimary">
                    {t('Settings.Settings')}
                </Typography>

                <Box mt={2} px={1}>
                    <FormControlLabel
                        control={
                            <DpxSwitch
                                edge="start"
                                checked={language}
                                onChange={(event) => {
                                    setLanguage(!language);
                                }}
                                inputProps={{
                                    'aria-label': 'primary checkbox',
                                }}
                                name="language"
                                color="primary"
                            />
                        }
                        label="EN/ES"
                    />
                </Box>

                <Box mt={2} px={1}>
                    <TextField
                        fullWidth
                        label={t('Settings.Theme')}
                        name="theme"
                        onChange={(event) =>
                            handleChange('theme', event.target.value)
                        }
                        select
                        SelectProps={{ native: true }}
                        value={values.theme}
                        variant="outlined"
                    >
                        {themesList.map((tema, index) => (
                            <option key={index}>{tema.name}</option>
                        ))}
                    </TextField>
                </Box>
                <Box mt={2} px={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleClearCache}
                    >
                        {t('Settings.Cache')}
                    </Button>
                </Box>
                <Box mt={2} px={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleSave}
                    >
                        {t('Settings.Save')}
                    </Button>
                </Box>
            </Popover>
        </>
    );
};

export default Settings;
