import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Calendars = [
    {
        exact: true,
        path: '/calendar',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'user',
            'general manager',
            'sales manager',
        ],
        component: lazy(() => import('src/views/calendar/CalendarView')),
    },
    {
        exact: true,
        path: '/calendar-BDC',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'marketing',
            'digital marketing',
            'user',
            'userBDC',
            'adminBDC',
            'super admin',
            'general manager',
        ],
        component: lazy(
            () => import('src/views/calendarLeadsBDC/CalendarView')
        ),
    },
    {
        exact: true,
        path: '/calendar-Service',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'user',
            'userService',
            'adminService',
            'super admin',
            'general manager',
        ],
        component: lazy(
            () => import('src/views/calendarLeadsService/CalendarView')
        ),
    },
];

export const Management = [
    {
        exact: true,
        path: '/management/tasks',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'general manager',
            'marketing',
            'sales manager',
        ],
        component: lazy(() => import('src/views/tasks/TaskListview')),
    },
    {
        exact: true,
        path: '/management/storesRecordings',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager', 'sales manager'],
        component: lazy(
            () => import('src/views/storeRecording/RecordingsDetailsView')
        ),
    },
    {
        exact: true,
        path: '/tasks',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'user',
            'general manager',
            'sales manager',
        ],
        component: lazy(() => import('src/views/tasks/TasksView')),
    },
    {
        exact: true,
        path: '/tasks-BDC',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'marketing',
            'digital marketing',
            'user',
            'userBDC',
            'adminBDC',
            'super admin',
            'general manager',
        ],
        component: lazy(() => import('src/views/tasksLeadsBDC/TasksView')),
    },
    {
        exact: true,
        path: '/management/alarms',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager', 'sales manager'],
        component: lazy(() => import('src/views/alarm/AlarmDetailsView')),
    },
    {
        exact: true,
        path: '/management/mailing',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'general manager',
            'admin',
            'marketing',
        ],
        component: lazy(() => import('src/views/email/EmailDetailsView')),
    },
];

export const Settings = [
    {
        exact: true,
        path: '/management/configs',
        guard: RolesGuard,
        roles: ['rockstar'],
        component: lazy(() => import('src/views/zapier/zapier')),
    },
];

export const Utils = [
    {
        exact: true,
        path: '/management/loadLeads',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'digital marketing',
            'general manager',
            'marketing',
        ],
        component: lazy(() => import('src/views/uploadCsv/DetailsView')),
    },
    {
        exact: true,
        path: '/custom-menu',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(() => import('src/views/CustomizeMenu')),
    },
];
