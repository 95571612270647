import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        path: '/create/:id/closure',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'user',
            'general manager',
            'sales manager',
        ],
        component: lazy(() => import('src/views/closures/ClosureCreateView')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'user',
            'general manager',
            'sales manager',
        ],
        path: '/management/closures/:id',
        component: lazy(() => import('src/views/closures/ClosureDetailsView')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'admin',
            'general manager',
            'marketing',
            'sales manager',
        ],
        path: '/management/closures/:id/edit',
        component: lazy(() => import('src/views/closures/ClosureEditView')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'admin',
            'general manager',
            'marketing',
            'sales manager',
        ],
        path: '/management/closures',
        component: lazy(() => import('src/views/closures/ClosureListView')),
    },
];
