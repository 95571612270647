import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        path: '/create/temperature',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'general manager',
            'marketing',
        ],
        component: lazy(
            () => import('src/views/temperature/TemperatureCreateView')
        ),
    },
    {
        exact: true,
        path: '/management/temperatures/:id',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'general manager',
            'marketing',
        ],
        component: lazy(
            () => import('src/views/temperature/TemperatureDetailsView')
        ),
    },
    {
        exact: true,
        path: '/management/temperatures/:id/edit',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'general manager',
            'marketing',
        ],
        component: lazy(
            () => import('src/views/temperature/TemperatureEditView')
        ),
    },
    {
        exact: true,
        path: '/management/temperatures',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'general manager',
            'marketing',
        ],
        component: lazy(
            () => import('src/views/temperature/TemperatureListView')
        ),
    },
];
