import { IEvaluationInitialState } from '../types';

export const DEFAULT_EVALUATION_STATE: IEvaluationInitialState = {
    evaluations: null,
    evaluation: null,
    total: 0,
    loading: false,
    error: null,
    success: null,
};
