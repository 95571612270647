import { IStore } from 'src/interfaces/store';
import {
    GET_STORES,
    CREATE_STORE,
    GET_STORE,
    DELETE_STORE,
    UPDATE_STORE,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    GET_STORES_BY_MAKE,
    GET_ALL_STORES,
    UPDATE_ALL_STORES,
    UPDATE_ATTRIBUTES_STORES,
    CLEAR_SNACKBARS,
} from '../types';
import { TAction, TInitialState } from './types';

export default (state: TInitialState, action: TAction) => {
    switch (action.type) {
        case CLEAR_SNACKBARS:
            return { ...state, success: null, error: null };
        case UPDATE_ATTRIBUTES_STORES:
            let storesChanged: IStore[] = [];
            if (state && state.stores) {
                storesChanged = state.stores.map((item: IStore) => {
                    return { ...item, ...action.payload };
                });
            }
            return {
                ...state,
                stores: storesChanged,
            };
        case UPDATE_ALL_STORES:
            let aux: IStore[] = [];
            if (state && state.allStores) {
                state.allStores.map((store: IStore) => {
                    if (!Array.isArray(action.payload)) {
                        if (store._id === action.payload._id)
                            aux.push(action.payload);
                        else aux.push(store);
                    }
                    return false;
                });
            }
            return {
                ...state,
                allStores: aux,
                loading: false,
            };
        case GET_ALL_STORES:
            return {
                ...state,
                allStores: action.payload,
                loading: false,
            };
        case GET_STORES:
            return {
                ...state,
                stores: action.payload,
                count: action.count,
                loading: false,
            };
        case GET_STORES_BY_MAKE:
            return {
                ...state,
                stores: action.payload,
                loading: false,
            };
        case GET_STORE:
            return {
                ...state,
                store: action.payload,
                loading: false,
            };
        case CREATE_STORE:
            return {
                ...state,
                loading: false,
            };
        case DELETE_STORE:
            state.stores = state.stores.filter(
                (store) => store._id.toString() !== action.payload.toString()
            );
            return {
                ...state,
                store: null,
                loading: false,
            };
        case UPDATE_STORE:
            let updatedStores: IStore[] = state?.stores || [];
            if (!Array.isArray(action.payload)) {
                const payload: IStore = action.payload;

                const updatedIndex = updatedStores.findIndex(
                    (store: IStore) => store?._id === payload._id
                );

                if (updatedIndex !== -1)
                    updatedStores[updatedIndex] = action.payload;
            }

            return {
                ...state,
                stores: updatedStores,
                store: action.payload,
                loading: false,
                success: action.successMessage,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CLEAR_STATE:
            return {
                ...state,
                store: {},
                stores: [],
                loading: false,
                error: null,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
};
