import { Dispatch } from 'react';
import { ISetLoading, SET_LOADING } from 'src/contexts/types';

/**
 * Will set loading to true
 *
 * @param {Dispatch<ISetLoading>} dispatch Function that update state
 */
export const setLoading = async (dispatch: Dispatch<ISetLoading>) =>
    dispatch({ type: SET_LOADING });
