export const DEVELOPER_LEVEL_ACCESS = 'developer';
export const GROUP_LEVEL_ACCESS = 'group';
export const ADMIN_LEVEL_ACCESS = 'admin';
export const USER_LEVEL_ACCESS = 'user';

export const ACCESS_LEVELS: TAccessLevel[] = [
    USER_LEVEL_ACCESS,
    ADMIN_LEVEL_ACCESS,
    GROUP_LEVEL_ACCESS,
    DEVELOPER_LEVEL_ACCESS,
];

export type TAccessLevel =
    | typeof DEVELOPER_LEVEL_ACCESS
    | typeof GROUP_LEVEL_ACCESS
    | typeof ADMIN_LEVEL_ACCESS
    | typeof USER_LEVEL_ACCESS;
