import { VariantType } from 'notistack';
import {
    MESSENGER_CHANNEL,
    WHATSAPP_CHANNEL,
} from 'src/constants/conversations';
import { TChannel } from 'src/interfaces/conversation';

/**
 * Will return the color of the snackbar depending of the channel
 *
 * @param {TChannel} channel
 * @return {*}
 */
export const getVariantColor = (channel: TChannel): VariantType => {
    if (channel === WHATSAPP_CHANNEL) return 'success';
    if (channel === MESSENGER_CHANNEL) return 'info';
    return 'warning';
};
