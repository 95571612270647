import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'nprogress/nprogress.css';
import 'src/assets/css/prism.css';
// css de las Scroll Bars
import 'src/assets/css/main.css';
import React from 'react';
import { enableES5 } from 'immer';
import * as serviceWorker from 'src/serviceWorker';
import { SettingsProvider } from 'src/contexts/SettingsContext';
import App from 'src/App';
import { createRoot } from 'react-dom/client';
import { captureException, initSentry } from './instrument';

initSentry();
window.addEventListener('error', (event) => captureException(event.error));

enableES5();
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <SettingsProvider>
        <App />
    </SettingsProvider>
);

serviceWorker.register();
