import {
    GET_TEMPERATURES,
    CREATE_TEMPERATURE,
    GET_TEMPERATURE,
    DELETE_TEMPERATURE,
    UPDATE_TEMPERATURE,
    SET_ERROR,
    GET_TEMPERATURES_BY_MODEL,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';

export default (state, action) => {
    switch (action.type) {
        case GET_TEMPERATURES_BY_MODEL:
            return {
                ...state,
                temperatures: action.payload,
                loading: false,
                error: null,
            };
        case GET_TEMPERATURES:
            return {
                ...state,
                temperatures: action.payload,
                loading: false,
                error: null,
                count: action.count,
            };
        case GET_TEMPERATURE:
            return {
                ...state,
                temperature: action.payload,
                loading: false,
                error: null,
            };
        case CREATE_TEMPERATURE:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case DELETE_TEMPERATURE:
            state.temperatures = state.temperatures.filter(
                (temperature) =>
                    temperature._id.toString() !== action.payload.toString()
            );
            return {
                ...state,
                temperature: null,
                loading: false,
                error: null,
            };
        case UPDATE_TEMPERATURE:
            return {
                ...state,
                temperature: action.payload,
                loading: false,
                error: null,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CLEAR_STATE:
            return {
                temperature: {},
                temperatures: [],
                loading: false,
                error: null,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
};
