import {
    GET_MAIL_DOCUMENTATIONS,
    GET_MAIL_DOCUMENTATIONS_BY_STORE,
    CREATE_MAIL_DOCUMENTATION,
    GET_MAIL_DOCUMENTATION,
    DELETE_MAIL_DOCUMENTATION,
    UPDATE_MAIL_DOCUMENTATION,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';

export default (state, action) => {
    switch (action.type) {
        case GET_MAIL_DOCUMENTATIONS_BY_STORE:
            let doc = {};

            if (action.payload && action.payload.length > 0)
                doc = action.payload[0];
            return {
                ...state,
                mailDocumentation: doc,
                loading: false,
                error: null,
            };
        case GET_MAIL_DOCUMENTATIONS:
            return {
                ...state,
                mailDocumentations: action.payload,
                loading: false,
                error: null,
            };
        case GET_MAIL_DOCUMENTATION:
            return {
                ...state,
                mailDocumentation: action.payload,
                loading: false,
                error: null,
            };
        case CREATE_MAIL_DOCUMENTATION:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case DELETE_MAIL_DOCUMENTATION:
            state.mailDocumentations = state.mailDocumentations.filter(
                (mailDocumentation) =>
                    mailDocumentation._id.toString() !==
                    action.payload.toString()
            );
            return {
                ...state,
                mailDocumentation: null,
                loading: false,
                error: null,
            };
        case UPDATE_MAIL_DOCUMENTATION:
            return {
                ...state,
                mailDocumentation: action.payload,
                loading: false,
                error: null,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CLEAR_STATE:
            return {
                loading: false,
                error: null,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
};
