import caronecloud from './clients/caronecloud';
import carmenmotors from './clients/carmenmotors';
import unitedauto from './clients/unitedauto';
import demo from './clients/demo';
import development from './clients/development';
import surman from './clients/surman';
import { apiCalls, apiV1, apiV2 } from './apis/apis';
import { ellyonsoft } from './clients';

const removeKeywords = [
    'www.',
    'localhost.',
    '.io',
    '.com',
    'crm.',
    'https://',
    'http://',
    'local.',
    'staging.',
    '.warbox',
];

const host = window.location.hostname.toLowerCase();

const siteName = removeKeywords.reduce((acc, keyword) => {
    return acc.replace(keyword, '');
}, host);

const CLIENTS = {
    caronecloud,
    carmenmotors,
    unitedauto,
    surman,
    demo,
    ellyonsoft,
    default: development,
};

const SELECTED_CLIENT = CLIENTS[siteName]
    ? CLIENTS[siteName]
    : CLIENTS['default'];

const SITE_SETTINGS = {
    ...SELECTED_CLIENT,
    apiV1,
    apiV2,
    apiCalls,
};

export default SITE_SETTINGS;
