import { lazy } from 'react';
import GuestGuard from 'src/components/GuestGuard';
import RolesGuard from 'src/components/RolesGuard';

export const Auth = [
    {
        exact: true,
        path: '/',
        component: lazy(() => import('src/views/home/HomeView')),
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/resetPassword/:token',
        component: lazy(() => import('src/views/auth/ResetPassword')),
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/forgotPassword',
        component: lazy(() => import('src/views/auth/ForgotPasswordView')),
    },
    {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/views/errors/NotFoundView')),
    },
];

export const Account = [
    {
        exact: true,
        path: '/account',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'carone',
            'marketing',
            'digital marketing',
            'user',
            'general manager',
            'sales manager',
            'userBDC',
            'adminBDC',
            'super admin',
        ],
        component: lazy(() => import('src/views/account/AccountView')),
    },
];
