import {
    GET_PACKAGES,
    CREATE_PACKAGE,
    GET_PACKAGE,
    DELETE_PACKAGE,
    UPDATE_PACKAGE,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';

export default (state, action) => {
    switch (action.type) {
        case GET_PACKAGES:
            return {
                ...state,
                packages: action.payload,
                loading: false,
                error: null,
            };
        case GET_PACKAGE:
            return {
                ...state,
                packageState: action.payload,
                loading: false,
                error: null,
            };
        case CREATE_PACKAGE:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case DELETE_PACKAGE:
            state.packages = state.packages.filter(
                (packageItem) =>
                    packageItem._id.toString() !== action.payload.toString()
            );
            return {
                ...state,
                packageState: null,
                loading: false,
                error: null,
            };
        case UPDATE_PACKAGE:
            return {
                ...state,
                packageState: action.payload,
                loading: false,
                error: null,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CLEAR_STATE:
            return {
                loading: false,
                error: null,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
};
