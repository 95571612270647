import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        path: '/create/status',
        guard: RolesGuard,
        roles: ['rockstar'],
        component: lazy(() => import('src/views/status/StatusCreateView')),
    },
    {
        exact: true,
        path: '/management/status/:id',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(() => import('src/views/status/StatusDetailsView')),
    },

    {
        exact: true,
        path: '/management/status/:id/edit',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(() => import('src/views/status/StatusEditView')),
    },
    {
        exact: true,
        path: '/management/status',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(() => import('src/views/status/StatusListView')),
    },
];
