import {
    GET_ROLES,
    CREATE_ROLE,
    GET_ROLE,
    DELETE_ROLE,
    UPDATE_ROLE,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';
import _ from 'lodash';

export default (state, action) => {
    switch (action.type) {
        case GET_ROLES:
            return {
                ...state,
                roles: action.payload,
                count: action.count,
                loading: false,
                error: null,
            };
        case GET_ROLE:
            return {
                ...state,
                role: action.payload,
                loading: false,
                error: null,
            };
        case CREATE_ROLE:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case DELETE_ROLE:
            if (state.roles) {
                state.roles = state.roles.filter(
                    (role) => role._id.toString() !== action.payload.toString()
                );
            }
            return {
                ...state,
                role: null,
                loading: false,
                error: null,
            };
        case UPDATE_ROLE:
            if (state && state.roles) {
                const indice = _.findIndex(state.roles, function (o) {
                    return o._id.toString() === action.payload._id.toString();
                });

                state.roles[indice] = action.payload;
            }
            return {
                ...state,
                role: action.payload,
                loading: false,
                error: null,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CLEAR_STATE:
            return {
                loading: false,
                error: null,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
};
