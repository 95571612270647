import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import {
    Avatar,
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Popover,
    SvgIcon,
    Tooltip,
    Typography,
    makeStyles,
} from '@material-ui/core';
import { Bell as BellIcon, MessageCircle as MessageIcon } from 'react-feather';
import moment from 'moment';
import useAuth from 'src/hooks/useAuth';
import useNotification from 'src/hooks/useNotification';
import { useTranslation } from 'react-i18next';
import { isAdmin, isUser } from 'src/utils/AuthRoles';
import { channel } from 'src/service/pusher';
import { useSnackbar } from 'notistack';
import { BOTH, NEW, PREOWNED } from 'src/constants/CARTYPE';
import { DIGITAL_AREA } from 'src/constants/user';

const useStyles = makeStyles((theme) => ({
    popover: {
        width: 320,
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
}));

const Notifications = ({ props, ...rest }) => {
    const classes = useStyles();
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();
    const { user } = useAuth();
    const {
        updateNotification,
        getNotificationsByUser,
        notifications,
        updateList,
    } = useNotification();
    const { enqueueSnackbar } = useSnackbar();

    const handleMarkAllAsRead = () => {
        if (notifications && notifications.length > 0)
            notifications.map((notification) =>
                updateNotification(notification._id)
            );
    };

    useEffect(() => {
        if (user && user.tier) {
            if (isUser(user.tier._id)) getNotificationsByUser(user._id, false);
        }

        //eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        if (user && user.tier && user.tier._id) {
            const tier = user.tier._id;

            const handleNewNotification = (notification) => {
                updateList(notification);
                enqueueSnackbar(t(notification.message), {
                    variant: 'info',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    onClick: () => {
                        history.push(`/management/leads/${notification.lead}`);
                    },
                });
            };

            const bindNewNotification = (storeId, areaType) => {
                channel.unbind(`notification_by_store_${storeId}_${areaType}`);
                channel.bind(
                    `notification_by_store_${storeId}_${areaType}`,
                    (notification) => {
                        handleNewNotification(notification);
                    }
                );
            };

            const bindNewNotificationUser = (userId) => {
                channel.bind(
                    `notification_by_agent_${userId}`,
                    (notification) => {
                        handleNewNotification(notification);
                    }
                );
            };

            const { areas, stores, carType } = user;

            if (isAdmin(tier)) {
                const storesUser = stores || [];
                storesUser.forEach((store) => {
                    areas.forEach((area) => {
                        if (area === DIGITAL_AREA) {
                            if (carType === NEW || carType === BOTH) {
                                bindNewNotification(store._id, NEW);
                            }
                            if (carType === PREOWNED || carType === BOTH) {
                                bindNewNotification(store._id, PREOWNED);
                            }
                        } else {
                            bindNewNotification(store._id, area);
                        }
                    });
                });
            } else if (isUser(tier)) {
                bindNewNotificationUser(user._id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    return (
        <div {...rest}>
            <Tooltip title="Notifications">
                <IconButton color="inherit" ref={ref} onClick={handleOpen}>
                    <SvgIcon fontSize="small">
                        <BellIcon />
                    </SvgIcon>
                </IconButton>
            </Tooltip>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{ paper: classes.popover }}
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
            >
                <Box p={2}>
                    <Typography variant="h5" color="textPrimary">
                        {t('Notifications.Notifications')}
                    </Typography>
                </Box>
                {notifications && notifications.length === 0 ? (
                    <>
                        <Box p={2}>
                            <Typography variant="h6" color="textPrimary">
                                {t('Notifications.NoNotifications')}
                            </Typography>
                        </Box>
                    </>
                ) : (
                    <>
                        <List disablePadding>
                            {notifications &&
                                notifications.map((notification) => {
                                    return (
                                        <ListItem
                                            component={RouterLink}
                                            divider
                                            key={notification._id}
                                            to="#"
                                            onClick={(e) => {
                                                updateNotification(
                                                    notification._id
                                                );
                                                history.push(
                                                    `/management/leads/${notification.lead}`
                                                );
                                            }}
                                        >
                                            <ListItemAvatar>
                                                <Avatar
                                                    className={classes.icon}
                                                >
                                                    <SvgIcon fontSize="small">
                                                        <MessageIcon />
                                                    </SvgIcon>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={t(
                                                    notification.message
                                                )}
                                                primaryTypographyProps={{
                                                    variant: 'subtitle2',
                                                    color: 'textPrimary',
                                                }}
                                                secondary={moment(
                                                    notification.createdAt
                                                ).fromNow()}
                                            />
                                        </ListItem>
                                    );
                                })}
                        </List>
                        <Box p={1} display="flex" justifyContent="center">
                            <Button
                                component={RouterLink}
                                size="small"
                                to="#"
                                onClick={handleMarkAllAsRead}
                            >
                                {t('Notifications.AllAsRead')}
                            </Button>
                        </Box>
                    </>
                )}
            </Popover>
        </div>
    );
};

export default Notifications;
