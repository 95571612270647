import { THEMES } from 'src/constants';

export const ellyonsoft = {
    siteName: 'ellyonsoft',
    senderName: 'Ellyonsoft',
    logo: '/static/warbox_logo.png',
    name: 'Ellyonsoft',
    theme: THEMES.LIGHT,
    client: 'ellyonsoft',
    themes: [
        { theme: 'LIGHT', name: 'LIGHT' },
        { theme: 'ONE_DARK', name: 'DARK' },
    ],
};
