import React, { useReducer } from 'react';
import RoleContext from './roleContext';
import RoleReducer from './roleReducer';
import api from '../../api/api';
import {
    GET_ROLES,
    CREATE_ROLE,
    GET_ROLE,
    DELETE_ROLE,
    UPDATE_ROLE,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';
import { HEADERS } from '../../constants/headers';

const RoleState = (props) => {
    const initialState = {
        roles: [],
        role: {},
        loading: false,
        error: null,
    };

    const [state, dispatch] = useReducer(RoleReducer, initialState);

    //Get Recordings by store
    const getRolesSuper = async (stores, pagination, query, typeQuery) => {
        setLoading();
        try {
            const res = await api.get(
                `/recordings/recordingsAr?${stores}&page=${pagination.page}&searchIndex=name&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1`,
                HEADERS()
            );
            dispatch({
                type: GET_ROLES,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const getRolesByGroup = async (groupId) => {
        clearRoleState();
        setLoading();
        try {
            const res = await api.get(`/groups/${groupId}/roles`, HEADERS());
            dispatch({ type: GET_ROLES, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Roles
    const getAllRoles = async () => {
        setLoading();
        try {
            const res = await api.get(`/roles`, HEADERS());

            dispatch({
                type: GET_ROLES,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Roles
    const getRoles = async (pagination, query, typeQuery) => {
        setLoading();
        try {
            let res;
            if (!query) query = '';

            if (pagination) {
                res = await api.get(
                    `/roles?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name&searchText=${query}&searchType=${typeQuery}&validation=1`,
                    HEADERS()
                );
            }
            dispatch({
                type: GET_ROLES,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Role
    const getRole = async (roleId) => {
        clearRoleState();
        setLoading();
        try {
            const res = await api.get(`/roles/${roleId}`, HEADERS());
            dispatch({ type: GET_ROLE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Delete Role
    const deleteRole = async (roleId) => {
        setLoading();
        try {
            const res = await api.delete(`/roles/${roleId}`, HEADERS());
            dispatch({ type: DELETE_ROLE, payload: res.data.deletedId });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Create Role
    const createRole = async (role) => {
        clearRoleState();
        setLoading();
        try {
            const res = await api.post(`/roles`, { ...role }, HEADERS());
            dispatch({ type: CREATE_ROLE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Update Role
    const updateRole = async (role, roleId) => {
        setLoading();
        try {
            const res = await api.put(
                `/roles/${roleId}`,
                { ...role },
                HEADERS()
            );
            dispatch({ type: UPDATE_ROLE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Clear State
    const clearRoleState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <RoleContext.Provider
            value={{
                loading: state.loading,
                roles: state.roles,
                role: state.role,
                error: state.error,
                count: state.count,
                getRoles,
                createRole,
                getRole,
                deleteRole,
                updateRole,
                clearRoleState,
                setLoading,
                getRolesSuper,
                getRolesByGroup,
                getAllRoles,
            }}
        >
            {props.children}
        </RoleContext.Provider>
    );
};

export default RoleState;
