import React, { useState, useEffect } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { Spinner } from '../Spinners/followTheLead/spinner';
import { useTranslation } from 'react-i18next';
// import { pingApi } from 'src/api/api';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const NO_INTERNET_CONNECTION = 'NoSignal';
// const NO_SERVER_CONNECTION = 'noServerConnection';

export const InternetSignal = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [isOnline, setIsOnline] = useState({
        status: navigator.onLine,
        message: NO_INTERNET_CONNECTION,
    });

    useEffect(() => {
        const handleOnline = (message) =>
            setIsOnline({ status: true, message });
        const handleOffline = (message) =>
            setIsOnline({ status: false, message });

        window.addEventListener('online', () =>
            handleOnline(NO_INTERNET_CONNECTION)
        );
        window.addEventListener('offline', () =>
            handleOffline(NO_INTERNET_CONNECTION)
        );

        return () => {
            window.removeEventListener('online', () =>
                handleOnline(NO_INTERNET_CONNECTION)
            );
            window.removeEventListener('offline', () =>
                handleOffline(NO_INTERNET_CONNECTION)
            );
        };
    }, []);

    //? temporal disable
    // useEffect(() => {
    //     const pingAPI = async () => {
    //         try {
    //             await pingApi.get('/');
    //             setIsOnline({ status: true, message: NO_SERVER_CONNECTION });
    //         } catch (error) {
    //             setIsOnline({ status: false, message: NO_SERVER_CONNECTION });
    //         }
    //     };
    //     const interval = setInterval(() => {
    //         pingAPI();
    //     }, 30000);

    //     return () => clearInterval(interval);
    // }, []);

    return (
        <Backdrop
            className={classes.backdrop}
            open={Boolean(!isOnline?.status)}
        >
            <Box display="flex" justifyContent="center" flexDirection="column">
                <Box display="flex" justifyContent="center">
                    <Box p={1}>
                        <Spinner />
                    </Box>
                </Box>
                <Box display="flex" justifyContent="center" mt={15}>
                    <Typography variant="h4">{t(isOnline.message)}</Typography>
                </Box>
            </Box>
        </Backdrop>
    );
};
