import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        path: '/create/company',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(() => import('src/views/company/CompanyCreateView')),
    },

    {
        exact: true,
        path: '/management/companies/:id',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(() => import('src/views/company/CompanyDetailsView')),
    },
    {
        exact: true,
        path: '/management/companies/:id/edit',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(() => import('src/views/company/CompanyEditView')),
    },
    {
        exact: true,
        path: '/management/companies',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(() => import('src/views/company/CompanyListView')),
    },
];
