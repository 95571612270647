import React, { useReducer } from 'react';
import LeadBDCContext from './LeadBDC';
import LeadBDCReducer from './LeadBDCReducer';
import api from '../../api/api';
import {
    GET_LEADSBDC,
    GET_LEADBDC,
    UPDATE_LEADBDC,
    DELETE_LEADBDC,
    CREATE_LEADBDC,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    GET_LEADSBDC_BY_STORE,
    GET_LEADSBDC_BY_USER,
    CALL_USER,
    ASSIGN_AGENTS,
    DELETE_COMMENT,
    ADD_APPOINTMENT,
    ADD_COMMENT,
} from '../types';
import { HEADERS } from '../../constants/headers';

const LeadBDCState = (props) => {
    const initialState = {
        leadsBDC: [],
        leadBDC: {},
        chartDash: [],
        loading: false,
        error: null,
        value: [],
        count: null,
        callToken: null,
    };

    const [state, dispatch] = useReducer(LeadBDCReducer, initialState);

    //Delete leadBDC from state
    const deleteCommentLeadBDC = async (commentId) =>
        dispatch({ type: DELETE_COMMENT, payload: commentId });

    //Get LeadsBDC
    const getLeadsBDC = async (pagination, query, typeQuery) => {
        setLoading();
        try {
            let res;
            if (!query) query = '';
            if (pagination) {
                res = await api.get(
                    `/leadsBDC?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-phone-agent-descModel-make-store&searchText=${query}&searchType=${typeQuery}&sort=-lastService`,
                    HEADERS()
                );
            }
            dispatch({
                type: GET_LEADSBDC,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get LeadsBDC
    const startCron = async (status) => {
        try {
            await api.post(
                `/leadsBDC/sendAlerts`,
                { start: status },
                HEADERS()
            );
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get LeadsBDC
    const getLeadsBDCByStore = async (
        storequery,
        pagination,
        tabs,
        query,
        typeQuery,
        multiStore
    ) => {
        setLoading();
        try {
            let res;
            if (!query) query = '';

            if (pagination) {
                if (tabs.includes('temperature')) {
                    let temp = tabs.split('.');
                    res = await api.get(
                        `/leadsBDC?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&rating=${temp[1]}&searchText=${query}${storequery}&searchType=${typeQuery}&validation=1${multiStore}&sort=-lastService`,
                        HEADERS()
                    );
                } else if (tabs.includes('status')) {
                    let status = tabs.split('.');
                    res = await api.get(
                        `/leadsBDC?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&status=${status[1]}&searchText=${query}${storequery}&searchType=${typeQuery}&validation=1${multiStore}&sort=-lastService`,
                        HEADERS()
                    );
                } else if (tabs.includes('subStatus')) {
                    let substatus = tabs.split('.');
                    res = await api.get(
                        `/leadsBDC?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&substatus=${substatus[1]}&searchText=${query}${storequery}&searchType=${typeQuery}&validation=1${multiStore}&sort=-lastService`,
                        HEADERS()
                    );
                } else if (tabs.includes('contacted')) {
                    let contacted = tabs.split('.');
                    res = await api.get(
                        `/leadsBDC?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&isContacted=${contacted[1]}&searchText=${query}${storequery}&searchType=${typeQuery}&validation=1${multiStore}&sort=-lastService`,
                        HEADERS()
                    );
                } else if (tabs.includes('assigned')) {
                    let assigned = tabs.split('.');
                    res = await api.get(
                        `/leadsBDC?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&assigned=${assigned[1]}&searchText=${query}${storequery}&searchType=${typeQuery}&validation=1${multiStore}&sort=-lastService`,
                        HEADERS()
                    );
                } else {
                    res = await api.get(
                        `/leadsBDC?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&searchText=${query}${storequery}&searchType=${typeQuery}&validation=1${multiStore}&sort=-lastService`,
                        HEADERS()
                    );
                }
            }

            dispatch({
                type: GET_LEADSBDC_BY_STORE,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get LeadsBDC by User
    const getLeadsBDCByUser = async (
        userId,
        pagination,
        tabs,
        query,
        typeQuery
    ) => {
        setLoading();
        try {
            let res;
            if (!query) query = '';

            if (pagination) {
                if (tabs.includes('temperature')) {
                    let temp = tabs.split('.');
                    res = await api.get(
                        `/leadsBDC?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&rating=${temp[1]}&searchText=${query}&agent=${userId}&searchType=${typeQuery}&validation=1&sort=-lastService`,
                        HEADERS()
                    );
                } else if (tabs.includes('status')) {
                    let status = tabs.split('.');
                    res = await api.get(
                        `/leadsBDC?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&status=${status[1]}&searchText=${query}&agent=${userId}&searchType=${typeQuery}&validation=1&sort=-lastService`,
                        HEADERS()
                    );
                } else if (tabs.includes('subStatus')) {
                    let substatus = tabs.split('.');
                    res = await api.get(
                        `/leadsBDC?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&substatus=${substatus[1]}&searchText=${query}&agent=${userId}&searchType=${typeQuery}&validation=1&sort=-lastService`,
                        HEADERS()
                    );
                } else if (tabs.includes('contacted')) {
                    let contacted = tabs.split('.');
                    res = await api.get(
                        `/leadsBDC?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&isContacted=${contacted[1]}&searchText=${query}&agent=${userId}&searchType=${typeQuery}&validation=1&sort=-lastService`,
                        HEADERS()
                    );
                } else if (tabs.includes('assigned')) {
                    let assigned = tabs.split('.');
                    res = await api.get(
                        `/leadsBDC?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&assigned=${assigned[1]}&searchText=${query}&agent=${userId}&searchType=${typeQuery}&validation=1&sort=-lastService`,
                        HEADERS()
                    );
                } else {
                    res = await api.get(
                        `/leadsBDC?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&searchText=${query}&agent=${userId}&searchType=${typeQuery}&validation=1&sort=-lastService`,
                        HEADERS()
                    );
                }
            }

            dispatch({
                type: GET_LEADSBDC_BY_USER,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Single Item by ID
    const getLeadBDC = async (leadBDCId) => {
        setLoading();
        try {
            const res = await api.get(`/leadsBDC/${leadBDCId}`, HEADERS());
            dispatch({
                type: GET_LEADBDC,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Update LeadBDC
    const updateLeadBDC = async (leadBDC, leadBDCId) => {
        setLoading();
        try {
            const res = await api.put(
                `/leadsBDC/${leadBDCId}`,
                { ...leadBDC },
                HEADERS()
            );
            dispatch({ type: UPDATE_LEADBDC, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Delete LeadBDC
    const deleteLeadBDC = async (leadBDCId) => {
        setLoading();
        try {
            const res = await api.delete(`/leadsBDC/${leadBDCId}`, HEADERS());
            dispatch({ type: DELETE_LEADBDC, payload: res.data.deletedId });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Create LeadBDC
    const createLeadBDC = async (leadBDC) => {
        clearState();
        setLoading();
        try {
            const res = await api.post(`/leadsBDC`, { ...leadBDC }, HEADERS());
            dispatch({ type: CREATE_LEADBDC, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const assignAgents = async (leadsBDC, agent, currentTab) => {
        setLoading();
        try {
            const res = await api.post(
                `/leadsBDC/assignAgent`,
                { leadsBDC, agent },
                HEADERS()
            );
            dispatch({
                type: ASSIGN_AGENTS,
                payload: res.data.data,
                tab: currentTab,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const AdvancedResults = async (pagination, query) => {
        setLoading();
        try {
            const res = await api.get(
                `/leadsBDC?page=${pagination.page}&limit=${pagination.limit}${query}&searchType=and&sort=-lastService`,
                HEADERS()
            );
            dispatch({
                type: GET_LEADSBDC,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const generateToken = async (data) => {
        try {
            const res = await api.post('/calls/generate', { data }, HEADERS());
            localStorage.setItem('callToken', res.data.token);
            dispatch({ type: CALL_USER, payload: res.data.token });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err });
        }
    };

    const makeCall = async (phoneNumber) => {
        await api.post('/calls/connect', phoneNumber, HEADERS());
    };

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    const addAppointment = (appointment) =>
        dispatch({ type: ADD_APPOINTMENT, payload: appointment });

    const addComment = (comment) => {
        if (comment && comment.action && comment.action.includes('sold'))
            return;
        dispatch({ type: ADD_COMMENT, payload: comment });
    };

    return (
        <LeadBDCContext.Provider
            value={{
                loading: state.loading,
                leadsBDC: state.leadsBDC,
                leadBDC: state.leadBDC,
                error: state.error,
                newS: state.newS,
                sold: state.sold,
                follow: state.follow,
                date: state.date,
                lastLeadsBDC: state.lastLeadsBDC,
                analytics: state.analytics,
                labels: state.labels,
                value: state.value,
                count: state.count,
                chart: state.chart,
                callToken: state.callToken,
                addAppointment,
                startCron,
                generateToken,
                getLeadsBDC,
                getLeadBDC,
                updateLeadBDC,
                deleteLeadBDC,
                createLeadBDC,
                clearState,
                setLoading,
                getLeadsBDCByStore,
                getLeadsBDCByUser,
                makeCall,
                assignAgents,
                AdvancedResults,
                deleteCommentLeadBDC,
                addComment,
            }}
        >
            {props.children}
        </LeadBDCContext.Provider>
    );
};

export default LeadBDCState;
