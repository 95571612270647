import React from 'react';
import {
    AdCampaigns,
    Alerts,
    Appointments,
    Auth,
    Charts,
    Companies,
    Conversations,
    Documents,
    DPXConversations,
    FinancialInstitutions,
    Group,
    Investments,
    Leads,
    LeadsBDC,
    LeadsService,
    Mails,
    MailsDocumentation,
    Makes,
    Medias,
    Notifications,
    Packages,
    Recordings,
    RecordingTests,
    Reminders,
    Roles,
    Sales,
    Service,
    Settings,
    Sources,
    Statuses,
    Stores,
    Substatuses,
    Tasks,
    Temperatures,
    TemplatesTwilio,
    Tickets,
    Users,
    Vehicles,
    VideoCalls,
    Visits,
    WspConversations,
    FleetCompany,
    EvaluationState,
} from 'src/contexts';

export const WarboxProvider = ({ children }) => {
    return (
        <AdCampaigns>
            <Alerts>
                <Appointments>
                    <Auth>
                        <Charts>
                            <Companies>
                                <Conversations>
                                    <Documents>
                                        <DPXConversations>
                                            <FinancialInstitutions>
                                                <Group>
                                                    <Investments>
                                                        <Leads>
                                                            <LeadsBDC>
                                                                <LeadsService>
                                                                    <Mails>
                                                                        <MailsDocumentation>
                                                                            <Makes>
                                                                                <Medias>
                                                                                    <Notifications>
                                                                                        <Packages>
                                                                                            <Recordings>
                                                                                                <RecordingTests>
                                                                                                    <Reminders>
                                                                                                        <Roles>
                                                                                                            <Sales>
                                                                                                                <Service>
                                                                                                                    <Settings>
                                                                                                                        <Sources>
                                                                                                                            <Statuses>
                                                                                                                                <Stores>
                                                                                                                                    <Substatuses>
                                                                                                                                        <Tasks>
                                                                                                                                            <Temperatures>
                                                                                                                                                <TemplatesTwilio>
                                                                                                                                                    <Tickets>
                                                                                                                                                        <Users>
                                                                                                                                                            <Vehicles>
                                                                                                                                                                <VideoCalls>
                                                                                                                                                                    <Visits>
                                                                                                                                                                        <WspConversations>
                                                                                                                                                                            <FleetCompany>
                                                                                                                                                                                <EvaluationState>
                                                                                                                                                                                    {
                                                                                                                                                                                        children
                                                                                                                                                                                    }
                                                                                                                                                                                </EvaluationState>
                                                                                                                                                                            </FleetCompany>
                                                                                                                                                                        </WspConversations>
                                                                                                                                                                    </Visits>
                                                                                                                                                                </VideoCalls>
                                                                                                                                                            </Vehicles>
                                                                                                                                                        </Users>
                                                                                                                                                    </Tickets>
                                                                                                                                                </TemplatesTwilio>
                                                                                                                                            </Temperatures>
                                                                                                                                        </Tasks>
                                                                                                                                    </Substatuses>
                                                                                                                                </Stores>
                                                                                                                            </Statuses>
                                                                                                                        </Sources>
                                                                                                                    </Settings>
                                                                                                                </Service>
                                                                                                            </Sales>
                                                                                                        </Roles>
                                                                                                    </Reminders>
                                                                                                </RecordingTests>
                                                                                            </Recordings>
                                                                                        </Packages>
                                                                                    </Notifications>
                                                                                </Medias>
                                                                            </Makes>
                                                                        </MailsDocumentation>
                                                                    </Mails>
                                                                </LeadsService>
                                                            </LeadsBDC>
                                                        </Leads>
                                                    </Investments>
                                                </Group>
                                            </FinancialInstitutions>
                                        </DPXConversations>
                                    </Documents>
                                </Conversations>
                            </Companies>
                        </Charts>
                    </Auth>
                </Appointments>
            </Alerts>
        </AdCampaigns>
    );
};
