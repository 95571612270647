import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        path: '/create/document',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
        ],
        component: lazy(() => import('src/views/document/DocumentCreateView')),
    },
    {
        exact: true,
        path: '/management/documents/:id',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
        ],
        component: lazy(() => import('src/views/document/DocumentDetailsView')),
    },
    {
        exact: true,
        path: '/management/documents/:id/edit',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
        ],
        component: lazy(() => import('src/views/document/DocumentEditView')),
    },

    {
        exact: true,
        path: '/management/documents',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
        ],
        component: lazy(() => import('src/views/document/DocumentListView')),
    },
];
