import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        path: '/create/package',
        guard: RolesGuard,
        roles: ['rockstar', 'adminService'],
        component: lazy(() => import('src/views/package/PackageCreateView')),
    },

    {
        exact: true,
        path: '/management/packages/:id',
        guard: RolesGuard,
        roles: ['rockstar', 'adminService'],
        component: lazy(() => import('src/views/package/PackageDetailsView')),
    },
    {
        exact: true,
        path: '/management/packages/:id/edit',
        guard: RolesGuard,
        roles: ['rockstar', 'adminService'],
        component: lazy(() => import('src/views/package/PackageEditView')),
    },
    {
        exact: true,
        path: '/management/packages',
        guard: RolesGuard,
        roles: ['rockstar', 'adminService'],
        component: lazy(() => import('src/views/package/PackageListView')),
    },
];
