import {
    GET_SETTINGS,
    UPDATE_SETTINGS,
    SET_LOADING,
    GET_MATRIX,
    CLEAR_MATRIX,
    UPDATE_TOKEN,
    SET_MENU,
    SET_ERROR,
} from '../types';

import _ from 'lodash';

export default (state, action) => {
    switch (action.type) {
        case SET_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SET_MENU:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_TOKEN:
            return {
                ...state,
                settings: { ...state.settings, zapierToken: action.payload },
                loading: false,
            };
        case GET_SETTINGS:
            return {
                ...state,
                settings: action.payload,
                loading: false,
            };
        case UPDATE_SETTINGS:
            return {
                ...state,
                settings: action.payload,
                loading: false,
            };
        case GET_MATRIX:
            let lead = _.filter(action.payload.actions, {
                action_type: 'lead',
            })[0];
            let clicks = _.filter(action.payload.actions, {
                action_type: 'link_click',
            })[0];
            return {
                ...state,
                clicks: clicks ? clicks.value : 0,
                impressions: action.payload.impressions,
                cpm: action.payload.cpm,
                leads: lead ? lead.value : 0,
                cost: action.payload.cpc,
                spend: action.payload.spend,
                loading: false,
            };
        case CLEAR_MATRIX:
            return {
                ...state,
                loading: false,
                clicks: null,
                impressions: null,
                cpm: null,
                leads: null,
                cost: null,
                spend: null,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
};
