export const STORE_AREA = 'store';
export const NEW_USER_AREA = 'nuevo';
export const PREOWNED_USER_AREA = 'seminuevo';

export const DIGITAL_AREA = 'digital';
export const NEW_AREA = 'nuevos';
export const PREOWNED_AREA = 'seminuevos';
export const BOTH_AREA = 'ambos';
export const BDC_AREA = 'bdc';
export const SERVICE_AREA = 'servicio';
export const HYP_AREA = 'hyp';
export const SPARE_PARTS_AREA = 'refacciones';
export const RH_AREA = 'rh';
export const COMPLAINTS_AREA = 'complaints';

export const NEW_CAROUSEL_AREA = 'new';
export const PREOWNED_CAROUSEL_AREA = 'preowned';
export const BDC_CAROUSEL_AREA = 'bdc';
export const FLEET_CAROUSEL_AREA = 'fleet';
export const SHOWROOM_CAROUSEL_AREA = 'showroom';

export const NEW_AREA_OBJETCT = {_id: '1', name: NEW_AREA};
export const PREOWNED_AREA_OBJETCT = {_id: '2', name: PREOWNED_AREA};

export const ARRAY_AREAS = [
    NEW_AREA,
    PREOWNED_AREA,
    BDC_AREA,
    SERVICE_AREA,
    HYP_AREA,
    SPARE_PARTS_AREA,
    RH_AREA,
    COMPLAINTS_AREA,
];
export const DEFAULT_AGENT_CARROUSEL_AREA = NEW_CAROUSEL_AREA;

export type TAgentCarouselAreas =
    | typeof NEW_CAROUSEL_AREA
    | typeof PREOWNED_CAROUSEL_AREA
    | typeof FLEET_CAROUSEL_AREA
    | typeof BDC_CAROUSEL_AREA
    | typeof DIGITAL_AREA
    | typeof SHOWROOM_CAROUSEL_AREA
    | typeof SERVICE_AREA
    | typeof HYP_AREA
    | typeof SPARE_PARTS_AREA
    | typeof BOTH_AREA;

export const AGENTS_CAROUSEL_AREAS: TAgentCarouselAreas[] = [
    NEW_CAROUSEL_AREA,
    PREOWNED_CAROUSEL_AREA,
    FLEET_CAROUSEL_AREA,
    BDC_CAROUSEL_AREA,
    SHOWROOM_CAROUSEL_AREA,
    DIGITAL_AREA,
    SERVICE_AREA,
    HYP_AREA,
    SPARE_PARTS_AREA,
    BOTH_AREA,
];

export type TagentsCarouselAreas =
    | typeof FLEET_CAROUSEL_AREA
    | typeof SHOWROOM_CAROUSEL_AREA
    | typeof BDC_CAROUSEL_AREA
    | typeof NEW_CAROUSEL_AREA
    | typeof PREOWNED_CAROUSEL_AREA
    | typeof DIGITAL_AREA
    | typeof SERVICE_AREA
    | typeof HYP_AREA
    | typeof SPARE_PARTS_AREA
    | typeof BOTH_AREA;
