import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        path: '/create/leadBDC',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'userBDC',
            'adminBDC',
            'super admin',
            'general manager',
        ],
        component: lazy(() => import('src/views/leadBDC/LeadBDCCreateView')),
    },
    {
        exact: true,
        path: '/management/leadsBDC/:id',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'userBDC',
            'adminBDC',
            'super admin',
            'general manager',
        ],
        component: lazy(() => import('src/views/leadBDC/LeadBDCDetailsView')),
    },
    {
        exact: true,
        path: '/management/leadsBDC/:id/edit',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'marketing',
            'digital marketing',
            'userBDC',
            'adminBDC',
            'super admin',
            'general manager',
        ],
        component: lazy(() => import('src/views/leadBDC/LeadBDCEditView')),
    },
    {
        exact: true,
        path: '/management/leadsBDC',
        guard: RolesGuard,
        roles: ['rockstar', 'userBDC', 'adminBDC', 'super admin'],
        component: lazy(() => import('src/views/leadBDC/LeadBDCListView')),
    },
];
