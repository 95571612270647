import { clearState, setLoading } from 'src/contexts/utils';
import { IEvaluationInitialContext } from '../types';
import { DEFAULT_EVALUATION_STATE } from './defaultState';
import * as EvaluationFunctions from '../utils';

export const DEFAULT_EVALUATION_CONTEXT: IEvaluationInitialContext = {
    ...DEFAULT_EVALUATION_STATE,
    ...EvaluationFunctions,
    clearState,
    setLoading,
};
