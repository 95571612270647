export const LEAD_AUTH = 'LEAD_AUTH';
export const SOURCE_AUTH = 'SOURCE_AUTH';
export const STORE_AUTH = 'STORE_AUTH';
export const MAKE_AUTH = 'MAKE_AUTH';
export const VEHICLES_AUTH = 'VEHICLES_AUTH';
export const USER_AUTH = 'USER_AUTH';
export const TEMPERATURES_AUTH = 'TEMPERATURES_AUTH';
export const RECORDINGS_AUTH = 'RECORDINGS_AUTH';
export const INVOICES_AUTH = 'INVOICES_AUTH';
export const TICKETS_AUTH = 'TICKETS_AUTH';
export const EVALUATION_AUTH = 'EVALUATION_AUTH';
export const AUTO_ASSIGN_CAROUSEL_AUTH = 'AUTO_ASSIGN_CAROUSEL_AUTH';
export const PERMISSIONS_AUTH = 'PERMISSIONS_AUTH';
export const TICKETS_RATING_AUTH = 'TICKETS_RATING_AUTH';
export const PAYMENT_AUTH = 'PAYMENT_AUTH';

export const CREATE_PERMISSION = 'create';
export const READ_PERMISSION = 'read';
export const UPDATE_PERMISSION = 'update';
export const DELETE_PERMISSION = 'delete';

export const PERMISSIONS = [
    LEAD_AUTH,
    SOURCE_AUTH,
    STORE_AUTH,
    MAKE_AUTH,
    VEHICLES_AUTH,
    USER_AUTH,
    TEMPERATURES_AUTH,
    RECORDINGS_AUTH,
    INVOICES_AUTH,
    TICKETS_AUTH,
    TICKETS_RATING_AUTH,
    EVALUATION_AUTH,
    AUTO_ASSIGN_CAROUSEL_AUTH,
    PERMISSIONS_AUTH,
    PAYMENT_AUTH,
];
