import { DEFAULT_FLEET_COMPANY_STATE } from 'src/constants/fleetCompany/stateConfiguration'

import {
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    GET_FLEET_COMPANIES,
    GET_FLEET_COMPANY,
    CREATE_FLEET_COMPANY,
    DELETE_FLEET_COMPANY,
    UPDATE_FLEET_COMPANY,
} from '../types'

export default (state, action) => {
    switch (action.type) {
        case GET_FLEET_COMPANIES:
            return {
                ...state,
                fleetCompanies: action.payload,
                loading: false,
                error: null,
                count: action.count,
            }
        case GET_FLEET_COMPANY:
            return {
                ...state,
                fleetCompany: action.payload,
                loading: false,
                error: null,
            }
        case CREATE_FLEET_COMPANY:
            return {
                ...state,
                loading: false,
                error: null,
            }
        case DELETE_FLEET_COMPANY:
            state.fleetCompanies = state.fleetCompanies.filter(
                (fleetCompany) =>
                    fleetCompany._id.toString() !== action.payload.toString()
            )
            return {
                ...state,
                fleetCompany: null,
                loading: false,
                error: null,
            }
        case UPDATE_FLEET_COMPANY:
            return {
                ...state,
                fleetCompany: action.payload,
                loading: false,
                error: null,
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case CLEAR_STATE:
            return DEFAULT_FLEET_COMPANY_STATE
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
