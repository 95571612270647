export const DEFAULT_FLEET_COMPANY_STATE = {
    fleetCompanies: [],
    fleetCompany: {},
    loading: false,
    error: null,
    count: null,
};

export const FLEET_COMPANY_ENDPOINT = 'fleetCompany';
export const FLEET_COMPANY_ADVANCED_SEARCH_STATE =
    'advancedSearchStateFleetCompany';
export const FLEET_COMPANY_ADVANCED_SEARCH = 'advancedSearchFleetCompany';
