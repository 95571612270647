import { TNotificationOptions } from 'src/components/Alerts/types';
import { DEFAULT_ANCHOR_ORIGIN, DEFAULT_VARIANT } from '../constants';

/**
 * Will return snackbar props
 *
 * @param {TNotificationOptions} [options]
 */
export const getSnackbarProps = (
    options?: TNotificationOptions
): TNotificationOptions => {
    const response: TNotificationOptions = {
        anchorOrigin: options?.anchorOrigin || DEFAULT_ANCHOR_ORIGIN,
        variant: options?.variant || DEFAULT_VARIANT,
    };

    return response;
};
