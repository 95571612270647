import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import {
    blue,
    grey,
    green,
    purple,
    pink,
    cyan,
    yellow,
    teal,
    deepPurple,
    indigo,
    deepOrange,
    red,
    lightGreen,
} from '@material-ui/core/colors';
import { alpha } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: theme.typography.fontFamily,
        alignItems: 'center',
        borderRadius: 2,
        display: 'inline-flex',
        flexGrow: 0,
        whiteSpace: 'nowrap',
        cursor: 'default',
        flexShrink: 0,
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightMedium,
        height: 20,
        justifyContent: 'center',
        letterSpacing: 0.5,
        minWidth: 20,
        padding: theme.spacing(0.5, 1),
        // textTransform: 'uppercase'
    },
    primary: {
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, 0.08),
    },
    secondary: {
        color: theme.palette.secondary.main,
        backgroundColor: alpha(theme.palette.secondary.main, 0.08),
    },
    error: {
        color: theme.palette.error.main,
        backgroundColor: alpha(theme.palette.error.main, 0.08),
    },
    success: {
        color: theme.palette.success.main,
        backgroundColor: alpha(theme.palette.success.main, 0.08),
    },
    extrahot: {
        color: '#6F6CC9',
        backgroundColor: alpha('#6F6CC9', 0.08),
    },
    warning: {
        color: theme.palette.warning.main,
        backgroundColor: alpha(theme.palette.warning.main, 0.08),
    },
    blue: {
        color: blue[600],
        backgroundColor: alpha(blue[600], 0.08),
    },
    deepPurple: {
        color: deepPurple[300],
        backgroundColor: alpha(deepPurple[500], 0.12),
    },
    deepOrange: {
        color: deepOrange[300],
        backgroundColor: alpha(deepOrange[600], 0.08),
    },
    yellow: {
        color: yellow[700],
        backgroundColor: alpha(yellow[600], 0.08),
    },
    green: {
        color: green[600],
        backgroundColor: alpha(green[600], 0.08),
    },
    purple: {
        color: purple[600],
        backgroundColor: alpha(purple[600], 0.08),
    },
    indigo: {
        color: indigo[300],
        backgroundColor: alpha(indigo[500], 0.1),
    },
    pink: {
        color: theme.palette.type === 'light' ? pink[900] : pink[100],
        backgroundColor: alpha(pink[200], 0.1),
    },
    deepPink: {
        color: pink[500],
        backgroundColor: alpha(pink[500], 0.12),
    },
    darkTeal: {
        color: teal['A700'],
        backgroundColor: alpha(teal['A700'], 0.08),
    },
    cyan: {
        color: cyan[600],
        backgroundColor: alpha(cyan[600], 0.08),
    },
    teal: {
        color: teal[600],
        backgroundColor: alpha(teal[600], 0.08),
    },
    lightGrey: {
        color: theme.palette.text.primary,
        backgroundColor: alpha(grey[600], 0.08),
    },
    lightPurple: {
        color: purple[400],
        backgroundColor: alpha(purple[600], 0.08),
    },
    grey: {
        padding: 10,
        color: theme.palette.text.primary,
        backgroundColor: alpha(grey[300], 0.08),
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: alpha(grey[600], 0.4),
        },
    },
    lowRed: {
        color: red[400],
        backgroundColor: alpha(red[400], 0.08),
    },
    lime: {
        color: lightGreen['A200'],
        backgroundColor: alpha(lightGreen['A200'], 0.08),
    },
}));

const Label = ({
    className = '',
    color = 'secondary',
    children,
    style = {},
    ...rest
}) => {
    const classes = useStyles();

    return (
        <span
            className={clsx(
                classes.root,
                {
                    [classes[color]]: color,
                },
                className
            )}
            style={{ cursor: 'pointer' }}
            {...rest}
        >
            {children}
        </span>
    );
};

Label.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

export default Label;
