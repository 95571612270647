import React, { useReducer } from 'react';
import InvestmentContext from './investmentContext';
import InvestmentReducer from './investmentReducer';
import api from '../../api/api';
import {
    GET_INVESTMENTS,
    GET_INVESTMENTS_BY_STORE,
    CREATE_INVESTMENT,
    GET_INVESTMENT,
    DELETE_INVESTMENT,
    UPDATE_INVESTMENT,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';
import { HEADERS } from '../../constants/headers';
import axios from 'axios';

const InvestmentState = (props) => {
    const initialState = {
        investments: [],
        investment: {},
        loading: false,
        error: null,
        count: null,
    };

    const [state, dispatch] = useReducer(InvestmentReducer, initialState);

    const AdvancedResults = async (pagination, query) => {
        setLoading();
        try {
            const res = await api.get(
                `/investments/getInvestmentAr?page=${pagination.page}&limit=${pagination.limit}${query}&searchType=and&validation=1`,
                HEADERS()
            );
            dispatch({
                type: GET_INVESTMENTS,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Investments
    const getInvestments = async (pagination, query, typeQuery) => {
        setLoading();
        try {
            const res = await api.get(
                `/investments?page=${pagination.page}&searchIndex=store-make-source&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1`,
                HEADERS()
            );

            dispatch({
                type: GET_INVESTMENTS,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Investments by store
    const getInvestmentsByStore = async (
        stores,
        pagination,
        query,
        typeQuery
    ) => {
        setLoading();
        try {
            const res = await api.get(
                `/investments?${stores}&page=${pagination.page}&searchIndex=store-make-source&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1`,
                HEADERS()
            );

            dispatch({
                type: GET_INVESTMENTS_BY_STORE,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Investment
    const getInvestment = async (investmentId) => {
        clearState();
        setLoading();
        try {
            const res = await api.get(
                `/investments/${investmentId}`,
                HEADERS()
            );
            dispatch({ type: GET_INVESTMENT, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Delete Investment
    const deleteInvestment = async (investmentId) => {
        setLoading();
        try {
            const res = await api.delete(
                `/investments/${investmentId}`,
                HEADERS()
            );
            dispatch({ type: DELETE_INVESTMENT, payload: res.data.deletedId });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Create Investment
    const createInvestment = async (investment, file) => {
        setLoading();

        try {
            const uploadConfig = await api.post(
                '/uploads/image',
                { type: file.type, fileName: file.name },
                HEADERS()
            );

            await axios.put(uploadConfig.data.url, file, {
                headers: { 'Content-Type': file ? file.type : null },
            });

            const dataKey = uploadConfig.data.key;

            const res = await api.post(
                `/investments`,
                { ...investment, invoice: dataKey },
                HEADERS()
            );
            dispatch({ type: CREATE_INVESTMENT, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Update Investment
    const updateInvestment = async (investment, investmentId, file) => {
        clearState();
        setLoading();

        try {
            let res;
            if (file) {
                const uploadConfig = await api.post(
                    '/uploads/image',
                    { type: file.type, fileName: file.name },
                    HEADERS()
                );

                await axios.put(uploadConfig.data.url, file, {
                    headers: { 'Content-Type': file ? file.type : null },
                });

                const dataKey = uploadConfig.data.key;

                res = await api.put(
                    `/investments/${investmentId}`,
                    { invoice: dataKey },
                    HEADERS()
                );
            } else {
                res = await api.put(
                    `/investments/${investmentId}`,
                    { ...investment },
                    HEADERS()
                );
            }
            dispatch({ type: UPDATE_INVESTMENT, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <InvestmentContext.Provider
            value={{
                loading: state.loading,
                investments: state.investments,
                investment: state.investment,
                error: state.error,
                count: state.count,
                getInvestments,
                createInvestment,
                getInvestment,
                deleteInvestment,
                updateInvestment,
                clearState,
                setLoading,
                getInvestmentsByStore,
                AdvancedResults,
            }}
        >
            {props.children}
        </InvestmentContext.Provider>
    );
};

export default InvestmentState;
