export const ROCKSTAR_STRING = 'rockstar';
export const SUPER_ADMIN_STRING = 'super admin';
export const ADMIN_STRING = 'admin';
export const ADMIN_BDC_STRING = 'admin bdc';
export const USER_STRING = 'user';
export const MARKETING_STRING = 'marketing';
export const RECEPTIONIST_STRING = 'receptionist';
export const DIGITAL_MARKETING_STRING = 'digital marketing';
export const GENERAL_MANAGER_STRING = 'general manager';
export const USER_BDC_STRING = 'user bdc';
export const USER_SERVICE_STRING = 'user service';
export const ADMIN_SERVICE_STRING = 'admin service';
export const RH_STRING = 'rh';
export const SALES_MANAGER_STRING = 'sales manager';
export const RECEPTIONIST_CALLS_STRING = 'receptionist calls';

export const ROLES_STRING_ARRAY = [
    ROCKSTAR_STRING,
    SUPER_ADMIN_STRING,
    ADMIN_STRING,
    ADMIN_BDC_STRING,
    USER_STRING,
    MARKETING_STRING,
    RECEPTIONIST_STRING,
    DIGITAL_MARKETING_STRING,
    GENERAL_MANAGER_STRING,
    USER_BDC_STRING,
    USER_SERVICE_STRING,
    ADMIN_SERVICE_STRING,
    RH_STRING,
    SALES_MANAGER_STRING,
    RECEPTIONIST_CALLS_STRING,
];

export type RolesStringArrayTypes = (typeof ROLES_STRING_ARRAY)[number];
