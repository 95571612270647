import { TYPEOF_OBJECT } from 'src/constants/utils/typeof';

export const Capitalize = (string: string) => {
    if (string === undefined) return '';

    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const CapitalizeNames = (string: string | undefined) => {
    if (string === undefined || string === null) return '';
    if (typeof string === TYPEOF_OBJECT) return '';

    const words = string.split(' ');

    let finalString = '';

    words.map((word, i) => {
        if (i !== 0 && i !== words.length) {
            finalString += ' ';
        }
        if (word.includes('.') || word.includes('/')) {
            finalString += word.toUpperCase();
        } else {
            finalString +=
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
        return false;
    });

    return finalString;
};

/**
 * Will format the phone number given with the format of
 * (+xx) xx xxxx xxxx
 *
 */
export const formatPhone = (phone: string): string => {
    if (!phone) return '';

    const countryCode = phone.substring(0, 3);
    const firstPart = phone.substring(3, 5);
    const secondPart = phone.substring(5, 9);
    const thirdPart = phone.substring(9);

    return `(${countryCode}) ${firstPart} ${secondPart} ${thirdPart}`;
};
