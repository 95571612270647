import {
    GET_CONVERSATIONS_WSP,
    CLEAR_STATE,
    SET_ERROR,
    CLEAR_ERROR,
    GET_CONVERSATION_WSP,
    MESSAGE_SOCKET_WSP,
    SET_FINAL_WSP,
    CONVERSATION_SOCKET_WSP,
    DELETE_CONVERSATION_WSP,
    CREATE_LEAD_WSP,
    UPDATE_CONVERSATION_WSP,
    CLEAR_CONVERSATION,
    SET_LOADING,
} from '../types';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

export default (state, action) => {
    const { enqueueSnackbar } = useSnackbar();

    switch (action.type) {
        case UPDATE_CONVERSATION_WSP:
            if (action.successMessage) {
                enqueueSnackbar(action.successMessage, {
                    variant: 'success',
                });
            }

            let indexE = state.wspConversations.findIndex(
                (item) => item._id === action.payload._id
            );
            state.wspConversations[indexE] = action.payload;
            return {
                ...state,
                wspConversation: action.payload,
            };
        case CREATE_LEAD_WSP:
            if (action.successMessage) {
                enqueueSnackbar(action.successMessage, {
                    variant: 'success',
                });
            }

            let indexU = state.wspConversations.findIndex(
                (item) => item._id === action.payload._id
            );
            state.wspConversations[indexU] = action.payload;
            return {
                ...state,
                wspConversation: action.payload,
            };
        case DELETE_CONVERSATION_WSP:
            let deleteC = state.wspConversations.filter(
                (item) => item._id !== action.payload
            );
            return {
                ...state,
                loading: false,
                wspConversation: {},
                wspConversations: deleteC,
            };
        case SET_FINAL_WSP:
            return {
                ...state,
                wspFinal: action.payload,
            };
        case CONVERSATION_SOCKET_WSP:
            return {
                ...state,
                wspConversations: [...state.wspConversations, action.payload],
            };
        case MESSAGE_SOCKET_WSP:
            let converMessageWSP = _.findIndex(
                state.wspConversations,
                (item) => item._id === action.wspConversation
            );

            if (converMessageWSP !== -1) {
                const currentConverMessageWSP =
                    state.wspConversations[converMessageWSP].messages;
                const updatedConverMessageWSP = Array.isArray(
                    currentConverMessageWSP
                )
                    ? currentConverMessageWSP
                    : [];
                state.wspConversations[converMessageWSP].messages = [
                    ...updatedConverMessageWSP,
                    action.payload,
                ];
            }

            const currentWspConversationMessages =
                state.wspConversation.messages;
            const updatedWspConversationMessages = Array.isArray(
                currentWspConversationMessages
            )
                ? currentWspConversationMessages
                : [];
            const currentWspMessages = state.wspMessages;
            const updatedWspMessages = Array.isArray(currentWspMessages)
                ? currentWspMessages
                : [];

            return {
                ...state,
                wspConversation: {
                    ...state.wspConversation,
                    messages: [
                        ...updatedWspConversationMessages,
                        action.payload,
                    ],
                },
                wspMessages: [...updatedWspMessages, action.payload],
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };
        case GET_CONVERSATION_WSP:
            let isRead = _.findIndex(
                state.wspConversations,
                (item) => item._id === action.payload._id
            );
            state.wspConversations[isRead] = action.payload;

            return {
                ...state,
                wspMessages: action.payload.messages,
                wspConversation: action.payload,
                loading: false,
            };

        case GET_CONVERSATIONS_WSP:
            let convers = [];
            if (action.reload) convers = action.payload;
            else convers = [...state.wspConversations, ...action.payload];

            return {
                ...state,
                wspConversations: convers,
                wspPagination: action.pagination,
                wspLastItems: action.payload.length,
                wspFinal: action.final,
                loading: false,
            };

        case CLEAR_STATE:
            return {
                ...state,
                wspConversation: {},
                wspConversations: [],
                wspPagination: {},
                wspMessages: [],
                error: null,
            };
        case CLEAR_CONVERSATION:
            return {
                ...state,
                wspConversation: {},
                wspMessages: [],
                error: null,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };

        default:
            return state;
    }
};
