import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        path: '/create/group',
        guard: RolesGuard,
        roles: ['rockstar'],
        component: lazy(() => import('src/views/group/GroupCreateView')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar'],
        path: '/management/groups/:id',
        component: lazy(() => import('src/views/group/GroupDetailsView')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar'],
        path: '/management/groups/:id/edit',
        component: lazy(() => import('src/views/group/GroupEditView')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar'],
        path: '/management/groups',
        component: lazy(() => import('src/views/group/GroupListView')),
    },
];
