import React, { useReducer } from 'react';
import api from 'src/api/api';
import wspConversationsReducer from './wspConversationsReducer';
import WspConversationsContext from './wspConversationsContext';

import {
    GET_CONVERSATIONS_WSP,
    CLEAR_STATE,
    CLEAR_MESSAGES_WSP,
    SET_ERROR,
    SEND_MESSAGE_WSP,
    CLEAR_ERROR,
    GET_CONVERSATION_WSP,
    MESSAGE_SOCKET_WSP,
    SET_FINAL_WSP,
    CONVERSATION_SOCKET_WSP,
    DELETE_CONVERSATION_WSP,
    CREATE_LEAD_WSP,
    UPDATE_CONVERSATION_WSP,
    CLEAR_CONVERSATION,
    SET_LOADING,
} from '../types';
import { HEADERS } from '../../constants/headers';
import { channel } from 'src/service/pusher';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const WspConversationsState = (props) => {
    const initialState = {
        wspConversation: {},
        wspConversations: [],
        wspPagination: {},
        wspLastItems: null,
        wspMessages: [],
        wspFinal: false,
        loading: false,
        error: null,
    };

    const { t } = useTranslation();

    const [state, dispatch] = useReducer(wspConversationsReducer, initialState);

    const updateWspConversation = async (values, conversationId) => {
        try {
            const res = await api.put(
                `/wspConversations/${conversationId}`,
                { ...values },
                HEADERS()
            );
            dispatch({
                type: UPDATE_CONVERSATION_WSP,
                payload: res.data.data,
                successMessage: t('SnackBar.ConversationUpdated'),
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const createLeadWsp = async (values) => {
        try {
            const res = await api.post(
                '/wspConversations/leads',
                { ...values },
                HEADERS()
            );

            dispatch({
                type: CREATE_LEAD_WSP,
                payload: res.data.data,
                successMessage: t('SnackBar.LeadCreated'),
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const deleteWspConversation = async (conversationId) => {
        try {
            const res = await api.delete(
                `/wspConversations/${conversationId}`,
                HEADERS()
            );
            dispatch({ type: DELETE_CONVERSATION_WSP, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const sendWspTemplate = async (values) => {
        await api.post(`/wspConversations/template`, { ...values }, HEADERS());
    };

    const getWspConversations = async (values, reload) => {
        try {
            setLoading();
            const res = await api.post(
                `/wspConversations`,
                { ...values },
                HEADERS()
            );
            dispatch({
                type: GET_CONVERSATIONS_WSP,
                payload: res.data?.data,
                pagination: res.data?.pagination,
                reload,
                final: res.data?.final,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const getWspConversation = async (conversationId, lastConversationId) => {
        try {
            setLoading();
            await channel.unbind(`conversation_${lastConversationId}`);

            const res = await api.get(
                `/wspConversations/${conversationId}`,
                HEADERS()
            );

            await channel.bind(`conversation_${conversationId}`, (data) => {
                addWspMessageSocket(data.message, data.conversation);
            });

            dispatch({ type: GET_CONVERSATION_WSP, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const addWspMessageSocket = async (message, conversation) => {
        try {
            let messageData = message;
            if (!messageData?._id) {
                let res = await api.get(`messages/${messageData}`, HEADERS());
                messageData = res.data.data;
            }
            dispatch({
                type: MESSAGE_SOCKET_WSP,
                payload: message,
                conversation,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const addWspConversationSocket = async (conversation) => {
        let conversationData = conversation;
        if (!conversationData?._id) {
            const res = await api.get(
                `/wspConversations/${conversationData}?limit=1&sort=_id,-1`,
                HEADERS()
            );
            conversationData = res.data.data;
        }
        dispatch({
            type: CONVERSATION_SOCKET_WSP,
            payload: conversationData,
        });
    };

    const sendWspMessage = async (values) => {
        try {
            if (values.files) {
                let files = [...values.files];
                delete values.files;

                for (let i = 0; i < files.length; i++) {
                    let response = await api.post(
                        '/uploads/image',
                        { type: files[i].type, fileName: files[i].name },
                        HEADERS()
                    );
                    await axios.put(response.data.url, files[i], {
                        headers: { 'Content-Type': files[i].type },
                    });

                    const res = await api.post(
                        `/wspConversations/sendMessage`,
                        {
                            ...values,
                            MediaUrl0: `https://automotive-api.s3.us-east-2.amazonaws.com/${response.data.key}`,
                            MediaContentType0: files[i].type.replace(
                                '; codecs=opus',
                                ''
                            ),
                        },
                        HEADERS()
                    );
                    dispatch({
                        type: SEND_MESSAGE_WSP,
                        payload: res.data.data,
                    });
                }
            } else if (values.documents) {
                let documents = [...values.documents];
                delete values.documents;

                for (let i = 0; i < documents.length; i++) {
                    const arrayFile = documents[i].file.split('.');
                    const type = arrayFile[arrayFile.length - 1];

                    const res = await api.post(
                        `/wspConversations/sendMessage`,
                        {
                            ...values,
                            MediaUrl0: `https://automotive-api.s3.us-east-2.amazonaws.com/${documents[i].file}`,
                            MediaContentType0: type,
                        },
                        HEADERS()
                    );
                    dispatch({
                        type: SEND_MESSAGE_WSP,
                        payload: res.data.data,
                    });
                }
            } else {
                const res = await api.post(
                    `/wspConversations/sendMessage`,
                    { ...values },
                    HEADERS()
                );
                dispatch({ type: SEND_MESSAGE_WSP, payload: res.data.data });
            }
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    const clearError = () => dispatch({ type: CLEAR_ERROR });
    const clearState = () => dispatch({ type: CLEAR_STATE });
    const clearWspConversation = () => dispatch({ type: CLEAR_CONVERSATION });
    const clearWspMessages = () => dispatch({ type: CLEAR_MESSAGES_WSP });
    const setWspFinal = (value = false) =>
        dispatch({ type: SET_FINAL_WSP, payload: value });
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <WspConversationsContext.Provider
            value={{
                wspConversations: state.wspConversations,
                wspPagination: state.wspPagination,
                loading: state.loading,
                error: state.error,
                wspConversation: state.wspConversation,
                wspLastItems: state.wspLastItems,
                wspMessages: state.wspMessages,
                wspFinal: state.wspFinal,
                addWspConversationSocket,
                clearError,
                getWspConversations,
                deleteWspConversation,
                createLeadWsp,
                setWspFinal,
                updateWspConversation,
                getWspConversation,
                clearState,
                addWspMessageSocket,
                sendWspMessage,
                clearWspMessages,
                sendWspTemplate,
                clearWspConversation,
            }}
        >
            {props.children}
        </WspConversationsContext.Provider>
    );
};

export default WspConversationsState;
