import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import DPXWrapper from 'src/components/DPXWrapper';

import { MainLayout, DashboardLayout } from 'src/layouts';

import {
    AccountRoutes,
    AdCampaignsRoutes,
    AuthRoutes,
    BDCRoutes,
    CalendarsRoutes,
    CatalogRoutes,
    ChartsRoutes,
    ChatBotRoutes,
    CompaniesRoutes,
    ConversationsRoutes,
    DashboardsRoutes,
    DocumentsRoutes,
    DrilldownsRoutes,
    FinancialInstitutionsRoutes,
    GroupsRoutes,
    InvestmentChartRoutes,
    LeadsRoutes,
    LeadsServiceRoutes,
    MakesRoutes,
    ManagementRoutes,
    PackagesRoutes,
    RecordingTestRoutes,
    RecordingsRoutes,
    RolesRoutes,
    SalesRoutes,
    SettingsRoutes,
    ShowroomRoutes,
    SourcesRoutes,
    StatusRoutes,
    StoresRoutes,
    SubstatusRoutes,
    TemperaturesRoutes,
    TemplatesTwilioRoutes,
    TicketsRoutes,
    UsersRoutes,
    UtilsRoutes,
    VehiclesRoutes,
    leadsBDCRoutes,
    FleetCompany,
    LeadsFleetRoutes,
    EvaluationsRoutes,
} from 'src/routing';

export const renderRoutes = (routes = []) => (
    <Suspense fallback={<LoadingScreen />}>
        <Switch>
            {routes.map((route, key) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                const { roles, path, exact, routes } = route;

                return (
                    <Route
                        key={key}
                        path={path}
                        exact={exact}
                        render={(props) => (
                            <Guard {...(Guard !== Fragment && { roles })}>
                                <Layout>
                                    {routes ? (
                                        renderRoutes(routes)
                                    ) : (
                                        <DPXWrapper {...props}>
                                            <Component />
                                        </DPXWrapper>
                                    )}
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
);

const routes = [
    ...AuthRoutes,
    {
        path: '/',
        layout: DashboardLayout,
        routes: [
            ...AccountRoutes,
            ...AdCampaignsRoutes,
            ...BDCRoutes,
            ...CalendarsRoutes,
            ...CatalogRoutes,
            ...ChartsRoutes,
            ...ChatBotRoutes,
            ...CompaniesRoutes,
            ...ConversationsRoutes,
            ...DashboardsRoutes,
            ...DocumentsRoutes,
            ...DrilldownsRoutes,
            ...FinancialInstitutionsRoutes,
            ...GroupsRoutes,
            ...InvestmentChartRoutes,
            ...LeadsRoutes,
            ...leadsBDCRoutes,
            ...LeadsServiceRoutes,
            ...LeadsFleetRoutes,
            ...EvaluationsRoutes,
            ...MakesRoutes,
            ...ManagementRoutes,
            ...PackagesRoutes,
            ...RecordingsRoutes,
            ...RecordingTestRoutes,
            ...RolesRoutes,
            ...SalesRoutes,
            ...SettingsRoutes,
            ...ShowroomRoutes,
            ...SourcesRoutes,
            ...StatusRoutes,
            ...StoresRoutes,
            ...SubstatusRoutes,
            ...TemperaturesRoutes,
            ...TemplatesTwilioRoutes,
            ...TicketsRoutes,
            ...UsersRoutes,
            ...UtilsRoutes,
            ...VehiclesRoutes,
            ...FleetCompany,
            {
                exact: true,
                path: '/management',
                component: () => <Redirect to="/management/leads" />,
            },
            {
                component: () => <Redirect to="/404" />,
            },
        ],
    },
    {
        path: '*',
        layout: MainLayout,
        routes: [
            {
                exact: true,
                path: '/',
                component: lazy(() => import('src/views/home/HomeView')),
            },
            {
                component: () => <Redirect to="/404" />,
            },
        ],
    },
];

export default routes;
