import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        path: '/create/ticket',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'user',
            'general manager',
            'sales manager',
        ],
        component: lazy(() => import('src/views/ticket/TicketCreateView')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'user',
            'sales manager',
            'general manager',
        ],
        path: '/management/tickets/:id',
        component: lazy(() => import('src/views/ticket/TicketDetailsView')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'user',
            'general manager',
            'sales manager',
        ],
        path: '/management/tickets',
        component: lazy(() => import('src/views/ticket/TicketListView')),
    },
];
