import { Dispatch } from 'react';
import { ApiV2 } from 'src/api/apiv2';
import { HEADERS } from 'src/constants/headers';
import { SET_LOADING } from 'src/contexts/types';
import { TEvaluationActionTypes } from '../types';
import { handleAxiosError } from 'src/contexts/utils/handleAxiosError';
import { DELETE_EVALUATIONS } from '../constants/actions';
import { getEvaluations } from './getEvaluations';
import { IEvaluation } from 'src/interfaces/evaluation';

/**
 * Will delete multiple reviews
 *
 * @param {Dispatch<TEvaluationActionTypes>} dispatch
 * @param {Partial<IEvaluation>} evaluation
 * @param {Partial<IEvaluation>} filters
 */
export const deleteEvaluations = async (
    dispatch: Dispatch<TEvaluationActionTypes>,
    evaluation: Partial<IEvaluation>,
    filters: Partial<IEvaluation>
) => {
    dispatch({ type: SET_LOADING });
    try {
        const res = await ApiV2.post(
            `/evaluations/bulk-delete`,
            evaluation,
            HEADERS()
        );
        dispatch({
            type: DELETE_EVALUATIONS,
            payload: res.data.deletedId,
        });

        if (filters) getEvaluations(dispatch, filters);
    } catch (error) {
        dispatch(handleAxiosError(error));
    }
};
