import React, { Fragment, useEffect } from 'react';
import useAuth from 'src/hooks/useAuth';
import { TAreas } from 'src/constants/lead/leadTypes';
import { TUserCarType } from 'src/constants/user';
import { channel } from 'src/service/pusher';
import { IStore } from 'src/interfaces/store';
import { getNotificationArea } from 'src/components/Alerts/utils/getNotificationArea';
import {
    ASSIGN_CONVERSATION_ALERT_TYPE,
    INCOMING_CONVERSATION_ALERT_TYPE,
    INCOMING_MESSAGE_ALERT_TYPE,
    CREATED_TICKET_ALERT_TYPE,
    RESPONSE_TICKET_ALERT_TYPE,
} from 'src/components/Alerts/constants';
import { SnackbarAlert } from './SnackbarAlert';
import {
    TNotification,
    TTicketNotification,
} from 'src/components/Alerts/types';
import {
    DEVELOPER_LEVEL_ACCESS,
    ADMIN_LEVEL_ACCESS,
    GROUP_LEVEL_ACCESS,
    USER_LEVEL_ACCESS,
    TAccessLevel,
} from 'src/components/access';
import { useTranslation } from 'react-i18next';
import { getChannelName } from 'src/utils/format/getChannelName';
import { getVariantColor } from '../utils/getVariantColor';
import { TICKETS_AUTH, READ_PERMISSION } from 'src/constants/permissions';
import { isPermissionGranted } from 'src/utils/permissions/isPermissionGranted';

export const WarboxNotifications = () => {
    const { user } = useAuth();
    const { t } = useTranslation();
    const { conversationsAlert, ticketAlert } = SnackbarAlert();

    const handleShowNotification = (event: TNotification) => {
        const { type, payload } = event;
        const { channel: channelPayload } = payload;

        const variant = getVariantColor(channelPayload);

        const channel = getChannelName(channelPayload);

        switch (type) {
            case INCOMING_CONVERSATION_ALERT_TYPE: {
                const title = `${t('SnackBar.IncomingMessage')} ${channel}`;
                return conversationsAlert(payload, { variant, title });
            }
            case INCOMING_MESSAGE_ALERT_TYPE: {
                const title = `${t('SnackBar.IncomingMessage')} ${channel}`;
                return conversationsAlert(payload, { variant, title });
            }
            case ASSIGN_CONVERSATION_ALERT_TYPE: {
                const title = `${t('SnackBar.AssignConversation')} ${channel}`;
                return conversationsAlert(payload, { variant, title });
            }
            default:
                return;
        }
    };

    const handleShowTicketNotification = (event: TTicketNotification) => {
        const { type, payload } = event;
        const { accessLevel, _id: userId } = user;

        if (
            accessLevel === GROUP_LEVEL_ACCESS &&
            payload.ticketUserId &&
            userId === payload.ticketUserId
        )
            return;

        switch (type) {
            case RESPONSE_TICKET_ALERT_TYPE: {
                const title = `${t('SnackBar.ResponseTicket')}`;
                return ticketAlert(payload, { variant: 'warning', title });
            }
            case CREATED_TICKET_ALERT_TYPE: {
                const title = `${t('SnackBar.TicketCreatedNotification')}`;
                return ticketAlert(payload, { variant: 'warning', title });
            }
            default:
                return;
        }
    };

    const bindByStoreAndArea = (
        storeId: string,
        userArea: TAreas,
        carType: TUserCarType
    ) => {
        const response = getNotificationArea(userArea, carType);
        response.forEach((area) =>
            channel.bind(`${storeId}_${area}`, handleShowNotification)
        );
    };

    const handleNotifications = (accessLevel: TAccessLevel) => {
        const {
            stores = [],
            areas = [],
            carType,
            _id: userId,
            group = {},
            permissions,
        } = user;
        const { _id: groupId } = group;

        const ticketPermissionGranted = isPermissionGranted({
            permissions: permissions || {},
            permission: TICKETS_AUTH,
            crud: READ_PERMISSION,
        });

        if (accessLevel === USER_LEVEL_ACCESS) {
            channel.bind(userId, handleShowNotification);
            channel.bind(userId, handleShowTicketNotification);
        }

        if (accessLevel === ADMIN_LEVEL_ACCESS) {
            channel.bind(userId, handleShowTicketNotification);
            stores.forEach((store: IStore) => {
                areas.forEach((userArea: TAreas) => {
                    bindByStoreAndArea(store._id, userArea, carType);
                });
            });
        }

        if (accessLevel === GROUP_LEVEL_ACCESS && groupId) {
            if (ticketPermissionGranted) {
                channel.bind(groupId, handleShowTicketNotification);
            }
            channel.bind(groupId, handleShowNotification);
            channel.bind(userId, handleShowTicketNotification);
        }

        if (accessLevel === DEVELOPER_LEVEL_ACCESS && ticketPermissionGranted) {
            channel.bind(userId, handleShowTicketNotification);
            channel.bind(DEVELOPER_LEVEL_ACCESS, handleShowTicketNotification);
        }
    };

    useEffect(() => {
        if (user?.accessLevel) handleNotifications(user.accessLevel);
    }, [user]);

    return <Fragment />;
};
