import React, { useReducer } from 'react';
import MailDocumentationContext from './mailDocumentationContext';
import MailDocumentationReducer from './mailDocumentationReducer';
import api from '../../api/api';
import {
    GET_MAIL_DOCUMENTATIONS,
    GET_MAIL_DOCUMENTATIONS_BY_STORE,
    CREATE_MAIL_DOCUMENTATION,
    GET_MAIL_DOCUMENTATION,
    DELETE_MAIL_DOCUMENTATION,
    UPDATE_MAIL_DOCUMENTATION,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';
import { HEADERS } from '../../constants/headers';

const MailDocumentationState = (props) => {
    const initialState = {
        mailDocumentations: [],
        mailDocumentation: {},
        loading: false,
        error: null,
    };

    const [state, dispatch] = useReducer(
        MailDocumentationReducer,
        initialState
    );

    //Get MailDocumentations
    const getMailDocumentationByStore = async (query) => {
        if (!query) query = '';
        setLoading();
        try {
            const res = await api.get(
                `/mailDocumentations?sort=name${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_MAIL_DOCUMENTATIONS_BY_STORE,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get MailDocumentations
    const getMailDocumentations = async (query) => {
        if (!query) query = '';
        setLoading();
        try {
            const res = await api.get(
                `/mailDocumentations?sort=name${query}`,
                HEADERS()
            );
            dispatch({ type: GET_MAIL_DOCUMENTATIONS, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get MailDocumentation
    const getMailDocumentation = async (mailDocumentationId) => {
        setLoading();
        try {
            const res = await api.get(
                `/mailDocumentations/${mailDocumentationId}`,
                HEADERS()
            );
            dispatch({ type: GET_MAIL_DOCUMENTATION, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Delete MailDocumentation
    const deleteMailDocumentation = async (mailDocumentationId) => {
        setLoading();
        try {
            const res = await api.delete(
                `/mailDocumentations/${mailDocumentationId}`,
                HEADERS()
            );
            dispatch({
                type: DELETE_MAIL_DOCUMENTATION,
                payload: res.data.deletedId,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Create MailDocumentation
    const createMailDocumentation = async (mailDocumentation) => {
        clearMailDocumentationState();
        setLoading();
        try {
            const res = await api.post(
                `/mailDocumentations`,
                { ...mailDocumentation },
                HEADERS()
            );
            dispatch({
                type: CREATE_MAIL_DOCUMENTATION,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Update MailDocumentation
    const updateMailDocumentation = async (
        mailDocumentation,
        mailDocumentationId
    ) => {
        setLoading();
        try {
            const res = await api.put(
                `/mailDocumentations/${mailDocumentationId}`,
                { ...mailDocumentation },
                HEADERS()
            );
            dispatch({
                type: UPDATE_MAIL_DOCUMENTATION,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Clear State
    const clearMailDocumentationState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <MailDocumentationContext.Provider
            value={{
                loading: state.loading,
                mailDocumentations: state.mailDocumentations,
                mailDocumentation: state.mailDocumentation,
                error: state.error,
                getMailDocumentations,
                createMailDocumentation,
                getMailDocumentation,
                deleteMailDocumentation,
                updateMailDocumentation,
                getMailDocumentationByStore,
                clearMailDocumentationState,
                setLoading,
            }}
        >
            {props.children}
        </MailDocumentationContext.Provider>
    );
};

export default MailDocumentationState;
