export const WSP_PLUGIN = 'wsp';
export const FB_PLUGIN = 'fb';
export const CALLS_PLUGIN = 'calls';
export const MAILING_PLUGIN = 'mailing';
export const WSP_MARKETING_PLUGIN = 'wspMarketing';
export const CHAT_BOT_PLUGIN = 'chatBot';
export const AUTO_ASSIGN_PLUGIN = 'autoAssignLead';

export const PLUGINS = [
    WSP_PLUGIN,
    FB_PLUGIN,
    CALLS_PLUGIN,
    MAILING_PLUGIN,
    WSP_MARKETING_PLUGIN,
    CHAT_BOT_PLUGIN,
    AUTO_ASSIGN_PLUGIN,
];

export type TPluginsKeys = (typeof PLUGINS)[number];

export type TPlugins = TPluginsKeys[];
