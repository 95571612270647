import { Dispatch } from 'react';
import { ApiV2 } from 'src/api/apiv2';
import { HEADERS } from 'src/constants/headers';
import { SET_LOADING } from 'src/contexts/types';
import { TEvaluationActionTypes } from '../types';
import { IEvaluation } from 'src/interfaces/evaluation';
import { handleAxiosError } from 'src/contexts/utils/handleAxiosError';
import { CREATE_EVALUATION } from '../constants/actions';

/**
 * Will create an evaluation based on the identification with the given options.
 *
 * @param {Dispatch<TEvaluationActionTypes>} dispatch
 * @param {Partial<IEvaluation>} evaluation
 */
export const createEvaluation = async (
    dispatch: Dispatch<TEvaluationActionTypes>,
    evaluation: Partial<IEvaluation>
) => {
    dispatch({ type: SET_LOADING });

    try {
        const res = await ApiV2.post(
            `/evaluations`,
            { ...evaluation },
            HEADERS()
        );

        dispatch({ type: CREATE_EVALUATION, payload: res.data.data });
    } catch (error) {
        dispatch(handleAxiosError(error));
    }
};
