const INCOMING_CONVERSATION_ALERT_TYPE = 'INCOMING_CONVERSATION';
const INCOMING_MESSAGE_ALERT_TYPE = 'INCOMING_MESSAGE';
const ASSIGN_CONVERSATION_ALERT_TYPE = 'ASSIGN_CONVERSATION';

const CREATED_TICKET_ALERT_TYPE = 'CREATED_TICKET';
const RESPONSE_TICKET_ALERT_TYPE = 'RESPONSE_TICKET';

export {
    INCOMING_MESSAGE_ALERT_TYPE,
    INCOMING_CONVERSATION_ALERT_TYPE,
    ASSIGN_CONVERSATION_ALERT_TYPE,
    CREATED_TICKET_ALERT_TYPE,
    RESPONSE_TICKET_ALERT_TYPE,
};
