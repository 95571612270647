import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import {
    isAdmin,
    isAdminBDC,
    isDigitalMkt,
    isGeneralManager,
    isRockstar,
    isSalesManager,
    isSuper,
    isUser,
    isUserBDC,
} from 'src/utils/AuthRoles';

import { isPluginAvailable } from 'src/utils/store/isPluginAvailable';
import {
    CALLS_PLUGIN,
    FB_PLUGIN,
    MAILING_PLUGIN,
    WSP_PLUGIN,
} from 'src/constants/plugins';

const rolesArray = {
    rockstar: '6144d4548fb0f13225997789',
    'super admin': '6144d45a8fb0f1322599778a',
    admin: '6144d45f8fb0f1322599778b',
    adminBDC: '6196c53dc958985a03b9c43d',
    user: '6144d47b8fb0f1322599778c',
    marketing: '6144d4818fb0f1322599778d',
    receptionist: '6144d4a28fb0f1322599778e',
    'digital marketing': '616876627e498e7f25454f95',
    'general manager': '616da4c3f5a2169e9d924f1f',
    userBDC: '618ac8ba843a13cff0ec541e',
    userService: '61af8a6435ce5943f31f3a66',
    adminService: '61af8a7535ce5943f31f3d13',
    'sales manager': '6228f9ee4062f9000d39a230',
    rh: '623a10c14d87472a1f65629a',
    carone: '634194e3365ba80e4ce05579',
};

const validAddOnConversations = (route, user) => {
    if (
        route === '/conversations' &&
        !(
            user &&
            user.stores &&
            user.tier &&
            (isPluginAvailable(user.stores, WSP_PLUGIN) ||
                isPluginAvailable(user.stores, FB_PLUGIN) ||
                isRockstar(user.tier._id) ||
                ((isSuper(user.tier._id) || isDigitalMkt(user.tier._id)) &&
                    user.group &&
                    (isPluginAvailable(user.group.stores, WSP_PLUGIN) ||
                        isPluginAvailable(user.group.stores, FB_PLUGIN))))
        )
    ) {
        return false;
    }
    return true;
};

const validAddOnCalls = (route, user) => {
    if (
        (route.includes('/management/recordings') ||
            route === '/create/recording') &&
        !(
            user &&
            user.stores &&
            user.tier &&
            (isPluginAvailable(user.stores, CALLS_PLUGIN) ||
                isRockstar(user.tier._id) ||
                ((isSuper(user.tier._id) ||
                    isDigitalMkt(user.tier._id) ||
                    isGeneralManager(user.tier._id) ||
                    isSalesManager(user.tier._id)) &&
                    user.group &&
                    isPluginAvailable(user.group.stores, CALLS_PLUGIN)))
        )
    ) {
        return false;
    }
    return true;
};

const validAddOnCampaigns = (route, user) => {
    if (
        (route.includes('/management/lists') ||
            route.includes('/management/campaigns') ||
            route === '/create/campaign' ||
            route === '/create/campaign') &&
        !(
            user &&
            user.stores &&
            user.tier &&
            (isPluginAvailable(user.stores, MAILING_PLUGIN) ||
                isRockstar(user.tier._id) ||
                ((isSuper(user.tier._id) || isDigitalMkt(user.tier._id)) &&
                    user.group &&
                    isPluginAvailable(user.group.stores, MAILING_PLUGIN)))
        )
    ) {
        return false;
    }
    return true;
};

const validCreateVehicleUser = (route, user) => {
    if (
        route.includes('/create/vehicle') &&
        !(user && user._id && user.tier && isRockstar(user.tier._id))
    ) {
        return false;
    }
    return true;
};

const RolesGuard = ({ children, roles }) => {
    const { user } = useAuth();
    const history = useHistory();

    if (!localStorage.getItem('token')) history.push('/');

    useEffect(() => {
        if (user && user.tier) {
            if (roles) {
                let access = false;
                roles.map((item) => {
                    if (rolesArray[item] === user.tier._id.toString()) {
                        access = true;
                    }
                    return false;
                });

                if (!access) {
                    history.push('/reports/dashboard');
                }

                if (!validCreateVehicleUser(history.location.pathname, user))
                    history.push('/management/vehicles');

                if (!validAddOnConversations(history.location.pathname, user))
                    history.push('/reports/dashboard');
                if (!validAddOnCalls(history.location.pathname, user))
                    history.push('/reports/dashboard');
                if (!validAddOnCampaigns(history.location.pathname, user))
                    history.push('/reports/dashboard');

                if (
                    (isUser(user.tier._id) ||
                        isAdmin(user.tier._id) ||
                        isUserBDC(user.tier._id) ||
                        isAdminBDC(user.tier._id)) &&
                    history.location.pathname ===
                        '/management/showroom-leads' &&
                    user.areas &&
                    !user.areas.includes('showroom')
                )
                    history.push('/reports/dashboard');
                if (
                    (isUser(user.tier._id) ||
                        isAdmin(user.tier._id) ||
                        isUserBDC(user.tier._id) ||
                        isAdminBDC(user.tier._id)) &&
                    history.location.pathname === '/management/leads' &&
                    user.areas &&
                    !user.areas.includes('digital')
                )
                    history.push('/reports/dashboard');
                if (
                    (isUser(user.tier._id) ||
                        isAdmin(user.tier._id) ||
                        isUserBDC(user.tier._id) ||
                        isAdminBDC(user.tier._id)) &&
                    history.location.pathname === '/create/showroom-lead' &&
                    user.areas &&
                    !user.areas.includes('showroom')
                )
                    history.push('/reports/dashboard');
                if (
                    (isUser(user.tier._id) ||
                        isAdmin(user.tier._id) ||
                        isUserBDC(user.tier._id) ||
                        isAdminBDC(user.tier._id)) &&
                    history.location.pathname === '/create/lead' &&
                    user.areas &&
                    !user.areas.includes('digital')
                )
                    history.push('/reports/dashboard');
            } else {
                history.push('/');
            }
        }
        //eslint-disable-next-line
    }, [user]);

    return <>{children}</>;
};

RolesGuard.propTypes = {
    children: PropTypes.node,
};

export default RolesGuard;
