export const APP_VERSION = '1.0.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
    LIGHT: 'LIGHT',
    ONE_DARK: 'ONE_DARK',
    PINK: 'PINK',
    LIGHT_PINK: 'LIGHT_PINK',
    ORNN: 'ORNN',
    CATS: 'CATS',
    NAVIDAD: 'NAVIDAD',
    'NAVIDAD 2023': 'NAVIDAD 2023',
    GRINCH: 'GRINCH',
    'GRINCH DARK': 'GRINCH DARK',
    NAVIDARKS: 'NAVIDARKS',
    GWEN: 'GWEN',
    GWEN_CAFE_CUTIE: 'GWEN_CAFE_CUTIE',
    NEW_YEAR: 'NEW_YEAR',
    BLUE: 'BLUE',
    RED: 'RED',
    SAN_VALENTIN: 'SAN_VALENTIN',
    SAN_VALENTIN_SAD: 'SAN_VALENTIN_SAD',
    HALLOWEEN: 'HALLOWEEN',
    CASTANUELA: 'CASTANUELA',
    NOWAYHOME: 'NOWAYHOME',
    DARK_WARBOX: 'DARK_WARBOX',
    LIGHT_WARBOX: 'LIGHT_WARBOX',
};
