import {
    GET_TEMPLATESTWILIO,
    CREATE_TEMPLATETWILIO,
    GET_TEMPLATETWILIO,
    DELETE_TEMPLATETWILIO,
    UPDATE_TEMPLATETWILIO,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    GET_TEMPLATESTWILIO_BY_STORE,
    GET_TEMPLATES,
} from '../types';

export default (state, action) => {
    switch (action.type) {
        case GET_TEMPLATES:
            return {
                ...state,
                total: action.total,
                templates: action.payload,
                loading: false,
                error: null,
            };
        case GET_TEMPLATESTWILIO_BY_STORE:
        case GET_TEMPLATESTWILIO:
            return {
                ...state,
                templatesTwilio: action.payload,
                count: action.count,
                loading: false,
                error: null,
            };
        case GET_TEMPLATETWILIO:
            return {
                ...state,
                templateTwilio: action.payload,
                loading: false,
                error: null,
            };
        case CREATE_TEMPLATETWILIO:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case DELETE_TEMPLATETWILIO:
            state.templatesTwilio = state.templatesTwilio.filter(
                (templateTwilio) =>
                    templateTwilio._id.toString() !== action.payload.toString()
            );
            return {
                ...state,
                templateTwilio: null,
                loading: false,
                error: null,
            };
        case UPDATE_TEMPLATETWILIO:
            return {
                ...state,
                templateTwilio: action.payload,
                loading: false,
                error: null,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CLEAR_STATE:
            return {
                templatesTwilio: [],
                templateTwilio: {},
                templates: [],
                loading: false,
                error: null,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
};
