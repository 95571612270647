import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'general manager',
            'marketing',
            'digital marketing',
        ],
        path: '/management/chatbot',
        component: lazy(() => import('src/views/chatBotIA/chatBotList')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'general manager',
            'marketing',
            'digital marketing',
        ],
        path: '/management/chatbot/:id',
        component: lazy(() => import('src/views/chatBotIA/chatBotDetails')),
    },
];
