import React, { useReducer } from 'react';
import MakeContext from './makeContext';
import MakeReducer from './makeReducer';
import api from '../../api/api';
import {
    GET_MAKES,
    GET_MAKE,
    CREATE_MAKE,
    DELETE_MAKE,
    UPDATE_MAKE,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';
import { HEADERS } from '../../constants/headers';
import axios from 'axios';

const MakeState = (props) => {
    const initialState = {
        pagination: {},
        makes: [],
        make: {},
        loading: false,
        error: null,
        count: null,
    };

    const [state, dispatch] = useReducer(MakeReducer, initialState);

    //Get Make
    const getMake = async (makeId) => {
        clearMakeState();
        setLoading();
        try {
            const res = await api.get(`/makes/${makeId}`, HEADERS());
            dispatch({ type: GET_MAKE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Create Make
    const createMake = async (make) => {
        clearMakeState();
        setLoading();
        try {
            const res = await api.post(`/makes`, { ...make }, HEADERS());
            dispatch({ type: CREATE_MAKE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Delete Make
    const deleteMake = async (makeId) => {
        setLoading();
        try {
            const res = await api.delete(`/makes/${makeId}`, HEADERS());
            dispatch({ type: DELETE_MAKE, payload: res.data.deletedId });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Update Make
    const updateMake = async (make, makeId, file) => {
        if (file) {
            let uploadConfig = await api.post(
                '/uploads/image',
                { type: file.type, fileName: file.name },
                HEADERS()
            );

            await axios.put(uploadConfig.data.url, file, {
                headers: { 'Content-Type': file ? file.type : null },
            });

            const dataKey = uploadConfig.data.key;

            make.image =
                'https://automotive-api.s3.us-east-2.amazonaws.com/' + dataKey;
        }
        setLoading();

        try {
            const res = await api.put(
                `/makes/${makeId}`,
                { ...make },
                HEADERS()
            );
            dispatch({ type: UPDATE_MAKE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Clear State
    const clearMakeState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    //Get Makes
    const getMakes = async (query = {}) => {
        clearMakeState();
        setLoading();
        try {
            const res = await api.post(
                `/makes/searchDocuments`,
                query,
                HEADERS()
            );
            let { pagination = {}, results = [] } = res?.data;
            dispatch({ type: GET_MAKES, payload: results, pagination });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    return (
        <MakeContext.Provider
            value={{
                loading: state.loading,
                makes: state.makes,
                make: state.make,
                error: state.error,
                count: state.count,
                getMakes,
                createMake,
                getMake,
                deleteMake,
                updateMake,
                setLoading,
                clearMakeState,
            }}
        >
            {props.children}
        </MakeContext.Provider>
    );
};

export default MakeState;
