import React from 'react';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createWarboxTheme } from 'src/theme';
import MomentUtils from '@date-io/moment';
import useSettings from 'src/hooks/useSettings';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export const MuiThemeProvider = ({ children }) => {
    const { settings } = useSettings();

    const theme = createWarboxTheme({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        theme: settings.theme,
    });

    return (
        <ThemeProvider theme={theme}>
            <StylesProvider jss={jss}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    {children}
                </MuiPickersUtilsProvider>
            </StylesProvider>
        </ThemeProvider>
    );
};
