import React from 'react';
import SITE_SETTINGS from 'src/constants/whiteLabel';

const Logo = (props) => {
    return (
        <img
            alt="Logo"
            src={SITE_SETTINGS.logo}
            {...props}
            style={{ height: '40px' }}
        />
    );
};

export default Logo;
