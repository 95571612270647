import React, { useReducer } from 'react';
import DocumentContext from './documentContext';
import DocumentReducer from './documentReducer';
import api from '../../api/api';
import {
    GET_DOCUMENTS,
    GET_DOCUMENTS_BY_STORE,
    CREATE_DOCUMENT,
    GET_DOCUMENT,
    DELETE_DOCUMENT,
    UPDATE_DOCUMENT,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    GET_DOCUMENTS_BY_CATALOGUE,
} from '../types';
import { HEADERS } from '../../constants/headers';
import axios from 'axios';

const DocumentState = (props) => {
    const initialState = {
        documents: [],
        document: {},
        loading: false,
        error: null,
        count: null,
        documentsByCatalogue: [],
    };

    const [state, dispatch] = useReducer(DocumentReducer, initialState);

    const AdvancedResults = async (pagination, query) => {
        setLoading();
        try {
            const res = await api.get(
                `/documents/documentsAr?page=${pagination.page}&limit=${pagination.limit}${query}&searchType=and&validation=1`,
                HEADERS()
            );
            dispatch({
                type: GET_DOCUMENTS,
                payload: res.data.data,
                count: res.data.count,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Documents
    const getDocuments = async (pagination, query, typeQuery, queryExtra) => {
        setLoading();
        if (!queryExtra) queryExtra = '';
        try {
            let finalQuery = `page=${pagination.page}&limit=${pagination.limit}&validation=1${queryExtra}`;

            if (query && query !== '')
                finalQuery += `&searchText=${query}&searchIndex=title-make-store-vehicle&searchType=${typeQuery}`;

            const res = await api.get(
                `/documents/documentsAr?${finalQuery}`,
                HEADERS()
            );
            dispatch({
                type: GET_DOCUMENTS,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Document
    const getDocument = async (documentId) => {
        clearState();
        setLoading();
        try {
            const res = await api.get(`/documents/${documentId}`, HEADERS());
            dispatch({ type: GET_DOCUMENT, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const getDocumentsByMultiStore = async (query) => {
        clearState();
        setLoading();
        try {
            const res = await api.get(
                `/documents/multiStores?${query}`,
                HEADERS()
            );
            dispatch({ type: GET_DOCUMENTS_BY_STORE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Document
    const getDocumentsByStore = async (storeId) => {
        clearState();
        setLoading();
        try {
            const res = await api.get(
                `/stores/${storeId}/documents`,
                HEADERS()
            );
            dispatch({ type: GET_DOCUMENTS_BY_STORE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Document
    const getDocumentsLead = async (leadId) => {
        setLoading();
        try {
            const res = await api.get(`/leads/${leadId}/documents`, HEADERS());
            dispatch({ type: GET_DOCUMENTS, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Document BDC
    const getDocumentsLeadBDC = async (leadId) => {
        setLoading();
        try {
            const res = await api.get(
                `/documents/${leadId}/documentsBDC`,
                HEADERS()
            );
            dispatch({ type: GET_DOCUMENTS, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Create Document
    const createDocument = async (document, file) => {
        setLoading();
        try {
            const uploadConfig = await api.post(
                '/uploads/image',
                { type: file.type, fileName: file.name },
                HEADERS()
            );

            await axios.put(uploadConfig.data.url, file, {
                headers: { 'Content-Type': file ? file.type : null },
            });

            const dataKey = uploadConfig.data.key;

            const res = await api.post(
                `/documents`,
                { ...document, file: dataKey },
                HEADERS()
            );
            dispatch({ type: CREATE_DOCUMENT, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Delete Document
    const deleteDocument = async (documentId) => {
        setLoading();
        try {
            const res = await api.delete(`/documents/${documentId}`, HEADERS());
            dispatch({ type: DELETE_DOCUMENT, payload: res.data.deletedId });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Update Document
    const updateDocument = async (document, documentId, file) => {
        setLoading();
        try {
            let res;
            let uploadConfig;
            if (file) {
                uploadConfig = await api.post(
                    '/uploads/image',
                    { type: file.type, fileName: file.name },
                    HEADERS()
                );

                await axios.put(uploadConfig.data.url, file, {
                    headers: { 'Content-Type': file ? file.type : null },
                });

                const dataKey = uploadConfig.data.key;

                res = await api.put(
                    `/documents/${documentId}`,
                    { ...document, file: dataKey },
                    HEADERS()
                );
                dispatch({ type: UPDATE_DOCUMENT, payload: res.data.data });
            } else {
                res = await api.put(
                    `/documents/${documentId}`,
                    { ...document },
                    HEADERS()
                );
                dispatch({ type: UPDATE_DOCUMENT, payload: res.data.data });
            }
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const getDocumentsByCatalogue = async (values) => {
        setLoading();
        try {
            const res = await api.post(
                `/documents/catalogue`,
                values,
                HEADERS()
            );
            dispatch({
                type: GET_DOCUMENTS_BY_CATALOGUE,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <DocumentContext.Provider
            value={{
                loading: state.loading,
                documents: state.documents,
                document: state.document,
                error: state.error,
                count: state.count,
                documentsByCatalogue: state.documentsByCatalogue,
                getDocuments,
                getDocument,
                createDocument,
                deleteDocument,
                updateDocument,
                getDocumentsByStore,
                getDocumentsByMultiStore,
                clearState,
                AdvancedResults,
                setLoading,
                getDocumentsLead,
                getDocumentsLeadBDC,
                getDocumentsByCatalogue,
            }}
        >
            {props.children}
        </DocumentContext.Provider>
    );
};

export default DocumentState;
