import { AxiosError } from 'axios';

/**
 * Wull return if axios has an error from backend
 *
 * @param {*} error
 * @return {*}  {error is { response: { data: any } }}
 */
export const isAxiosError = (error: AxiosError) => {
    return error?.response?.data !== undefined;
};
