import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import useAuth from 'src/hooks/useAuth';
import { useHistory } from 'react-router';
import { channel } from 'src/service/pusher';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import { ExternalLink as ExternalLinkIcon } from 'react-feather';
import useDPXConversations from 'src/hooks/useDPXConversations';
import useWspConversations from 'src/hooks/useWspConversation';
import { WarboxNotifications } from 'src/components/Alerts/components';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 80,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256,
        },
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto',
    },
}));

const DashboardLayout = ({ children, favoritesRoutes = [] }) => {
    const classes = useStyles();
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const { loadUser, user } = useAuth();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [myRoutes, setMyRoutes] = useState([]);
    const { t } = useTranslation();
    const { getConversation, clearConversation } = useDPXConversations();
    const { getWspConversations, clearWspConversation } = useWspConversations();

    useEffect(() => {
        if (localStorage.getItem('favorites'))
            setMyRoutes(JSON.parse(localStorage.getItem('favorites')));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!user || JSON.stringify(user) === '{}') {
            loadUser();
        }
        // eslint-disable-next-line
    }, []);

    const actionIcomingMsg = (data) => {
        <IconButton
            onClick={() => {
                clearConversation();
                clearWspConversation();
                if (data.api === 'twilio-api')
                    getConversation(data.conversation);
                if (data.api === 'wsp-api')
                    getWspConversations(data.conversation);
                history.push('/conversations');
            }}
        >
            <ExternalLinkIcon />
        </IconButton>;
    };

    useEffect(() => {
        if (user && user._id) {
            channel.unbind(`incoming_msg_by_agent_${user._id}`);
            channel.bind(`incoming_msg_by_agent_${user._id}`, (data) => {
                enqueueSnackbar(
                    `${t('SnackBar.IncomingMessage')} ${CapitalizeNames(
                        data.channel
                    )}`,
                    {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'info',
                        action: actionIcomingMsg(data),
                    }
                );
            });
        }
    }, []);

    return (
        <Fragment>
            <WarboxNotifications />
            <div className={classes.root}>
                <TopBar
                    onMobileNavOpen={() => setMobileNavOpen(true)}
                    canMarkAsFavorite={favoritesRoutes.includes(
                        history.location.pathname
                    )}
                />
                <NavBar
                    onMobileClose={() => setMobileNavOpen(false)}
                    openMobile={isMobileNavOpen}
                    favoritesRoutes={myRoutes}
                />
                <div className={classes.wrapper}>
                    <div className={classes.contentContainer}>
                        <div className={classes.content}>
                            {children && children}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

DashboardLayout.propTypes = {
    children: PropTypes.node,
};

export default DashboardLayout;
