const apiV1 = {
    production: 'https://api.warbox.io/api/v1',
    staging: 'https://staging.api.warbox.io/api/v1',
    development: 'http://localhost:5001/api/v1',
};
const apiV2 = {
    production: 'https://api.warbox.io/api/v2',
    staging: 'https://staging.api.warbox.io/api/v2',
    development: 'http://localhost:5001/api/v2',
};
const apiCalls = {
    production: 'https://api-calls.warbox.io/api/v1',
    staging: 'https://staging.api.calls.warbox.io/api/v1',
    development: 'http://localhost:5001/api/v1',
};

export { apiV1, apiV2, apiCalls };
