import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        path: '/create/make',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(() => import('src/views/make/MakeCreateView')),
    },
    {
        exact: true,
        path: '/management/makes/:id',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(() => import('src/views/make/MakeDetailsView')),
    },
    {
        exact: true,
        path: '/management/makes/:id/edit',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(() => import('src/views/make/MakeEditView')),
    },
    {
        exact: true,
        path: '/management/makes',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(() => import('src/views/make/MakeListView')),
    },
];
