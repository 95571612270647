import React from 'react';
import { useTheme } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import './spinner.css';

const START_ALPHA = 0.9;
const DECREASE = 0.1;

export const Spinner = ({ size = 30 }) => {
    const theme = useTheme();

    const divs = Array.from({ length: 5 }, (_, index) => (
        <div
            key={index}
            style={{
                height: size,
                width: size,
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    START_ALPHA - DECREASE * index
                ),
            }}
        />
    ));

    return <div className="spinner">{divs}</div>;
};
