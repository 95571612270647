import {
    TAnchorOriginHorizontal,
    TAnchorOriginVertical,
} from 'src/components/Alerts/types';
import { VariantType } from 'notistack';

type TAnchorOrigin = {
    vertical: TAnchorOriginVertical;
    horizontal: TAnchorOriginHorizontal;
};

export const DEFAULT_ANCHOR_ORIGIN: TAnchorOrigin = {
    vertical: 'top',
    horizontal: 'right',
};

export const DEFAULT_VARIANT: VariantType = 'info';
