import { TCRUD, TPermissionKey, TPermissions } from 'src/interfaces/user';

/**
 * Will return if the permission is granted for the user
 *
 * @param {{
 *     permissions: TPermissions;
 *     permission: TPermissionKey;
 *     crud: TCRUD;
 * }} {
 *     permissions,
 *     permission,
 *     crud,
 * }
 * @return {*}
 */
export const isPermissionGranted = ({
    permissions,
    permission,
    crud,
}: {
    permissions: TPermissions;
    permission: TPermissionKey;
    crud: TCRUD;
}): boolean => {
    return permissions[permission]?.[crud] || false;
};
