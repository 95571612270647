import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    AppBar,
    Box,
    Button,
    Divider,
    Toolbar,
    Hidden,
    Typography,
    makeStyles,
} from '@material-ui/core';
import { APP_VERSION } from 'src/constants';
import Logo from 'src/components/Logo';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
    },
    toolbar: {
        height: 64,
    },
    logo: {
        marginRight: theme.spacing(2),
    },
    link: {
        fontWeight: theme.typography.fontWeightMedium,
        '& + &': {
            marginLeft: theme.spacing(2),
        },
    },
    divider: {
        width: 1,
        height: 32,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
}));

const TopBar = ({ className, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <AppBar
            className={clsx(classes.root, className)}
            color="default"
            {...rest}
        >
            <Toolbar className={classes.toolbar}>
                <RouterLink to="/">
                    <Logo className={classes.logo} />
                </RouterLink>
                <Hidden mdDown>
                    <Typography variant="caption" color="textSecondary">
                        Version {APP_VERSION}
                    </Typography>
                </Hidden>
                <Box flexGrow={1} />
                <Divider className={classes.divider} />
                <Button
                    color="secondary"
                    component="a"
                    href="/login"
                    variant="contained"
                    size="small"
                >
                    {t('Login.SignIn')}
                </Button>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
};

export default TopBar;
