import {
    GET_MAKES,
    CREATE_MAKE,
    GET_MAKE,
    DELETE_MAKE,
    UPDATE_MAKE,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    SET_MAKES,
} from '../types';

export default (state, action) => {
    switch (action.type) {
        case GET_MAKES:
            return {
                ...state,
                makes: action.payload,
                pagination: action.pagination,
                loading: false,
                error: null,
                count: action?.pagination?.total || 0,
            };
        case SET_MAKES:
            return {
                ...state,
                makes: action.payload,
                loading: false,
                error: null,
            };
        case GET_MAKE:
            return {
                ...state,
                make: action.payload,
                loading: false,
                error: null,
            };
        case CREATE_MAKE:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case DELETE_MAKE:
            state.makes = state.makes.filter(
                (make) => make._id.toString() !== action.payload.toString()
            );
            return {
                ...state,
                make: null,
                loading: false,
                error: null,
            };
        case UPDATE_MAKE:
            return {
                ...state,
                make: action.payload,
                loading: false,
                error: null,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CLEAR_STATE:
            return {
                pagination: {},
                make: {},
                makes: [],
                loading: false,
                error: null,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
};
