import React, { useReducer } from 'react';
import SettingsContext from './settingsContext';
import SettingsReducer from './settingsReducer';
import api from '../../api/api';
import {
    GET_SETTINGS,
    UPDATE_SETTINGS,
    SET_ERROR,
    SET_LOADING,
    GET_MATRIX,
    CLEAR_MATRIX,
    UPDATE_TOKEN,
    SET_MENU,
} from '../types';
import { HEADERS } from '../../constants/headers';

const SettingsState = (props) => {
    const initialState = {
        settings: {},
        loading: false,
        error: null,
        clicks: null,
        impressions: null,
        cpm: null,
        leads: null,
        cost: null,
        spend: null,
    };

    const [state, dispatch] = useReducer(SettingsReducer, initialState);

    //Get Settings
    const getSettings = async () => {
        setLoading();
        try {
            const res = await api.get(`/settings`, HEADERS());
            dispatch({ type: GET_SETTINGS, payload: res.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Settings
    const setMenu = async (values) => {
        setLoading();
        try {
            await api.post(`/settings/setMenu`, { ...values }, HEADERS());
            dispatch({ type: SET_MENU });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Get Matrix
    const getMatrix = async (account, date, token) => {
        setLoading();
        try {
            const res = await api.get(
                `https://graph.facebook.com/v10.0/${account}/insights?date_preset=${date}&fields=spend%2Cimpressions%2Ccpm%2Ccpc%2Cactions&access_token=${token}`,
                HEADERS()
            );
            dispatch({ type: GET_MATRIX, payload: res.data.data[0] });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Clear Matrix
    const clearMatrix = () => dispatch({ type: CLEAR_MATRIX });

    //Update Settings
    const updateSettings = async (settings, settingsId) => {
        setLoading();
        try {
            const res = await api.put(
                `/settings/${settingsId}`,
                { ...settings },
                HEADERS()
            );
            dispatch({ type: UPDATE_SETTINGS, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Update Zapier token
    const createZapierToken = async () => {
        setLoading();
        try {
            const res = await api.get(`/auth/zapierToken`, HEADERS());
            dispatch({ type: UPDATE_TOKEN, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };
    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <SettingsContext.Provider
            value={{
                loading: state.loading,
                error: state.error,
                settings: state.settings,
                clicks: state.clicks,
                impressions: state.impressions,
                cpm: state.cpm,
                leads: state.leads,
                cost: state.cost,
                spend: state.spend,
                createZapierToken,
                getSettings,
                updateSettings,
                setLoading,
                getMatrix,
                clearMatrix,
                setMenu,
            }}
        >
            {props.children}
        </SettingsContext.Provider>
    );
};

export default SettingsState;
