import React, { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { colors } from '@material-ui/core';
import { Edit, ExternalLink, Users } from 'react-feather';
import { useHistory } from 'react-router';
import { CapitalizeNames } from 'src/utils/capitalize';
import { useTranslation } from 'react-i18next';
import { Rating } from '@material-ui/lab';
import i18next from 'src/utils/i18next';
import moment from 'moment';
import ReminderModal from 'src/components/ReminderModal';
import Menu from 'src/components/CustomizedMenu';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarBorder';
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    Hidden,
    Link,
    IconButton,
    List,
    Typography,
    makeStyles,
} from '@material-ui/core';

import {
    Clipboard as TestIcon,
    Search,
    Send as WhatsAppIcon,
    Phone as CallIcon,
    BarChart as BarChartIcon,
    PieChart as PieChartIcon,
    Database as DatabaseIcon,
    Feather as VsIcon,
    Smile as RoleIcon,
    Coffee as ControlIcon,
    Home,
    Mail as TemplateIcon,
    Book as TicketIcon,
    LifeBuoy as GroupIcon,
    Package as PackageIcon,
    Cloud as TemperaturesIcon,
    Airplay as SourceIcon,
    Aperture as MakeIcon,
    Home as StoreIcon,
    Truck as VehicleIcon,
    AlertCircle as StatusIcon,
    User as UserIcon,
    Calendar as CalendarIcon,
    DollarSign as SoldChartIcon,
    Globe as AllChartIcon,
    Clock as HourIcon,
    File as DocumentIcon,
    CloudDrizzle as SettingIcon,
    Settings,
    MessageCircle as WhatsappIcon,
    UploadCloud as LoadIcon,
    Clock as TaskIcon,
    Mic as RecordingIcon,
    Globe as CompanyIcon,
    FileText as ClosureIcon,
    ShoppingBag as FleetCompanyIcon,
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import Repeater from './Repeater';
import useReminder from 'src/hooks/useReminder';
import {
    isAdmin,
    isRockstar,
    isSuper,
    isUser,
    isDigitalMkt,
    isGeneralManager,
    isSalesManager,
    isRH,
    isMarketing,
} from 'src/utils/AuthRoles';
import SITE_SETTINGS from 'src/constants/whiteLabel';
import { isPluginAvailable } from 'src/utils/store/isPluginAvailable';
import {
    CALLS_PLUGIN,
    FB_PLUGIN,
    MAILING_PLUGIN,
    WSP_PLUGIN,
} from 'src/constants/plugins';

const roles = {
    rockstar: '6144d4548fb0f13225997789',
    superAdmin: '6144d45a8fb0f1322599778a',
    admin: '6144d45f8fb0f1322599778b',
    adminBDC: '6196c53dc958985a03b9c43d',
    user: '6144d47b8fb0f1322599778c',
    marketing: '6144d4818fb0f1322599778d',
    receptionist: '6144d4a28fb0f1322599778e',
    digitalMarketing: '616876627e498e7f25454f95',
    generalManager: '616da4c3f5a2169e9d924f1f',
    salesManager: '6228f9ee4062f9000d39a230',
    userBDC: '618ac8ba843a13cff0ec541e',
    userService: '61af8a6435ce5943f31f3a66',
    adminService: '61af8a7535ce5943f31f3d13',
};

function renderNavItems({
    items,
    pathname,
    depth = 0,
    user,
    isFavoriteActive,
    favoriteRoutes,
    setFavoritesRoutes,
}) {
    return (
        <List disablePadding>
            {items.reduce(
                (acc, item) =>
                    reduceChildRoutes({
                        acc,
                        item,
                        pathname,
                        depth,
                        user,
                        isFavoriteActive,
                        favoriteRoutes,
                        setFavoritesRoutes,
                    }),
                []
            )}
        </List>
    );
}

function reduceChildRoutes({
    acc,
    pathname,
    item,
    depth,
    user,
    isFavoriteActive,
    favoriteRoutes,
    setFavoritesRoutes,
}) {
    const key = item.title + depth + Math.random();

    if (item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: false,
        });

        acc.push(
            <NavItem
                isFavoriteActive={isFavoriteActive}
                favoriteRoutes={favoriteRoutes}
                setFavoritesRoutes={setFavoritesRoutes}
                depth={depth}
                icon={item.icon}
                info={item.info}
                key={key}
                open={Boolean(open)}
                title={item.title}
            >
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items,
                    isFavoriteActive,
                    user,
                })}
            </NavItem>
        );
    } else {
        switch (item.href) {
            case '/conversations':
                if (
                    user &&
                    user.tier &&
                    ((user.stores &&
                        (isPluginAvailable(user.stores, WSP_PLUGIN) ||
                            isPluginAvailable(user.stores, FB_PLUGIN))) ||
                        isRockstar(user.tier._id) ||
                        ((isSuper(user.tier._id) ||
                            isDigitalMkt(user.tier._id)) &&
                            user.group &&
                            (isPluginAvailable(user.group.stores, WSP_PLUGIN) ||
                                isPluginAvailable(
                                    user.group.stores,
                                    FB_PLUGIN
                                ))))
                ) {
                    acc.push(
                        <NavItem
                            isFavoriteActive={isFavoriteActive}
                            favoriteRoutes={favoriteRoutes}
                            setFavoritesRoutes={setFavoritesRoutes}
                            depth={depth}
                            href={item.href}
                            icon={item.icon}
                            info={item.info}
                            key={key}
                            title={item.title}
                        />
                    );
                }
                break;
            case '/management/recordings':
                if (
                    user &&
                    user.tier &&
                    ((user.stores &&
                        isPluginAvailable(user.stores, CALLS_PLUGIN)) ||
                        isRockstar(user.tier._id) ||
                        ((isSuper(user.tier._id) ||
                            isDigitalMkt(user.tier._id)) &&
                            user.group &&
                            isPluginAvailable(user.group.stores, CALLS_PLUGIN)))
                ) {
                    acc.push(
                        <NavItem
                            isFavoriteActive={isFavoriteActive}
                            favoriteRoutes={favoriteRoutes}
                            setFavoritesRoutes={setFavoritesRoutes}
                            depth={depth}
                            href={item.href}
                            icon={item.icon}
                            info={item.info}
                            key={key}
                            title={item.title}
                        />
                    );
                }
                break;
            case '/management/lists':
                if (
                    user &&
                    user.stores &&
                    user.tier &&
                    (isPluginAvailable(user.stores, MAILING_PLUGIN) ||
                        isRockstar(user.tier._id) ||
                        ((isSuper(user.tier._id) ||
                            isDigitalMkt(user.tier._id)) &&
                            user.group &&
                            isPluginAvailable(
                                user.group.stores,
                                MAILING_PLUGIN
                            )))
                ) {
                    acc.push(
                        <NavItem
                            isFavoriteActive={isFavoriteActive}
                            favoriteRoutes={favoriteRoutes}
                            setFavoritesRoutes={setFavoritesRoutes}
                            depth={depth}
                            href={item.href}
                            icon={item.icon}
                            info={item.info}
                            key={key}
                            title={item.title}
                        />
                    );
                }
                break;
            case '/management/showroom-leads':
                if (
                    user &&
                    user.tier &&
                    (isRockstar(user.tier._id) ||
                        isSuper(user.tier._id) ||
                        isGeneralManager(user.tier._id) ||
                        isSalesManager(user.tier._id) ||
                        ((isUser(user.tier._id) ||
                            isAdmin(user.tier._id) ||
                            isMarketing(user.tier._id) ||
                            isDigitalMkt(user.tier._id)) &&
                            user.areas &&
                            user.areas.includes('showroom')))
                ) {
                    acc.push(
                        <NavItem
                            isFavoriteActive={isFavoriteActive}
                            favoriteRoutes={favoriteRoutes}
                            setFavoritesRoutes={setFavoritesRoutes}
                            depth={depth}
                            href={item.href}
                            icon={item.icon}
                            info={item.info}
                            key={key}
                            title={item.title}
                        />
                    );
                }
                break;
            case '/management/leads':
                if (
                    user &&
                    user.tier &&
                    (isRockstar(user.tier._id) ||
                        isSuper(user.tier._id) ||
                        isGeneralManager(user.tier._id) ||
                        isSalesManager(user.tier._id) ||
                        ((isUser(user.tier._id) ||
                            isAdmin(user.tier._id) ||
                            isMarketing(user.tier._id) ||
                            isDigitalMkt(user.tier._id)) &&
                            user.leadType &&
                            (user.leadType === 'all' ||
                                user.leadType === 'lead')))
                ) {
                    acc.push(
                        <NavItem
                            isFavoriteActive={isFavoriteActive}
                            favoriteRoutes={favoriteRoutes}
                            setFavoritesRoutes={setFavoritesRoutes}
                            depth={depth}
                            href={item.href}
                            icon={item.icon}
                            info={item.info}
                            key={key}
                            title={item.title}
                        />
                    );
                }
                break;
            default:
                acc.push(
                    <NavItem
                        isFavoriteActive={isFavoriteActive}
                        favoriteRoutes={favoriteRoutes}
                        setFavoritesRoutes={setFavoritesRoutes}
                        depth={depth}
                        href={item.href}
                        icon={item.icon}
                        info={item.info}
                        key={key}
                        title={item.title}
                    />
                );
                break;
        }
    }

    return acc;
}

const useStyles = makeStyles((theme) => ({
    iconMenu: {
        background: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            background: theme.palette.primary.dark,
        },
        width: 30,
        height: 30,
        padding: 7.5,
    },
    iconFavorite: {
        background: theme.palette.warning.main,
        color: 'white',
        '&:hover': {
            background: theme.palette.warning.dark,
        },
        width: 30,
        height: 30,
        padding: 7.5,
    },
    item: {
        display: 'block',
        paddingTop: 0,
        paddingBottom: 0,
    },
    itemLeaf: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    button: {
        color: colors.grey[500],
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
    },
    buttonLeaf: {
        color: colors.blueGrey[800],
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightRegular,
        '&.depth-0': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
    select: {
        paddingTop: '1em',
        paddingBottom: '0.5em',
    },
    icon: {
        color: theme.palette.icon,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
    },
    expandIcon: {
        marginLeft: 'auto',
        height: 16,
        width: 16,
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
    },
    active: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        '& $icon': {
            color: theme.palette.primary.main,
        },
    },
    mobileDrawer: {
        width: 256,
    },
    desktopDrawer: {
        width: 256,
        top: 80,
        height: 'calc(100% - 80px)',
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64,
    },
    primaryColor: {
        color: theme.palette.primary.main,
    },
}));

const NavBar = ({ onMobileClose, openMobile, favoritesRoutes, ...rest }) => {
    const classes = useStyles();
    const location = useLocation();
    const { user } = useAuth();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const history = useHistory();
    const { getRemindersByUser, reminders } = useReminder();
    const loc = useLocation();
    const [intervalC, setIntervalC] = useState();
    const [currentReminder, setCurrentReminder] = useState({});
    const [open, setOpen] = useState(false);
    const [isFavoriteActive, setFavoriteActive] = useState(false);
    const [favoriteRoutes, setFavoritesRoutes] = useState([]);

    useEffect(() => {
        if (localStorage.getItem('favoriteRoutes')) {
            const favoritesArray = JSON.parse(
                localStorage.getItem('favoriteRoutes')
            );
            setFavoritesRoutes(favoritesArray);
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        localStorage.setItem('favoriteRoutes', JSON.stringify(favoriteRoutes));
        //eslint-disable-next-line
    }, [favoriteRoutes]);

    const handleDisableSnack = (key) => closeSnackbar(key);

    const handleRenderMenu = () => {
        const isCarOne = SITE_SETTINGS.client === 'carone';
        const isWarbox = SITE_SETTINGS.client !== 'carone';

        if (SITE_SETTINGS.client === 'demo')
            return (
                <div style={{ position: 'relative' }}>
                    <Repeater
                        renderNavItems={renderNavItems}
                        data={DEMO_MENU}
                        user={user}
                        location={location}
                        role={roles.rockstar}
                        isFavoriteActive={isFavoriteActive}
                        favoriteRoutes={favoriteRoutes}
                        setFavoritesRoutes={setFavoritesRoutes}
                    />
                </div>
            );

        if (isCarOne && !isRockstar(user.tier._id) && user.menu)
            return (
                <Menu
                    menu={JSON.parse(user.menu)}
                    isFavoriteActive={isFavoriteActive}
                    favoriteRoutes={favoriteRoutes}
                    setFavoritesRoutes={setFavoritesRoutes}
                />
            );

        if (isRockstar(user.tier._id))
            return (
                <div style={{ position: 'relative' }}>
                    <Repeater
                        renderNavItems={renderNavItems}
                        data={sectionsRockstarX}
                        user={user}
                        location={location}
                        role={roles.rockstar}
                        isFavoriteActive={isFavoriteActive}
                        favoriteRoutes={favoriteRoutes}
                        setFavoritesRoutes={setFavoritesRoutes}
                    />
                </div>
            );

        if (isWarbox && isSuper(user.tier._id))
            return (
                <div style={{ position: 'relative' }}>
                    <Repeater
                        renderNavItems={renderNavItems}
                        data={SUPER_ADMIN_MENU}
                        user={user}
                        location={location}
                        role={roles.superAdmin}
                        isFavoriteActive={isFavoriteActive}
                        favoriteRoutes={favoriteRoutes}
                        setFavoritesRoutes={setFavoritesRoutes}
                    />
                </div>
            );

        if (isWarbox && isMarketing(user.tier._id))
            return (
                <div style={{ position: 'relative' }}>
                    <Repeater
                        renderNavItems={renderNavItems}
                        data={MARKETING_MENU}
                        user={user}
                        location={location}
                        role={roles.marketing}
                        isFavoriteActive={isFavoriteActive}
                        favoriteRoutes={favoriteRoutes}
                        setFavoritesRoutes={setFavoritesRoutes}
                    />
                </div>
            );

        if (isWarbox && isSalesManager(user.tier._id))
            return (
                <div style={{ position: 'relative' }}>
                    <Repeater
                        renderNavItems={renderNavItems}
                        data={GENERAL_SALES_MENU}
                        user={user}
                        location={location}
                        role={roles.salesManager}
                        isFavoriteActive={isFavoriteActive}
                        favoriteRoutes={favoriteRoutes}
                        setFavoritesRoutes={setFavoritesRoutes}
                    />
                </div>
            );

        if (isWarbox && isGeneralManager(user.tier._id))
            return (
                <div style={{ position: 'relative' }}>
                    <Repeater
                        renderNavItems={renderNavItems}
                        data={GENERAL_MANAGER_MENU}
                        user={user}
                        location={location}
                        role={roles.generalManager}
                        isFavoriteActive={isFavoriteActive}
                        favoriteRoutes={favoriteRoutes}
                        setFavoritesRoutes={setFavoritesRoutes}
                    />
                </div>
            );

        if (isWarbox && isAdmin(user.tier._id))
            return (
                <div style={{ position: 'relative' }}>
                    <Repeater
                        renderNavItems={renderNavItems}
                        data={ADMIN_MENU}
                        user={user}
                        location={location}
                        role={roles.admin}
                        isFavoriteActive={isFavoriteActive}
                        favoriteRoutes={favoriteRoutes}
                        setFavoritesRoutes={setFavoritesRoutes}
                    />
                </div>
            );

        if (isWarbox && isUser(user.tier._id))
            return (
                <div style={{ position: 'relative' }}>
                    <Repeater
                        renderNavItems={renderNavItems}
                        data={USER_MENU}
                        user={user}
                        location={location}
                        role={roles.user}
                        isFavoriteActive={isFavoriteActive}
                        favoriteRoutes={favoriteRoutes}
                        setFavoritesRoutes={setFavoritesRoutes}
                    />
                </div>
            );
    };

    useEffect(() => {
        if (user && user.tier) {
            if (user && user._id && isUser(user.tier._id)) {
                getRemindersByUser(user._id);
            }
        }

        //eslint-disable-next-line
    }, [user]);

    const myTimer = () => {
        if (reminders)
            reminders.map((reminder, index) => {
                if (
                    moment(new Date()).isAfter(reminder.date) &&
                    user &&
                    reminder.user &&
                    reminder.user._id === user._id &&
                    reminder.lead &&
                    reminder.lead.name
                ) {
                    enqueueSnackbar(
                        t('SnackBar.ReminderTask') +
                            ' ' +
                            CapitalizeNames(reminder.lead.name),
                        {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'warning',
                            persist: true,
                            preventDuplicate: true,
                            key: reminder._id,
                            action: (
                                <IconButton
                                    onClick={() => {
                                        setCurrentReminder(reminder);
                                        setOpen(true);
                                    }}
                                >
                                    <ExternalLink />
                                </IconButton>
                            ),
                        }
                    );
                }
                return false;
            });
    };

    useEffect(() => {
        if (reminders) {
            clearInterval(intervalC);
            setIntervalC(setInterval(myTimer, 5000));
        }
        //eslint-disable-next-line
    }, [reminders]);

    useEffect(() => {
        let language;
        if (localStorage.getItem('i18nextLng')) {
            language = localStorage.getItem('i18nextLng');
        } else {
            language = 'en';
        }
        i18next.changeLanguage(language);
    }, []);

    const DEMO_MENU = [
        {
            subheader: t('Navbar.Principal'),
            items: [
                {
                    title: t('Navbar.Leads'),
                    icon: DatabaseIcon,
                    href: '/management/leads',
                },
                {
                    title: t('Navbar.Recordings'),
                    icon: RecordingIcon,
                    href: '/management/recordings',
                },
                {
                    title: t('Navbar.CalendarTasks'),
                    icon: TaskIcon,
                    href: '/tasks',
                },
                {
                    title: t('Navbar.Appointments'),
                    icon: CalendarIcon,
                    href: '/calendar',
                },
            ],
        },
        {
            subheader: t('Navbar.DrillDown'),
            items: [
                {
                    title: t('Navbar.Global'),
                    icon: AllChartIcon,
                    href: '/extra/highcharts/all',
                },
                {
                    title: t('Navbar.ModelSource'),
                    icon: SoldChartIcon,
                    href: '/drilldown/modelsource',
                },
                {
                    title: t('Drilldowns.Models'),
                    icon: VehicleIcon,
                    href: '/drilldown/models',
                },
                {
                    title: t('Navbar.Calls'),
                    icon: CallIcon,
                    href: '/drilldown/calls',
                },
                {
                    title: t('Charts.BureauStore'),
                    icon: UserIcon,
                    href: '/drilldown/score',
                },
            ],
        },
        {
            subheader: t('Navbar.ChartsH'),
            href: '/extra/highcharts',
            icon: BarChartIcon,
            items: [
                {
                    title: t('Navbar.StatusReport'),
                    icon: StatusIcon,
                    href: '/extra/highcharts/status-report',
                },
                {
                    title: t('Navbar.Ft'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/temperatures',
                },
                {
                    title: t('Navbar.ModelsByLeads'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/modelsTable',
                },
                {
                    title: t('Navbar.SourcesByLeads'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/sourcesTable',
                },
                {
                    title: t('Navbar.LeadsAgentsSources'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/agentsSourcesTable',
                },
                {
                    title: t('Navbar.Monthly'),
                    icon: CalendarIcon,
                    href: '/extra/highcharts/monthly',
                },
                {
                    title: t('Navbar.Daily'),
                    icon: CalendarIcon,
                    href: '/extra/highcharts/daily',
                },
                {
                    title: t('Navbar.Hours'),
                    icon: HourIcon,
                    href: '/extra/highcharts/hours',
                },
                {
                    title: t('Navbar.StoresExtraScores'),
                    icon: StoreIcon,
                    href: '/extra/highcharts/stores-extra-scores',
                },
                {
                    title: t('Navbar.AgentsLeads'),
                    icon: UserIcon,
                    href: '/extra/highcharts/agentsLeads',
                },
                {
                    title: t('Navbar.Recordings'),
                    icon: RecordingIcon,
                    href: '/extra/highcharts/recordings-reviews',
                },
            ],
        },
    ];

    const sectionsRockstarX = [
        {
            subheader: t('Navbar.Dashboard'),
            items: [
                {
                    title: t('Navbar.Principal'),
                    icon: PieChartIcon,
                    href: '/reports/dashboard',
                },
                {
                    title: t('Navbar.Financing'),
                    icon: PieChartIcon,
                    href: '/reports/sales',
                },
            ],
        },
        {
            subheader: t('Chat.Title'),
            items: [
                {
                    title: t('Chat.Title'),
                    icon: WhatsappIcon,
                    href: '/conversations',
                },
                {
                    title: t('Documents.Catalogue'),
                    icon: TicketIcon,
                    href: '/management/catalogue',
                },
                {
                    title: 'Bot IA',
                    icon: WhatsappIcon,
                    href: '/management/chatbot',
                },
            ],
        },
        {
            subheader: t('Navbar.Main'),
            items: [
                {
                    title: t('Navbar.Leads'),
                    icon: DatabaseIcon,
                    href: '/management/leads',
                },
                {
                    title: t('Navbar.LeadsFleet'),
                    icon: DatabaseIcon,
                    href: '/management/fleet-leads',
                },
                {
                    title: t('Navbar.ShowroomLeads'),
                    icon: DatabaseIcon,
                    href: '/management/showroom-leads',
                },
                {
                    title: t('Navbar.bdc'),
                    icon: DatabaseIcon,
                    href: '/management/bdc',
                },
                {
                    title: t('Navbar.LeadsBDC'),
                    icon: DatabaseIcon,
                    href: '/management/leadsBDC',
                },
                {
                    title: t('Navbar.LeadsService'),
                    icon: DatabaseIcon,
                    href: '/management/leadsService',
                },
                {
                    title: t('Navbar.Recordings'),
                    icon: RecordingIcon,
                    href: '/management/recordings',
                },
                {
                    title: t('Navbar.Closures'),
                    href: '/management/closures',
                    icon: ClosureIcon,
                },
                {
                    title: t('Navbar.CalendarTasks'),
                    icon: TaskIcon,
                    href: '/tasks',
                },
                {
                    title: t('Navbar.CalendarTasksLeadsBDC'),
                    icon: TaskIcon,
                    href: '/tasks-BDC',
                },
                {
                    title: t('Navbar.Tasks'),
                    icon: TaskIcon,
                    href: '/management/tasks',
                },
                {
                    title: t('Navbar.Appointments'),
                    icon: CalendarIcon,
                    href: '/calendar',
                },
                {
                    title: t('Navbar.AppointmentsLeadsBDC'),
                    icon: CalendarIcon,
                    href: '/calendar-BDC',
                },
            ],
        },
        {
            subheader: t('Navbar.DrillDown'),
            items: [
                {
                    title: t('Navbar.Global'),
                    icon: AllChartIcon,
                    href: '/extra/highcharts/all',
                },
                {
                    title: t('Charts.Captacion'),
                    icon: VsIcon,
                    href: '/extra/highcharts/captacion-medios',
                },
                {
                    title: t('Navbar.ModelSource'),
                    icon: SoldChartIcon,
                    href: '/drilldown/modelsource',
                },

                {
                    title: t('Drilldowns.Models'),
                    icon: VehicleIcon,
                    href: '/drilldown/models',
                },

                {
                    title: t('Drilldowns.Sources'),
                    icon: SourceIcon,
                    href: '/drilldown/sources',
                },
                {
                    title: t('Navbar.Visits'),
                    icon: Home,
                    href: '/drilldown/visits',
                },

                {
                    title: t('Navbar.Calls'),
                    icon: CallIcon,
                    href: '/drilldown/calls',
                },
                {
                    title: t('Charts.BureauStore'),
                    icon: UserIcon,
                    href: '/drilldown/score',
                },
                {
                    title: t('Drilldowns.PendingLeads'),
                    icon: GroupIcon,
                    href: '/drilldown/pending-leads',
                },
                {
                    title: t('Navbar.Recordings'),
                    icon: RecordingIcon,
                    href: '/drilldown/recordings',
                },
            ],
        },
        {
            subheader: t('Navbar.ChartsH'),
            href: '/extra/highcharts',
            icon: BarChartIcon,
            items: [
                {
                    title: t('Charts.DaysToSale'),
                    icon: SoldChartIcon,
                    href: '/extra/highcharts/sales-time',
                },
                {
                    title: t('Navbar.StatusReport'),
                    icon: StatusIcon,
                    href: '/extra/highcharts/status-report',
                },
                {
                    title: t('Navbar.Ft'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/temperatures',
                },
                {
                    title: t('Navbar.ModelsByLeads'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/modelsTable',
                },
                {
                    title: t('Navbar.SourcesByLeads'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/sourcesTable',
                },
                {
                    title: t('Navbar.LeadsAgentsSources'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/agentsSourcesTable',
                },
                {
                    title: t('Navbar.ModelsByAgents'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/agentsTable',
                },
                {
                    title: t('Navbar.AddonsReport'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/addons-report',
                },
                {
                    title: t('Navbar.FtRSI'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/funnel',
                },
                {
                    title: t('Navbar.Bureau'),
                    icon: UserIcon,
                    href: '/extra/highcharts/score',
                },
                {
                    title: t('Navbar.VisitsReport'),
                    icon: UserIcon,
                    href: '/reports/visits-leads',
                },
                {
                    title: t('Navbar.ReportClosures'),
                    href: '/reports/closures',
                    icon: ClosureIcon,
                },
                {
                    title: t('Navbar.PendingTasksReport'),
                    href: '/reports/pending-tasks',
                    icon: TaskIcon,
                },
                {
                    title: t('Navbar.FTC'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/temperaturesComparative',
                },
                {
                    title: t('Navbar.FTT'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/totalTemperatures',
                },
                // {
                //   title: t('Navbar.Lbs'),
                //   icon: PyramidChartIcon,
                //   href: '/extra/highcharts/pyramid'
                // },
                {
                    title: t('Navbar.AgentsLeads'),
                    icon: UserIcon,
                    href: '/extra/highcharts/agentsLeads',
                },
                {
                    title: t('Charts.MonitorAgents'),
                    icon: Users,
                    href: '/extra/highcharts/monitoringAgents',
                },
                {
                    title: t('Navbar.AgentsCalls'),
                    icon: UserIcon,
                    href: '/extra/highcharts/agentsCalls',
                },

                {
                    title: t('Navbar.AgentsSubstatus'),
                    icon: UserIcon,
                    href: '/extra/highcharts/agentsStatus',
                },
                {
                    title: t('Navbar.Appointments'),
                    icon: CalendarIcon,
                    href: '/extra/highcharts/appointments',
                },
                {
                    title: t('Navbar.Visits'),
                    icon: Home,
                    href: '/extra/highcharts/visits',
                },
                {
                    title: t('Navbar.Recordings'),
                    icon: RecordingIcon,
                    href: '/extra/highcharts/recordings-reviews',
                },
                {
                    title: t('Navbar.Monthly'),
                    icon: CalendarIcon,
                    href: '/extra/highcharts/monthly',
                },
                {
                    title: t('Navbar.Daily'),
                    icon: CalendarIcon,
                    href: '/extra/highcharts/daily',
                },
                {
                    title: t('Navbar.Hours'),
                    icon: HourIcon,
                    href: '/extra/highcharts/hours',
                },
                {
                    title: t('Navbar.Lbm'),
                    icon: VehicleIcon,
                    href: '/extra/highcharts/models',
                },
                {
                    title: t('Charts.UnitComparative'),
                    icon: VehicleIcon,
                    href: '/extra/highcharts/unit-comparative',
                },
                {
                    title: t('Charts.UnitComparativeExtra'),
                    icon: VehicleIcon,
                    href: '/extra/highcharts/unit-comparative-extra',
                },
                {
                    title: t('Charts.ByTime'),
                    icon: HourIcon,
                    href: '/extra/highcharts/time',
                },
                {
                    title: t('Navbar.Vs'),
                    icon: VsIcon,
                    href: '/extra/highcharts/vs',
                },
                {
                    title: t('Navbar.StoresExtraScores'),
                    icon: StoreIcon,
                    href: '/extra/highcharts/stores-extra-scores',
                },
                {
                    title: t('Navbar.StoresScores'),
                    icon: StoreIcon,
                    href: '/extra/highcharts/stores-scores',
                },
            ],
        },
        {
            subheader: t('Navbar.Control'),
            icon: ControlIcon,
            href:
                loc.pathname === '/management/leads' ||
                loc.pathname === '/management/showroom-leads'
                    ? '/none'
                    : '/management',

            items: [
                {
                    title: t('Navbar.Roles'),
                    href: '/management/roles',
                    icon: RoleIcon,
                },
                {
                    title: t('Navbar.Wsp'),
                    href: '/management/templates-wsp',
                    icon: WhatsAppIcon,
                },
                {
                    title: t('Navbar.Makes'),
                    href: '/management/makes',
                    icon: MakeIcon,
                },
                {
                    title: t('Navbar.Stores'),
                    href: '/management/stores',
                    icon: StoreIcon,
                },
                {
                    title: t('Navbar.FleetCompany'),
                    href: '/management/fleetCompanies',
                    icon: FleetCompanyIcon,
                },
                {
                    title: t('Navbar.Evaluation'),
                    href: '/management/evaluations',
                    icon: DocumentIcon,
                },
                {
                    title: t('Navbar.Sources'),
                    href: '/management/sources',
                    icon: SourceIcon,
                },
                {
                    title: t('Navbar.Vehicles'),
                    href: '/management/vehicles',
                    icon: VehicleIcon,
                },
                {
                    title: t('Navbar.Status'),
                    href: '/management/status',
                    icon: StatusIcon,
                },
                {
                    title: t('Navbar.Users'),
                    href: '/management/users',
                    icon: UserIcon,
                },
                {
                    title: t('Navbar.Documents'),
                    href: '/management/documents',
                    icon: DocumentIcon,
                },
                {
                    title: t('Navbar.Temperatures'),
                    href: '/management/temperatures',
                    icon: SettingIcon,
                },
                {
                    title: t('Navbar.Companies'),
                    href: '/management/companies',
                    icon: CompanyIcon,
                },
                {
                    title: t('Navbar.FinancialInstitution'),
                    href: '/management/financialInstitutions',
                    icon: SoldChartIcon,
                },
                {
                    title: t('Navbar.LeadFinder'),
                    href: '/leadFinder',
                    icon: Search,
                },
                {
                    title: t('Navbar.LoadLeads'),
                    href: '/management/loadLeads',
                    icon: LoadIcon,
                },
                {
                    title: t('Navbar.Alarms'),
                    href: '/management/alarms',
                    icon: HourIcon,
                },
                {
                    title: t('Navbar.Emails'),
                    href: '/management/mailing',
                    icon: TemplateIcon,
                },
                {
                    title: t('Navbar.Recordings'),
                    href: '/management/storesRecordings',
                    icon: RecordingIcon,
                },
                {
                    title: t('RecordingTests.RecordingTests'),
                    href: '/management/recording-tests',
                    icon: TestIcon,
                },
                {
                    title: t('Navbar.Tickets'),
                    href: '/management/tickets',
                    icon: TicketIcon,
                },
                {
                    title: t('Navbar.Packages'),
                    href: '/management/packages',
                    icon: PackageIcon,
                },
                {
                    title: t('Navbar.AdCampaigns'),
                    href: '/management/adCampaigns',
                    icon: TemplateIcon,
                },
                {
                    title: t('Navbar.Zapier'),
                    href: '/management/configs',
                    icon: Settings,
                },
                {
                    title: t('Navbar.Groups'),
                    href: '/management/groups',
                    icon: GroupIcon,
                },
                {
                    title: t('Navbar.ChartInvestment'),
                    href: '/management/investments-chart',
                    icon: GroupIcon,
                },
            ],
        },
    ];

    const MARKETING_MENU = [
        {
            subheader: t('Navbar.Dashboard'),
            items: [
                {
                    title: t('Navbar.Principal'),
                    icon: PieChartIcon,
                    href: '/reports/dashboard',
                },
            ],
        },
        {
            subheader: t('Chat.Title'),
            items: [
                {
                    title: t('Chat.Title'),
                    icon: WhatsappIcon,
                    href: '/conversations',
                },
                {
                    title: t('Documents.Catalogue'),
                    icon: TicketIcon,
                    href: '/management/catalogue',
                },
                {
                    title: 'Bot IA',
                    icon: WhatsappIcon,
                    href: '/management/chatbot',
                },
            ],
        },
        {
            subheader: t('Navbar.Main'),
            items: [
                {
                    title: t('Navbar.Leads'),
                    icon: DatabaseIcon,
                    href: '/management/leads',
                },
                {
                    title: t('Navbar.Recordings'),
                    icon: RecordingIcon,
                    href: '/management/recordings',
                },
                {
                    title: t('Navbar.CalendarTasks'),
                    icon: TaskIcon,
                    href: '/tasks',
                },
                {
                    title: t('Navbar.Appointments'),
                    icon: CalendarIcon,
                    href: '/calendar',
                },
            ],
        },

        {
            subheader: t('Navbar.DrillDown'),
            items: [
                {
                    title: t('Charts.Captacion'),
                    icon: VsIcon,
                    href: '/extra/highcharts/captacion-medios',
                },
                {
                    title: t('Navbar.ModelSource'),
                    icon: SoldChartIcon,
                    href: '/drilldown/modelsource',
                },

                {
                    title: t('Drilldowns.Models'),
                    icon: VehicleIcon,
                    href: '/drilldown/models',
                },
                {
                    title: t('Drilldowns.Sources'),
                    icon: SourceIcon,
                    href: '/drilldown/sources',
                },
                {
                    title: t('Navbar.Visits'),
                    icon: Home,
                    href: '/drilldown/visits',
                },
                {
                    title: t('Navbar.Calls'),
                    icon: CallIcon,
                    href: '/drilldown/calls',
                },
                {
                    title: t('Navbar.Recordings'),
                    icon: RecordingIcon,
                    href: '/drilldown/recordings',
                },
            ],
        },
        {
            subheader: t('Navbar.ChartsH'),
            href: '/extra/highcharts',
            icon: BarChartIcon,
            items: [
                {
                    title: t('Charts.DaysToSale'),
                    icon: SoldChartIcon,
                    href: '/extra/highcharts/sales-time',
                },
                {
                    title: t('Navbar.StatusReport'),
                    icon: StatusIcon,
                    href: '/extra/highcharts/status-report',
                },
                {
                    title: t('Navbar.ModelsByLeads'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/modelsTable',
                },
                {
                    title: t('Navbar.SourcesByLeads'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/sourcesTable',
                },
                {
                    title: t('Navbar.LeadsAgentsSources'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/agentsSourcesTable',
                },
                {
                    title: t('Navbar.ModelsByAgents'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/agentsTable',
                },
                {
                    title: t('Navbar.Recordings'),
                    icon: RecordingIcon,
                    href: '/extra/highcharts/recordings-reviews',
                },
                {
                    title: t('Navbar.Lbm'),
                    icon: VehicleIcon,
                    href: '/extra/highcharts/models',
                },
                {
                    title: t('Charts.UnitComparative'),
                    icon: VehicleIcon,
                    href: '/extra/highcharts/unit-comparative',
                },
            ],
        },
        {
            subheader: t('Navbar.Control'),
            icon: ControlIcon,
            href:
                loc.pathname === '/management/leads' ||
                loc.pathname === '/management/showroom-leads'
                    ? '/none'
                    : '/management',

            items: [
                {
                    title: t('Navbar.Users'),
                    href: '/management/users',
                    icon: UserIcon,
                },
                {
                    title: t('Navbar.Documents'),
                    href: '/management/documents',
                    icon: DocumentIcon,
                },
            ],
        },
    ];

    const SUPER_ADMIN_MENU = [
        {
            subheader: t('Navbar.Dashboard'),
            items: [
                {
                    title: t('Navbar.Principal'),
                    icon: PieChartIcon,
                    href: '/reports/dashboard',
                },

                {
                    title: t('Navbar.Financing'),
                    icon: PieChartIcon,
                    href: '/reports/sales',
                },
            ],
        },
        {
            subheader: t('Chat.Title'),
            items: [
                {
                    title: t('Chat.Title'),
                    icon: WhatsappIcon,
                    href: '/conversations',
                },
                {
                    title: t('Documents.Catalogue'),
                    icon: TicketIcon,
                    href: '/management/catalogue',
                },
                {
                    title: 'Bot IA',
                    icon: WhatsappIcon,
                    href: '/management/chatbot',
                },
            ],
        },
        {
            subheader: t('Navbar.Main'),
            items: [
                {
                    title: t('Navbar.Leads'),
                    icon: DatabaseIcon,
                    href: '/management/leads',
                },
                {
                    title: t('Navbar.Recordings'),
                    icon: RecordingIcon,
                    href: '/management/recordings',
                },
                {
                    title: t('Navbar.Closures'),
                    href: '/management/closures',
                    icon: ClosureIcon,
                },
                {
                    title: t('Navbar.CalendarTasks'),
                    icon: TaskIcon,
                    href: '/tasks',
                },
                {
                    title: t('Navbar.Tasks'),
                    icon: TaskIcon,
                    href: '/management/tasks',
                },
                {
                    title: t('Navbar.Appointments'),
                    icon: CalendarIcon,
                    href: '/calendar',
                },
            ],
        },

        {
            subheader: t('Navbar.DrillDown'),
            items: [
                {
                    title: t('Charts.Captacion'),
                    icon: VsIcon,
                    href: '/extra/highcharts/captacion-medios',
                },
                {
                    title: t('Navbar.ModelSource'),
                    icon: SoldChartIcon,
                    href: '/drilldown/modelsource',
                },

                {
                    title: t('Drilldowns.Models'),
                    icon: VehicleIcon,
                    href: '/drilldown/models',
                },
                {
                    title: t('Drilldowns.Sources'),
                    icon: SourceIcon,
                    href: '/drilldown/sources',
                },
                {
                    title: t('Navbar.Visits'),
                    icon: Home,
                    href: '/drilldown/visits',
                },
                {
                    title: t('Navbar.Calls'),
                    icon: CallIcon,
                    href: '/drilldown/calls',
                },
                {
                    title: t('Navbar.Recordings'),
                    icon: RecordingIcon,
                    href: '/drilldown/recordings',
                },
            ],
        },
        {
            subheader: t('Navbar.ChartsH'),
            href: '/extra/highcharts',
            icon: BarChartIcon,
            items: [
                {
                    title: t('Charts.DaysToSale'),
                    icon: SoldChartIcon,
                    href: '/extra/highcharts/sales-time',
                },
                {
                    title: t('Navbar.StatusReport'),
                    icon: StatusIcon,
                    href: '/extra/highcharts/status-report',
                },
                {
                    title: t('Navbar.ModelsByLeads'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/modelsTable',
                },
                {
                    title: t('Navbar.SourcesByLeads'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/sourcesTable',
                },
                {
                    title: t('Navbar.LeadsAgentsSources'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/agentsSourcesTable',
                },
                {
                    title: t('Navbar.ModelsByAgents'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/agentsTable',
                },
                {
                    title: t('Navbar.Recordings'),
                    icon: RecordingIcon,
                    href: '/extra/highcharts/recordings-reviews',
                },
                {
                    title: t('Navbar.Lbm'),
                    icon: VehicleIcon,
                    href: '/extra/highcharts/models',
                },
                {
                    title: t('Charts.UnitComparative'),
                    icon: VehicleIcon,
                    href: '/extra/highcharts/unit-comparative',
                },
                // {
                //     title: t('Navbar.Conversations'),
                //     icon: WhatsappIcon,
                //     href: '/extra/highcharts/conversations',
                // },
            ],
        },
        {
            subheader: t('Navbar.Control'),
            icon: ControlIcon,
            href:
                loc.pathname === '/management/leads' ||
                loc.pathname === '/management/showroom-leads'
                    ? '/none'
                    : '/management',

            items: [
                {
                    title: t('Navbar.Stores'),
                    href: '/management/stores',
                    icon: StoreIcon,
                },
                {
                    title: t('Navbar.Sources'),
                    href: '/management/sources',
                    icon: SourceIcon,
                },
                {
                    title: t('Navbar.Vehicles'),
                    href: '/management/vehicles',
                    icon: VehicleIcon,
                },
                {
                    title: t('Navbar.Users'),
                    href: '/management/users',
                    icon: UserIcon,
                },
                {
                    title: t('Navbar.Documents'),
                    href: '/management/documents',
                    icon: DocumentIcon,
                },
                {
                    title: t('Navbar.Alarms'),
                    href: '/management/alarms',
                    icon: HourIcon,
                },
                {
                    title: t('Navbar.Recordings'),
                    href: '/management/storesRecordings',
                    icon: RecordingIcon,
                },
                {
                    title: t('RecordingTests.RecordingTests'),
                    href: '/management/recording-tests',
                    icon: TestIcon,
                },
            ],
        },
    ];

    const GENERAL_MANAGER_MENU = [
        {
            subheader: t('Navbar.Dashboard'),
            items: [
                {
                    title: t('Navbar.Principal'),
                    icon: PieChartIcon,
                    href: '/reports/dashboard',
                },

                {
                    title: t('Navbar.Financing'),
                    icon: PieChartIcon,
                    href: '/reports/sales',
                },
            ],
        },
        {
            subheader: t('Chat.Title'),
            items: [
                {
                    title: t('Chat.Title'),
                    icon: WhatsappIcon,
                    href: '/conversations',
                },
                {
                    title: t('Documents.Catalogue'),
                    icon: TicketIcon,
                    href: '/management/catalogue',
                },
                {
                    title: 'Bot IA',
                    icon: WhatsappIcon,
                    href: '/management/chatbot',
                },
            ],
        },
        {
            subheader: t('Navbar.Main'),
            items: [
                {
                    title: t('Navbar.Leads'),
                    icon: DatabaseIcon,
                    href: '/management/leads',
                },
                {
                    title: t('Navbar.Recordings'),
                    icon: RecordingIcon,
                    href: '/management/recordings',
                },
                {
                    title: t('Navbar.Closures'),
                    href: '/management/closures',
                    icon: ClosureIcon,
                },
                {
                    title: t('Navbar.CalendarTasks'),
                    icon: TaskIcon,
                    href: '/tasks',
                },
                {
                    title: t('Navbar.Tasks'),
                    icon: TaskIcon,
                    href: '/management/tasks',
                },
                {
                    title: t('Navbar.Appointments'),
                    icon: CalendarIcon,
                    href: '/calendar',
                },
            ],
        },

        {
            subheader: t('Navbar.DrillDown'),
            items: [
                {
                    title: t('Charts.Captacion'),
                    icon: VsIcon,
                    href: '/extra/highcharts/captacion-medios',
                },
                {
                    title: t('Navbar.ModelSource'),
                    icon: SoldChartIcon,
                    href: '/drilldown/modelsource',
                },

                {
                    title: t('Drilldowns.Models'),
                    icon: VehicleIcon,
                    href: '/drilldown/models',
                },
                {
                    title: t('Drilldowns.Sources'),
                    icon: SourceIcon,
                    href: '/drilldown/sources',
                },
                {
                    title: t('Navbar.Visits'),
                    icon: Home,
                    href: '/drilldown/visits',
                },
                {
                    title: t('Navbar.Calls'),
                    icon: CallIcon,
                    href: '/drilldown/calls',
                },
                {
                    title: t('Navbar.Recordings'),
                    icon: RecordingIcon,
                    href: '/drilldown/recordings',
                },
            ],
        },
        {
            subheader: t('Navbar.ChartsH'),
            href: '/extra/highcharts',
            icon: BarChartIcon,
            items: [
                {
                    title: t('Charts.DaysToSale'),
                    icon: SoldChartIcon,
                    href: '/extra/highcharts/sales-time',
                },
                {
                    title: t('Navbar.StatusReport'),
                    icon: StatusIcon,
                    href: '/extra/highcharts/status-report',
                },
                {
                    title: t('Navbar.ModelsByLeads'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/modelsTable',
                },
                {
                    title: t('Navbar.SourcesByLeads'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/sourcesTable',
                },
                {
                    title: t('Navbar.LeadsAgentsSources'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/agentsSourcesTable',
                },
                {
                    title: t('Navbar.ModelsByAgents'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/agentsTable',
                },
                {
                    title: t('Navbar.Recordings'),
                    icon: RecordingIcon,
                    href: '/extra/highcharts/recordings-reviews',
                },
                {
                    title: t('Navbar.Lbm'),
                    icon: VehicleIcon,
                    href: '/extra/highcharts/models',
                },
                {
                    title: t('Charts.UnitComparative'),
                    icon: VehicleIcon,
                    href: '/extra/highcharts/unit-comparative',
                },
            ],
        },
        {
            subheader: t('Navbar.Control'),
            icon: ControlIcon,
            href:
                loc.pathname === '/management/leads' ||
                loc.pathname === '/management/showroom-leads'
                    ? '/none'
                    : '/management',

            items: [
                {
                    title: t('Navbar.Stores'),
                    href: '/management/stores',
                    icon: StoreIcon,
                },
                {
                    title: t('Navbar.Sources'),
                    href: '/management/sources',
                    icon: SourceIcon,
                },
                {
                    title: t('Navbar.Vehicles'),
                    href: '/management/vehicles',
                    icon: VehicleIcon,
                },
                {
                    title: t('Navbar.Users'),
                    href: '/management/users',
                    icon: UserIcon,
                },
                {
                    title: t('Navbar.Documents'),
                    href: '/management/documents',
                    icon: DocumentIcon,
                },
                {
                    title: t('Navbar.Alarms'),
                    href: '/management/alarms',
                    icon: HourIcon,
                },
                {
                    title: t('Navbar.Recordings'),
                    href: '/management/storesRecordings',
                    icon: RecordingIcon,
                },
                {
                    title: t('RecordingTests.RecordingTests'),
                    href: '/management/recording-tests',
                    icon: TestIcon,
                },
            ],
        },
    ];

    const GENERAL_SALES_MENU = [
        {
            subheader: t('Navbar.Dashboard'),
            items: [
                {
                    title: t('Navbar.Principal'),
                    icon: PieChartIcon,
                    href: '/reports/dashboard',
                },

                {
                    title: t('Navbar.Financing'),
                    icon: PieChartIcon,
                    href: '/reports/sales',
                },
            ],
        },
        {
            subheader: t('Navbar.Main'),
            items: [
                {
                    title: t('Navbar.Leads'),
                    icon: DatabaseIcon,
                    href: '/management/leads',
                },
            ],
        },
        {
            subheader: t('Navbar.Control'),
            icon: ControlIcon,
            href: '/management',
            items: [
                {
                    title: t('Navbar.Recordings'),
                    href: '/management/storesRecordings',
                    icon: RecordingIcon,
                },
                {
                    title: t('Navbar.Alarms'),
                    href: '/management/alarms',
                    icon: HourIcon,
                },
                {
                    title: t('Navbar.Documents'),
                    href: '/management/documents',
                    icon: DocumentIcon,
                },
            ],
        },
    ];

    const ADMIN_MENU = [
        {
            subheader: t('Navbar.Dashboard'),
            items: [
                {
                    title: t('Navbar.Principal'),
                    icon: PieChartIcon,
                    href: '/reports/dashboard',
                },
            ],
        },
        {
            subheader: t('Chat.Title'),
            items: [
                {
                    title: t('Chat.Title'),
                    icon: WhatsappIcon,
                    href: '/conversations',
                },
                {
                    title: t('Documents.Catalogue'),
                    icon: TicketIcon,
                    href: '/management/catalogue',
                },
                {
                    title: 'Bot IA',
                    icon: WhatsappIcon,
                    href: '/management/chatbot',
                },
            ],
        },
        {
            subheader: t('Navbar.Main'),
            items: [
                {
                    title: t('Navbar.Leads'),
                    icon: DatabaseIcon,
                    href: '/management/leads',
                },
                {
                    title: t('Navbar.Recordings'),
                    icon: RecordingIcon,
                    href: '/management/recordings',
                },
                {
                    title: t('Navbar.CalendarTasks'),
                    icon: TaskIcon,
                    href: '/tasks',
                },
                {
                    title: t('Navbar.Appointments'),
                    icon: CalendarIcon,
                    href: '/calendar',
                },
            ],
        },

        {
            subheader: t('Navbar.DrillDown'),
            items: [
                {
                    title: t('Charts.Captacion'),
                    icon: VsIcon,
                    href: '/extra/highcharts/captacion-medios',
                },
                {
                    title: t('Navbar.ModelSource'),
                    icon: SoldChartIcon,
                    href: '/drilldown/modelsource',
                },

                {
                    title: t('Drilldowns.Models'),
                    icon: VehicleIcon,
                    href: '/drilldown/models',
                },
                {
                    title: t('Drilldowns.Sources'),
                    icon: SourceIcon,
                    href: '/drilldown/sources',
                },
                {
                    title: t('Navbar.Visits'),
                    icon: Home,
                    href: '/drilldown/visits',
                },
                {
                    title: t('Navbar.Calls'),
                    icon: CallIcon,
                    href: '/drilldown/calls',
                },
                {
                    title: t('Navbar.Recordings'),
                    icon: RecordingIcon,
                    href: '/drilldown/recordings',
                },
            ],
        },
        {
            subheader: t('Navbar.ChartsH'),
            href: '/extra/highcharts',
            icon: BarChartIcon,
            items: [
                {
                    title: t('Charts.DaysToSale'),
                    icon: SoldChartIcon,
                    href: '/extra/highcharts/sales-time',
                },
                {
                    title: t('Navbar.StatusReport'),
                    icon: StatusIcon,
                    href: '/extra/highcharts/status-report',
                },
                {
                    title: t('Navbar.ModelsByLeads'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/modelsTable',
                },
                {
                    title: t('Navbar.SourcesByLeads'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/sourcesTable',
                },
                {
                    title: t('Navbar.LeadsAgentsSources'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/agentsSourcesTable',
                },
                {
                    title: t('Navbar.ModelsByAgents'),
                    icon: TemperaturesIcon,
                    href: '/extra/highcharts/agentsTable',
                },
                {
                    title: t('Navbar.Recordings'),
                    icon: RecordingIcon,
                    href: '/extra/highcharts/recordings-reviews',
                },
                {
                    title: t('Navbar.Lbm'),
                    icon: VehicleIcon,
                    href: '/extra/highcharts/models',
                },
                {
                    title: t('Charts.UnitComparative'),
                    icon: VehicleIcon,
                    href: '/extra/highcharts/unit-comparative',
                },
                // {
                //     title: t('Navbar.Conversations'),
                //     icon: WhatsappIcon,
                //     href: '/extra/highcharts/conversations',
                // },
            ],
        },
        {
            subheader: t('Navbar.Control'),
            icon: ControlIcon,
            href:
                loc.pathname === '/management/leads' ||
                loc.pathname === '/management/showroom-leads'
                    ? '/none'
                    : '/management',

            items: [
                {
                    title: t('Navbar.Users'),
                    href: '/management/users',
                    icon: UserIcon,
                },
                {
                    title: t('Navbar.Documents'),
                    href: '/management/documents',
                    icon: DocumentIcon,
                },
            ],
        },
    ];

    const USER_MENU = [
        {
            subheader: t('Navbar.Dashboard'),
            items: [
                {
                    title: t('Navbar.Principal'),
                    icon: PieChartIcon,
                    href: '/reports/dashboard',
                },
            ],
        },
        {
            subheader: t('Chat.Title'),
            items: [
                {
                    title: t('Chat.Title'),
                    icon: WhatsappIcon,
                    href: '/conversations',
                },
            ],
        },
        {
            subheader: t('Navbar.Main'),
            items: [
                {
                    title: t('Navbar.Leads'),
                    icon: DatabaseIcon,
                    href: '/management/leads',
                },
                {
                    title: t('Navbar.CalendarTasks'),
                    icon: TaskIcon,
                    href: '/tasks',
                },
                {
                    title: t('Navbar.Appointments'),
                    icon: CalendarIcon,
                    href: '/calendar',
                },
            ],
        },
    ];

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const content = (
        <Box height="100%" display="flex" flexDirection="column">
            <ReminderModal
                open={open}
                setOpen={setOpen}
                reminder={currentReminder}
                disableSnack={handleDisableSnack}
            />
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <Hidden lgUp>
                    <Box p={2} display="flex" justifyContent="center">
                        <RouterLink to="/">
                            <Logo />
                        </RouterLink>
                    </Box>
                </Hidden>
                <Box p={2}>
                    <Box display="flex" justifyContent="center">
                        <RouterLink to="/account">
                            <Avatar
                                alt="User"
                                className={classes.avatar}
                                src={
                                    user && user.image
                                        ? `https://automotive-api.s3.us-east-2.amazonaws.com/${user.image}`
                                        : ''
                                }
                            />
                        </RouterLink>
                    </Box>
                    <Box mt={2} textAlign="center">
                        <Link
                            component={RouterLink}
                            to="/account"
                            variant="h5"
                            color="textPrimary"
                            underline="none"
                        >
                            {user && user.name
                                ? CapitalizeNames(user.name)
                                : 'None'}
                        </Link>
                        {user &&
                            user.averageRating !== undefined &&
                            user.tier &&
                            !isRockstar(user.tier._id) &&
                            !isSuper(user.tier._id) &&
                            !isRH(user.tier._id) && (
                                <Box>
                                    <Rating
                                        value={user.averageRating}
                                        precision={0.5}
                                        readOnly
                                        icon={
                                            <StarIcon
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                }}
                                            />
                                        }
                                        emptyIcon={
                                            <StarOutlineIcon
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                }}
                                            />
                                        }
                                    />
                                </Box>
                            )}
                        {user &&
                            user._id &&
                            user._id === '5d7a514b5d2c12c7449be042' && (
                                <Box>
                                    <Rating
                                        value={5}
                                        precision={0.5}
                                        readOnly
                                        icon={
                                            <StarIcon
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                }}
                                            />
                                        }
                                        emptyIcon={
                                            <StarOutlineIcon
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                }}
                                            />
                                        }
                                    />
                                </Box>
                            )}
                        <Typography variant="body2" color="textSecondary">
                            <span
                                className={classes.primaryColor}
                                variant="body2"
                            >
                                {user && user.tier
                                    ? ' ' + CapitalizeNames(user.tier.name)
                                    : ''}
                            </span>
                        </Typography>
                        {user &&
                            user.tier &&
                            !isRockstar(user.tier._id) &&
                            !isSuper(user.tier._id) &&
                            !isRH(user.tier._id) && (
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {t('Account.CarType')}:{' '}
                                    <span
                                        className={classes.primaryColor}
                                        variant="body2"
                                    >
                                        {user && user.carType
                                            ? ' ' +
                                              t(`LeadsBDC.${user.carType}`)
                                            : ''}
                                    </span>
                                </Typography>
                            )}
                        {user &&
                        user.tier &&
                        (isRockstar(user.tier._id) ||
                            isSuper(user.tier._id) ||
                            isRH(user.tier._id)) ? (
                            <Typography
                                pt={4}
                                variant="body2"
                                style={{ textTransform: 'capitalize' }}
                            >
                                Global
                            </Typography>
                        ) : user && user.tier && isDigitalMkt(user.tier._id) ? (
                            <Typography
                                pt={4}
                                variant="body2"
                                style={{ textTransform: 'capitalize' }}
                            >
                                {CapitalizeNames(user.tier.name)}
                            </Typography>
                        ) : user && user.stores && user.stores.length > 1 ? (
                            <Typography
                                pt={4}
                                variant="body2"
                                style={{ textTransform: 'capitalize' }}
                            >
                                {t('Titles.MultiStores')}
                            </Typography>
                        ) : (
                            user &&
                            user.stores &&
                            user.stores[0] && (
                                <Typography
                                    pt={4}
                                    variant="body2"
                                    style={{ textTransform: 'capitalize' }}
                                >
                                    {CapitalizeNames(user.stores[0].name)}
                                </Typography>
                            )
                        )}
                    </Box>
                    {user &&
                        user.birthday &&
                        moment(user.birthday).format('DD MMMM') ===
                            moment().format('DD MMMM') && (
                            <Box ml={2} mt={4}>
                                <center>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        {t('Users.Birthday')}
                                    </Typography>
                                </center>
                            </Box>
                        )}
                    {moment().format('DD MM') === '02 11' && (
                        <Box ml={2} mt={4}>
                            <center>
                                †
                                <br />
                                ꧁༺ 🌺 💀 🌹 ༻꧂
                                <br />
                                _____
                                <br />
                                / ../ ....+ ....\
                                <br />
                                | ..| ....RIP ...|
                                <br />
                                | ..| ............. |<br />
                                | ..| ..............|
                                <br />
                                \ .| ..|.. ./\/\//\ .|/
                                <br />
                                <br />
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {t('Messages.DeadsDay')}
                                </Typography>
                            </center>
                        </Box>
                    )}
                    {moment().format('DD MM') === '31 10' && (
                        <Box ml={2} mt={4}>
                            <center>
                                {/*eslint-disable-next-line*/}
                                <sub>🎃</sub>
                                {/*eslint-disable-next-line*/}
                                <sup>🎃</sup>
                                {/*eslint-disable-next-line*/}
                                <sub>🎃</sub>
                                <br />
                                ꧁༒ 👻 ༒꧂
                                <br />
                                ~ 🍬 🍭 🍫 ~
                                <br />
                                <br />
                                {/*eslint-disable-next-line*/}
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    🐈‍⬛ {t('Messages.Halloween')}
                                </Typography>
                            </center>
                        </Box>
                    )}
                    {moment('2021-10-31T00:00:00-00:00').diff(
                        moment(),
                        'days'
                    ) > 0 && (
                        <Box ml={2} mt={4}>
                            <center>
                                <Typography
                                    variant="h6"
                                    color="textPrimary"
                                    style={{
                                        color:
                                            user &&
                                            user.birthday &&
                                            moment(user.birthday).format(
                                                'DD MMMM'
                                            ) === moment().format('DD MMMM')
                                                ? 'black'
                                                : moment().format('DD MM') ===
                                                    '29 10'
                                                  ? 'white'
                                                  : 'white',
                                    }}
                                >
                                    {localStorage.getItem('language') &&
                                    localStorage.getItem('language') === 'es'
                                        ? `Faltan ${
                                              moment(
                                                  '2021-10-31T00:00:00-00:00'
                                              ).diff(moment(), 'days') + 1
                                          } días para halloween 🎃`
                                        : `${
                                                moment(
                                                    '2021-10-31T00:00:00-00:00'
                                                ).diff(moment(), 'days') + 1
                                            } days until halloween 🎃` &&
                                            localStorage.getItem('language') ===
                                                'en'
                                          ? `${
                                                moment(
                                                    '2021-10-31T00:00:00-00:00'
                                                ).diff(moment(), 'days') + 1
                                            } days until halloween 🎃`
                                          : ''}
                                </Typography>
                            </center>
                        </Box>
                    )}
                    {moment('2021-12-26T00:00:00-00:00').diff(
                        moment(),
                        'days'
                    ) > 0 && (
                        <Box ml={2} mt={4}>
                            <center>
                                <Typography variant="h6" color="textPrimary">
                                    {localStorage.getItem('language') &&
                                    localStorage.getItem('language') === 'es'
                                        ? `Faltan ${moment(
                                              '2021-12-26T00:00:00-00:00'
                                          ).diff(
                                              moment(),
                                              'days'
                                          )} días para navidad 🎅🏼`
                                        : `${moment(
                                                '2021-12-26T00:00:00-00:00'
                                            ).diff(
                                                moment(),
                                                'days'
                                            )} days until christmas 🎅🏼` &&
                                            localStorage.getItem('language') ===
                                                'en'
                                          ? `${moment(
                                                '2021-12-26T00:00:00-00:00'
                                            ).diff(
                                                moment(),
                                                'days'
                                            )} days until christmas 🎅🏼`
                                          : ''}
                                </Typography>
                            </center>
                        </Box>
                    )}
                    <Box display="flex" justifyContent="flex-end">
                        {user &&
                            user.tier &&
                            (isRockstar(user.tier._id) ||
                                isSuper(user.tier._id)) && (
                                <IconButton
                                    className={classes.iconMenu}
                                    onClick={() => history.push('/custom-menu')}
                                    style={{ marginRight: 5 }}
                                >
                                    <Edit style={{ fontSize: 10 }} />
                                </IconButton>
                            )}
                        <IconButton
                            className={classes.iconFavorite}
                            onClick={() => setFavoriteActive((e) => !e)}
                        >
                            {isFavoriteActive ? (
                                <StarIcon
                                    style={{ width: '20px', height: '20px' }}
                                />
                            ) : (
                                <StarOutlineIcon
                                    style={{ width: '20px', height: '20px' }}
                                />
                            )}
                        </IconButton>
                    </Box>
                </Box>
                <Divider />

                {user && user.tier && handleRenderMenu()}
            </PerfectScrollbar>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.desktopDrawer }}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
};

export default NavBar;
