import { baseURL } from './api';
import axios, { AxiosRequestConfig, Method } from 'axios';
import { AWS_S3 } from 'src/constants/crm/aws';
import { channel } from 'src/service/pusher';

const handleLogout = async () => {
    localStorage.clear();
    channel.unbindAll();
};

class ApiService {
    headers = {};
    constructor() {
        if (typeof window !== 'undefined' && window.localStorage) {
            const token = localStorage.getItem('token');
            if (token) {
                this.headers = {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                };
            }
        }
    }

    async request(
        method: Method | undefined,
        url: string,
        data: any = null,
        config: AxiosRequestConfig = {}
    ) {
        try {
            let urlRequest = `${baseURL}${url}`;
            if (url.includes(AWS_S3)) urlRequest = url;

            let axiosBody = {
                method,
                url: `${baseURL}${url}`,
                headers: { ...this.headers, ...config.headers },
                ...config,
            };

            if (method !== 'delete') axiosBody.data = data;

            const response = await axios(axiosBody);

            return response;
        } catch (error) {
            // @ts-ignore
            if (error?.response?.status === 401) {
                await handleLogout();
            }
            throw error;
        }
    }

    post = (url: string, data: any, config: AxiosRequestConfig = {}) =>
        this.request('post', url, data, config);
    get = (url: string, config: AxiosRequestConfig = {}) =>
        this.request('get', url, null, config);
    put = (url: string, data: any, config: AxiosRequestConfig = {}) =>
        this.request('put', url, data, config);
    delete = (url: string, config: AxiosRequestConfig = {}) =>
        this.request('delete', url, null, config);
}

export default new ApiService();
