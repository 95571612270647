import {
    GET_ADCAMPAIGNS,
    CREATE_ADCAMPAIGN,
    GET_ADCAMPAIGN,
    DELETE_ADCAMPAIGN,
    UPDATE_ADCAMPAIGN,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';

export default (state, action) => {
    switch (action.type) {
        case GET_ADCAMPAIGNS:
            return {
                ...state,
                adCampaigns: action.payload,
                loading: false,
                count: action.count,
                error: null,
            };
        case GET_ADCAMPAIGN:
            return {
                ...state,
                adCampaign: action.payload,
                loading: false,
                error: null,
            };
        case CREATE_ADCAMPAIGN:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case DELETE_ADCAMPAIGN:
            if (state.adCampaigns) {
                state.adCampaigns = state.adCampaigns.filter(
                    (adCampaign) =>
                        adCampaign._id.toString() !== action.payload.toString()
                );
            }
            return {
                ...state,
                adCampaign: null,
                loading: false,
                error: null,
            };
        case UPDATE_ADCAMPAIGN:
            return {
                ...state,
                adCampaign: action.payload,
                loading: false,
                error: null,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CLEAR_STATE:
            return {
                loading: false,
                error: null,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
};
