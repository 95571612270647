import React, { useReducer } from 'react';
import LeadServiceContext from './LeadService';
import LeadServiceReducer from './LeadServiceReducer';
import api from '../../api/api';
import {
    GET_LEADSSERVICE,
    GET_LEADSERVICE,
    UPDATE_LEADSERVICE,
    DELETE_LEADSERVICE,
    CREATE_LEADSERVICE,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    GET_LEADSSERVICE_BY_STORE,
    GET_LEADSSERVICE_BY_USER,
    CALL_USER,
    ASSIGN_AGENTS,
    DELETE_COMMENT,
    ADD_APPOINTMENT,
    ADD_COMMENT,
} from '../types';
import { HEADERS } from '../../constants/headers';

const LeadServiceState = (props) => {
    const initialState = {
        leadsService: [],
        leadService: {},
        chartDash: [],
        loading: false,
        error: null,
        value: [],
        count: null,
        callToken: null,
    };

    const [state, dispatch] = useReducer(LeadServiceReducer, initialState);

    //Delete leadService from state
    const deleteCommentLeadService = async (commentId) =>
        dispatch({ type: DELETE_COMMENT, payload: commentId });

    //Get LeadsService
    const getLeadsService = async (pagination, query, typeQuery) => {
        setLoading();
        try {
            let res;
            if (!query) query = '';
            if (pagination) {
                res = await api.get(
                    `/leadsService?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-phone-agent-descModel-make-store&searchText=${query}&searchType=${typeQuery}`,
                    HEADERS()
                );
            }
            dispatch({
                type: GET_LEADSSERVICE,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    // get by filter with generalAggregationV2
    const getLeadsServiceV2 = async (data) => {
        setLoading();
        try {
            const res = await api.post(
                `/leadsService/AggregationV2/`,
                data,
                HEADERS()
            );
            dispatch({
                type: GET_LEADSSERVICE,
                payload: res.data.results.data,
                count: res.data.results.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get LeadsService
    const startCron = async (status) => {
        try {
            await api.post(
                `/leadsService/sendAlerts`,
                { start: status },
                HEADERS()
            );
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get LeadsService
    const getLeadsServiceByStore = async (
        storequery,
        pagination,
        tabs,
        query,
        typeQuery,
        multiStore
    ) => {
        setLoading();
        try {
            let res;
            if (!query) query = '';

            if (pagination) {
                if (tabs.includes('temperature')) {
                    let temp = tabs.split('.');
                    res = await api.get(
                        `/leadsService?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&rating=${temp[1]}&searchText=${query}${storequery}&searchType=${typeQuery}&validation=1${multiStore}`,
                        HEADERS()
                    );
                } else if (tabs.includes('status')) {
                    let status = tabs.split('.');
                    res = await api.get(
                        `/leadsService?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&status=${status[1]}&searchText=${query}${storequery}&searchType=${typeQuery}&validation=1${multiStore}`,
                        HEADERS()
                    );
                } else if (tabs.includes('subStatus')) {
                    let substatus = tabs.split('.');
                    res = await api.get(
                        `/leadsService?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&substatus=${substatus[1]}&searchText=${query}${storequery}&searchType=${typeQuery}&validation=1${multiStore}`,
                        HEADERS()
                    );
                } else if (tabs.includes('contacted')) {
                    let contacted = tabs.split('.');
                    res = await api.get(
                        `/leadsService?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&isContacted=${contacted[1]}&searchText=${query}${storequery}&searchType=${typeQuery}&validation=1${multiStore}`,
                        HEADERS()
                    );
                } else if (tabs.includes('assigned')) {
                    let assigned = tabs.split('.');
                    res = await api.get(
                        `/leadsService?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&assigned=${assigned[1]}&searchText=${query}${storequery}&searchType=${typeQuery}&validation=1${multiStore}`,
                        HEADERS()
                    );
                } else {
                    res = await api.get(
                        `/leadsService?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&searchText=${query}${storequery}&searchType=${typeQuery}&validation=1${multiStore}`,
                        HEADERS()
                    );
                }
            }

            dispatch({
                type: GET_LEADSSERVICE_BY_STORE,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get LeadsService by User
    const getLeadsServiceByUser = async (
        userId,
        pagination,
        tabs,
        query,
        typeQuery
    ) => {
        setLoading();
        try {
            let res;
            if (!query) query = '';

            if (pagination) {
                if (tabs.includes('temperature')) {
                    let temp = tabs.split('.');
                    res = await api.get(
                        `/leadsService?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&rating=${temp[1]}&searchText=${query}&agent=${userId}&searchType=${typeQuery}&validation=1`,
                        HEADERS()
                    );
                } else if (tabs.includes('status')) {
                    let status = tabs.split('.');
                    res = await api.get(
                        `/leadsService?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&status=${status[1]}&searchText=${query}&agent=${userId}&searchType=${typeQuery}&validation=1`,
                        HEADERS()
                    );
                } else if (tabs.includes('subStatus')) {
                    let substatus = tabs.split('.');
                    res = await api.get(
                        `/leadsService?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&substatus=${substatus[1]}&searchText=${query}&agent=${userId}&searchType=${typeQuery}&validation=1`,
                        HEADERS()
                    );
                } else if (tabs.includes('contacted')) {
                    let contacted = tabs.split('.');
                    res = await api.get(
                        `/leadsService?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&isContacted=${contacted[1]}&searchText=${query}&agent=${userId}&searchType=${typeQuery}&validation=1`,
                        HEADERS()
                    );
                } else if (tabs.includes('assigned')) {
                    let assigned = tabs.split('.');
                    res = await api.get(
                        `/leadsService?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&assigned=${assigned[1]}&searchText=${query}&agent=${userId}&searchType=${typeQuery}&validation=1`,
                        HEADERS()
                    );
                } else {
                    res = await api.get(
                        `/leadsService?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&searchText=${query}&agent=${userId}&searchType=${typeQuery}&validation=1`,
                        HEADERS()
                    );
                }
            }

            dispatch({
                type: GET_LEADSSERVICE_BY_USER,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Single Item by ID
    const getLeadService = async (leadServiceId) => {
        setLoading();
        try {
            const res = await api.get(
                `/leadsService/${leadServiceId}`,
                HEADERS()
            );
            dispatch({
                type: GET_LEADSERVICE,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Update LeadService
    const updateLeadService = async (leadService, leadServiceId) => {
        setLoading();
        try {
            const res = await api.put(
                `/leadsService/${leadServiceId}`,
                { ...leadService },
                HEADERS()
            );
            dispatch({ type: UPDATE_LEADSERVICE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Delete LeadService
    const deleteLeadService = async (leadServiceId) => {
        setLoading();
        try {
            const res = await api.delete(
                `/leadsService/${leadServiceId}`,
                HEADERS()
            );
            dispatch({ type: DELETE_LEADSERVICE, payload: res.data.deletedId });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Create LeadService
    const createLeadService = async (leadService) => {
        clearState();
        setLoading();
        try {
            const res = await api.post(
                `/leadsService`,
                { ...leadService },
                HEADERS()
            );
            dispatch({ type: CREATE_LEADSERVICE, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const assignAgents = async (leadsService, agent, currentTab) => {
        setLoading();
        try {
            const res = await api.post(
                `/leadsService/assignAgent`,
                { leadsService, agent },
                HEADERS()
            );
            dispatch({
                type: ASSIGN_AGENTS,
                payload: res.data.data,
                tab: currentTab,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const AdvancedResults = async (pagination, query) => {
        setLoading();
        try {
            const res = await api.get(
                `/leadsService?page=${pagination.page}&limit=${pagination.limit}${query}&searchType=and`,
                HEADERS()
            );
            dispatch({
                type: GET_LEADSSERVICE,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const generateToken = async (data) => {
        try {
            const res = await api.post('/calls/generate', { data }, HEADERS());
            localStorage.setItem('callToken', res.data.token);
            dispatch({ type: CALL_USER, payload: res.data.token });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err });
        }
    };

    const makeCall = async (phoneNumber) => {
        await api.post('/calls/connect', phoneNumber, HEADERS());
    };

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    const addAppointment = (appointment) =>
        dispatch({ type: ADD_APPOINTMENT, payload: appointment });

    const addComment = (comment) => {
        if (comment && comment.action && comment.action.includes('sold'))
            return;
        dispatch({ type: ADD_COMMENT, payload: comment });
    };

    return (
        <LeadServiceContext.Provider
            value={{
                loading: state.loading,
                leadsService: state.leadsService,
                leadService: state.leadService,
                error: state.error,
                newS: state.newS,
                sold: state.sold,
                follow: state.follow,
                date: state.date,
                lastLeadsService: state.lastLeadsService,
                analytics: state.analytics,
                labels: state.labels,
                value: state.value,
                count: state.count,
                chart: state.chart,
                callToken: state.callToken,
                addAppointment,
                startCron,
                generateToken,
                getLeadsService,
                getLeadsServiceV2,
                getLeadService,
                updateLeadService,
                deleteLeadService,
                createLeadService,
                clearState,
                setLoading,
                getLeadsServiceByStore,
                getLeadsServiceByUser,
                makeCall,
                assignAgents,
                AdvancedResults,
                deleteCommentLeadService,
                addComment,
            }}
        >
            {props.children}
        </LeadServiceContext.Provider>
    );
};

export default LeadServiceState;
