import React, { useEffect } from 'react';
import { IRoleAccess, TRoleAccess } from './types';
import useAuth from 'src/hooks/useAuth';
import { useHistory } from 'react-router';

export const AccesLevelGuard = ({ children, roles }: IRoleAccess) => {
    const { user } = useAuth();
    const history = useHistory();

    const hasRoleAccess = ({ roles }: TRoleAccess) => {
        return roles.some(
            (accessLevel) => user?.accessLevel?.includes(accessLevel)
        );
    };

    useEffect(() => {
        if (user && user._id) {
            if (!hasRoleAccess({ roles })) history.push('/');
        }
        //eslint-disable-next-line
    }, [user]);

    return <>{children}</>;
};
