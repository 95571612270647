import {
    GET_RECORDING_TESTS,
    CREATE_RECORDING_TEST,
    GET_RECORDING_TEST,
    DELETE_RECORDING_TEST,
    UPDATE_RECORDING_TEST,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';

export default (state, action) => {
    switch (action.type) {
        case GET_RECORDING_TESTS:
            return {
                ...state,
                recordingTests: action.payload,
                count: action.count,
                loading: false,
                error: null,
            };
        case GET_RECORDING_TEST:
            return {
                ...state,
                recordingTest: action.payload,
                loading: false,
                error: null,
            };
        case CREATE_RECORDING_TEST:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case DELETE_RECORDING_TEST:
            state.recordingTests = state.recordingTests.filter(
                (recordingTest) =>
                    recordingTest._id.toString() !== action.payload.toString()
            );
            return {
                ...state,
                recordingTest: null,
                loading: false,
                error: null,
            };
        case UPDATE_RECORDING_TEST:
            return {
                ...state,
                recordingTest: action.payload,
                loading: false,
                error: null,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CLEAR_STATE:
            return {
                recordingTests: [],
                recordingTest: {},
                loading: false,
                error: null,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
};
