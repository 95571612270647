import { THEMES } from 'src/constants';

export default {
    siteName: 'unitedauto',
    senderName: 'Warbox - United Group',
    logo: '/static/warbox_logo.png',
    name: 'Warbox',
    theme: THEMES.LIGHT_WARBOX,
    client: 'unitedgroup',
    themes: [
        { theme: 'WARBOX_LIGHT', name: 'LIGHT' },
        { theme: 'WARBOX_ONE_DARK', name: 'DARK' },
    ],
};
