import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        path: '/create/vehicle',
        guard: RolesGuard,
        roles: ['rockstar'],
        component: lazy(() => import('src/views/vehicle/VehicleCreateView')),
    },
    {
        exact: true,
        path: '/management/vehicles/:id',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'digital marketing',
            'general manager',
        ],
        component: lazy(() => import('src/views/vehicle/VehicleDetailsView')),
    },
    {
        exact: true,
        path: '/management/vehicles/:id/edit',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(() => import('src/views/vehicle/VehicleEditView')),
    },
    {
        exact: true,
        path: '/management/vehicles',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'digital marketing',
            'general manager',
        ],
        component: lazy(() => import('src/views/vehicle/VehicleListView')),
    },
];
