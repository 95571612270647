import {
    GET_INVESTMENTS,
    CREATE_INVESTMENT,
    GET_INVESTMENT,
    DELETE_INVESTMENT,
    UPDATE_INVESTMENT,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    GET_INVESTMENTS_BY_STORE,
} from '../types';

export default (state, action) => {
    switch (action.type) {
        case GET_INVESTMENTS_BY_STORE:
        case GET_INVESTMENTS:
            return {
                ...state,
                investments: action.payload,
                count: action.count,
                loading: false,
                error: null,
            };
        case GET_INVESTMENT:
            return {
                ...state,
                investment: action.payload,
                loading: false,
                error: null,
            };
        case CREATE_INVESTMENT:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case DELETE_INVESTMENT:
            state.investments = state.investments.filter(
                (investment) =>
                    investment._id.toString() !== action.payload.toString()
            );
            return {
                ...state,
                investment: null,
                loading: false,
                error: null,
            };
        case UPDATE_INVESTMENT:
            return {
                ...state,
                investment: action.payload,
                loading: false,
                error: null,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CLEAR_STATE:
            return {
                investments: [],
                investment: {},
                loading: false,
                error: null,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
};
